import { atomWithReset, atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { IRegistrationTokenResponse, ISimpleUserProfileResponse, IUserResponse } from 'services'

export const registrationTokenDataAtom = atomWithStorage<IRegistrationTokenResponse[] | null>('registrationToken', null)

export const atomOrderBy = atom<string>('asc')
export const atomPage = atom<number>(1)
export const atomSearchTerm = atom<string>('')
export const atomTeacherPropsData = atom<ISimpleUserProfileResponse | undefined>(undefined)
export const atomSelectedTeachers = atomWithReset<IUserResponse[]>([])
export const atomShowFilters = atomWithReset<boolean>(false)
