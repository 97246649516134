import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  containerWrapper: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
    padding: theme.spacingInset.sm,
    paddingBottom: '64px', // header height
    height: 'auto',
    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      paddingInline: theme.spacingInline.xxxs
    }
  },
  selectorContainer: {
    padding: `${theme.spacingInset.xxs} 0px ${theme.spacingInset.xxs} 0px`
  },
  externalLink: {
    color: theme.colors.neutral.dark10,
    fontWeight: theme.font.fontWeight.bold,
    cursor: 'pointer'
  },
  pdfDownloadButton: {
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`,
    fontSize: `${theme.font.fontSize.xs} !important`,
    height: '40px !important'
  },
  contentWrapper: {
    gap: theme.spacingStack.xxs,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacingInset.sm
    }
  },
  searchContainer: {
    gap: theme.spacingInline.nano,
    flexDirection: 'column-reverse !important' as 'column-reverse',
    marginBottom: theme.spacingInline.xxxs,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row',
      marginBottom: 0,
      gap: theme.spacingInline.xxxs
    }
  }
}))
