import { Card, Button, Text2, Grid } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import useStyles from './style'
import { createRef } from 'react'

interface ISpreadsheetDownloadProps {
  spreadSheetVersion: 'professores' | 'estudantes'
  dataTestid?: string
}

export const SpreadsheetDownload: React.FC<ISpreadsheetDownloadProps> = ({ spreadSheetVersion, dataTestid }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const downloadRef = createRef<HTMLAnchorElement>()

  const handleDownloadTemplateSpreadsheet = () => {
    downloadRef?.current?.click()
    const cdn = process.env.REACT_APP_CDN
    const version = spreadSheetVersion
    window.location.href = cdn && version
      ? `${cdn}/cloe_cadastro_de_${version}.xlsx`
      : '#'
  }
  return (
    <Grid container className={styles.cardContainer}>
      <a ref={downloadRef} data-testid={dataTestid} />
      <Card extraClasses={styles.card}>
        <Grid container className={styles.cardTitle}>
          <span><InsertDriveFileOutlinedIcon fontSize='large' sx={{ color: '#DADADA', marginRight: '4px' }} /></span>
          <Text2 fontSize='md' fontWeight='medium' lineHeight='xxl' mobile='sm' neutral='dark30'>{t('Planilha modelo')}</Text2>
        </Grid>
        <Grid container className={styles.cardBtn}>
          <Button variant='outlined' onClick={handleDownloadTemplateSpreadsheet}><GetAppOutlinedIcon />{t('Baixar')}</Button>
        </Grid>
      </Card>
    </Grid>
  )
}
