import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((_theme: CustomTheme) => createStyles({
  root: {
    width: 'auto',
    verticalAlign: 'middle',
    '&.size-xs': { height: '16px' },
    '&.size-sm': { height: '24px' },
    '&.size-md': { height: '40px' },
    '&.size-lg': { height: '56px' }
  }
}))
