import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  card: {
    '& .MuiCardContent-root': {
      background: 'rgba(230, 230, 230, 0.32)',
      padding: '12px 16px!important'
    }
  },
  checkbox: {
    '& .MuiTypography-root': {
      color: `${theme.colors.neutral.dark30}!important`,
      fontSize: `${theme.font.fontSize.sm}!important`,
      paddingLeft: theme.spacingInline.nano
    },
    '& .Mui-disabled': {
      color: `${theme.colors.neutral.light40}!important`
    }
  },
  closeIcon: {
    cursor: 'pointer',
    lineHeight: theme.font.lineHeight.xxs
  }
}))
