import { ProfileRegisterPageContainer, SpreadsheetStatus, SpreadsheetDownload } from 'components/common'
import { Grid, Link, Text2 } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import FileUpload from 'components/common/FileUpload'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import { UserSchoolProfileTypeEnum } from 'services'

export const BulkCreateNewTeacher: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ProfileRegisterPageContainer
      link={<Link data-testid='register_teacher_back_to_teachers_list' variant='returnArrow' onClick={() => history.push(PROFILE_TABS('teachers'))} linkStyle>{t('Lista professores')}</Link>}
      title={
        <Grid container className={styles.heading}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>{t('Cadastro de professores em massa')}</Text2>
        </Grid>
      }
      description={<Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>{t('Baixe a planilha modelo, preencha com os dados e depois suba a planilha.')}</Text2>}
      additionalBlockOfContent={<SpreadsheetDownload dataTestid='mass_registration_teacher_download_template_spreadsheet' spreadSheetVersion='professores' />}
    >
      <Grid container>
        <Grid container item sm={12}>
          <FileUpload dataTestid='mass_registration_teacher_click_here_to_upload_the_spreadsheet' target={UserSchoolProfileTypeEnum.teacher} accept='.xls, .xlsx, .csv' />
        </Grid>
        <Grid container item sm={12}>
          <SpreadsheetStatus dataTestid='mass_registration_teacher_send_pre-registration_notification' target={UserSchoolProfileTypeEnum.teacher} />
        </Grid>
      </Grid>
    </ProfileRegisterPageContainer>
  )
}
