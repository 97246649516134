import { IUserMeResponse } from 'services'
import Storage from 'utils/storage'
import { AppcuesEventsEnum } from './events'

// window.Appcues.identify(
//   "<<< USER_ID >>>", // unique, required
//   {
//     createdAt: 1566932390, // Unix timestamp of user signup date
//     purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
//     planTier: "Standard", // Current user’s plan tier
//     role: "Admin", // Current user’s role or permissions
//     accountId: "1234", // Current user's account ID
//     firstName: "John", // current user's first name
//     companyName: "Acme Corp", // Current user’s company name
//     email: "john.doe@example.com", // Current user's email
//     location: "90210", // a zipcode, state, or country enables location-based targeting
//     version: "2.0", // users on different versions may need to see different content
//     language: "spanish", // for multi-language applications
//     renewalDate: 1577880288 // to remind users to renew
//   })

// export const cleanUsername = (usernameWithPrefix: string): string =>
//   usernameWithPrefix.replace(/^mbta-active-directory_/, '')

declare global {
  interface Window {
    Appcues?: {
      identify: (userId: number, obj: {firstName: string | undefined, email: string | undefined, role: string | undefined }) => void
      page: () => void
      anonymous: () => void
      show: (id: string) => void
      track: (eventName: keyof typeof AppcuesEventsEnum) => void
    }
  }
}

export const useAppcues = () => {
  const eventDispatcher = (eventName: keyof typeof AppcuesEventsEnum) => {
    if (window.Appcues) {
      window.Appcues.track(eventName)
    }
  }

  const verifyIdentity = (user?: IUserMeResponse | null) => {
    if (window.Appcues) {
      window.Appcues.page()
      if (user?.id) {
        const userId = user.id
        const obj = {
          firstName: user?.username,
          email: user?.email,
          role: Storage.props.getProfile()?.type
        }
        window.Appcues.identify(userId, obj)
      }
    }
  }

  return {
    eventDispatcher,
    verifyIdentity
  }
}
