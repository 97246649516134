/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbersItem: {
    background: theme.colorBrand.medium,
    borderRadius: theme.borderRadius.lg,
    boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',
    padding: theme.spacingInset.sm,
    '&.fullHeight': {
      height: '100%'
    }
  },
  bigNumbersCircleContainer: {
    border: `2px solid ${theme.colors.neutral.lightBase}`,
    aspectRatio: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.xs
    },
    textOverflow: 'ellipsis'
  },
  boxSubTitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.xxs
    },
    textOverflow: 'ellipsis'
  }
}))
