import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    classList: {
      minHeight: '82vh',
      padding: theme.spacingInline.xxs,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacingInline.xxxs
      }
    },
    emptyImage: {
      margin: '0 auto',
      maxWidth: '500px',
      '& img': {
        textAlign: 'center',
        width: '100%'
      }
    },
    emptyMessage: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      margin: '48px 0px 32px 0px',
      maxWidth: '400px',
      textAlign: 'center'
    }
  }
})
