import { makeStyles, createStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    margin: theme.spacingSquish.quarck,
    '&.small': {
      height: '16px',
      width: '16px'
    },
    '&.medium': {
      height: '24px',
      width: '24px'
    },
    '&.large': {
      height: '32px',
      width: '32px'
    }
  }
}))
