import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    codeButtons: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    header: {
      background: theme.colors.neutral.lightBase,
      padding: '32px 24px 12px 24px',
      [theme.breakpoints.down('sm')]: {
        top: '61px',
        zIndex: 1
      }
    },
    helperText: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    orderBy: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0!important',
        '& .MuiFormControl-root': {
          display: 'none!important'
        }
      }
    },
    pagination: {
      [theme.breakpoints.up('md')]: {
        lineHeight: '55px'
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiPagination-root': {
          margin: '0 auto',
          width: 'fit-content'
        }
      }
    },
    search: {
      '& .MuiFormControl-root': {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    tooltip: {
      width: '167px'
    },
    totalTeachers: {
      justifyContent: 'flex-end',
      margin: '16px 0 0',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    toggleButtonGroup: {
      color: theme.colors.neutral.light40,
      fontSize: theme.font.fontSize.xs,
      maxWidth: '353px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        paddingBottom: theme.spacingInline.nano
      }
    },
    autonomousCodeContainer: {
      display: 'flex !important',
      gap: theme.spacingInline.nano
    }
  }
})
