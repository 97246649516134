import { IRecordEventClick, IRecordEventRoute, IAnalytics, IDataSetup } from '../interfaces'

export class AnalyticsProvider {
  private readonly services: IAnalytics[]

  constructor(services: IAnalytics[]) {
    this.services = services
  }

  public setup(data: IDataSetup) {
    this.services.filter(cur => cur.hasSetup).forEach(cur => {
      cur.setup(data)
      return null
    })
  }

  public recordEventClick(data: IRecordEventClick, filterByName?: any[string]) {
    let filter = (_cur: IAnalytics) => true
    if (filterByName?.length) {
      filter = (cur: IAnalytics) => cur?.name && filterByName.includes[cur.name]
    }
    this.services.filter(filter).forEach(cur => {
      cur.recordEventClick(data)
      return null
    })
  }

  public recordEventRoute(data: IRecordEventRoute) {
    this.services.forEach(cur => {
      cur.recordEventRoute(data)
      return null
    })
  }
}
