import { IApiResponse, request } from 'services'
import { ACCEPT_TERMS, GET_TERMS } from './CONSTANTS'
import { CLOE_TERMS_TYPES, IAcceptanceTermsResponse, ITermsResponse } from 'services/types/terms'

export const getTerms = async (type: CLOE_TERMS_TYPES): Promise<IApiResponse<ITermsResponse>> =>
  await request({ url: GET_TERMS(type) })

export const approveCloeTerms = async (type: CLOE_TERMS_TYPES, data: IAcceptanceTermsResponse): Promise<IApiResponse<boolean>> =>
  await request({
    url: ACCEPT_TERMS(type),
    method: 'POST',
    data: data
  })
