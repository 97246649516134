import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import { Grid, SimpleTab, SimpleTabs, TabPanel, Text2 } from 'components/design-system'
import React, { useRef, useState } from 'react'
import useStyles from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import { DetailedPerformanceActivity, DetailedPerformanceEvaluation } from '..'
import { IUnitStudentDetailedPerformance } from 'services'

interface IDetailedPerformanceAccordion {
  detailedPerformance: IUnitStudentDetailedPerformance
  expanded?: boolean
}

export const DetailedPerformanceAccordion: React.FC<IDetailedPerformanceAccordion> = ({ detailedPerformance, expanded = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const expandAccordionRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  // states
  const [accordionDataTestId, setAccordionDataTestId] = useState<string>('followup_student_activity_content_unit_expand')
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded ?? false)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => setCurrentTabIndex(newValue)

  const handleExpanded = () => {
    setIsExpanded(!isExpanded)
    const isAccordionExpanded = expandAccordionRef.current?.attributes.getNamedItem('aria-expanded')?.value
    isAccordionExpanded === 'true'
      ? setAccordionDataTestId('followup_student_activity_content_unit_expand')
      : setAccordionDataTestId('followup_student_activity_content_unit_retract')
  }

  const expandIcon = <ExpandMoreIcon data-testid={accordionDataTestId} fontSize='large' onClick={handleExpanded} sx={{ color: `${theme.colorBrand.medium}!important` }} />

  return (
    <Accordion className={classes.accordion} data-testid={accordionDataTestId} expanded={isExpanded}>
      <AccordionSummary
        className={classes.accordionSummary}
        data-testid={accordionDataTestId}
        expandIcon={expandIcon}
        onClick={handleExpanded}
        ref={expandAccordionRef}
      >
        <Grid container>
          <Grid item xs={12}>
            <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark40' cursor='pointer'>
              {detailedPerformance.title}
            </Text2>
          </Grid>
          <Grid item xs={12}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' colorbrand='medium' cursor='pointer'>
              {detailedPerformance.description}
            </Text2>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <SimpleTabs
          value={currentTabIndex}
          onChange={handleChange}
        >
          <SimpleTab data-testid='followup_student_activity_evaluations' icon={<BallotOutlinedIcon />} iconPosition='start' label={t('Avaliações')} />
          <SimpleTab data-testid='followup_student_activity_activities' icon={<ExtensionOutlinedIcon />} iconPosition='start' label={t('Atividades')} />
        </SimpleTabs>
        <TabPanel value={currentTabIndex} index={0}>
          <DetailedPerformanceEvaluation
            evaluations={detailedPerformance.evaluations}
          />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <DetailedPerformanceActivity
            activities={detailedPerformance.activities}
          />
        </TabPanel>
      </AccordionDetails>
    </Accordion>
  )
}
