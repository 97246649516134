import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    heading: {
      marginTop: theme.spacingStack.md
    },
    rightBoxContent: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    card: {
      width: '248px',
      height: '300px',
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacingInset.xs,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '288px',
        height: '56px'
      }
    },
    infoIconBox: {
      position: 'absolute',
      top: '5px',
      right: '5px'
    },
    tooltip: {
      width: '110px'
    },
    cancelBtn: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginTop: theme.spacingStack.xs,
        '& > button': {
          width: '100%'
        }
      }
    }
  }
})
