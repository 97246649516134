import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { IRegistrationTokenResponse, UserSchoolProfileTypeEnum, getRegistrationToken } from 'services'
import { useStore } from 'store'
import { registrationTokenDataAtom } from '../TeacherTab/atomStore'
import moment from 'moment'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

export const useToken = () => {
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()

  // atoms
  const [registrationTokenList, setRegistrationTokenList] = useAtom(registrationTokenDataAtom)

  // states
  const [token, setToken] = useState<IRegistrationTokenResponse | undefined>(undefined)

  useEffect(() => {
    getToken()
  }, [school])

  useEffect(() => {
    if (registrationTokenList) {
      // removing pre-registration tokens of bulk registration
      const filteredTokens = registrationTokenList.filter(rt => !rt.users_permissions_user && rt.active)
      // get the date of most recent token
      const maxDate = moment.max(filteredTokens.map(_t => moment(_t.created_at)))
      const foundToken = filteredTokens.find(ft => moment(ft.created_at).isSame(maxDate))

      setToken(foundToken)
    }
  }, [registrationTokenList])

  const getToken = async () => {
    try {
      const response = await getRegistrationToken(UserSchoolProfileTypeEnum.teacher, undefined, school?.id)

      if (response.success) {
        setRegistrationTokenList(response.data)
      } else {
        throw new Error('Erro ao tentar obter token')
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  return {
    getToken,
    token
  }
}
