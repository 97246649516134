import { useState } from 'react'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useSnackbar } from 'navigation/hooks'
import {
  IClassesAlreadyClonedException,
  IClassItem,
  IClassToCloneWithUnmarkedStudents,
  IEnrollClassesNextYearClassSubscriptionRequest,
  EnrollClassesThrowExceptionsEnum,
  enrollClassesNextYearClassSubscription,
  checkClassSubscription,
  ICheckClassSubscriptionRequest
} from 'services'
import { atomEnableSaveButton, atomReloadDestinyList, atomSelectedFiltersDestiny, atomSelectedFiltersOrigin } from '../atomStore'

interface IPayloadBuilder {
  originClasses: IClassItem[]
  school_id: number
  schoolPeriod_id: number
  grade_id: number
}

export const useEnrollClasses = () => {
  const { createSnackbar } = useSnackbar()
  const selectedFiltersOrigin = useAtomValue(atomSelectedFiltersOrigin)
  const selectedFiltersDestiny = useAtomValue(atomSelectedFiltersDestiny)

  const [classesAlreadyClonedException, setClassesAlreadyClonedException] = useState<IClassesAlreadyClonedException[]>([])
  const [enrollClassesThrowExceptions, setEnrollClassesThrowExceptions] = useState<keyof typeof EnrollClassesThrowExceptionsEnum | undefined>(undefined)

  const setEnableSaveButton = useUpdateAtom(atomEnableSaveButton)
  const setReloadDestinyList = useUpdateAtom(atomReloadDestinyList)

  const clearClassEnrollExceptionList = () => {
    setClassesAlreadyClonedException([])
  }

  const clearEnrollClassesThrowExceptions = () => {
    setEnrollClassesThrowExceptions(undefined)
  }

  const extractSelectedClassesFromOriginClassesWithUnmarkedStudents = (originClasses: IClassItem[]) => {
    const classListWithUnmarkedStudents: IClassToCloneWithUnmarkedStudents[] = []
    for (const originClass of originClasses) {
      if (originClass.checked === true && originClass?.students) {
        classListWithUnmarkedStudents.push({
          id: originClass.id,
          unmarkedStudents: originClass.students
            .filter(student => student.checked === false)
            .map(stu => stu.user_id)
        })
      }
    }
    return classListWithUnmarkedStudents
  }

  const checkStatusClassSubscription = async (payloadReq: IPayloadBuilder) => {
    try {
      const classesToEnrollForNextSchoolYear = extractSelectedClassesFromOriginClassesWithUnmarkedStudents(payloadReq.originClasses)
      if (classesToEnrollForNextSchoolYear.length > 0 && selectedFiltersOrigin?.grade) {
        const payload: ICheckClassSubscriptionRequest = {
          schoolId: payloadReq.school_id,
          classes: classesToEnrollForNextSchoolYear.map(c => c.id),
          origin_grade: selectedFiltersOrigin.grade,
          grade_id: payloadReq.grade_id
        }

        const response = await checkClassSubscription(payload)

        if (response?.success && response.data.length) {
          // checks if the returns indicate that all classes have already been cloned to the destination
          const hasBeenClonedAndDestinyHasGrade = response.data.filter(res => res.hasBeenCloned && res.destiny_has_grade)
          if (hasBeenClonedAndDestinyHasGrade.length === classesToEnrollForNextSchoolYear.length) {
            setEnrollClassesThrowExceptions(EnrollClassesThrowExceptionsEnum.allClassesAlreadyCloned)
            return false
          }

          // checks if the returns indicate that some classes have already been cloned to the target
          if (hasBeenClonedAndDestinyHasGrade.length > 0 && hasBeenClonedAndDestinyHasGrade.length !== classesToEnrollForNextSchoolYear.length) {
            const classesAlreadyCloned: IClassesAlreadyClonedException[] = hasBeenClonedAndDestinyHasGrade.map((c, i) => ({
              id: String(i + 1),
              message: c.origin_class
            }))
            setClassesAlreadyClonedException(classesAlreadyCloned)
            setEnrollClassesThrowExceptions(EnrollClassesThrowExceptionsEnum.fewClassesAlreadyCloned)
            return false
          }

          // checks if there is any return indicating that it cannot be a itinerary
          const cannotBeItinerary = response.data.find(res => res.cannot_be_itinerary)
          if (cannotBeItinerary && selectedFiltersDestiny.gradeType === 5) {
            setEnrollClassesThrowExceptions(EnrollClassesThrowExceptionsEnum.cannotBeItinerary)
            return false
          }

          // checks if there is any return indicating that it is trying to skip grade
          const foundSkippingGrade = response.data.find(res => res.skiping_grade)
          if (foundSkippingGrade) {
            setEnrollClassesThrowExceptions(EnrollClassesThrowExceptionsEnum.skippingGrade)
            return false
          }

          // when it does not match any of the above conditions
          return true
        }

        if (response?.errors) {
          throw new Error('Erro ao verificar status das turmas')
        }

        return true
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
        setReloadDestinyList(true)
      }
    }
  }

  const enrollClassesForNextSchoolYear = async (payloadReq: IPayloadBuilder) => {
    try {
      const classesToEnrollForNextSchoolYear = extractSelectedClassesFromOriginClassesWithUnmarkedStudents(payloadReq.originClasses)
      if (classesToEnrollForNextSchoolYear.length > 0 && selectedFiltersDestiny?.grade) {
        const payload: IEnrollClassesNextYearClassSubscriptionRequest = {
          schoolId: payloadReq.school_id,
          classes: classesToEnrollForNextSchoolYear,
          school_period: payloadReq.schoolPeriod_id,
          grade_id: payloadReq.grade_id
        }
        const response = await enrollClassesNextYearClassSubscription(payload)
        if (response?.success) {
          setEnableSaveButton(true)
          createSnackbar({ content: 'Turmas adicionadas com sucesso', severity: 'success' })
        }

        if (response?.errors) {
          const invalidSchoolPeriod = response?.errors.find(e => e.id === 'class-subscriptions.error.school-period.invalid')
          if (invalidSchoolPeriod) {
            setEnrollClassesThrowExceptions(EnrollClassesThrowExceptionsEnum.allClassesAlreadyCloned)
          } else {
            throw new Error('Erro ao adicionar turmas')
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setReloadDestinyList(true)
    }
  }

  return {
    classesAlreadyClonedException,
    enrollClassesThrowExceptions,
    checkStatusClassSubscription,
    enrollClassesForNextSchoolYear,
    clearEnrollClassesThrowExceptions,
    clearClassEnrollExceptionList
  }
}
