import { convertArrToGETParams } from 'utils/url'
import { UserSchoolProfileTypeEnum } from 'services'

export const CLASS_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number) =>
  `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/performance/school/${schoolId}/class/${classId}/discipline/${disciplineId}`

export const STUDENTS_LIST_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, offset: number, limit: number, search?: string, sortBy?: string) => {
  const rawURL = `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/performance/school/${schoolId}/class/${classId}/students`
  const paramsObj = { offset, limit, disciplineId, sortBy, search }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const STUDENT_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, studentId: number) =>
  `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/performance/school/${schoolId}/class/${classId}/discipline/${disciplineId}/student/${studentId}`

export const DETAILED_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, studentId: number, limit?: number, offset?: number, search?: string, sortBy?: 'asc' | 'desc') => {
  const rawURL = `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/performance/activity/school/${schoolId}/class/${classId}/discipline/${disciplineId}/student/${studentId}`
  const paramsObj = { limit, offset, search, sortBy }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const LAST_UPDATED_DATE = () => `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/datasets/getHistory`

export const WEEKLY_USAGE = (schoolId: number) => `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/access/school/${schoolId}/weekly-usage`

export const ENROLLMENT_DATA = (schoolId: number, profileType: UserSchoolProfileTypeEnum, frequency: 'month' | 'day') => `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/school/${schoolId}/school-period/latest/enrollments/profile-type/${profileType}?frequency=${frequency}`
