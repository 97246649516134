import { Document, Page, View } from '@react-pdf/renderer'
import { IClassGradeTypeWithGrades, IClassResponse, IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { commonStyles } from './style'
import { ClassCard, CredentialsOutlinedCard, Footer, Header, PrintCard, UserList } from './components'

interface IPrintableUserListProps {
  classGradeTypeWithGrades?: IClassGradeTypeWithGrades[]
  currentClass?: IClassResponse
  profile: UserSchoolProfileTypeEnum.teacher | UserSchoolProfileTypeEnum.student
  totalUsers: number
  users: IUserResponse[]
}

export const PrintableUserList: React.FC<IPrintableUserListProps> = ({ classGradeTypeWithGrades, currentClass, profile, totalUsers, users }) => {
  const activeUsers = users.filter(u => u.active)
  const inactiveUsers = users.filter(u => !u.active)

  return (
    <Document>
      <Page size='A4' style={commonStyles.page}>
        {currentClass && (
          <Header currentClass={currentClass} />
        )}

        {currentClass && classGradeTypeWithGrades && (
          <ClassCard classGradeTypeWithGrades={classGradeTypeWithGrades} currentClass={currentClass} profile={profile} totalUsers={totalUsers} />
        )}

        <View style={commonStyles.displayFlexRow}>
          <CredentialsOutlinedCard />
          <PrintCard/>
        </View>

        <UserList profile={profile} type='active' users={activeUsers} />

        <UserList profile={profile} type='inactive' users={inactiveUsers} />

        <Footer />
      </Page>
    </Document>
  )
}
