import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { Button, Text2 } from 'components/design-system'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import useStyle from './style'
import { useTranslation } from 'react-i18next'
import { ICloeProgramResponse, IGradeTypeEnum } from 'services/types'
import Mark from 'mark.js'
import { useAtom } from 'jotai'
import { searchPhraseAtom } from 'pages/ContentManagement/components/ProgramCloeView/atomStore'
import { Tag2 } from 'components/design-system/Tag2'

const CardTooltip = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()
  return (
    <Box className={classes.cardTooltip}>
      <Text2
        fontSize='xs'
        fontWeight='medium'
        lineHeight='xs'
        mobile='xs'
        customColor={theme.colors.neutral.lightBase}
      >
        {t('A unidade foi desabilitada pela coordenação e não pode ser acessada pelos professores.')}
      </Text2>
    </Box>
  )
}

export const CloeCard = ({ contentUnit, onClick }: { contentUnit: ICloeProgramResponse, onClick: () => void }) => {
  const [isHover, setIsHover] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()

  const {
    title,
    cover,
    grade,
    disciplines,
    classes_count: classesCount,
    suggested_position: suggestedPosition,
    skillTaxonomies,
    wasDeleted
  } = contentUnit
  const thumb = cover?.formats?.small?.url || cover?.formats?.thumbnail?.url || ''
  const curricularContent = contentUnit?.grouped_knowledge ?? []
  const bnccSkill = skillTaxonomies.bnccSkill ?? []
  const gradeType = grade?.grade_type
  const learningGoals = skillTaxonomies.learning_and_development_goals ?? []
  const bnccAxis = skillTaxonomies.bncc_axis ?? []
  const [searchPhrase] = useAtom(searchPhraseAtom)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !wasDeleted) {
      event.preventDefault()
      onClick()
    }
  }

  const handleCardResume = (_gradeType: IGradeTypeEnum | null) => {
    if (_gradeType === 1) {
      return learningGoals?.map((item, index) => (
        item?.name + (index < learningGoals.length - 1 ? ', ' : '')
      ))
    }
    if (_gradeType === 5) {
      return bnccAxis?.map((item, index) => (
        item?.name + (index < bnccAxis.length - 1 ? ', ' : '')
      ))
    }
    return curricularContent?.map((item, index) => (
      item?.knowledge_area + (index < curricularContent.length - 1 ? ', ' : '')
    ))
  }

  const handleCardFrontResume = (_gradeType: IGradeTypeEnum | null) => {
    if (_gradeType === 5) {
      return bnccAxis?.map((item, index) => (
        item?.name + (index < bnccAxis.length - 1 ? ', ' : '')
      ))
    }
    return curricularContent?.map((item, index) => (
      item?.knowledge_area + (index < curricularContent.length - 1 ? ', ' : '')
    ))
  }

  useEffect(() => {
    const setMark = () => {
      const domSelectedToHighlight = document?.querySelectorAll(`#content-unit-card-${contentUnit?.id}`) ?? ''
      const markInstance = new Mark(domSelectedToHighlight)
      markInstance.unmark({
        done: () => {
          const text = searchPhrase ?? ''
          markInstance.mark(text)
        }
      })
    }
    setMark()
  }, [searchPhrase])

  return (
    <Box
      id={`content-unit-card-${contentUnit?.id}`}
      tabIndex={0}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className={classes.cardMainContainer}
      sx={{
        '&:focus': {
          ...(wasDeleted ? {} : { border: `2px solid ${theme.colorBrand.medium}` })
        }
      }}
      aria-disabled={wasDeleted}
    >
      <Box className={classes.cardFrontFace} sx={{ opacity: isHover && !wasDeleted ? '0' : '1' }}>
        <Box className={classes.tagImageContainer}>
          {wasDeleted && isHover && <CardTooltip />}
          <Box
            className={classes.thumbImage}
            sx={{
              filter: wasDeleted ? 'grayscale(100%)' : 'grayscale(0%)',
              backgroundImage: `url(${thumb})`
            }}
          />
          <Box className={classes.tagsContainer}>
            {disciplines.map(discipline => (
              <Tag2 variant={discipline.code} className={classes.tag}>
                {discipline.name}
              </Tag2>
            ))}
            <Tag2
              background={theme.colorBrand.lightest}
              color={theme.colorBrand.dark}
              className={classes.tag}
            >
              {grade.name}
            </Tag2>
          </Box>
        </Box>
        <Box
          className={classes.textContainer}
          sx={{
            padding: theme.spacingInset.xs,
            filter: wasDeleted ? 'grayscale(100%)' : 'grayscale(0%)'
          }}
        >
          <Box className={classes.title}>
            <Text2
              fontSize='xxs'
              fontWeight='medium'
              lineHeight='xxs'
              mobile='xxs'
              customColor={theme.colors.neutral.dark40}
            >
              {t('Unidade {{suggestedPosition}} | {{classesCount}} aulas', { suggestedPosition, classesCount })}
            </Text2>
            <Text2
              fontSize='md'
              fontWeight='bold'
              lineHeight='xs'
              mobile='md'
              customColor={theme.colorBrand.medium}
              className={classes.titleText}
            >
              {title}
            </Text2>
          </Box>
          <Text2
            fontSize='xs'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xs'
            customColor={theme.colors.neutral.dark10}
            className={classes.frontDescription}
          >
            {handleCardFrontResume(gradeType)}
          </Text2>
        </Box>
      </Box>

      {!wasDeleted && (
        <Box className={classes.cardBackFace} sx={{ opacity: isHover ? '1' : '0' }}>
          <Box className={classes.textContainer}>
            <Box className={classes.title}>
              <Text2
                fontSize='xxs'
                fontWeight='medium'
                lineHeight='xxs'
                mobile='xxs'
                customColor={theme.colors.neutral.dark40}
              >
                {t('Unidade {{suggestedPosition}} | {{classesCount}} aulas', { suggestedPosition, classesCount })}
              </Text2>
              <Text2
                fontSize='md'
                fontWeight='bold'
                lineHeight='xs'
                mobile='md'
                customColor={theme.colorBrand.medium}
                className={classes.titleText}
              >
                {title}
              </Text2>
            </Box>
            <Text2
              fontSize='xs'
              fontWeight='medium'
              lineHeight='xs'
              mobile='xs'
              customColor={theme.colors.neutral.dark10}
              className={classes.backDescription}
            >
              {handleCardResume(gradeType)}
            </Text2>
            <Box className={classes.bnccContainer}>
              {bnccSkill?.slice(0, 4).map((item, index) => (
                <Tag2
                  key={index}
                  background={theme.colorBrand.lightest}
                  color={theme.colorBrand.dark}
                  className={classes.tag}
                >
                  {item.code}
                </Tag2>
              ))}
              {bnccSkill?.length > 4 && (
                <Tag2
                  background={theme.colorBrand.lightest}
                  color={theme.colorBrand.dark}
                  className={classes.tag}
                >
                  ...
                </Tag2>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'contents' }}>
            <Button
              endIcon={<ArrowForwardIcon />}
              variant={'primary'}
              data-testid='program_cloe_select_content_unit'
              className={classes.cardButton}
            >
              {t('Ver currículo')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
