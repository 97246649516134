import { DisciplineCodeEnum } from 'services'
import useStyles from './style'
import Chip, { ChipProps } from '@mui/material/Chip'
import CancelIcon from '@mui/icons-material/Cancel'

export interface ITagProps extends ChipProps {
  active?: boolean
  background?: string
  discipline?: DisciplineCodeEnum
  textcolor?: string
  cancelIconDataTestId?: string
}

export const Tag: React.FC<ITagProps> = ({ active = false, ...props }) => {
  const classes = useStyles(props)

  return (
    <Chip
      {...props}
      className={`${classes.root} ${props.className as string} ${active ? 'active' : ''}`}
      deleteIcon={<CancelIcon data-testid={props.cancelIconDataTestId}/>}
    />
  )
}
