import { useEffect, useState } from 'react'
import { BigNumberBox, BigNumberBoxSubtitleProps } from 'components/common'
import { useTranslation } from 'react-i18next'
import { UserSchoolProfileTypeEnum } from 'services'
import { useLinkedProfilesBox } from '../hooks'

export const LinkedProfilesBoxTeacher: React.FC = () => {
  const { t } = useTranslation()
  const { data, fetchData, error, setReload, loading } = useLinkedProfilesBox(UserSchoolProfileTypeEnum.teacher)

  // states
  const [subtitles, setSubtitles] = useState<BigNumberBoxSubtitleProps[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      const _subtitles: BigNumberBoxSubtitleProps[] = []

      _subtitles.push({
        label: t('{{total}} não vinculados', { total: data?.notSubscribed })
      })

      setSubtitles(_subtitles)
    }
  }, [data])

  const tryAgainCallback = () => {
    setReload(true)
  }

  return (
    <BigNumberBox
      bigNumber={data?.subscribed}
      loading={loading}
      subtitles={subtitles}
      title={
        t(
          data?.schoolPeriod ? 'Vinculados a turmas em {{year}}' : 'Vinculados a turmas',
          {
            year: data?.schoolPeriod
          }
        )
      }
      hasError={error}
      tryAgainCallback={tryAgainCallback}
      tooltipText={t('Professores com perfil ativo vinculados a turmas do ano vigente.')}
    />
  )
}
