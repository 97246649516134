import { useSnackbar } from 'navigation/hooks'
import { IClassSubscriptionInactivateRequest, IUserResponse, inactivateClassSubscription } from 'services'
import { useTranslation } from 'react-i18next'

interface IRequestData {
  recipients: IUserResponse[]
  classId: number
}

export const useRemoveTeacherFromClass = () => {
  const { createSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const removeTeacherFromClass = async ({ recipients, classId }: IRequestData) => {
    try {
      const classSubscriptionInactivateRequest: IClassSubscriptionInactivateRequest = {
        classId,
        users: recipients.map((rec) => rec.id)
      }
      const response = await inactivateClassSubscription(classSubscriptionInactivateRequest)
      if (response?.success) createSnackbar({ content: 'Professores(as) removidos(as) com sucesso', severity: 'success' })

      if (response?.errors) {
        throw new Error(t('Não foi possível remover os(as) professores(as) da turma'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  return {
    removeTeacherFromClass
  }
}
