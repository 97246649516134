import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  yearInput: {
    '&.MuiFilledInput-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      borderRadius: theme.borderRadius.sm
    },
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)'
    },
    '&.MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)'
    },
    width: '100%',
    '& .MuiInputLabel-root': {
      left: '32px'
    }
  }
}))
