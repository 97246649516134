import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)
dayjs.locale('pt-br')

export const dateOffset = (date: Date | string) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date
  return parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000
}

export const formatDayMonthLabel = (rawDate: Date | string) => {
  const formattedDate = dayjs(rawDate).format('DD/MMM')
  return formattedDate
}

export const abbreviatedMonthName = (rawDate: Date) => {
  const abbreviatedMonth = dayjs(rawDate).format('MMM')
  return abbreviatedMonth
}

export const convertStringToDate = (rawDate: string | Date) => {
  const convertedDate = dayjs(rawDate).toDate()
  return convertedDate
}

export const formatDateToString = (rawDate: Date | string) => {
  return dayjs(rawDate).format('YYYY-MM-DD')
}

export const formatSecondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}
