import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IDoubleColumnContainerProps } from './index'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: (props: IDoubleColumnContainerProps) => props.rounded ? '16px 16px 0px 0px' : 'initial',
    minHeight: '851px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '568px'
    }
  },
  leftColumnContent: {
    background: `${theme.colorBrand.lightest}29`,
    padding: `${theme.spacingInset.md} ${theme.spacingInset.sm}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  rightColumnContent: {
    padding: `${theme.spacingInset.md} ${theme.spacingInset.sm}`,
    width: '100%'
  }
}))
