import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DoubleColumnContainer, LeftColumnContent, RightColumnContent } from 'components/common'
import { Button, Grid, Link, Text2 } from 'components/design-system'
import { CLASS_MANAGEMENT, PROFILE_TABS } from 'navigation/CONSTANTS'
import { useAtomValue } from 'jotai/utils'
import okGirl from 'assets/ok.svg'
import useStyles from './style'
import { atomCurrentClass } from 'pages/ClassManagement/atomStore'

export const StudentSuccess: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()
  const currentClass = useAtomValue(atomCurrentClass)

  const backToClass = () => history.push(currentClass
    ? CLASS_MANAGEMENT(`view/${currentClass?.id}`)
    : PROFILE_TABS('students')
  )

  return (
    <DoubleColumnContainer rounded>
      <LeftColumnContent>
        <Grid container rowSpacing={4}>
          <Grid container item rowSpacing={6}>
            <Grid item xs={12} md={12}>
              <Link
                variant='returnArrow'
                onClick={backToClass}
                linkStyle
              >
                {t('Voltar para turma')}
              </Link>
            </Grid>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
                {t('Cadastro finalizado com sucesso!')}
              </Text2>
            </Grid>
          </Grid>
        </Grid>
      </LeftColumnContent>
      <RightColumnContent>
        <Grid container alignItems='center' direction='column' justifyContent='center' spacing={8}>
          <Grid item className={styles.imageContainer}>
            <img src={okGirl} />
          </Grid>
          <Grid item>
            <Button
              onClick={backToClass}
              type='button'
              variant='primary'
            >
              {t('Voltar para turma')}
            </Button>
          </Grid>
        </Grid>
      </RightColumnContent>
    </DoubleColumnContainer>
  )
}
