import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiCard-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      border: `1px solid ${theme.colors.neutral.light10}`,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      '& .click-item-pointer': {
        cursor: 'pointer'
      },
      '& .MuiCardContent-root': {
        padding: '24px 16px'
      }
    }
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifycontent: 'center',
    minheight: '48px',
    position: 'relative',
    verticalAlign: 'middle'
  },
  iconWrapper: {
    display: 'flex',
    '& > svg': {
      color: theme.colorBrand.medium,
      cursor: 'pointer',
      fontSize: theme.font.fontSize.xl
    }
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  statusLabel: {
    paddingRight: '4px'
  },
  summary: {
    display: 'flex',
    flexGrow: 1,
    margin: '12px 0'
  }
}))
