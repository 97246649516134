import { useAuthResetDefaultPassword } from 'navigation/hooks'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { useUpdateAtom } from 'jotai/utils'
import { ResetDefaultPasswordModal } from '../ResetDefaultPasswordModal'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { useMediaQuery, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { CircleButton } from 'components/common/CircleButton'

interface IResetPasswordOptionButton {
  recipients: IUserResponse[]
  clearRecipients: () => void
  profile: UserSchoolProfileTypeEnum
  dataTestId: string
}

export const ResetPasswordOptionButton = ({ recipients, clearRecipients, profile, dataTestId }: IResetPasswordOptionButton) => {
  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))
  const { t } = useTranslation()

  const [isOpenResetDefaultPasswordModal, setIsOpenResetDefaultPasswordModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const setReloadList = useUpdateAtom(atomReloadList)

  const { resetDefaultPassword, verifyConditionsToActivateRestorePasswordButton, enableRestorePasswordButton } = useAuthResetDefaultPassword()

  const handleResetDefaultPassword = async () => {
    if (recipients.length) {
      setIsLoading(true)
      await resetDefaultPassword({
        users: [...recipients.map(student => student.id)]
      })
    }

    setIsLoading(false)
    setIsOpenResetDefaultPasswordModal(false)
    clearRecipients()
    setReloadList(true)
  }

  const handleOpenDefaultPasswordModal = () => {
    setIsOpenResetDefaultPasswordModal(true)
  }

  useEffect(() => {
    if (recipients.length) {
      verifyConditionsToActivateRestorePasswordButton(recipients)
    }
  }, [recipients])

  return (
    <>
      {
        !isLaptop
          ? <Button variant='link' disabled={!recipients.length || enableRestorePasswordButton} onClick={handleOpenDefaultPasswordModal} data-testid={dataTestId}>
            <VpnKeyOutlinedIcon sx={{ marginRight: '5px' }} /> {t('Restaurar senhas para padrão')}
          </Button>
          : <CircleButton disabled={!recipients.length || enableRestorePasswordButton} aria-label='Restaurar senhas para padrão' title='Restaurar senhas para padrão' onClick={handleOpenDefaultPasswordModal} data-testid={dataTestId}>
            <VpnKeyOutlinedIcon />
          </CircleButton>
      }

      <ResetDefaultPasswordModal
        open={isOpenResetDefaultPasswordModal}
        onClose={() => setIsOpenResetDefaultPasswordModal(!isOpenResetDefaultPasswordModal)}
        onResetPassword={handleResetDefaultPassword}
        list={recipients}
        profile={profile}
        isLoading={isLoading}
      />
    </>
  )
}
