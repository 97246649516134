import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ITagProps } from './index'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiChip-root': {
      background: theme.colors.neutral.light10,
      borderRadius: theme.borderRadius.xs,
      color: theme.colors.neutral.darkBase,
      fontSize: (props: ITagProps) => props.size === 'small'
        ? theme.font.fontSize.xxs
        : theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      height: '32px',
      lineHeight: '18px',
      transition: '.3s',
      '&.MuiChip-sizeSmall': {
        height: '26px'
      },
      '&.MuiChip-filled': {
        '&.active': {
          background: (props: ITagProps) => {
            if (props.discipline) return theme.colors.curricularComponents[props.discipline]
            if (props.background) return props.background
            return theme.colorBrand.medium
          },
          color: (props: ITagProps) => {
            if (props.discipline) return theme.colors.neutral.dark20
            if (props.textcolor) return props.textcolor
            return theme.colors.neutral.lightBase
          }
        }
      },
      '&.MuiChip-outlined': {
        background: theme.colors.neutral.lightBase,
        border: `2px solid ${theme.colors.neutral.light10}`,
        '&.active': {
          border: `2px solid ${theme.colorBrand.medium}`,
          color: theme.colorBrand.medium
        }
      }
    }
  }
}))
