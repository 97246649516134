import { UserSchoolProfileTypeEnum } from 'services'
import { RegisteredProfilesBoxStudent } from './student'
import { RegisteredProfilesBoxTeacher } from './teacher'

interface IRegisteredProfilesBox {
  selectedProfile: UserSchoolProfileTypeEnum
}

export const RegisteredProfilesBox: React.FC<IRegisteredProfilesBox> = ({ selectedProfile }) => {
  if (selectedProfile === UserSchoolProfileTypeEnum.student) {
    return <RegisteredProfilesBoxStudent />
  }
  if (selectedProfile === UserSchoolProfileTypeEnum.teacher) {
    return <RegisteredProfilesBoxTeacher />
  }
  return <></>
}
