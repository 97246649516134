import { ChangeEvent, useState } from 'react'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import OutlinedInput from '@mui/material/OutlinedInput'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { InputBaseProps } from '@mui/material'
import InputMask from 'react-input-mask'
import {
  helperTextStyles,
  formStyles,
  FilledInputStyles,
  InputLabelStyles,
  OutlinedInputStyles
} from './style'

interface State {
  password: string
  showPassword: boolean
}

export interface ITextField extends InputBaseProps {
  id: string
  variant: 'outlined' | 'filled'
  assistivetext?: string
  error?: boolean
  warning?: boolean | number
  success?: boolean
  label?: string
  password?: boolean
  mask?: string
  dataTestId?: string
}

function changeType(error?: boolean, warning?: boolean | number, success?: boolean) {
  if (error) return 'error'
  if (warning) return 'warning'
  if (success) return 'success'
  return 'default'
}

export const TextField = (props: ITextField) => {
  const {
    assistivetext,
    className,
    endAdornment,
    error,
    id,
    label,
    mask,
    onBlur,
    onChange,
    password,
    startAdornment,
    success,
    value,
    variant,
    warning,
    dataTestId,
    disabled
  } = props

  const [values, setValues] = useState<State>({
    showPassword: false,
    password: ''
  })

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
    if (onChange) {
      onChange(event)
    }
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const type = changeType(error, warning, success)
  const FilledInputClasses = FilledInputStyles(props)
  const InputLabelClasses = InputLabelStyles(props)
  const HelperTextClasses = helperTextStyles(props)
  const OutlinedInputClasses = OutlinedInputStyles(props)
  const FormClasses = formStyles(props)

  if (variant === 'filled') {
    return (
      <FormControl className={`${FormClasses.root} ${type} ${className as string}`} variant={variant}>
        {label && (
          <InputLabel variant={variant} className={`${InputLabelClasses.root} ${type} ${className as string}`} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {password
          ? (
            <FilledInput
              {...props}
              className={`${FilledInputClasses.root} ${type} ${className as string}`}
              disableUnderline
              onChange={handleChange('password')}
              type={values.showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword
                      ? <VisibilityOffOutlinedIcon />
                      : <VisibilityOutlinedIcon />
                    }
                  </IconButton>
                </InputAdornment>
              }
              data-testid={dataTestId}
            />
          )
          : mask
            ? (
              <InputMask
                mask={mask}
                value={value as string}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
              >
                {() => (
                  <FilledInput
                    id={id}
                    className={`${FilledInputClasses.root} ${type} ${className as string}`}
                    disableUnderline
                    data-testid={dataTestId}
                    startAdornment={
                      startAdornment &&
                        <InputAdornment position='start'>
                          {startAdornment}
                        </InputAdornment>
                    }
                    disabled={disabled}
                  />
                )}
              </InputMask>
            )
            : (
              <FilledInput
                {...props}
                className={`${FilledInputClasses.root} ${type} ${className as string}`}
                disableUnderline
                startAdornment={
                  startAdornment &&
                    <InputAdornment position='start'>
                      {startAdornment}
                    </InputAdornment>
                }
                endAdornment={
                  endAdornment &&
                    <InputAdornment position='end'>
                      {endAdornment}
                    </InputAdornment>
                }
                data-testid={dataTestId}
              />
            )
        }
        {assistivetext && (
          <FormHelperText variant={variant} className={`${HelperTextClasses.root} ${type} ${className as string}`} id={`assistivetext-${id}`}>
            {assistivetext}
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  return (
    <FormControl className={`${FormClasses.root} ${type} ${className as string}`} variant={variant}>
      <InputLabel variant={variant} className={`${InputLabelClasses.root} ${type} ${className as string}`} htmlFor={id}>
        {label}
      </InputLabel>
      {password
        ? (
          <OutlinedInput
            {...props}
            className={`${OutlinedInputClasses.root} ${type} ${className as string}`}
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword
                    ? <VisibilityOutlinedIcon />
                    : <VisibilityOffOutlinedIcon />
                  }
                </IconButton>
              </InputAdornment>
            }
            data-testid={dataTestId}
          />
        )
        : mask
          ? (
            <InputMask
              mask={mask}
              value={value as string}
              onBlur={onBlur}
              onChange={onChange}
              disabled={disabled}
            >
              {() => (
                <OutlinedInput
                  id={id}
                  className={`${OutlinedInputClasses.root} ${type} ${className as string}`}
                  label={label}
                  data-testid={dataTestId}
                  startAdornment={
                    startAdornment &&
                      <InputAdornment position='start'>
                        {startAdornment}
                      </InputAdornment>
                  }
                  disabled={disabled}
                />
              )}
            </InputMask>
          )
          : (
            <OutlinedInput
              {...props}
              className={`${OutlinedInputClasses.root} ${type} ${className as string}`}
              startAdornment={
                startAdornment &&
                  <InputAdornment position='start'>
                    {startAdornment}
                  </InputAdornment>
              }
              endAdornment={
                endAdornment &&
                  <InputAdornment position='end'>
                    {endAdornment}
                  </InputAdornment>
              }
              data-testid={dataTestId}
            />
          )
      }
      {assistivetext && (
        <FormHelperText variant={variant} className={`${HelperTextClasses.root} ${type} ${className as string}`} id={`assistivetext-${id}`}>
          {assistivetext}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default TextField
