/* eslint-disable @typescript-eslint/naming-convention */
import { useTheme } from '@mui/material'
import { Text2, Checkbox, Alert, Loading, Link } from 'components/design-system'
import { ModalWrapper } from 'components/common'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'

import useStyle from './style'
import { useSnackbar } from 'navigation/hooks'
import { CLOE_TERMS_TYPES } from 'services/types/terms'
import { useAuth } from 'navigation/ProvideAuth'
import { approveCloeTerms, getTerms } from 'services/terms'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { atomTerms } from 'pages/Terms/atomStore'
import { TERMS_EXPANDED } from 'navigation/CONSTANTS'
import { useLocation } from 'react-router-dom'
import { ILocation } from 'services/types/routes'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

export const ApproveCloeSuperModal: React.FC = () => {
  const classes = useStyle()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [hasChoosen, setHasChoosen] = useState<boolean>(false)
  const [stateMachine, setStateMachine] = useState(StateMachineEnum.IDLE)
  const theme = useTheme()
  const { createSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const auth = useAuth()

  const [isChecked, setIsChecked] = useState(false)

  const { profile, school } = useStore()
  const [apiTerms, setApiTerms] = useAtom(atomTerms)

  const schoolParameters = profile?.school
  const cloeSuperParams = {
    has_approved_cloe_super: schoolParameters?.has_approved_cloe_super,
    has_cloe_super: schoolParameters?.has_cloe_super,
    is_new_client: schoolParameters?.is_new_client
  }

  const location = useLocation<ILocation>()

  useEffect(() => {
    verifyCloeSuperStatus()
  }, [cloeSuperParams, school])

  useEffect(() => {
    reset()
  }, [school?.id])

  useEffect(() => {
    findTerms()
  }, [school])

  const reset = () => {
    setHasChoosen(false)
  }

  const findTerms = useCallback(async () => {
    try {
      setStateMachine(StateMachineEnum.LOADING)
      const response = await getTerms(CLOE_TERMS_TYPES.SUPER)

      if (!response.success) {
        throw new Error()
      }
      setApiTerms(response.data)
      setStateMachine(StateMachineEnum.READY)
    } catch (err) {
      setStateMachine(StateMachineEnum.ERROR)
    }
  }, [setApiTerms, setStateMachine])

  const verifyCloeSuperStatus = async () => {
    const isTermsPage = location.pathname === TERMS_EXPANDED()

    if (hasChoosen || isTermsPage) {
      setIsOpen(false)
      return
    }

    const {
      has_approved_cloe_super,
      has_cloe_super
    } = cloeSuperParams

    if (has_cloe_super && !has_approved_cloe_super) {
      // esse cara contratou cloe super e não aprovou os termos do super
      setIsOpen(true)
      return
    }

    setIsOpen(false)
  }

  const handleApproveCloeSuper = () => {
    setIsChecked(old => !old)
  }

  const handleConfirm = async () => {
    if (!isChecked) {
      createSnackbar({
        content: 'Para confirmar, é necessário aceitar os termos de uso!',
        severity: 'warning'
      })

      return
    }

    try {
      if (!school?.id) throw new Error()

      const data = {
        schoolId: school.id,
        UserId: auth?.user?.id ?? profile?.user ?? 0
      }

      const response = await approveCloeTerms(CLOE_TERMS_TYPES.SUPER, data)

      if (response.success) {
        createSnackbar({
          content: 'Termos de uso aprovados com sucesso!',
          severity: 'success'
        })
        setIsOpen(false)
        setHasChoosen(true)
      } else {
        throw new Error()
      }
    } catch (err: any) {
      createSnackbar({
        content: 'Ocorreu um erro e não foi possivel aprovar os termos de uso. Tente novamente.',
        severity: 'error'
      })
    }
  }

  const handleCancel = () => {
    setIsOpen(false)
    setHasChoosen(true)
  }

  const states = {
    IDLE: '',
    LOADING: <Loading type='linear' />,
    ERROR: <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} outlined={true} severity='error' onActionClick={findTerms} action={`${t('Recarregar')}`} />,
    READY: (
      <>
        <div className={classes.title}>
          <HandshakeOutlinedIcon className={classes.relatedIcons} />
          <Text2
            fontSize='md'
            fontWeight='semibold'
            customColor={theme.colorBrand.medium}
          >
            {'Termos de uso - Cloe Super'}
          </Text2>
        </div>
        <Text2
          fontSize='xs'
          fontWeight='medium'
          customColor={theme.colors.neutral.dark40}
        >
          <div className={classes.termsTextContainer}>
            <div
              className={classes.termsText}
              dangerouslySetInnerHTML={{
                __html: apiTerms?.usage_terms ?? ''
              }}
            />
            <div className={classes.termsTextFade} />
          </div>

          <div className={classes.checkboxContainer}>
            <Checkbox
              onClick={handleApproveCloeSuper}
              size='medium'
              customClasses={classes.checkboxLabel}
              defaultChecked={false}
            >
              <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' neutral='dark30'>
                Declaro que li e estou ciente dos
              </Text2>
            </Checkbox>
            <Text2 sx={{ marginTop: '8px' }} fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' support='colorSupport01'>
              <Link variant='primary' href={TERMS_EXPANDED('terms')} target='_blank' className={classes.termsLink}>
                Termos de uso da Cloe Super
              </Link>
            </Text2>
          </div>
        </Text2>
      </>
    )
  }

  return (
    <ModalWrapper
      open={isOpen}
      confirmLabel={'Confirmar'}
      cancelLabel={'Cancelar'}
      onCancel={handleCancel}
      onConfirm={handleConfirm}>
      <div className={classes.container}>
        {states[stateMachine]}
      </div>
    </ModalWrapper>
  )
}
