import { Loading } from 'components/design-system'
import React, { KeyboardEvent, useState, useEffect, useRef } from 'react'
import { ClassHeader, ClassList, EditSchoolPeriodModal, SchoolPeriodAccordion, SchoolPeriodModal } from './components'
import { MobileOrderByModal, MobileSearchBar } from 'components/common'
import { useStore } from 'store'
import { checkSchoolPeriod, getAvailableYears, getClasses, ISchoolPeriod, SchoolPeriodType } from 'services'
import { useTheme } from '@mui/styles'
import { Theme, useMediaQuery } from '@mui/material'
import useStyle from './style'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import {
  atomGradeType,
  atomGrade,
  atomOrderBy,
  atomPage,
  atomSchoolPeriod,
  atomSchoolPeriods,
  atomSearchTerm,
  atomClassPropsData,
  atomPreSelectedSchoolPeriod,
  atomReloadClasslist,
  atomIsRenamingClass,
  atomActive,
  atomIsLoadingCreateSchoolPeriod
} from './atomStore'
import { useClassManagement } from 'navigation/hooks'
import { useAppcues } from 'components/AppCues/useAppcues'
import { AppcuesEventsEnum } from 'components/AppCues/events'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const ClassManagement: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { school } = useStore()
  const theme = useTheme<Theme>()
  const { createSnackbar } = useSnackbar()
  const { getSchoolPeriodType } = useClassManagement()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { eventDispatcher } = useAppcues()

  // atoms
  const gradeType = useAtomValue(atomGradeType)
  const grade = useAtomValue(atomGrade)
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const page = useAtomValue(atomPage)
  const [searchTerm, setSearchTerm] = useAtom(atomSearchTerm)
  const active = useAtomValue(atomActive)
  const [schoolPeriod, setSchoolPeriod] = useAtom(atomSchoolPeriod)
  const isRenamingClass = useAtomValue(atomIsRenamingClass)
  const [schoolPeriods, setSchoolPeriods] = useAtom(atomSchoolPeriods)
  const [reloadClassList, setReloadClassList] = useAtom(atomReloadClasslist)

  const setPage = useUpdateAtom(atomPage)
  const setClassPropsData = useUpdateAtom(atomClassPropsData)
  const setPreSelectedSchoolPeriod = useUpdateAtom(atomPreSelectedSchoolPeriod)

  const resetIsLoadingCreateSchoolPeriod = useResetAtom(atomIsLoadingCreateSchoolPeriod)

  // states
  const [visible, setVisible] = useState<boolean>(true)
  const [pageYOffset, setPageYOffset] = useState<number>(0)
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [openEditSchoolPeriodModal, setOpenEditSchoolPeriodModal] = useState<boolean>(false)
  const [openSchoolPeriodModal, setOpenSchoolPeriodModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [schoolPeriodType, setSchoolPeriodType] = useState<SchoolPeriodType>('current')

  const getSchoolPeriods = async (schoolId: number) => {
    await checkSchoolPeriod(schoolId)
    const response = await getAvailableYears(schoolId)

    try {
      if (response.success) {
        setSchoolPeriods(response.data)
        resetIsLoadingCreateSchoolPeriod()
        if (!schoolPeriod) {
          setPreSelectedSchoolPeriod(response.data[0])
          setSchoolPeriod(response.data[0])
          setReloadClassList(true)
        } else {
          setSchoolPeriod(response.data.find((sp: ISchoolPeriod) => sp.id === schoolPeriod.id))
        }
      } else {
        throw new Error(t('Erro ao carregar anos'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  useEffect(() => {
    if (school?.id) {
      getSchoolPeriods(Number(school.id))
    }
  }, [school])

  const previousPageYOffset = usePrevious(pageYOffset) as number

  const handleScroll = () => {
    const isScrollToDown = pageYOffset > previousPageYOffset
    if (!isScrollToDown !== visible) setVisible(!isScrollToDown)
  }

  if (document.body.clientWidth < theme.breakpoints.values.sm) {
    document.removeEventListener('scroll', () => setPageYOffset(window.scrollY))
    document.addEventListener('scroll', () => setPageYOffset(window.scrollY))
  }

  useEffect(() => {
    handleScroll()
  }, [pageYOffset])

  const handleMobileSearch = (event: any) => {
    if (event.target.value.length >= 3 || event.target.value.length === 0) {
      setTimeout(() => {
        setPage(1)
        setSearchTerm(event.target.value.trim())
      }, 500)
    }
  }

  const filter = async (schoolPeriodId: number, pageNumber: number, gradeTypeId: number, gradeId: number, sort: string, search: string) => {
    if (schoolPeriods.length && schoolPeriodId !== 0) {
      try {
        setIsLoading(true)
        const response = await getClasses(
          school?.id ?? 0,
          schoolPeriodId,
          pageNumber,
          active,
          gradeTypeId,
          gradeId,
          sort,
          search
        )
        if (response?.success) {
          setClassPropsData(response.data)
        } else {
          throw new Error(t('Não foi possível carregar as turmas'))
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      } finally {
        setReloadClassList(false)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (!reloadClassList) {
      filter(schoolPeriod?.id ?? 0, page ?? 1, gradeType?.id ?? 0, grade?.id ?? 0, orderBy, searchTerm)
    }
  }, [school, gradeType, grade, orderBy, page, active])

  useEffect(() => {
    if (reloadClassList) {
      filter(schoolPeriod?.id ?? 0, page ?? 1, gradeType?.id ?? 0, grade?.id ?? 0, orderBy, searchTerm)
    }
  }, [reloadClassList])

  useEffect(() => {
    if (isMobile) {
      filter(schoolPeriod?.id ?? 0, page ?? 1, gradeType?.id ?? 0, grade?.id ?? 0, orderBy, searchTerm)
    }
  }, [searchTerm])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      filter(schoolPeriod?.id ?? 0, page ?? 1, gradeType?.id ?? 0, grade?.id ?? 0, orderBy, searchTerm)
      event.preventDefault()
    }
  }

  const handleClick = () => {
    filter(schoolPeriod?.id ?? 0, page ?? 1, gradeType?.id ?? 0, grade?.id ?? 0, orderBy, searchTerm)
  }

  const handleCloseSchoolPeriodModal = () => setOpenSchoolPeriodModal(false)
  const handleOpenSchoolPeriodModal = () => setOpenSchoolPeriodModal(true)

  const handleCloseEditSchoolPeriodModal = () => setOpenEditSchoolPeriodModal(false)
  const handleOpenEditSchoolPeriodModal = () => setOpenEditSchoolPeriodModal(true)

  const handleCloseOrderByModal = () => setOpenFilterModal(false)
  const handleOpenOrderByModal = () => setOpenFilterModal(true)

  const handleSelectOrder = (optionValue: string) => {
    setOrderBy(optionValue)
    setOpenFilterModal(false)
  }

  useEffect(() => {
    if (schoolPeriod) {
      setSchoolPeriodType(getSchoolPeriodType(schoolPeriod))
    }
  }, [schoolPeriod])

  useEffect(() => {
    if (schoolPeriod && getSchoolPeriodType(schoolPeriod) === 'future') {
      eventDispatcher(AppcuesEventsEnum.adm_ano_escolar_futuro)
    }
  }, [schoolPeriod])

  const handleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <div className={classes.container}>
      <SchoolPeriodAccordion
        handleExpanded={handleExpanded}
        isExpanded={isExpanded}
        schoolPeriodType={schoolPeriodType}
      />
      <ClassHeader
        handleClick={handleClick}
        handleKeyDown={handleKeyDown}
        handleOpenEditSchoolPeriodModal={handleOpenEditSchoolPeriodModal}
        handleOpenSchoolPeriodModal={handleOpenSchoolPeriodModal}
        schoolPeriodType={schoolPeriodType}
      />
      {isLoading ? <Loading type='linear' /> : <ClassList />}
      {
        !isRenamingClass
          ? <MobileSearchBar
            handleSearch={handleMobileSearch}
            handleOpenOrderBy={handleOpenOrderByModal}
            visible={visible}
          />
          : null
      }
      <MobileOrderByModal
        handleClose={handleCloseOrderByModal}
        handleSelectOrder={handleSelectOrder}
        open={openFilterModal}
        selectedOrderBy={orderBy}
      />
      <SchoolPeriodModal
        handleClose={handleCloseSchoolPeriodModal}
        open={openSchoolPeriodModal}
      />
      <EditSchoolPeriodModal
        handleClose={handleCloseEditSchoolPeriodModal}
        open={openEditSchoolPeriodModal}
        reloadSchoolPeriods={getSchoolPeriods}
      />
    </div>
  )
}

export default ClassManagement
