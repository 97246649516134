import { IRecordEventClick, IRecordEventRoute, IAnalytics } from '../interfaces'

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[]
  }
}
export class AnalyticsGoogle extends IAnalytics {
  readonly name = 'google'
  readonly hasSetup = false
  setup(_data: any) {
    // This is intentional
  }

  recordEventClick(data: IRecordEventClick) {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: data.name,
        ...data.attributes
      })
    } else {
      console.error('AnalyticsGoogle not handling IRecordEventClick')
    }
  }

  recordEventRoute(_data: IRecordEventRoute) {
    // This is intentional
  }
}
