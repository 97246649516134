import React, { ReactChild } from 'react'

import MUIAlert from '@mui/material/Alert'
import MUIAlertTitle from '@mui/material/AlertTitle'
import { AlertProps } from '@mui/material'
import Analytics from 'utils/analytics'

import { Button } from 'components/design-system'

import useStyles from './style'
import { useStore } from 'store'

export interface IAlertProps extends Omit<AlertProps, 'severity'> {
  title?: string
  content: string
  outlined?: boolean
  action?: ReactChild | (() => void) | string
  onActionClick?: () => void
  actionDataTestId?: string
  severity: 'error' | 'warning' | 'info' | 'success'
  themeColor?: string
}

export const Alert: React.FC<IAlertProps> = ({ outlined, title, content, action, ...props }) => {
  const classes = useStyles()
  const { profile, school } = useStore()

  const getVariantBySeverity = () => {
    switch (props.severity) {
      case 'error':
        return 'feedbackNegativeGhost'
      case 'warning':
        return 'feedbackWarningGhost'
      case 'info':
        return 'feedbackInformationGhost'
      case 'success':
      default:
        return 'feedbackPositiveGhost'
    }
  }

  const handleActionButtonClick = () => {
    !!props.onActionClick && props.onActionClick()

    if (props?.actionDataTestId) {
      Analytics.recordEventClick({
        name: props.actionDataTestId,
        attributes: {
          ...profile?.analytics,
          period_title: school?.school_periods[0].title ?? ''
        }
      })
    }
  }

  const ActionButton = () => {
    if (!action) {
      return <></>
    }

    return (
      <Button
        variant={getVariantBySeverity()}
        size='small'
        data-testid={props?.actionDataTestId ?? ''}
        style={props.themeColor ? { color: props.themeColor } : {}}
        onClick={handleActionButtonClick}>
        {action}
      </Button>
    )
  }

  return (
    <MUIAlert
      {...props}
      variant={outlined ? 'outlined' : 'filled'}
      action={<ActionButton />}
      className={`${props.className as string} ${classes.root}`}
    >
      {title && <MUIAlertTitle>{title}</MUIAlertTitle>}
      <span
        dangerouslySetInnerHTML={{
          __html: content ?? ''
        }}
      />
    </MUIAlert>
  )
}
