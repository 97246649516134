import { useTranslation } from 'react-i18next'
import { getGradesbySchool } from 'services/taught-disciplines'
import { useEffect, useState } from 'react'
import { toast } from 'components/design-system/Toast/manager'
import { IDisciplineResponse, IGradeResponse } from 'services/types'
import { useStore } from 'store'
import { getSchoolDisciplines } from 'services/school'
import { allAvailableDisciplinesAtom, disciplinesPerSegmentGradeAtom, gradesAtom, lastFetchProfileAtom, lastFetchTimestampAtom, segmentsAtom, selectedDisciplinesAtom } from '../atoms'
import { useAtom } from 'jotai'

export interface IGrades extends IGradeResponse {
  title: string
  segment: number
}

export interface IUniqueDisciplines {
  disciplines: IDisciplineResponse[]
  id: number
  name: string
  order: string
  code: "EI" | "EF1" | "EF2" | "EM" | "EM_IT" | "multigrade"
}

enum StateMachineEnum {
  IDLE = 'IDLE',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  READY = 'READY'
}

interface IUseGradeDisciplinesProps {
  grade?: IGrades
  onFetch?: () => void
}

// Cache duration in milliseconds (2 hours)
const CACHE_DURATION = 2 * 60 * 60 * 1000

export const useGradeDisciplines = (props: IUseGradeDisciplinesProps) => {
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [lastFetchTimestamp, setLastFetchTimestamp] = useAtom(lastFetchTimestampAtom)
  const [lastFetchProfile, setLastFetchProfile] = useAtom(lastFetchProfileAtom)

  const [grades, setGrades] = useAtom(gradesAtom)
  const [segments, setSegments] = useAtom(segmentsAtom)
  const [allAvailableDisciplines, setAllAvailableDisciplines] = useAtom(allAvailableDisciplinesAtom)
  const [disciplinesPerSegmentGrade, setDisciplinesPerSegmentGrade] = useAtom(disciplinesPerSegmentGradeAtom)
  const [disciplines, setDisciplines] = useAtom(selectedDisciplinesAtom)

  const { profile } = useStore()
  const { t } = useTranslation()

  const isCacheValid = (): boolean => {
    if (!lastFetchTimestamp || !lastFetchProfile) return false

    const isTimeValid = Date.now() - lastFetchTimestamp < CACHE_DURATION
    const isSameProfile = profile?.id === lastFetchProfile.id && profile?.school === lastFetchProfile.school
    return isTimeValid && isSameProfile
  }

  const hasData = (): boolean => {
    return Boolean(grades.length && segments.length && disciplinesPerSegmentGrade.length)
  }

  useEffect(() => {
    const shouldFetchData = !hasData() || !isCacheValid()

    if (shouldFetchData) {
      handleFetchData()
    } else {
      setStateMachine(StateMachineEnum.READY)
    }
  }, [])

  useEffect(() => {
    adjustGradeDisciplines()
  }, [disciplinesPerSegmentGrade, props.grade])

  // Cache cleanup effect
  useEffect(() => {
    const cleanup = () => {
      if (!isCacheValid()) {
        resetAtoms()
      }
    }

    const intervalId = setInterval(cleanup, CACHE_DURATION)

    return () => clearInterval(intervalId)
  }, [lastFetchTimestamp])

  const resetAtoms = () => {
    setLastFetchTimestamp(null)
    setLastFetchProfile(null)
    setGrades([])
    setSegments([])
    setAllAvailableDisciplines([])
    setDisciplines([])
  }

  const handleFetchData = async () => {
    setStateMachine(StateMachineEnum.LOADING)

    await Promise.all([
      handleGetGrades(),
      handleFetchDisciplines()
    ])

    if (props?.onFetch) {
      props.onFetch()
    }

    if (stateMachine === StateMachineEnum.ERROR) {
      return
    }

    setLastFetchTimestamp(Date.now())
    setLastFetchProfile(profile)
    setStateMachine(StateMachineEnum.READY)
  }

  const adjustGradeDisciplines = async () => {
    const selectedGrade = props.grade?.id
    const selectedSegment = props.grade?.segment

    if (selectedGrade === undefined || selectedSegment === undefined) return

    const availableGrades = disciplinesPerSegmentGrade?.find(segment =>
      segment?.id === selectedSegment)?.grades ?? []
    const availableDisciplines = availableGrades.find(grade => grade.id === selectedGrade)?.disciplines ?? []
    const disciplinesSorted = availableDisciplines
      .map(discipline => ({ ...discipline, title: discipline.name }))
      .sort((a, b) => a.name.localeCompare(b.name))
    setDisciplines(disciplinesSorted)
  }

  const handleFetchDisciplines = async () => {
    try {
      const school = profile?.school

      if (!school?.id) {
        throw new Error()
      }

      const response = await getSchoolDisciplines(school.id)

      if (!response.success) {
        throw new Error()
      }

      const availableDisciplines = response.data.filter(d => !['multigrade'].includes(d.code))

      setDisciplinesPerSegmentGrade(availableDisciplines)

      const allAvailableDisciplines = availableDisciplines.map(({ grades, ...segment }) => ({
        ...segment,
        disciplines: grades.map(grade =>
          grade.disciplines
        ).flat(2).sort((disciplineA, disciplineB) => disciplineA.name.localeCompare(disciplineB.name))
      }))

      const uniqueAllTaughtDisciplines: IUniqueDisciplines[] = allAvailableDisciplines.map(({ disciplines, ...segment }) => {
        const includedDisciplines: number[] = []
        const uninqueDisciplines = disciplines.filter(discipline => {
          if (!discipline.id) return false

          const isAlreadyIncluded = includedDisciplines.includes(discipline.id)

          if (isAlreadyIncluded) return false

          includedDisciplines.push(discipline.id)

          return true
        })

        return {
          ...segment,
          disciplines: uninqueDisciplines.map(discipline => ({ ...discipline, title: discipline.name }))
        }
      })

      setAllAvailableDisciplines(uniqueAllTaughtDisciplines)

    } catch (err) {
      setStateMachine(StateMachineEnum.ERROR)

      toast.handler({
        content: t('Não foi possivel capturar as disciplinas disponiveis.'),
        duration: 1000,
        severity: 'error'
      })
    }
  }

  const handleGetGrades = async () => {
    try {
      const response = await getGradesbySchool()

      if (!response?.success) {
        throw new Error()
      }

      const segments = response?.data.sort((a, b) => a.id - b.id)

      setSegments(segments)

      // @ts-expect-error
      const grades: IGrades[] = response?.data?.reduce<IGradeResponse[]>((aggGrades, gradeType) => {
        const grades = gradeType?.grades?.map(grade => {
          return {
            ...grade,
            title: grade.name,
            segment: gradeType.id
          }
        }) ?? []

        return [
          ...aggGrades,
          ...grades
        ]
      }, [])

      setGrades(grades)
    } catch (err) {
      setStateMachine(StateMachineEnum.ERROR)

      toast.handler({
        content: t('Não foi possivel capturar as séries disponiveis.'),
        duration: 1000,
        severity: 'error'
      })
    }
  }

  return {
    grades,
    disciplines,
    allAvailableDisciplines,
    stateMachine,
    segments
  }
}
