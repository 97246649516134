import * as React from 'react'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { Grid, Tag, Text2 } from 'components/design-system'
import makeStyles from './style'
import { Theme, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
interface IStatus {
  label: string
  type: 'positive' | 'warning' | 'negative' | 'information'
}

export interface ISheetStatusAccordionProps extends AccordionProps {
  status?: IStatus
  title: string
  subTitle?: string
  action?: React.ReactNode
  dataTestid?: string
  showDownload?: boolean
  handleSheetDownload: (e: any) => Promise<void>
}

export const SheetStatusAccordion: React.FC<ISheetStatusAccordionProps> = ({ children, action, status, title, subTitle, dataTestid, showDownload, handleSheetDownload, ...props }) => {
  const classes = makeStyles()
  const theme = useTheme<Theme>()
  const customTheme = useTheme<CustomTheme>()
  const openRef = React.createRef<HTMLAnchorElement>()

  const clickedToOpen = () => {
    openRef?.current?.click()
  }

  return (
    <>
      <a ref={openRef} data-testid={dataTestid}/>
      <MuiAccordion
        {...props} className={`${classes.root} ${props.className as string}`}
        sx={{
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }}
      >
        <MuiAccordionSummary expandIcon={<ExpandMoreOutlinedIcon />} onClick={() => clickedToOpen()}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={12} md={3} xl={5}>
              <Typography noWrap> <Text2 fontSize='md' fontWeight='medium' lineHeight='xxs' mobile='xs' neutral='dark30'>
                {title} <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxxs' neutral='light20'>{subTitle}</Text2>
              </Text2></Typography>
            </Grid>
            <Grid container item justifyContent='flex-end' md={8} xl={6} spacing={2}>
              {status &&
                <Grid container item className={classes.statusContainer} xs={12} md={4} xl={4}>
                  <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark30' className={classes.statusLabel}>Status: </Text2>
                  <Tag
                    active
                    background={theme.colors[status.type].feedbackLight}
                    label={status.label}
                    textcolor={customTheme.colors.neutral.darkBase}
                  />
                </Grid>
              }
              {action &&
                <Grid container item className={classes.statusContainer} md={6} xl={6}>
                  {action}
                </Grid>
              }
            </Grid>
            <Grid container item justifyContent='flex-start' md={1} xl={1}>
              {
                showDownload &&
                  <DownloadOutlinedIcon sx={{ color: theme.colorBrand.dark, cursor: 'pointer' }} onClick={ async (e) => await handleSheetDownload(e)}/>
              }
            </Grid>
          </Grid>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          {children}
        </MuiAccordionDetails>
      </MuiAccordion>
    </>
  )
}
