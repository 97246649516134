/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  chartContainer: {
    width: '100%',
    flexDirection: 'row !important' as 'row',
    alignItem: 'flex-start',
    flexWrap: 'nowrap !important' as 'nowrap'
  },
  chartDataContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap !important' as 'nowrap',
    flexDirection: 'column !important' as 'column',
    gap: theme.spacingInline.xxxs,
    justifyContents: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row'
    }
  },
  toolTipContainer: {
    backgroundColor: theme.colorBrand.darkest,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInset.xs,
    boxSizing: 'border-box',
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xxs,

    '& p': {
      margin: 0
    }
  },
  toolTipTitle: {
    fontWeight: theme.font.fontWeight.bold
  },
  toolTipColumnValue: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm
  },
  legendTable: {
    listStyle: 'none',
    paddingLeft: theme.spacingInline.xxxs,
    display: 'flex',
    gap: theme.spacingStack.nano,
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  legendTableItem: {
    fontSize: theme.font.fontSize.lg,
    verticalAlign: 'middle',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  legendBullet: {
    content: '',
    position: 'absolute',
    left: 0,
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  customLabel: {
    color: `${theme.colorBrand.medium} !important`,
    fontSize: theme.font.fontSize.xl
  },
  bulletPoint: {

  },
  summaryBulletList: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    justifyContent: 'center',

    '& .bulletPoint': {
      width: theme.font.fontSize.xxxs,
      height: theme.font.fontSize.xxxs,

      borderRadius: theme.borderRadius.circular
    }
  },
  bulletsItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacingInline.quarck,
    fontSize: theme.font.fontSize.xxs
  },
  bulletPerEnrollment: {
    color: `${theme.colors.support.colorSupport02} !important`,

    '& .bulletPoint': {
      backgroundColor: `${theme.colors.support.colorSupport02} !important`
    }
  },
  bulletPerReenrollment: {
    color: `${theme.colors.information.feedbackMedium} !important`,
    gap: theme.spacingInline.nano,
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,

    '& .bulletPoint': {
      backgroundColor: `${theme.colors.information.feedbackMedium} !important`
    }
  }
}))
