import { Calendar, ICalendarProps } from 'components/common'
import { ContentfulCard, IContentfulCardProps } from 'components/design-system'
import makeStyles from './style'
import { atomSelectingProgramDateCard } from '../../atomStore'
import { useAtom } from 'jotai'

interface IProgramManagementCardProps {
  contentfulCardProps: IContentfulCardProps
  calendarProps: ICalendarProps
  cardId: number
}

export const ProgramManagementCard: React.FC<IProgramManagementCardProps> = ({ children, ...props }) => {
  const styles = makeStyles()
  const [selectingProgramDateCard] = useAtom(atomSelectingProgramDateCard)

  return (
    <div className={styles.programClassContainer}>
      {
        selectingProgramDateCard !== props.cardId
          ? <ContentfulCard
            additionalClasses={styles.card}
            image={{
              src: props.contentfulCardProps.image.src,
              alt: props.contentfulCardProps.image.alt,
              height: props.contentfulCardProps.image.height,
              disabled: props.contentfulCardProps.image.disabled
            }}
            tag={{
              label: props.contentfulCardProps.tag?.label,
              discipline: props.contentfulCardProps.tag?.discipline
            }}
          >
            {children}
          </ContentfulCard>
          : <Calendar onCancel={props.calendarProps.onCancel} onConfirm={props.calendarProps.onConfirm} onChange={props.calendarProps.onChange}/>
      }
    </div>
  )
}
