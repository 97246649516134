import { useAtom } from 'jotai'
import { IClassItem } from 'services'
import { atomOriginClasses } from '../atomStore'

export const useClassesSelectionControl = () => {
  const [originClasses, setOriginClasses] = useAtom(atomOriginClasses)

  const enableStudentsIfAllClassesAreUnchecked = () => {
    if (originClasses) {
      const newClassList = [...originClasses]
      const areAllClassesUnselected = newClassList.every(cls => cls.checked === false)
      if (areAllClassesUnselected) {
        for (const currentClass of newClassList) {
          if (currentClass.students) {
            for (const student of currentClass.students) {
              student.disabled = false
            }
          }
        }
        setOriginClasses(newClassList)
      }
    }
  }

  const unselectClassWhichHasNoInnerSelection = () => {
    if (originClasses) {
      const newClassList = [...originClasses]
      for (const originClass of newClassList) {
        if (originClass?.students) {
          const unselectedItems = originClass.students.every(st => st.checked === false)
          if (unselectedItems && originClass.checked) {
            originClass.checked = false
            for (const student of originClass.students) {
              student.disabled = true
            }
            enableStudentsIfAllClassesAreUnchecked()
            setOriginClasses(newClassList)
          }
        }
      }
    }
  }

  const disableStudentsFromNonSelectedClasses = (selectedClass: IClassItem) => {
    if (originClasses) {
      const newClassList = [...originClasses]
      for (const originClass of newClassList) {
        if (originClass.students) {
          for (const student of originClass.students) {
            if (originClass.id === selectedClass.id && selectedClass.checked) {
              student.disabled = false
            } else if (originClass.id !== selectedClass.id && originClass.checked) {
              student.disabled = false
            } else {
              student.disabled = true
              student.checked = false
            }
          }
          setOriginClasses(newClassList)
        }
      }
    }
  }

  return {
    enableStudentsIfAllClassesAreUnchecked,
    unselectClassWhichHasNoInnerSelection,
    disableStudentsFromNonSelectedClasses
  }
}
