import { createRef, useState } from 'react'
import { IDiscipline } from 'services/types'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { CloeTag, Icon } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IListDisciplinesProps {
  dataTestid?: string
  disciplines?: IDiscipline[]
  currentDiscipline?: IDiscipline | null
  onChange?: (discipline: any) => void
  hidden?: boolean
  title?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  tagsContainer: {
    display: ({ hidden }: IListDisciplinesProps) => hidden ? 'none' : 'flex',
    fontSize: theme.font.fontSize.sm,
    flexFlow: 'inherit',
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',
    maxHeight: theme.spacingInset.md,
    alignItems: 'center',
    display: 'flex',

    '&.disabled': {
      cursor: 'not-allowed'
    },

    [theme.breakpoints.up('sm')]: {
      maxHeight: 'inherit',
      alignItems: 'inherit',
      display: 'inherit'
    }
  },
  titleContainer: {
    marginBlock: theme.spacingInline.nano,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.xs,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.sm
    },
    '& > p': {
      margin: 0
    }
  },
  titleDrop: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    display: 'flex',
    '& > p': {
      margin: 0
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))

export const ListDisciplines: React.FC<IListDisciplinesProps> = (props: IListDisciplinesProps) => {
  const { dataTestid, disciplines, currentDiscipline, onChange, hidden, title, disabled } = props
  const { t } = useTranslation()
  const classes = useStyles({ hidden })
  const disciplineRef = createRef<HTMLDivElement>()

  // states
  const [dropDown, setDropDown] = useState<boolean>(!!title)

  const handleClick = (discipline: any) => {
    disciplineRef?.current?.click()
    onChange?.(discipline)
  }

  return (
    <>
      {!!title && (
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {title}
          </div>
          <div
            className={classes.titleDrop}
            onClick={() => setDropDown(!dropDown)}
            id='see_all_mobile'
          >
            <p>{t('Ver todos')}</p>
            {dropDown
              ? <Icon titleAccess={t('Ver todos')} size='medium'>
                <KeyboardArrowUpOutlinedIcon />
              </Icon>
              : <Icon titleAccess={t('Recolher todos')} size='medium'>
                <KeyboardArrowDownOutlinedIcon />
              </Icon>
            }
          </div>
        </div>
      )}

      <div ref={disciplineRef} data-testid={dataTestid} hidden />
      <div className={`${classes.tagsContainer} ${!dropDown && 'active'}`}>
        {
          disciplines?.map(discipline =>
            <CloeTag
              key={discipline?.code}
              className={`${classes.tag}`}
              variant={currentDiscipline?.id === discipline.id ? discipline.code : undefined}
              onClick={() => handleClick(discipline)}
              disabled={disabled}
            >
              {t(discipline?.name ?? '')}
            </CloeTag>
          )
        }
      </div>
    </>
  )
}
