import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'components/common'
import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  Text2,
  Tag,
  ContentfulCard,
  Loading
} from 'components/design-system'
import { PROGRAM_MANAGEMENT } from 'navigation/CONSTANTS'
import AddIcon from '@mui/icons-material/Add'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import makeStyles from './style'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import {
  atomDiscipline,
  atomGradeType,
  atomGrade,
  atomDisciplineProgramList,
  atomCurrentSelectedProgram
} from '../../atomStore'
import { CreateSchoolProgramModal } from '../CreateSchoolProgramModal/index'
import { useState, useEffect } from 'react'
import {
  getSchoolProgramByGradetypeAndGrade,
  IContentUnit,
  IContentUnitWithDiscipline,
  IDisciplineResponse,
  IProgramRequest
} from 'services'
import { Typography } from '@mui/material'
import { useStore } from 'store'
import placeholder from 'assets/base-screen-background.svg'

import { useSnackbar } from 'navigation/hooks/useSnackbar'

export const CloeProgramList: React.FC = () => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { school } = useStore()
  const customTheme = useTheme<CustomTheme>()
  const { createSnackbar } = useSnackbar()

  // atoms
  const [currentDiscipline, setCurrentDiscipline] = useAtom(atomDiscipline)
  const [currentProgram, setCurrentProgram] = useAtom(atomCurrentSelectedProgram)
  const disciplineList = useAtomValue(atomDisciplineProgramList)
  const gradeType = useAtomValue(atomGradeType)
  const grade = useAtomValue(atomGrade)

  // states
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [contentUnits, setContentUnits] = useState<IContentUnitWithDiscipline[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchProgramData = async ({ gradeTypeId, gradeId, schoolId, discipline }: IProgramRequest) => {
    if (gradeTypeId && gradeId && schoolId && discipline) {
      try {
        setIsLoading(true)
        const response = await getSchoolProgramByGradetypeAndGrade(gradeTypeId, gradeId, schoolId, discipline.id)
        if (response.success) {
          setCurrentProgram(response.data)
          setContentUnits(filterContentUnitByDiscipline(discipline, response.data.content_units))
        } else {
          createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
        }
      } catch (err) {
        createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const filterContentUnitByDiscipline = (discipline: IDisciplineResponse, contentUnitList: IContentUnit[] | undefined) => {
    if (contentUnitList) {
      return contentUnitList.map(ctu => {
        if (ctu.discipline_id === discipline.id) {
          return {
            ...ctu,
            discipline_name: discipline.name,
            discipline_code: discipline.code
          }
        }
        return ctu
      }).filter(ctu => ctu.discipline_id === discipline.id)
    }
  }

  useEffect(() => {
    if (gradeType?.id && grade?.id && school?.id && currentDiscipline) {
      fetchProgramData({
        gradeTypeId: gradeType.id,
        gradeId: grade.id,
        schoolId: Number(school?.id),
        discipline: currentDiscipline
      })
    }
  }, [currentDiscipline, school?.id])

  useEffect(() => {
    if (disciplineList?.length) {
      setCurrentDiscipline(disciplineList[0])
    }
  }, [disciplineList])

  return (
    <Container>
      <Grid container>
        {/* Header */}
        <Grid container item alignItems='center' justifyContent='space-between' rowSpacing={3}>
          <Grid item xs={12} md={12}>
            <Link variant='returnArrow' onClick={() => history.push(PROGRAM_MANAGEMENT)} data-testid='program_cloe_back_to_home_screen' linkStyle>
              {t('Voltar para tela inicial')}
            </Link>
          </Grid>
          <Grid container item xs={12} md columnSpacing={1}>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='lg' colorbrand='darkest'>
                {currentProgram?.cloe_program ? t('Programa cloe') : t('Programa da escola')}
              </Text2>
            </Grid>
            <Grid item>
              <Tag variant='outlined' label={gradeType?.name} />
            </Grid>
            <Grid item>
              <Tag variant='outlined' label={grade?.name} />
            </Grid>
          </Grid>
          {currentProgram &&
            <Grid item xs={12} md='auto'>
              <Button variant='ghost' onClick={() => setOpenModal(true)} data-testid='program_cloe_create_school_program'>
                <AddIcon /> criar programa da escola
              </Button>
            </Grid>
          }
          <Grid container item xs={12} md={12} spacing={1}>
            <Grid item xs={12} md={12}>
              {t('Selecione um componente curricular')}:
            </Grid>
            {disciplineList?.map(d =>
              <Grid item key={d.id}>
                <Tag
                  label={d.name}
                  discipline={d.code}
                  className={`${d.id === currentDiscipline?.id ? 'active' : ''}`}
                  onClick={() => setCurrentDiscipline(d)}
                  data-testid='program_cloe_curricular_component'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Content units */}
        {
          isLoading
            ? <Grid container className={classes.loadingBox}><Loading type='circular' /></Grid>
            : <Grid container item justifyContent='flex-start' alignItems='flex-start' spacing={2} sx={{ marginTop: customTheme.spacingInline.md }}>
              <Grid container item alignItems='center'>
                <Grid item sx={{ marginRight: customTheme.spacingInline.nano }}>
                  <Avatar sx={{ bgcolor: customTheme.colorBrand.medium }}>
                    <NewspaperIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Text2 fontSize='md' fontWeight='bold' lineHeight='sm' mobile='sm' colorbrand='medium'>
                    {t('Unidade de conteúdo')}
                  </Text2>
                </Grid>
              </Grid>
              <Grid container item spacing={3} className={classes.cardsContainer}>
                {
                  contentUnits?.map((item) => (
                    <Grid item key={item.id}>
                      <ContentfulCard
                        additionalClasses={classes.card}
                        image={{
                          src: item?.cover?.url ?? placeholder,
                          alt: item?.title,
                          height: '141'
                        }}
                        tag={{
                          label: item.discipline_name ?? item?.code,
                          discipline: item.discipline_code
                        }}
                      >
                        <Grid container rowSpacing={2}>
                          <Grid container item rowSpacing={1}>
                            <Grid item xs={3} md={3}>
                              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark30'>
                                {item.classes} aulas
                              </Text2>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography noWrap><Text2 fontSize='sm' fontWeight='bold' lineHeight='xs' mobile='sm' colorbrand='medium'>
                                {item?.title}
                              </Text2></Typography>
                            </Grid>
                          </Grid>
                          <Grid container item rowSpacing={1} columnSpacing={1}>
                            {
                              item.skills.map(skill =>
                                <Grid item xs={4} md={4}>
                                  <Tooltip
                                    label={skill.code}
                                    placement='top-start'
                                    title={skill.name}
                                    variant='colored'
                                  >
                                    <Tag
                                      className={classes.tag}
                                      label={skill.code}
                                    />
                                  </Tooltip>
                                </Grid>
                              )
                            }
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                            <Button className={classes.accessContentButton} fullWidth>
                              Acessar o conteúdo
                            </Button>
                          </Grid> */}
                      </ContentfulCard>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
        }
      </Grid>
      <CreateSchoolProgramModal
        open={openModal}
        programData={{
          gradeId: grade?.id ?? 0,
          schoolId: Number(school?.id),
          gradeTypeId: gradeType?.id ?? 0
        }}
        onOpen={setOpenModal}
      />
    </Container>
  )
}
