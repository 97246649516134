import { UserSchoolProfileTypeEnum } from 'services'
import { ProfilesAccessedLastWeekBoxStudent } from './student'
import { ProfilesAccessedLastWeekBoxTeacher } from './teacher'

interface IProfilesAccessedLastWeekBox {
  selectedProfile: UserSchoolProfileTypeEnum
}

export const ProfilesAccessedLastWeekBox: React.FC<IProfilesAccessedLastWeekBox> = ({ selectedProfile }) => {
  if (selectedProfile === UserSchoolProfileTypeEnum.student) {
    return <ProfilesAccessedLastWeekBoxStudent />
  }
  if (selectedProfile === UserSchoolProfileTypeEnum.teacher) {
    return <ProfilesAccessedLastWeekBoxTeacher />
  }
  return <></>
}
