import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { MenuItem } from '@mui/material'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import { DropDown } from 'components/common/DropDown'

import useStyles from './style'

interface INavigationButtonProps {
  goTo: string
}

export const NavigationButton: React.FC<INavigationButtonProps> = ({ goTo }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const classes = useStyles()

  const handleNavigation = () => {
    history.push(goTo)
  }

  return (
    <div className={classes.navationButtonWrapper}>
      <DropDown name={t('Mais opções')}>
        <MenuItem className={classes.menuItemContainer} onClick={handleNavigation}>
          <div className={`${classes.navationButton}`} data-testid='home_page_coordinator_link_class_management'>
            <FolderOpenIcon /> {t('Ir para Gestão de turmas')}
          </div>
        </MenuItem>
      </DropDown>
    </div>
  )
}
