import React from 'react'
import { Container, Grid } from 'components/design-system'
import { QuickAccessHeader, ReEnrollmentBanner } from 'components/common'
import makeStyles from './style'
import { LastWeekAccess } from './components/LastWeekAccess'
import { NewEnrollmentsTimeline } from './components/NewEnrollmentsTimeline'
import { FeatureToggleSwitcher } from 'components/feature-toggle/FeatureToggleSwitcher'
import { ToggleFeaturesEnum } from 'components/feature-toggle/enabledFeatures'
import { NewEnrollmentsSummary } from './components/NewEnrollmentsSummary'
import { useTheme } from '@mui/material'
import { useFeatureToggle } from 'components/feature-toggle/hooks/useFeatureToggle'

export const Home: React.FC = () => {
  const classes = makeStyles()
  const theme = useTheme()

  const IS_RE_ENROLL_STAGE = true

  const { validateEnabledFeatures } = useFeatureToggle()

  const validateFeature = () => {
    if (validateEnabledFeatures) return !!validateEnabledFeatures(ToggleFeaturesEnum.enrollment_chart)
  }

  const isFeatureEnabled = validateFeature()

  const Banner = () => {
    if (IS_RE_ENROLL_STAGE) {
      return <ReEnrollmentBanner />
    }
    return <QuickAccessHeader />
  }

  return (
    <Container extraclasses={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Banner />
        </Grid>
        <Grid item xs={12}>
          <LastWeekAccess />
        </Grid>
        <Grid container item xs={12} spacing={theme.spacingInline.xxs}>
          <FeatureToggleSwitcher feature={ToggleFeaturesEnum.enrollment_chart}>
            <Grid item sm={12} md={6}>
              <NewEnrollmentsSummary />
            </Grid>
          </FeatureToggleSwitcher>
          <Grid item sm={12} md={isFeatureEnabled ? 6 : false}>
            <NewEnrollmentsTimeline />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
