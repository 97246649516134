/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ClassNameMap } from '@mui/material/styles'
import { heading, subtitle, body, colors } from './styles'

const useStyles = (type: string): ClassNameMap<string> => {
  switch (type) {
    case 'subtitle':
      return subtitle()
    case 'heading':
      return heading()
    default:
      return body()
  }
}

export interface ITextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  type?: 'heading' | 'subtitle' | 'body'
  size?: 'display' | 'xLarge' | 'large' | 'large2' | 'medium' | 'small' | 'small2' | 'small3' | 'small4' | 'smallMedium' | 'studentXs' | 'studentSmall'
  | 'default' | 'mdSemiBold' | 'smMedium'
  color?: 'darkest' | 'dark' | 'medium' | 'light' | 'lightest' | 'neutralDark' | 'neutralDark40' | 'neutralLight'
}

export const Text: React.FC<ITextProps> = ({ type = 'body', size = 'default', color, children, ...props }) => {
  const classes = useStyles(type)
  const colorClass = `${color as string}Color` ?? ''
  return (
    <span
      {...props}
      className={`${classes.root} ${size} ${colors().root} ${colorClass} ${props.className as string}`}
    >
      {children}
    </span>
  )
}
