import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
// TODO: remover variante do componente TAG, como design system esse componente deve ser totalmente agnostico
import { DisciplineCodeEnumKeys } from 'services/types/discipline'
import { DisciplineCodeEnum } from 'services/types'
import { createRef } from 'react'

export interface ICloeTagProps {
  variant?: DisciplineCodeEnumKeys
  label?: string
  background?: string
  color?: string
  className?: string
  onClick?: () => void
  tabIndex?: number
  disabled?: boolean
  dataTestid?: string
  disableDoubleClick?: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  tag: {
    transition: 'all ease-in-out 0.1s',
    color: (props: ICloeTagProps) => {
      if (!props.variant) return props.color ? props.color : theme.colors.neutral.darkBase
      if (props.variant === DisciplineCodeEnum.ALL) return theme.colors.neutral.lightBase
      else return theme.colors.neutral.darkBase
    },
    background: (props: ICloeTagProps) => {
      if (!props.variant) return props.background ? props.background : theme.colors.neutral.light10
      else return theme.colors.curricularComponents[props.variant] as any ?? theme.colors.curricularComponents.DEFAULT
    },
    padding: '7px 10px',
    minWidth: 'fit-content',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    borderRadius: theme.borderRadius.xs,
    cursor: (props: ICloeTagProps) => {
      if (props.disabled) return 'not-allowed'
      else if (props.onClick) return 'pointer'
      return 'default'
    }
  }
}))

export const CloeTag: React.FC<ICloeTagProps> = ({ children, label, dataTestid, ...props }) => {
  const classes = useStyles(props)
  const inputRef = createRef<HTMLDivElement>()

  const handleClick = () => {
    if (!props.disableDoubleClick) {
      if (dataTestid) inputRef?.current?.click()
    }
    props?.onClick?.()
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid} hidden />
      <label
        data-testid={dataTestid ?? ''}
        className={`ui-ds-tag ${classes.tag} ${props.className ?? ''}`}
        tabIndex={props?.tabIndex ?? 0}
        onClick={!props.disabled ? handleClick : undefined}
      >
        {!label ? children : label}
      </label>
    </>
  )
}
