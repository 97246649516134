import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCommonStyles } from '../../style'
import useStyles from './style'
import { useStore } from 'store'
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import { Brand, Icon } from 'components/design-system'
import { atomCurrentActiveMenuSidebar } from 'navigation/atomStore'
import { useAtom } from 'jotai'
import { atomProfileTab } from 'pages/ProfileTabs/atomStore'
import { PROFILE_TABS, CONTENT_MANAGEMENT, CLASS_MANAGEMENT, ROOT } from 'navigation/CONSTANTS'
import { SideBarItem } from '../SideBarItem'
import { useResetAtom } from 'jotai/utils'
import { useLmsPlatform } from 'navigation/hooks/useLmsPlatform/useLmsPlatform'
import { useAppcues } from 'components/AppCues/useAppcues'
import { AppcuesEventsEnum } from 'components/AppCues/events'

interface IUpperMenuProps {
  activeMenuSidebar: boolean
  toggleActiveMenuSidebar: () => void
}

export const UpperMenu: React.FC<IUpperMenuProps> = ({ activeMenuSidebar, toggleActiveMenuSidebar }) => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { profile } = useStore()

  // atoms
  const [currentActiveMenuSidebar, setCurrentActiveMenuSidebar] = useAtom(atomCurrentActiveMenuSidebar)

  const { eventDispatcher } = useAppcues()
  const { getLink } = useLmsPlatform(t, profile)

  const resetProfileTab = useResetAtom(atomProfileTab)

  const getLmsLink = async () => {
    setCurrentActiveMenuSidebar('lms')

    eventDispatcher(AppcuesEventsEnum.adm_link_formacao)

    await getLink()
  }

  const handleUserManagement = () => {
    resetProfileTab()
    setCurrentActiveMenuSidebar('user-management')
  }

  return (
    <div className={`${commonClasses.box} ${!activeMenuSidebar && commonClasses.beforeMobile}`}>
      <div className={`${commonClasses.top} ${classes.topBevel}`} />
      <div className={classes.inLineBrandMobile}>
        <Icon titleAccess='CloseIcon' onClick={toggleActiveMenuSidebar} size='large'>
          <CloseOutlinedIcon />
        </Icon>
        <Brand size='sm' type='logo' variant='white' />
      </div>
      <SideBarItem
        dataTestId='menu_home_coordinator_home_page'
        href={ROOT}
        icon={<HomeOutlinedIcon />}
        label='Página inicial'
        onClick={() => setCurrentActiveMenuSidebar('home')}
        selected={currentActiveMenuSidebar === 'home'}
      />
      <SideBarItem
        dataTestId='menu_home_coodinator_class_management'
        href={CLASS_MANAGEMENT('list')}
        icon={<FolderOpenIcon />}
        label='Gestão de turmas'
        onClick={() => setCurrentActiveMenuSidebar('class-management')}
        selected={currentActiveMenuSidebar === 'class-management'}
      />
      <SideBarItem
        dataTestId='menu_home_coodinator_user_management'
        href={PROFILE_TABS('teachers')}
        icon={<SupervisedUserCircleOutlinedIcon />}
        label='Gestão de usuários'
        onClick={handleUserManagement}
        selected={currentActiveMenuSidebar === 'user-management'}
      />
      <SideBarItem
        dataTestId='menu_home_coodinator_program_management'
        href={CONTENT_MANAGEMENT('program-content')}
        icon={<InventoryOutlinedIcon />}
        label='Gestão de conteúdos'
        onClick={() => setCurrentActiveMenuSidebar('program-management')}
        selected={currentActiveMenuSidebar === 'program-management'}
      />
      <SideBarItem
        dataTestId='menu_home_coodinator_courses'
        icon={<LocalLibraryOutlinedIcon />}
        label='Formação'
        onClick={async () => await getLmsLink()}
        selected={currentActiveMenuSidebar === 'lms'}
      />
      <div className={classes.bottom} />
    </div>
  )
}
