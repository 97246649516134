import { Checkbox, Text2, TextField } from 'components/design-system'
import { ClassListEnum, IClassItem, IClassRequest } from 'services'
import { StudentSelectionList } from 'pages/ClassManagement/components'
import makeStyles from './style'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useState, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useStore } from 'store'
import { atomReloadDestinyList, atomSchoolPeriod, atomClassToDelete, atomSelectedFiltersDestiny } from 'pages/ClassManagement/atomStore'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useEditSchoolClass } from '../../hooks'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
interface IClassCardAccordionProps {
  classItem: IClassItem
  classListType: ClassListEnum
  selectedOriginClass: boolean
  selectedDestinyClass: boolean
  onSelectOriginClass: (classItem: IClassItem) => void
  onSelectDestinyClass: (classItem: IClassItem) => void
}

type OriginAccordionStates = 'selectable'
type DestinyAccordionStates = 'selectable' | 'creatingClass' | 'renamingClass'
interface ICreateClass {
  studentClassName: string
}

export const ClassCardAccordion: React.FC<IClassCardAccordionProps> = ({ classItem, classListType, selectedOriginClass, selectedDestinyClass, onSelectOriginClass, onSelectDestinyClass }) => {
  const { t } = useTranslation()
  const classes = makeStyles()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const { error, editClassHandler } = useEditSchoolClass()
  const expandAccordionRef = useRef<HTMLDivElement | null>(null)

  // state machines
  const [originAccordionState] = useState<OriginAccordionStates>('selectable')
  const [destinyAccordionState, setDestinyAccordionState] = useState<DestinyAccordionStates>('selectable')
  const [accordionDataTestId, setAccordionDataTestId] = useState<string>('menu_class_management_import_class_recall')

  const expandIcon = <ExpandMoreIcon fontSize='large' className={classes.expandMoreIcon} data-testid={accordionDataTestId}/>

  const handleTagAccordionBasedOnExpandedAttr = () => {
    const isAccordionExpanded = expandAccordionRef.current?.attributes.getNamedItem('aria-expanded')?.value
    isAccordionExpanded === 'true'
      ? setAccordionDataTestId('menu_class_management_import_class_recall')
      : setAccordionDataTestId('menu_class_management_import_class_expand')
  }

  // atoms
  const selectedFiltersDestiny = useAtomValue(atomSelectedFiltersDestiny)
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const setReloadDestinyList = useUpdateAtom(atomReloadDestinyList)
  const setClassToDelete = useUpdateAtom(atomClassToDelete)

  const handleOriginClassSelection = (_classItem: IClassItem) => {
    if (_classItem) onSelectOriginClass(_classItem)
  }

  const handleDestinyClassSelection = (_classItem: IClassItem) => {
    if (_classItem) onSelectDestinyClass(_classItem)
  }

  const deleteClass = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setClassToDelete(classItem)
  }

  const studentClassNameSchema = Yup.object().shape({
    studentClassName: Yup.string().required('Campo obrigatório')
  })

  const initialValuesForm = {
    studentClassName: classItem?.name ?? ''
  }

  const handleRenameClass = async (values: ICreateClass, _classItem: IClassItem) => {
    if (_classItem && school?.id && selectedFiltersDestiny?.grade && selectedFiltersDestiny?.schoolPeriod) {
      const payload: IClassRequest = {
        name: values.studentClassName.trim(),
        student_limit: 30,
        school: school.id,
        grades: [selectedFiltersDestiny.grade],
        school_period: selectedFiltersDestiny.schoolPeriod
      }
      const response = await editClassHandler(payload, { classId: _classItem.id, schoolId: school.id })

      if (response?.success) {
        createSnackbar({ content: 'Turma editada com sucesso', severity: 'success' })
        setDestinyAccordionState('selectable')
        setReloadDestinyList(true)
      }

      if (error) {
        createSnackbar({ content: error, severity: 'error' })
      }
    }
  }

  const originAccordionStateMachine = {
    states: {
      selectable: (
        <Accordion className={classes.accordion} defaultExpanded disableGutters data-testid={accordionDataTestId}>
          <AccordionSummary
            ref={expandAccordionRef}
            expandIcon={expandIcon}
            onClick={handleTagAccordionBasedOnExpandedAttr}
            data-testid={accordionDataTestId}
          >
            <Grid container alignItems='center' justifyContent='space-between' className={classes.accordionSummaryGrid}>
              <Grid item onClick={(e: React.MouseEvent<HTMLDivElement>) => { e.stopPropagation() }}>
                <Checkbox
                  onChange={() => handleOriginClassSelection(classItem)}
                  label={classItem.name}
                  customClasses={classes.checkboxOrigin}
                  checked={selectedOriginClass}
                  aria-checked={selectedOriginClass}
                  dataTestid='menu_class_management_import_class_originl_class'
                />
              </Grid>
              {(classListType === ClassListEnum.origin && classItem.students && classItem.students.length > 0) && (
                <Grid item>
                  <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='light40' cursor='pointer'>
                    {t('{{totalStudents}} selecionados', { totalStudents: classItem.students?.filter(s => s.checked).length })}
                  </Text2>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant='fullWidth' className={classes.divider} />
            <StudentSelectionList classItem={classItem} classListType={classListType} students={classItem.students ?? []} />
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  const destinyAccordionStateMachine = {
    states: {
      selectable: (
        <Accordion className={`${classes.accordion} ${selectedDestinyClass ? 'selected' : ''}`} defaultExpanded disableGutters>
          <AccordionSummary expandIcon={expandIcon}>
            <Grid container alignItems='center' justifyContent='space-between' className={classes.accordionSummaryGrid}>
              <Grid item xs={6} xl={8}>
                <FormControlLabel
                  label={classItem.name}
                  onClick={(e: React.MouseEvent<HTMLLabelElement>) => { e.stopPropagation() }}
                  className={classes.radioDestinyLabel}
                  control={
                    <Radio
                      checked={selectedDestinyClass}
                      onChange={() => handleDestinyClassSelection(classItem)}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation() }}
                      name={classItem.name}
                      disableRipple
                    />
                  }
                />
              </Grid>
              <Grid container item xs='auto' xl='auto' spacing={2}>
                <Grid item xs display='flex' justifyContent='flex-end'>
                  <Text2
                    fontSize='xs' fontWeight='semibold' lineHeight='xs' colorbrand='medium' mobile='xxs' cursor='pointer'
                    data-testid='menu_class_management_import_rename_class'
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation()
                      setDestinyAccordionState('renamingClass')
                    }}>
                    {t('Renomear')}
                  </Text2>
                </Grid>
                {!schoolPeriod?.current && (
                  <Grid item xs display='flex' justifyContent='flex-end'>
                    <Text2
                      onClick={deleteClass} fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' negative='feedbackMedium' cursor='pointer'
                      data-testid='menu_class_management_import_class_delete_class'
                    >
                      {t('Excluir')}
                    </Text2>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant='fullWidth' className={classes.divider} />
            <StudentSelectionList classItem={classItem} classListType={classListType} students={classItem.students ?? []} />
          </AccordionDetails>
        </Accordion>
      ),
      creatingClass: null,
      renamingClass: (
        <Accordion className={classes.accordion} defaultExpanded disableGutters>
          <AccordionSummary expandIcon={expandIcon}>
            <Grid container alignItems='center' justifyContent='space-between' className={classes.accordionSummaryGrid}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValuesForm}
                  validationSchema={studentClassNameSchema}
                  onSubmit={async (values) => { handleRenameClass(values, classItem) }}
                >
                  {({ values, errors, handleSubmit, handleChange }) => (
                    <Form onSubmit={ handleSubmit } >
                      <Grid container spacing={2}>
                        <Grid item md xl>
                          <Field
                            component={TextField}
                            name='studentClassName'
                            id='studentClassName'
                            variant='filled'
                            label={t('Nome da turma*')}
                            value={values.studentClassName}
                            onChange={handleChange}
                            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                            assistivetext={errors.studentClassName ? t('Campo obrigatório') : undefined}
                            error={Boolean(errors.studentClassName)}
                            className={classes.studentClassNameInput}
                          >
                          </Field>
                        </Grid>
                        <Grid container item md='auto' xl='auto' spacing={2} display='flex' justifyContent='space-between' alignItems='center'>
                          <Grid item xs display='flex' justifyContent='flex-end'>
                            <Text2
                              fontSize='xs' fontWeight='semibold' lineHeight='sm' colorbrand='medium' mobile='xs' cursor='pointer'
                              onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation()
                                handleSubmit()
                              }}>
                              {t('Salvar')}
                            </Text2>
                          </Grid>
                          <Grid item xs display='flex' justifyContent='flex-end'>
                            <Text2
                              fontSize='xs' fontWeight='semibold' lineHeight='sm' negative='feedbackMedium' mobile='xs' cursor='pointer'
                              data-testid='menu_class_management_import_cancel_changes'
                              onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation()
                                setDestinyAccordionState('selectable')
                              }}
                            >
                              {t('Cancelar')}
                            </Text2>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant='fullWidth' className={classes.divider} />
            <StudentSelectionList classItem={classItem} classListType={classListType} students={classItem.students ?? []} />
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  return classListType === ClassListEnum.origin
    ? originAccordionStateMachine.states[originAccordionState]
    : destinyAccordionStateMachine.states[destinyAccordionState]
}
