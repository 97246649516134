import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import useStyle from './styles'

export const BigNumberSkeleton: React.FC = () => {
  const classes = useStyle()

  return (
    <Grid container item xs={12} className={`${classes.bigNumbersItem} ${classes.bigNumbersItemLoading}`}>
      <Grid item xs={12}>
        <Skeleton variant='circular' width={64} height={64} animation='wave' />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' sx={{ width: '100%' }} height={24} animation='wave' />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' sx={{ width: '40%' }} height={12} animation='wave' />
      </Grid>
    </Grid>
  )
}
