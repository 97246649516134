import { Font, StyleSheet } from '@react-pdf/renderer'

import MontserratThin from 'assets/fonts/Montserrat-Thin.ttf'
import MontserratExtraLight from 'assets/fonts/Montserrat-ExtraLight.ttf'
import MontserratLight from 'assets/fonts/Montserrat-Light.ttf'
import MontserratRegular from 'assets/fonts/Montserrat-Regular.ttf'
import MontserratMedium from 'assets/fonts/Montserrat-Medium.ttf'
import MontserratSemiBold from 'assets/fonts/Montserrat-SemiBold.ttf'
import MontserratBold from 'assets/fonts/Montserrat-Bold.ttf'
import MontserratExtraBold from 'assets/fonts/Montserrat-ExtraBold.ttf'
import MontserratBlack from 'assets/fonts/Montserrat-Black.ttf'

Font.register({
  family: 'Montserrat',
  format: 'truetype',
  fonts: [
    { src: MontserratThin, fontWeight: 100 },
    { src: MontserratExtraLight, fontWeight: 200 },
    { src: MontserratLight, fontWeight: 300 },
    { src: MontserratRegular, fontWeight: 400 },
    { src: MontserratMedium, fontWeight: 500 },
    { src: MontserratSemiBold, fontWeight: 600 },
    { src: MontserratBold, fontWeight: 700 },
    { src: MontserratExtraBold, fontWeight: 800 },
    { src: MontserratBlack, fontWeight: 900 }
  ]
})

Font.registerHyphenationCallback(word => (
  [word]
))

// unit used is pt
export const commonStyles = StyleSheet.create({
  bold: {
    fontWeight: 'bold'
  },
  displayFlexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flex4: {
    flex: 4
  },
  grayBackgound: {
    backgroundColor: '#F9F9F9'
  },
  grow0: {
    flexGrow: 0
  },
  infoTitle: {
    color: '#4B61AA'
  },
  medium: {
    fontWeight: 'medium'
  },
  negativeTitle: {
    color: '#C91212'
  },
  outlinedCard: {
    border: '0.8 solid #E6E6E6',
    borderRadius: 8
  },
  outlinedContentCard: {
    color: '#434343',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 9,
    padding: '18 24'
  },
  page: {
    flexDirection: 'column',
    fontFamily: 'Montserrat',
    padding: '21 28.5 42 28.5'
  },
  pageNumber: {
    bottom: 28.5,
    color: '#4B61AA',
    fontSize: 7.5,
    left: 0,
    position: 'absolute',
    right: 28.5,
    textAlign: 'right'
  },
  pill: {
    backgroundColor: '#F7F8FB',
    borderRadius: 24,
    color: '#4B61AA',
    flexDirection: 'column',
    flexGrow: 0,
    padding: '12 24'
  },
  pillLabel: {
    fontSize: 9,
    fontWeight: 'medium'
  },
  pillName: {
    fontSize: 9,
    fontWeight: 'bold'
  },
  positiveTitle: {
    color: '#00B086'
  },
  section: {
    marginTop: 24
  },
  sectionTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 13.5,
    fontWeight: 'semibold',
    marginBottom: 6
  },
  spaceBetween: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleIcon: {
    marginRight: 7.5,
    padding: 1, // used to prevent image cropping
    width: 18
  },
  whiteBackgound: {
    backgroundColor: '#FFFFFF'
  }
})
