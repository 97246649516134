/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Box, Grid, Skeleton } from '@mui/material'

import useStyle from './styles'
import { BigNumber } from '../'
import { TeacherCard } from '../BigNumbersReady/components'

export const BigNumbersLoading: React.FC = () => {
  const classes = useStyle()

  return (
    <>
      <Skeleton variant='text' width={160} height={60} animation='wave' />

      <Grid
        container
        spacing={8}
        marginTop={3.5}
        mx='auto'
        className={classes.classTeachersContainer}
      >
        <Box>
          <TeacherCard.Skeleton />
        </Box>
        <Box>
          <TeacherCard.Skeleton />
        </Box>
        <Box>
          <TeacherCard.Skeleton />
        </Box>
      </Grid>

      <Grid container item spacing={2} marginTop={1}>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Skeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Skeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Skeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Skeleton />
        </Grid>
      </Grid>
    </>
  )
}
