import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    optionCard: {
      color: theme.colors.neutral.dark30,
      cursor: 'pointer',
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold,
      lineHeight: '24px'
    },
    orderBySelectionModal: {
      '& .MuiBox-root': {
        '&:focus': {
          outline: 'none'
        }
      }
    }
  }
})
