import MUiTab from '@mui/material/Tab'
import { TabProps } from '@mui/material'
import useStyles from './style'

export const Tab: React.FC<TabProps> = props => {
  const classes = useStyles()
  return (
    <MUiTab
      className={`${classes.root}`}
      disableRipple
      {...props}
      id={`cloe-tab-${props.id as string}`}
    />
  )
}
