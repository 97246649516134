import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    card: {
      padding: theme.spacingInset.quarck,
      margin: `${theme.spacingStack.xxs} 0`,
      width: '100%'
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '112px',
      padding: theme.spacingInset.quarck,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 10px transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.colors.neutral.light20,
        outline: 'none',
        borderRadius: theme.borderRadius.sm
      }
    }
  }
})
