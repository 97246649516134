import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiAccordion-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: `${theme.borderRadius.sm}!important`,
      boxShadow: 'none'
    },
    '& .MuiAccordionSummary-root': {
      padding: '12px 16px !important'
    },
    '& .MuiAccordionSummary-content': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 24px)'
      }
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.colorBrand.medium
    }
  },
  statusLabel: {
    paddingRight: '4px'
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  }
}))
