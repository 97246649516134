import React from 'react'
import useStyle from '../style'
import { Grid, Text2 } from 'components/design-system'
import { Typography } from '@mui/material'
import { ClipBoardCopy } from '../../../ClipBoardCopy'
import { useTranslation } from 'react-i18next'

export interface IUsernameProps {
  username: string
  copyButtonDataTestId?: string
}

export const Username: React.FC<IUsernameProps> = ({ username, copyButtonDataTestId }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container item direction='column' xs={12} md={6} lg={2}>
      <Grid item>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
          {t('Nome de usuário')}
        </Text2>
      </Grid>
      <Grid container item alignItems='center' wrap='nowrap' className={classes.info}>
        <Typography noWrap>
          <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40' className={classes.ellipsis}>
            {username ?? '-'}
          </Text2>
        </Typography>
        <ClipBoardCopy dataTestId={copyButtonDataTestId} copyItem={username ?? '-'} />
      </Grid>
    </Grid>
  )
}
