import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DoubleColumnContainer, LeftColumnContent, RightColumnContent, DatePicker } from 'components/common'
import { Button, Grid, Link, Text2, TextField, Select, Switch } from 'components/design-system'
import { CLASS_MANAGEMENT, PROFILE_TABS, USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { useStore } from 'store'
import { useAtomValue } from 'jotai/utils'
import { Formik, Form, Field, FieldProps } from 'formik'
import useStyles from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import {
  createClassSubscription,
  createUserSchoolProfile,
  getClassSubscriptionBySchoolClassUser,
  getUserByEmail,
  getUserByPhone,
  getUserSchoolProfileBySchoolAndUser,
  IClassSubscriptionRequest,
  IUserSchoolProfileRequest,
  UserSchoolProfileTypeEnum,
  IUserStudentRegisterRequest,
  StudentFormValues,
  updateClassSubscription,
  updateUserSchoolProfile,
  userRegister
} from 'services'
import { StudentSchema } from 'schemas/yup/student'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { generateDefaultEmail, isDoNotSendEmail } from 'utils'
import { useSnackbar, useUserSendDefaultCredentials } from 'navigation/hooks'
import { atomCurrentClass } from 'pages/ClassManagement/atomStore'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'

export const CreateStudent: React.FC = () => {
  const { t } = useTranslation()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const { sendCredentials } = useUserSendDefaultCredentials()
  const { allGradesData } = useFetchAllGradeTypes()

  const history = useHistory()
  const styles = useStyles()

  // atoms
  const currentClass = useAtomValue(atomCurrentClass)

  // states
  const [userSchoolProfileId, setUserSchoolProfileId] = useState<number>(0)

  const initialValues = {
    name: '',
    surname: '',
    birthDate: '',
    email: '',
    phone: '',
    anchorGrade: '',
    enrollment: '',
    sendCredentialsNotice: false
  }

  const handlePreFormatData = ({ name, surname, birthDate, email, phone, anchorGrade, enrollment }: StudentFormValues): IUserStudentRegisterRequest => ({
    email: email ? email?.trim() : generateDefaultEmail(),
    name: name?.trim() ?? '',
    surname: surname?.trim() ?? '',
    birth_date: birthDate
      ? parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }).toISOString()
      : '',
    phone: phone ? Number(phone?.replace(/\D/g, '')) : null,
    profile: UserSchoolProfileTypeEnum.student,
    schoolId: school?.id ?? 0,
    anchor_grade: anchorGrade,
    confirmed: false,
    classId: currentClass?.id ?? 0,
    enrollment: enrollment ? enrollment.trim() : null,
    is_single_registration: true,
    isDefaultPass: true,
    sentDefaultCredentials: false
  })

  const getUserByEmailOrPhone = async (user: IUserStudentRegisterRequest, formattedBirthDate: string) => {
    if (user.email && !(isDoNotSendEmail(user.email))) {
      const usersFoundByEmail = await getUserByEmail(user.email)
      if (usersFoundByEmail.data.length) {
        return usersFoundByEmail.data.find(u =>
          u.name === user.name &&
          u.surname === user.surname &&
          u.birth_date === formattedBirthDate
        )
      }
    }

    if (user.phone) {
      const usersFoundByPhone = await getUserByPhone(user.phone)
      if (usersFoundByPhone.data.length) {
        return usersFoundByPhone.data.find(u =>
          u.name === user.name &&
          u.surname === user.surname &&
          u.birth_date === formattedBirthDate
        )
      }
    }
  }

  const handleUserSchoolProfile = async (userProfile: string, userFoundId: number) => {
    let responseUserSchoolProfile

    // checks if there is a 'UserSchoolProfile' looking for the school id, profile type and user id found
    if (school?.id && userProfile && userFoundId) {
      responseUserSchoolProfile = await getUserSchoolProfileBySchoolAndUser(school?.id, userProfile, userFoundId)
    }

    let inactiveUserSchoolProfile

    if (responseUserSchoolProfile?.data.length) {
      const activeUserSchoolProfile = responseUserSchoolProfile?.data.find(usp => usp.active)

      // If there is already an active record returns true
      if (activeUserSchoolProfile) return true

      inactiveUserSchoolProfile = responseUserSchoolProfile?.data.find(usp => !usp.active)
      if (inactiveUserSchoolProfile) {
        const userSchoolProfileRequest: IUserSchoolProfileRequest = {
          active: true
        }
        await updateUserSchoolProfile(inactiveUserSchoolProfile.id, userSchoolProfileRequest)
      }
    }

    // if there is no 'UserSchoolProfile' registered for this user, then we register a new one
    if (!responseUserSchoolProfile?.data.length) {
      const userSchoolProfileRequest: IUserSchoolProfileRequest = {
        active: true,
        school: Number(school?.id),
        type: UserSchoolProfileTypeEnum.student,
        user: userFoundId,
        year: new Date().getFullYear()
      }
      await createUserSchoolProfile(userSchoolProfileRequest)
    }

    setUserSchoolProfileId(responseUserSchoolProfile?.data[0].id ?? 0)

    return false
  }

  const handleClassSubscription = async (userProfile: string, userFoundId: number) => {
    let responseClassSubscription

    // checks if there is a 'ClassSubscription' looking for the school id, class id and user id found
    if (school?.id && userProfile && userFoundId) {
      responseClassSubscription = await getClassSubscriptionBySchoolClassUser(school?.id, Number(currentClass?.id), userFoundId)
    }

    let inactiveClassSubscription

    if (responseClassSubscription?.data.length) {
      const activeUserSchoolProfile = responseClassSubscription?.data.find(cs => cs.active)

      // If there is already an active record returns true
      if (activeUserSchoolProfile) return true

      inactiveClassSubscription = responseClassSubscription?.data.find(cs => cs.active === false)
      if (inactiveClassSubscription) {
        const classSubscriptionRequest: IClassSubscriptionRequest = {
          ...inactiveClassSubscription,
          active: true,
          class_id: inactiveClassSubscription.class_id.id,
          user_id: inactiveClassSubscription.user_id.id,
          user_school_profile: inactiveClassSubscription.user_school_profile.id
        }
        await updateClassSubscription(inactiveClassSubscription.id, classSubscriptionRequest)
      }
    }

    // if there is no 'ClassSubscription' registered for this user, then we register a new one
    if (!responseClassSubscription?.data.length && !inactiveClassSubscription && school?.id && userProfile && userFoundId && userSchoolProfileId) {
      const classSubscriptionRequest: IClassSubscriptionRequest = {
        active: true,
        class_id: Number(currentClass?.id),
        grade_year: new Date().getFullYear(),
        user_id: userFoundId,
        user_school_profile: userSchoolProfileId
      }
      await createClassSubscription(classSubscriptionRequest)
    }

    return false
  }

  const handleExistingUser = async (userProfile: string, userFoundId?: number) => {
    if (userFoundId) {
      const checkUserSchoolProfile = await handleUserSchoolProfile(userProfile, userFoundId)
      const checkClassSubscription = await handleClassSubscription(userProfile, userFoundId)

      if (checkUserSchoolProfile && checkClassSubscription) {
        createSnackbar({ content: t('Este usuário já está cadastrado nessa turma.'), severity: 'error' })
      } else {
        history.push(USER_MANAGEMENT('students/success-registration'))
      }
    }
  }

  const handleSubmit = async ({ name, surname, birthDate, email, phone, anchorGrade, enrollment, sendCredentialsNotice }: StudentFormValues) => {
    const user = handlePreFormatData({ name, surname, birthDate, email, phone, anchorGrade, enrollment })

    // date in format yyyy-MM-dd used to compare
    const formattedBirthDate = format(parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }), 'yyyy-MM-dd')

    // checks if there is already a user registered with this e-mail address or phone number
    const userFound = await getUserByEmailOrPhone(user, formattedBirthDate)

    if (!userFound) {
      const response = await userRegister(user)

      if (response.success) {
        if (sendCredentialsNotice) {
          await sendCredentials({
            users: [Number(response.data.id)]
          })
        }
        history.push(USER_MANAGEMENT('students/success-registration'))
      } else {
        createSnackbar({ content: t('Ocorreu um erro ao tentar cadastrar o usuário. Tente novamente.'), severity: 'error' })
      }
    } else {
      handleExistingUser(user.profile, Number(userFound?.id))
    }
  }

  const handleBackButtonLink = () => history.push(currentClass
    ? CLASS_MANAGEMENT(`view/${currentClass?.id}`)
    : PROFILE_TABS('students')
  )

  return (
    <DoubleColumnContainer rounded>
      <LeftColumnContent>
        <Grid container rowSpacing={4}>
          <Grid container item rowSpacing={6}>
            <Grid item xs={12}>
              <Link
                variant='returnArrow'
                onClick={handleBackButtonLink}
                linkStyle
              >
                {t('Voltar para turma')}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
                {t('Formulário de cadastro')}
              </Text2>
            </Grid>
          </Grid>
          <Grid item>
            <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
              {t('Informe os dados do novo estudantes nos campos ao lado.')}
            </Text2>
          </Grid>
        </Grid>
      </LeftColumnContent>
      <RightColumnContent>
        <Grid container className={styles.title}>
          <Text2 fontSize='md' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark40'>
            {t('Turmas que o estudante fará parte')}: {' '}
            <Text2 fontSize='md' fontWeight='semibold' lineHeight='xxs' mobile='xxs' neutral='dark40'>{currentClass?.name}</Text2>
          </Text2>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={StudentSchema}
          onSubmit={async (values) => await handleSubmit(values)}
          validateOnBlur={false}
        >
          {({ dirty, errors, isValid, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={5}>
                <Grid container item xs={12} md={8} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      *{t('Campo obrigatório')}
                    </Text2>
                  </Grid>
                  <Grid item xs={12} md={12} className={styles.fieldGrid}>
                    <Field name='name'>
                      {({ field }: FieldProps<StudentFormValues>) => (
                        <TextField
                          {...field}
                          id='name'
                          variant='filled'
                          label={`${t('Nome')}*`}
                          error={Boolean(errors?.name && touched.name)}
                          assistivetext={(errors?.name && touched.name)
                            ? errors?.name
                            : ''
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={styles.fieldGrid}>
                  <Field name='surname'>
                    {({ field }: FieldProps<StudentFormValues>) => (
                      <TextField
                        {...field}
                        id='surname'
                        variant='filled'
                        label={`${t('Sobrenome')}*`}
                        error={Boolean(errors?.surname && touched.surname)}
                        assistivetext={(errors?.surname && touched.surname)
                          ? errors?.surname
                          : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={12} spacing={0.5} className={styles.fieldGrid}>
                  <Field name='birthDate'>
                    {({ field }: FieldProps<StudentFormValues>) => (
                      <Grid container display='flex' alignItems='center'>
                        <div>
                          <TextField
                            {...field}
                            id='birthDate'
                            mask='99/99/9999'
                            variant='filled'
                            label={`${t('Data de nascimento')}*`}
                            error={Boolean(errors?.birthDate && touched.birthDate)}
                            assistivetext={(errors?.birthDate && touched.birthDate)
                              ? errors?.birthDate
                              : ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
                          />
                        </div>
                        <div>
                          <DatePicker maxDate={new Date()} minDate={new Date('01/01/1900')} onChange={(date: string) => setFieldValue('birthDate', date)} />
                        </div>
                      </Grid>
                    )}
                  </Field>
                  <Grid item xs={12} md={12} className={styles.helpText}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      {t('Digite apenas números')}
                    </Text2>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={styles.fieldGrid}>
                  <Field name='email'>
                    {({ field }: FieldProps<StudentFormValues>) => (
                      <TextField
                        {...field}
                        id='email'
                        variant='filled'
                        label={t('E-mail do responsável')}
                        error={Boolean(errors?.email && touched.email)}
                        warning={Number(!!((errors as any).emailOrPhone) && touched.email)}
                        assistivetext={(errors?.email ?? (errors as any).emailOrPhone) && touched.email
                          ? (errors?.email ?? (errors as any).emailOrPhone)
                          : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={5} spacing={5}>
                  <Grid container item xs={12} md={12} spacing={0.5}>
                    <Grid item xs={12} md={12} className={styles.fieldGrid}>
                      <Field name='phone'>
                        {({ field }: FieldProps<StudentFormValues>) => (
                          <TextField
                            {...field}
                            id='phone'
                            mask='(99) 99999-9999'
                            variant='filled'
                            label={t('Telefone do responsável')}
                            error={Boolean(errors?.phone && touched.phone)}
                            warning={Number(!!((errors as any).emailOrPhone) && touched.phone)}
                            assistivetext={(errors?.phone ?? (errors as any).emailOrPhone) && touched.phone
                              ? (errors?.phone ?? (errors as any).emailOrPhone)
                              : ''
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.helpText}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Digite apenas números')}
                      </Text2>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field name='anchorGrade'>
                      {({ field }: FieldProps<StudentFormValues>) => (
                        <Select
                          {...field}
                          id='anchorGrade'
                          variant='filled'
                          label={`${t('Ano/Série')}*`}
                          IconComponent={ExpandMoreIcon}
                          error={Boolean(errors?.anchorGrade && touched.anchorGrade)}
                          assistivetext={(errors?.anchorGrade && touched.anchorGrade)
                            ? errors?.anchorGrade
                            : ''
                          }
                        >
                          {allGradesData?.map(({ id, code }) => (
                            <MenuItem key={id} value={code}>
                              {code}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={12} className={styles.fieldGrid}>
                    <Field name='enrollment'>
                      {({ field }: FieldProps<StudentFormValues>) => (
                        <TextField
                          {...field}
                          id='enrollment'
                          variant='filled'
                          label={t('Número da matrícula')}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field name='sendCredentialsNotice'>
                    {({ field }: FieldProps<StudentFormValues>) => (
                      <Switch
                        {...field}
                        id='sendCredentialsNotice'
                        label={t('Enviar e-mail com credenciais')}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item justifyContent='flex-end' xs={12} md={12} spacing={4}>
                  <Grid item>
                    <Button
                      variant='outlined'
                      onClick={handleBackButtonLink}
                    >
                      {t('Cancelar')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!(dirty && isValid)}
                      type='submit'
                      variant='primary'
                    >
                      {t('Finalizar')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </RightColumnContent>
    </DoubleColumnContainer>
  )
}
