import { ProvideAuth } from 'navigation/ProvideAuth'
import { Routes } from 'navigation/routes'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { ThemeProvider as LegacyThemeProvider } from '@mui/styles'
import { theme } from '../src/styles/theme'
import { useEffect, useState } from 'react'
import { useStore } from 'store'
import adminFavIcon from 'assets/favicon-admin.png'
import coordinatorFavIcon from 'assets/favicon-coordinator.png'
import { FeatureToggleProvider } from 'components/feature-toggle/contexts'
import { UserSchoolProfileTypeEnum } from 'services'

const App: React.FC = () => {
  const [switchTheme, setSwitchTheme] = useState<UserSchoolProfileTypeEnum.admin | UserSchoolProfileTypeEnum.coordinator>(UserSchoolProfileTypeEnum.admin)
  const { profile } = useStore()

  const favicons = {
    admin: adminFavIcon,
    coordinator: coordinatorFavIcon
  }

  useEffect(() => {
    if (profile?.type === UserSchoolProfileTypeEnum.coordinator) {
      setSwitchTheme(UserSchoolProfileTypeEnum.coordinator)
      document.getElementById('favicon')?.setAttribute('href', favicons.coordinator)
    } else {
      setSwitchTheme(UserSchoolProfileTypeEnum.admin)
      document.getElementById('favicon')?.setAttribute('href', favicons.admin)
    }
  }, [profile])

  return (
    <LegacyThemeProvider theme={theme(switchTheme)}>
      <ThemeProvider theme={theme(switchTheme)}>
        <ProvideAuth>
          <BrowserRouter getUserConfirmation={() => {
            // This is intentional
          }}>
            <FeatureToggleProvider>
              <Routes />
            </FeatureToggleProvider>
          </BrowserRouter>
        </ProvideAuth>
      </ThemeProvider>
    </LegacyThemeProvider>
  )
}

export default App
