import React from 'react'
import useStyle from '../style'
import { Grid, Text2 } from 'components/design-system'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ClipBoardCopy } from '../../../ClipBoardCopy'
import { useTranslation } from 'react-i18next'

export interface IDateOfBirthProps {
  birthDate: string
  copyButtonDataTestId?: string
}

export const DateOfBirth: React.FC<IDateOfBirthProps> = ({ birthDate, copyButtonDataTestId }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container item direction='column' xs={12} md={6} lg>
      <Grid item>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
          {t('Data de nascimento')}
        </Text2>
      </Grid>
      <Grid container item alignItems='center' wrap='nowrap' className={classes.info}>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
          {birthDate ? format(parse(birthDate, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy') : '-'}
        </Text2>
        <ClipBoardCopy
          dataTestId={copyButtonDataTestId}
          copyItem={birthDate ? format(parse(birthDate, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy') : '-'}
        />
      </Grid>
    </Grid>
  )
}
