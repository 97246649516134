import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => {
  return {
    fillContainerStyle: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fullWidth: {
      width: '100%'
    }
  }
})
