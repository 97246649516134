import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    container: {
      '& .MuiBox-root': {
        minHeight: '100vh'
      }
    },
    header: {
      padding: '0px 24px'
    },
    twoColumnContainer: {
      justifyContent: 'center',
      minHeight: '872px',
      height: '100%',
      marginTop: '30px'
    },
    topTableContainer: {
      maxHeight: '150px'
    },
    topTableSection: {
      padding: theme.spacingInset.sm,
      minHeight: '100px',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    gradeTypeList: {
      overflowY: 'scroll',
      maxHeight: '100px'
    },
    intersection: {
      borderLeft: `1px solid ${theme.colors.neutral.light10}`
    },
    bottomTableSection: {
      padding: theme.spacingInset.sm,
      borderTop: `1px solid ${theme.colors.neutral.light10}`,
      minHeight: '200px',
      maxHeight: '700px',
      overflow: 'hidden'
    },
    gradesContainer: {
      overflowY: 'scroll',
      maxHeight: '600px',
      height: '100%',
      padding: `${theme.spacingStack.xxs} 0`
    },
    gradeCard: {
      cursor: 'grab',
      padding: theme.spacingInset.xs,
      '&.MuiCard-root': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '193px',
        height: '56px',
        borderRadius: theme.borderRadius.sm,
        '& .MuiCardContent-root:last-child': {
          padding: '0',
          width: '100%'
        }
      }
    },
    classIcon: {
      marginRight: theme.spacingInset.xs
    },
    studentCard: {
      '&.MuiCard-root': {
        background: 'rgba(230, 230, 230, 0.32)',
        width: '100%'
      }
    },
    draggableClassItem: {
      cursor: 'grab'
    },
    draggingOverStudentContainer: {
      transition: 'all .2s',
      '&.MuiCard-root': {
        background: 'rgba(255, 255, 255, 0.50)',
        boxShadow: '0px 5px 15px rgba(20, 20, 20, 0.16)',
        backdropFilter: 'blur(10px)'
      }
    },
    draggingClassCard: {
      '&.MuiCard-root': {
        background: theme.colorBrand.lightest,
        border: '1px solid #80B8DD',
        boxShadow: '0px 16px 56px rgba(20, 20, 20, 0.16)'
      }
    },
    scrollStyle: {
      '&::-webkit-scrollbar': {
        width: '3px'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 10px #eee'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.25)',
        outline: 'none',
        borderRadius: '50px'
      }
    }
  }
})
