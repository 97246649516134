import { UserSchoolProfileTypeEnum, createOrManageUserProfileActive } from 'services'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useState } from 'react'

interface ICreateCoordProfile {
  users: number[]
  schoolId: number
}

interface IManageProfile {
  users: number[]
  active: boolean
}

interface IUpdateCoordProfile {
  profile: IManageProfile
  schoolId: number
}

interface IUpdateTeacherProfile {
  profile: IManageProfile
  schoolId: number
}

export const useManageSchoolProfile = () => {
  const { createSnackbar } = useSnackbar()
  const [coordProfileActive, setCoordProfileActive] = useState<boolean>(false)
  const [teacherProfileActive, setTeacherProfileActive] = useState<boolean>(false)

  const updateCoordProfileActive = (value: boolean) => setCoordProfileActive(value)
  const updateTeacherProfileActive = (value: boolean) => setTeacherProfileActive(value)

  const createCoordProfile = async (request: ICreateCoordProfile) => {
    const coordinator = {
      targetProfile: UserSchoolProfileTypeEnum.coordinator,
      users: request.users,
      schoolId: request.schoolId,
      activate: true
    }
    try {
      const response = await createOrManageUserProfileActive(coordinator)
      if (response?.success) updateCoordProfileActive(true)
      createSnackbar({ content: 'Perfil vinculado com sucesso.', severity: 'success' })
    } catch (error) {
      createSnackbar({ content: 'Erro ao salvar informação. Tente novamente.', severity: 'error' })
    }
  }

  const updateCoordProfile = async (request: IUpdateCoordProfile) => {
    const profile = {
      targetProfile: UserSchoolProfileTypeEnum.coordinator,
      users: request.profile.users,
      schoolId: request.schoolId,
      activate: !request.profile.active
    }

    try {
      const response = await createOrManageUserProfileActive(profile)
      if (response?.success) updateCoordProfileActive(!request.profile.active)
      createSnackbar({ content: `Perfil ${request.profile.active ? 'desvinculado' : 'vinculado'} com sucesso.`, severity: 'success' })
    } catch (error) {
      createSnackbar({ content: 'Erro ao salvar informação. Tente novamente.', severity: 'error' })
    }
  }

  const updateTeacherProfile = async (request: IUpdateTeacherProfile) => {
    const teacher = {
      targetProfile: UserSchoolProfileTypeEnum.teacher,
      users: request.profile.users,
      schoolId: request.schoolId,
      activate: !request.profile.active
    }

    try {
      const response = await createOrManageUserProfileActive(teacher)
      if (response.success) updateTeacherProfileActive(!request.profile.active)
      createSnackbar({ content: `Perfil ${request.profile.active ? 'desativado' : 'ativado'} com sucesso.`, severity: 'success' })
    } catch (error) {
      createSnackbar({ content: 'Erro ao salvar informação. Tente novamente.', severity: 'error' })
    }
  }

  return {
    coordProfileActive,
    teacherProfileActive,
    createCoordProfile,
    updateCoordProfileActive,
    updateTeacherProfileActive,
    updateCoordProfile,
    updateTeacherProfile
  }
}
