import { atom } from 'jotai'
import { atomWithReset, atomWithStorage } from 'jotai/utils'
import {
  IClassGradeTypeWithGrades,
  IClassItem,
  IClassListObject,
  IClassResponse,
  IClassSubscriptionResponse,
  IGrade,
  IGradeType,
  IRegistrationTokenResponse,
  ISchoolPeriod,
  ISimpleUserProfileResponse,
  IStudentsPerformance,
  ManageClassTabs
} from 'services'

export interface IManageClassSelectedFilters {
  schoolPeriod: number | undefined
  gradeType: number | undefined
  gradeTypeName: string
  grade: number | undefined
  gradeName: string
}

export type ManageClassTabsType = `${ManageClassTabs}`

export const atomCurrentTab = atomWithReset<ManageClassTabs>(ManageClassTabs.teacher)

export const atomSchoolPeriods = atomWithStorage<ISchoolPeriod[]>('schoolPeriods', [])

export const atomGradeType = atomWithStorage<IGradeType | null>('gradeType', null)
export const atomGrade = atomWithStorage<IGrade | null>('grade', null)
export const atomOrderBy = atomWithStorage<string>('orderBy', 'asc')
export const atomPage = atomWithStorage<number>('page', 1)
export const atomSchoolPeriod = atomWithReset<ISchoolPeriod | null>(null)
export const atomSearchTerm = atomWithStorage<string>('searchTerm', '')
export const atomActive = atomWithStorage<number>('current', -1)

export const atomPreSelectedSchoolPeriod = atomWithReset<ISchoolPeriod | null>(null)

export const atomClassPropsData = atom<IClassListObject | undefined>(undefined)
export const atomStudentPropsData = atom<ISimpleUserProfileResponse | undefined>(undefined)

export const atomSelectedStudentIds = atom<number[]>([])

export const atomStudentClassSubscriptions = atomWithReset<IClassSubscriptionResponse[]>([])
export const atomTeacherClassSubscriptions = atomWithReset<IClassSubscriptionResponse[]>([])

export const atomNoFetchData = atomWithReset<boolean>(false)

export const atomCurrentClass = atomWithReset<IClassResponse | null>(null)

export const registrationTokenData = atom<IRegistrationTokenResponse[]>([])

export const atomOriginClasses = atomWithReset<IClassItem[] | undefined>(undefined)
export const atomDestinyClasses = atomWithReset<IClassItem[] | undefined>(undefined)

export const atomReloadDestinyList = atomWithReset<boolean>(false)

export const atomClassToDelete = atomWithReset<IClassItem | undefined>(undefined)
export const atomStudentToDelete = atomWithReset<number>(0)

export const atomDestinySelectedClass = atomWithReset<IClassItem | undefined>(undefined)

export const atomSelectedFiltersOrigin = atomWithReset<IManageClassSelectedFilters>({
  schoolPeriod: undefined,
  gradeType: undefined,
  gradeTypeName: '',
  grade: undefined,
  gradeName: ''
})

export const atomSelectedFiltersDestiny = atomWithReset<IManageClassSelectedFilters>({
  schoolPeriod: undefined,
  gradeType: undefined,
  gradeTypeName: '',
  grade: undefined,
  gradeName: ''
})

export const atomDisplayCreateClassCard = atom<boolean>(false)

export const atomEnableSaveButton = atomWithReset<boolean>(false)
export const atomReloadClasslist = atomWithReset<boolean>(false)
export const atomIsRenamingClass = atomWithReset<boolean>(false)

export const atomIsLoadingCreateSchoolPeriod = atomWithReset<boolean>(false)

export const atomSearchTermReports = atomWithReset<string>('')
export const atomOrderByReports = atomWithReset<string>('situation')

type ReportContentStates = 'classReport' | 'studentReport'
export const reportStateAtom = atomWithReset<ReportContentStates>('classReport')

export const currentStudentPerformanceAndDataAtom = atomWithReset<IStudentsPerformance | undefined>(undefined)

export const searchAtom = atomWithReset<string>('')
export const sortByAtom = atomWithReset<'asc' | 'desc'>('desc')

export const atomClassGradeTypeWithGrades = atomWithReset<IClassGradeTypeWithGrades[]>([])
