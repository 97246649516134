import { Grid } from '@mui/material'
import { Button, Loading, Modal, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { modalStyles } from './style'

interface IConfirmationModal {
  cancelButtonText?: string
  cancelButtonVariant?: 'ghost' | 'outlined'
  confirmButtonBackground?: 'primary' | 'feedbackNegative' | 'feedbackPositive' | 'feedbackWarning' | 'feedbackInformation'
  confirmButtonText?: string
  cancelButtonDataTestid?: string
  confirmButtonDataTestid?: string
  confirmCallback: any
  description?: string
  subtitle?: string
  subdescription?: string
  handleClose?: any
  isLoading?: boolean
  isOpen: boolean
  size?: 'sm' | 'md'
  title?: string
  children?: React.ReactNode
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({
  cancelButtonText = 'Cancelar',
  cancelButtonVariant = 'ghost',
  confirmButtonBackground = 'primary',
  confirmButtonText = 'OK',
  cancelButtonDataTestid = '',
  confirmButtonDataTestid = '',
  confirmCallback,
  description,
  subdescription,
  handleClose,
  isLoading = false,
  isOpen,
  size = 'md',
  title,
  subtitle,
  children
}) => {
  const { t } = useTranslation()
  const styles = modalStyles()

  return (
    <Modal
      size={size}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Grid container alignItems='center' justifyContent='center' rowSpacing={1}>
        {
          <>
            {title && (
              <Grid item xs={12} md={12} className={styles.modalTitle} id='modal-title'>
                {t(title)}
              </Grid>
            )}
            {description && (
              <Grid item xs={12} md={12} className={styles.modalDescription} id='modal-description'>
                {t(description)}
              </Grid>
            )}
            {children && (
              <Grid item xs={12} md={12}>
                {children}
              </Grid>
            )}
            {subtitle && (
              <Grid item xs={12} md={12} className={styles.modalTitle} id='modal-subtitle'>
                {t(subtitle ?? '')}
              </Grid>
            )}
            {subdescription &&
              <Grid item xs={12} md={12} className={styles.modalSubdescription} id='modal-subdescription'>
                <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark30'>
                  {t(subdescription)}
                </Text2>
              </Grid>
            }
          </>
        }
        <Grid container item className={styles.actionButtons} alignItems='center' justifyContent='center'>
          {
            handleClose &&
              <Grid item xs='auto'>
                <Button
                  className={styles.cancelButton}
                  onClick={handleClose}
                  type='button'
                  variant={cancelButtonVariant}
                  data-testid={cancelButtonDataTestid}
                >
                  {t(cancelButtonText)}
                </Button>
              </Grid>
          }
          <Grid item xs='auto'>
            <Button
              className={styles.submitButton}
              onClick={() => confirmCallback()}
              type='button'
              variant={confirmButtonBackground}
              data-testid={confirmButtonDataTestid}
            >
              {isLoading ? <Loading size={30} light /> : t(confirmButtonText)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmationModal
