import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  dropdown: {
    background: theme.colors.neutral.lightBase,
    border: 'none!important',
    position: 'absolute',
    boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)',
    zIndex: 2,
    '& .MuiCardContent-root:last-child': {
      padding: '16px 0px 16px 16px!important'
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-137px'
    }
  },
  filterButton: {
    '&.MuiButton-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      borderRadius: '8px!important',
      minWidth: 'auto!important',
      padding: '16px!important',
      '&:hover': {
        backgroundColor: 'rgba(230, 230, 230, 0.64)'
      },
      '& svg': {
        color: theme.colorBrand.dark
      }
    }
  }
}))
