import MUITooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { styled, useTheme, useMediaQuery } from '@mui/material'
import { maxLength } from 'utils/string'
import { Tag2 } from 'components/design-system/Tag2'

interface ITooltipProps {
  text: string
  label?: string
  component?: JSX.Element
  className?: string
  placement?: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined
}

const CoustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  backgroundColor: theme.colorBrand.darkest,
  borderRadius: theme.borderRadius.sm,

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colorBrand.darkest,
    color: theme.colors.neutral.lightBase,
    maxWidth: '253px',
    fontSize: theme.font.fontSize.xs,
    fontFamily: theme.font.fontFamily,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.fontSize.lg,
    marginBottom: '0 !important',
    padding: theme.spacingInset.xs
  }
}))

export const Tooltip2 = (props: ITooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { text: rawText, label, component, className, placement } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const text = maxLength(rawText, 350)

  return (
    <CoustomTooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={label ? <><b>{label}: </b>{text}</> : <>{text}</>}
      placement={placement ?? 'top-start'}
    >
      <a onClick={() => setShowTooltip(!showTooltip)} style={{ paddingBottom: isMobile ? theme.spacingInset.xxs : 0 }}>
        {
          label &&
          <Tag2 className={className}>{label}</Tag2>
        }
        {
          component ?? <React.Fragment />
        }
      </a>
    </CoustomTooltip>
  )
}
