/* eslint-disable @typescript-eslint/restrict-template-expressions */
import makeStyles from './style'
import readingListImage from 'assets/reading-list.svg'
import noSearchResults from 'assets/search.svg'
import { ProfileCard, EmptyOrErrorMessage, ProfilesListBulkOptions } from 'components/common'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import { atomStudentPropsData, atomSelectedStudents } from '../../atomStore'
import { IUserResponse, ListTypeEnum, UserSchoolProfileTypeEnum } from 'services'
import { atomSearchTerm } from '../../atomStore/index'
import { studentListProfileBulkOptionsTags, studentTags } from 'services/types/tag'

export const StudentList: React.FC = () => {
  const styles = makeStyles()

  // atoms
  const [selectedStudents, setSelectedStudents] = useAtom(atomSelectedStudents)
  const [studentPropsData] = useAtom(atomStudentPropsData)
  const searchTerm = useAtomValue(atomSearchTerm)

  const handleSelectStudent = (student: IUserResponse) => setSelectedStudents(
    selectedStudents.find(user => user.id === student.id)
      ? selectedStudents.filter(u => u.id !== student.id)
      : [...selectedStudents, student]
  )

  const selectAll = () => {
    const allStudentsFromThePage = studentPropsData?.users
      ? studentPropsData.users
      : []

    setSelectedStudents(
      selectedStudents.concat(
        allStudentsFromThePage.filter(item => !selectedStudents.find(student => student === item))
      )
    )
  }

  const clearSelection = () => setSelectedStudents([])

  if (studentPropsData?.users?.length) {
    return (
      <div className={styles.studentList}>
        <ProfilesListBulkOptions
          selectAllListItems={selectAll}
          clearListItemsSelection={clearSelection}
          tagging={studentListProfileBulkOptionsTags}
          selectionList={selectedStudents}
          hasSelection={!!selectedStudents?.length}
          profile={UserSchoolProfileTypeEnum.student}
          listbycontext={ListTypeEnum.list}
        />
        {
          studentPropsData?.users?.map(user =>
            <ProfileCard
              key={user?.id}
              checked={!!(selectedStudents?.find(u => u.id === user?.id))}
              onClick={() => handleSelectStudent(user)}
              profile={user?.profiles.find(p => p.type === UserSchoolProfileTypeEnum.student)}
              profiletype={UserSchoolProfileTypeEnum.student}
              user={user}
              disabled={!user?.profiles.find(p => p.active && p.type === UserSchoolProfileTypeEnum.student)}
              tags={studentTags}
            />
          )
        }
      </div>
    )
  }

  return (
    studentPropsData?.users.length === 0 && searchTerm
      ? <EmptyOrErrorMessage
        message='Não encontramos o nome digitado na lista de estudantes. <b>Verifique e tente novamente.</b>'
        image={noSearchResults}
      />
      : <EmptyOrErrorMessage
        message='A lista de estudantes está vazia.'
        image={readingListImage}
      />
  )
}

export default StudentList
