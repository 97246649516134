
import { Image, Text, View } from '@react-pdf/renderer'
import { IClassGradeTypeWithGrades, IClassResponse, UserSchoolProfileTypeEnum } from 'services'
import { useTranslation } from 'react-i18next'
import { commonStyles } from '../../style'
import { backpackOutlinedIcon, bookOutlinedIcon, schoolOutlinedIcon } from 'assets/base64Icons'
import { twoDigitFormatter } from 'utils'
import { styles } from './style'

interface IClassCardProps {
  classGradeTypeWithGrades: IClassGradeTypeWithGrades[]
  currentClass: IClassResponse
  profile: UserSchoolProfileTypeEnum.teacher | UserSchoolProfileTypeEnum.student
  totalUsers: number
}

export const ClassCard: React.FC<IClassCardProps> = ({ classGradeTypeWithGrades, currentClass, profile, totalUsers }) => {
  const { t } = useTranslation()

  const _totalUsers = twoDigitFormatter(totalUsers)

  const totalTypes = {
    teacher: (
      <View style={styles.classInfoItem}>
        <View style={[commonStyles.grow0, styles.classInfoIcon]}>
          <Image src={bookOutlinedIcon} />
        </View>
        <View style={[commonStyles.grow0, styles.classInfoBold]}>
          <Text>{_totalUsers}</Text>
        </View>
        <View style={[commonStyles.grow0, styles.classInfo]}>
          <Text>{t('professores')}</Text>
        </View>
      </View>
    ),
    student: (
      <View style={styles.classInfoItem}>
        <View style={[commonStyles.grow0, styles.classInfoIcon]}>
          <Image src={backpackOutlinedIcon} />
        </View>
        <View style={[commonStyles.grow0, styles.classInfoBold]}>
          <Text>{_totalUsers}</Text>
        </View>
        <View style={[commonStyles.grow0, styles.classInfo]}>
          <Text>{t('estudantes')}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.classCard}>
      <View style={styles.segmentsContainer}>
        <Text style={styles.segments}>
          {classGradeTypeWithGrades.map((classGradeType, index) => `${classGradeType.gradeType}${index + 1 === classGradeTypeWithGrades.length ? '' : ', '}`)}
        </Text>
      </View>
      <View>
        <Text style={styles.grades}>
          {classGradeTypeWithGrades.map((gt, index) =>
            gt.gradeNames.map(
              (gradeName, i) => `${gradeName}${(index + 1 === classGradeTypeWithGrades.length) && (i + 1 === gt.gradeNames.length) ? '' : ', '}`
            )
          )}
        </Text>
      </View>
      <View>
        <Text style={styles.className}>{currentClass.name}</Text>
      </View>
      <View style={styles.classInfoContainer}>
        <View style={styles.classInfoItem}>
          <View style={[commonStyles.grow0, styles.classInfoIcon]}>
            <Image src={schoolOutlinedIcon} />
          </View>
          <View style={[commonStyles.grow0, styles.classInfoBold]}>
            <Text>{t('ID da turma')}:</Text>
          </View>
          <View style={[commonStyles.grow0, styles.classInfo]}>
            <Text>{currentClass.id}</Text>
          </View>
        </View>
        {totalTypes[profile]}
      </View>
    </View>
  )
}
