import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  tagsContainer: {
    position: 'absolute',
    display: 'flex',
    gap: theme.spacingInline.nano,
    padding: theme.spacingInline.xxxs,
    flexFlow: 'wrap'
  },
  tag: {
    fontSize: theme.font.fontSize.xxs,
    '&.disabled': {
      cursor: 'not-allowed'
    }
  },
  grade: {
    fontSize: theme.font.fontSize.xxs,
    '&.disabled': {
      cursor: 'not-allowed'
    }
  },
  bnccContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    overflowX: 'auto',
    gap: theme.spacingInline.quarck
  },
  cardButton: {
    height: theme.spacingInset.md + '!important',
    fontSize: theme.font.fontSize.xxs + '!important'
  },
  cardTooltip: {
    width: '264px',
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInline.xxxs,
    position: 'absolute',
    background: theme.colorBrand.dark,
    zIndex: '2',
    margin: '10px 20px'
  },
  cardMainContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '356px',
    width: '304px',
    transition: 'opacity 0.3s linear',
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light20}`,
    '&:hover': {
      boxShadow: theme.shadowLevels.level3
    }
  },
  cardFrontFace: {
    width: '304px',
    height: '356px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '0',
    transition: 'opacity 0.3s linear'
  },
  tagImageContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  thumbImage: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: '4px 4px 0 0'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxxs
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInline.nano
  },
  frontDescription: {
    maxHeight: '72px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis'
  },
  backDescription: {
    maxHeight: '105px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3
  },
  cardBackFace: {
    width: '304px',
    height: '356px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '0',
    transition: 'opacity 0.3s linear',
    padding: theme.spacingInline.xxxs,
    justifyContent: 'space-between'
  },
  titleText: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis'
  }
}))
