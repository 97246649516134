export const USER_SEND_DEFAULT_CREDENTIALS = () => `${USER_BASE()}/send-default-credentials-notification`
export const SESSION = () => '/users/session'

export const USER_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/users`
export const USER_EMAIL = (email: string) => `${USER_BASE()}?email=${email}`
export const USER_GET_BY_ID = (userId: number) => `${USER_BASE()}/${userId}`
export const USER_ME = () => `${USER_BASE()}/me`
export const USER_PHONE = (phone: number) => `${USER_BASE()}?phone=${phone}`
export const USER_TOKEN_VALIDATION = (token: string) => `${process.env.REACT_APP_BACKEND_URL as string}/registration-tokens/validate/${token}`
export const USER_UPDATE = (userId: number) => `${USER_BASE()}/${userId}`
