import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.MuiCard-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      border: `1px solid ${theme.colors.neutral.light10}`
    },
    '&.disabled': {
      backgroundColor: theme.colors.neutral.light10
    }
  },
  button: {
    minWidth: '100%!important',
    '&.MuiButton-root': {
      marginTop: theme.spacingStack.nano
    }
  },
  classEditNameRow: {
    height: '77px'
  },
  classIdRow: {
    height: '44px'
  },
  classNameRow: {
    height: '64px'
  },
  editClassIcon: {
    marginLeft: theme.spacingInset.xs,
    color: `${theme.colorBrand.medium}!important`
  },
  studentClassCardNameInput: {
    width: '100%'
  },
  editClassButtonGroup: {
    justifyContent: 'space-around',
    alignItems: 'center',
    margintop: '0'
  }
}))
