import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbersItem: {
    background: '#E9E5F0', // cor não está nos padrões cloe, TODO: FALAR COM UX
    borderRadius: theme.borderRadius.lg,
    boxShadow: '0px 16px 56px rgba(20, 20, 20, 0.16)',
    height: '178px',
    padding: theme.spacingInset.sm
  },
  bigNumbersItemLoading: {
    background: theme.colors.neutral.light10,
    boxShadow: 'none'
  }
}))
