import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiChip-label': {
      padding: '0'
    },
    '&.MuiChip-sizeSmall': {
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      height: '16px',
      lineHeight: '12px',
      minWidth: '16px',
      padding: '2px 4px'
    },
    '&.MuiChip-sizeMedium': {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      height: '24px',
      lineHeight: '24px',
      minWidth: '24px',
      padding: '4px 8px'
    },
    '& .MuiChip-icon': {
      marginRight: '0!important',
      marginLeft: '-2px!important'
    },
    '&.default': {
      backgroundColor: theme.colors.neutral.light10,
      color: theme.colors.neutral.darkBase,
      '& .MuiChip-icon': {
        color: theme.colors.neutral.darkBase
      }
    },
    '&.error': {
      backgroundColor: theme.colors.negative.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '& .MuiChip-icon': {
        color: theme.colors.neutral.lightBase
      }
    },
    '&.info': {
      backgroundColor: theme.colors.information.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '& .MuiChip-icon': {
        color: theme.colors.neutral.lightBase
      }
    },
    '&.success': {
      backgroundColor: theme.colors.positive.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '& .MuiChip-icon': {
        color: theme.colors.neutral.lightBase
      }
    },
    '&.warning': {
      backgroundColor: theme.colors.warning.feedbackMedium,
      color: theme.colors.neutral.darkBase,
      '& .MuiChip-icon': {
        color: theme.colors.neutral.darkBase
      }
    }
  }
}))
