import { useEffect, useState } from 'react'
import { BigNumberBox, BigNumberBoxSubtitleProps } from 'components/common'
import { useTranslation } from 'react-i18next'
import { UserSchoolProfileTypeEnum } from 'services'
import { useLinkedProfilesBox } from '../hooks'

export const LinkedProfilesBoxStudent: React.FC = () => {
  const { t } = useTranslation()
  const { data, fetchData, error, setReload, loading } = useLinkedProfilesBox(UserSchoolProfileTypeEnum.student)

  // states
  const [subtitles, setSubtitles] = useState<BigNumberBoxSubtitleProps[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      const _subtitles: BigNumberBoxSubtitleProps[] = []

      const variant = () => {
        if (data?.subscribed > data?.inContract) {
          return 'error'
        }
        if (data?.subscribed < data?.inContract) {
          return 'success'
        }
        return 'warning'
      }
      _subtitles.push({
        label: t('em contrato'),
        pill: {
          label: String(data?.inContract),
          variant: variant()
        }
      })

      _subtitles.push({
        label: t('{{total}} não matriculados', { total: data?.notSubscribed })
      })

      setSubtitles(_subtitles)
    }
  }, [data])

  const tryAgainCallback = () => {
    setReload(true)
  }

  return (
    <BigNumberBox
      bigNumber={data?.subscribed}
      loading={loading}
      subtitles={subtitles}
      title={
        t(
          data?.schoolPeriod ? 'Matriculados em turmas de {{year}}' : 'Matriculados em turmas',
          {
            year: data?.schoolPeriod
          }
        )
      }
      hasError={error}
      tryAgainCallback={tryAgainCallback}
      tooltipText={t('Estudantes com perfil ativo matriculados em turmas do ano vigente.')}
    />
  )
}
