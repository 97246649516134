import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  globalAlert: {
    height: '120px',
    borderRadius: theme.borderRadius.md,
    padding: theme.spacingInset.sm
  }
}))
