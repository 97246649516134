import { Grid, Icon, Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { useReport } from 'pages/ClassManagement/hooks/useReport'
import { useChart } from 'navigation/hooks'

export interface IStudentSituationDescription {
  isLoading: boolean
  studentSituation: number
}

enum StateMachineEnum {
  LOADING = 'LOADING',
  READY = 'READY'
}

export const StudentSituationDescription: React.FC<IStudentSituationDescription> = ({ isLoading, studentSituation }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { getThemeBySituation } = useChart()
  const { description, getValuesBySituation, icon, title } = useReport()
  const feedbackTheme = getThemeBySituation(studentSituation)

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.LOADING)

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  useEffect(() => {
    getValuesBySituation(studentSituation)
  }, [studentSituation])

  const states = {
    LOADING: (
      <Grid container rowSpacing={2}>
        <Grid container item alignItems='center' columnSpacing={1}>
          <Grid item xs='auto'>
            <Skeleton variant='circular' width={40} height={40} />
          </Grid>
          <Grid item xs>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Skeleton animation='wave' width={64} height={40} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' width={204} height={40} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container rowSpacing={2}>
        <Grid container item alignItems='center' columnSpacing={1}>
          <Grid className={`${classes.icon} ${studentSituation === 3 ? classes.inverted : ''}`} item xs='auto'>
            <Icon iconColor={feedbackTheme?.feedbackMedium} size={studentSituation === 3 ? 'xxxl' : 'xlarge'}>
              {icon}
            </Icon>
          </Grid>
          <Grid item xs>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Text2 fontSize='xs' fontWeight='bold' lineHeight='sm' mobile='sm' customColor={feedbackTheme?.feedbackMedium}>
                  {t('Situação')}
                </Text2>
              </Grid>
              <Grid item xs={12}>
                <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' customColor={feedbackTheme?.feedbackMedium}>
                  {title}
                </Text2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark10'>
            {description}
          </Text2>
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}

export default StudentSituationDescription
