import { useEffect, useState } from 'react'
import { ILinkedProfilesBoxResponse, UserSchoolProfileTypeEnum, getLinkedProfilesBoxData } from 'services'
import { useSnackbar } from 'navigation/hooks'
import { useStore } from 'store'
import { pluralLowerCaseFormattedProfiles } from 'utils'
import { useTranslation } from 'react-i18next'

export const useLinkedProfilesBox = (profileType: UserSchoolProfileTypeEnum) => {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()

  // atoms
  const [data, setData] = useState<ILinkedProfilesBoxResponse | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  // states
  const [reload, setReload] = useState<boolean>(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await getLinkedProfilesBoxData(school?.id ?? 0, profileType)
      if (response.success) {
        setError(false)
        setData(response?.data)
      } else {
        setError(true)
        throw new Error(t(`Erro ao tentar obter dados de ${pluralLowerCaseFormattedProfiles[profileType]} vinculados`))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setReload(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (reload) fetchData()
  }, [reload])

  return {
    data,
    error,
    fetchData,
    loading,
    setReload
  }
}
