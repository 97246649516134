import RemoveIcon from '@mui/icons-material/Remove'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { useState } from 'react'
import { RemoveTeacherFromClassModal } from '../RemoveTeacherFromClassModal'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { atomCurrentClass } from 'pages/ClassManagement/atomStore'
import { useRemoveTeacherFromClass } from 'navigation/hooks/useRemoveTeacherFromClass'
import { CircleButton } from 'components/common/CircleButton'

interface IRemoveTeacherFromClassOptionButton {
  recipients: IUserResponse[]
  clearRecipients: () => void
  profile: UserSchoolProfileTypeEnum
}

export const RemoveTeacherFromClassOptionButton = ({ recipients, clearRecipients, profile }: IRemoveTeacherFromClassOptionButton) => {
  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))
  const { t } = useTranslation()

  const { removeTeacherFromClass } = useRemoveTeacherFromClass()
  const [isOpenRemoveTeacherFromClassModal, setIsOpenRemoveTeacherFromClassModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const setReloadList = useUpdateAtom(atomReloadList)
  const schoolClass = useAtomValue(atomCurrentClass)

  const handleRemoveTeacherFromClass = async () => {
    if (recipients.length && profile === UserSchoolProfileTypeEnum.teacher && schoolClass?.id) {
      try {
        setIsLoading(true)
        const classSubscriptionInactivateRequest = {
          classId: schoolClass.id,
          recipients
        }
        await removeTeacherFromClass(classSubscriptionInactivateRequest)
      } finally {
        setIsLoading(false)
        setIsOpenRemoveTeacherFromClassModal(false)
        clearRecipients()
        setReloadList(true)
      }
    }
  }

  const handleOpenRemoveTeacherFromClassModal = () => {
    setIsOpenRemoveTeacherFromClassModal(true)
  }

  return (
    <>
      {
        !isLaptop
          ? <Button
            variant='link'
            disabled={!recipients.length}
            onClick={handleOpenRemoveTeacherFromClassModal}
            data-testid='manage_class_teachers_remove_from_class'
          >
            <RemoveIcon sx={{ marginRight: '5px' }} /> {t('Remover da turma')}
          </Button>
          : <CircleButton
            disabled={!recipients.length}
            aria-label='Remover da turma'
            title='Remover da turma'
            onClick={handleOpenRemoveTeacherFromClassModal}
            data-testid='manage_class_teachers_remove_from_class'
          >
            <RemoveIcon />
          </CircleButton>
      }

      <RemoveTeacherFromClassModal
        open={isOpenRemoveTeacherFromClassModal}
        onClose={() => setIsOpenRemoveTeacherFromClassModal(!isOpenRemoveTeacherFromClassModal)}
        onRemoveTeacherFromClass={handleRemoveTeacherFromClass}
        isLoading={isLoading}
      />
    </>
  )
}
