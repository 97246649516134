import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    accessContentButton: {
      padding: `${theme.spacingSquish.nano}!important`
    },
    tag: {
      '&.MuiChip-root': {
        width: '100%',
        background: 'rgba(230, 230, 230, 0.32)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: theme.colorBrand.lightest
        }
      }
    },
    loadingBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '500px'
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    card: {
      width: '100%',
      maxWidth: '302px',
      height: '500px'
    }
  }
})
