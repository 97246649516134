import React, { useState } from 'react'
import useStyle from '../style'
import { Grid, Link, Tag, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { useTheme } from '@mui/material'
import { ResetDefaultPasswordModal } from 'components/common/ProfilesListBulkOptions/components'
import { useAuthResetDefaultPassword } from 'navigation/hooks'
import { useUpdateAtom } from 'jotai/utils'
import { CustomTheme } from 'styles/types/CustomTheme'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { UserSchoolProfileTypeEnum } from 'services'

export interface IPasswordProps {
  disabled: boolean
  isDefaultPass: boolean
  profiletype: UserSchoolProfileTypeEnum
  userId: number
}

export const Password: React.FC<IPasswordProps> = ({ disabled, isDefaultPass, profiletype, userId }) => {
  const { t } = useTranslation()
  const { resetDefaultPassword } = useAuthResetDefaultPassword()
  const classes = useStyle()
  const theme = useTheme<CustomTheme>()

  // atoms
  const setReloadList = useUpdateAtom(atomReloadList)

  // states
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenResetDefaultPasswordModal, setIsOpenResetDefaultPasswordModal] = useState<boolean>(false)

  const handleOpenDefaultPasswordModal = () => setIsOpenResetDefaultPasswordModal(true)

  const handleResetDefaultPassword = async () => {
    if (userId) {
      setIsLoading(true)
      await resetDefaultPassword({
        users: [userId]
      })
    }

    setIsLoading(false)
    setIsOpenResetDefaultPasswordModal(false)
    setReloadList(true)
  }

  return (
    <>
      <Grid container item direction='column' xs={12} md={6} lg>
        <Grid item>
          <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
            <Grid container spacing={1}>
              <Grid item>
                <VpnKeyOutlinedIcon />
              </Grid>
              <Grid item>
                {t('Senha')}
              </Grid>
            </Grid>
          </Text2>
        </Grid>
        <Grid container item alignItems='center' wrap='nowrap' className={classes.info}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <Tag
                active
                background={theme.colors.neutral.light10}
                label={t(disabled ? 'Bloqueada' : (isDefaultPass ? 'Padrão' : 'Pessoal'))}
                textcolor={disabled ? theme.colors.neutral.dark40 : theme.colors.neutral.darkBase}
              />
            </Grid>
            <Grid item>
              {!isDefaultPass && !disabled && (
                <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' colorbrand='dark'>
                  <Link onClick={handleOpenDefaultPasswordModal} variant='link' nomargin>
                    {t('Restaurar')}
                  </Link>
                </Text2>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ResetDefaultPasswordModal
        open={isOpenResetDefaultPasswordModal}
        onClose={() => setIsOpenResetDefaultPasswordModal(!isOpenResetDefaultPasswordModal)}
        onResetPassword={handleResetDefaultPassword}
        profile={profiletype}
        isLoading={isLoading}
      />
    </>
  )
}
