/* eslint-disable @typescript-eslint/naming-convention */
import { request } from 'services/base'
import { IApiResponse, IDisciplineResponse, IGradeTypesResponse } from 'services/types'
import { IClassDisciplinesGradeResponse, ISubscriptionsTaughtDisciplinesResponse } from 'services/types/taught-disciplines'
import { DISCIPLINES_GRADE, GRADE_TYPE_SCHOOL, SUBSCRIPTIONS_TAUGHT_DISCIPLINES, DISCIPLINES_MULTIGRADE } from './CONSTANTS'

export interface ITeacherSubscription {
  class_id: number | string
  disciplines: IDisciplineResponse[]
}

export const getGradesbySchool = async (): Promise<IApiResponse<IGradeTypesResponse[]>> =>
  await request({ url: GRADE_TYPE_SCHOOL() })

export const getDisciplineByGrade = async (gradeid: number, school_period?: number, classActive?: boolean): Promise<IApiResponse<IClassDisciplinesGradeResponse[]>> =>
  await request({
    url: DISCIPLINES_GRADE(gradeid, school_period, classActive)
  })

export const getDisciplineMultigradeByClass = async (classId: number, school_period?: number, classActive?: boolean): Promise<IApiResponse<IClassDisciplinesGradeResponse[]>> =>
  await request({
    url: DISCIPLINES_MULTIGRADE(classId, school_period, classActive)
  })

export const getSubscriptionTaughtDisciplinesBySchool = async (schoolPeriodId?: number): Promise<IApiResponse<ISubscriptionsTaughtDisciplinesResponse[]>> =>
  await request({ url: SUBSCRIPTIONS_TAUGHT_DISCIPLINES(schoolPeriodId) })

// TODO: tirar daqui e criar o service de subscription
// tirar ITeacherSubscription do atomStore
export const saveSubscriptionTeacher = async (data: ITeacherSubscription[]): Promise<IApiResponse<any>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/subscriptions/teacher`, method: 'post', data: data })
