import { atomSnackbar } from 'pages/BaseScreen/atomStore'
import { useUpdateAtom } from 'jotai/utils'
import { AlertColor } from '@mui/material/Alert'
import { SnackbarProps } from '@mui/material'

interface ISnackbarOptions extends SnackbarProps {
  content: string
  severity: AlertColor | undefined
}

export const useSnackbar = () => {
  const setSnackbar = useUpdateAtom(atomSnackbar)

  const createSnackbar = (options: ISnackbarOptions) => {
    setSnackbar({
      content: options.content,
      severity: options.severity,
      horizontal: 'left',
      vertical: 'bottom'
    })
  }

  return { createSnackbar }
}
