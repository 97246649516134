import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { Trans, useTranslation } from 'react-i18next'

interface IVinculateCoordProfileToTeacherModalProps {
  open: boolean
  onClose: () => void
  onVinculateCoordProfileToTeacher: () => void
  isLoading: boolean
  callToActionText: string
  cancelButtonDataTestid: string
  confirmButtonDataTestid: string
}
export const VinculateCoordProfileToTeacherModal = ({
  open,
  onClose,
  onVinculateCoordProfileToTeacher,
  isLoading,
  callToActionText,
  cancelButtonDataTestid,
  confirmButtonDataTestid
}: IVinculateCoordProfileToTeacherModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onVinculateCoordProfileToTeacher}
      confirmButtonText={callToActionText}
      isLoading={isLoading}
      cancelButtonDataTestid={cancelButtonDataTestid}
      confirmButtonDataTestid={confirmButtonDataTestid}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='md' neutral='darkBase'>
            {t('Confirmação de vínculo de perfil de coordenador')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark20'>
            <Trans>Ao ter seu perfil vinculado, o(a) professor(a) terá acesso à gestão de turmas, usuários e programas.<br /></Trans>
            <Trans> Tem certeza de que deseja vincular o perfil de coordenador a este(a) professor(a)?</Trans>
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
