/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Bar, BarChart as RechartBarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Text, LabelList } from 'recharts'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'

import useStyle from './style'
import { ICustomTickProps, ICustomTooltipProps } from './types'

interface IBarProps {
  name: string
  stackId: string
  dataKey: string
  textColor: string
  backgroundColor: string
}

interface IBarChart {
  data: any[]
  xAxisLabelDataKey: string
  bars: IBarProps[]
}

export const BarChart: React.FC<IBarChart> = ({ data, xAxisLabelDataKey, bars }) => {
  const classes = useStyle()
  const theme = useTheme()

  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const labelCommonStyle = {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs
  }

  const barsGroupedByStackId: Record<string, IBarProps[]> = bars.reduce<Record<string, IBarProps[]>>((groups, item) => {
    const { stackId } = item
    if (!groups[stackId]) {
      groups[stackId] = []
    }
    groups[stackId].push(item)
    return groups
  }, {})

  const barsGaps = useMemo(() => {
    if (isMobile) {
      return '10%'
    }
    if (isTablet) {
      return '20%'
    }

    return '25%'
  }, [isTablet, isMobile])

  const renderCustomAxisTick = ({ x, y, payload }: ICustomTickProps) => (
    <Text x={x} y={y} dy={16} textAnchor='middle' fill={theme.colors.neutral.dark40} {...labelCommonStyle}>
      {payload.value}
    </Text>
  )

  const renderCustomLegend = ({ payload, layout }: LegendProps): React.ReactElement | null => {
    return (
      <ul className={classes.legendTable}>
        {payload?.map((entry: any, index: number) => (
          <li key={`item-${index}`} style={{ color: entry.color }} className={classes.legendTableItem}>
            <span className={classes.legendBullet} style={{ backgroundColor: entry.color }} />
            <span style={{ ...labelCommonStyle, marginLeft: theme.spacingInline.xxxs }}>{entry.value}</span>
          </li>
        ))}
      </ul>
    )
  }

  const renderCustomTooltip = ({ active, payload, label }: ICustomTooltipProps) => {
    if (active && payload?.length) {
      const totalSum = payload.reduce((acc, { value }) => {
        const isValueNumber = !isNaN(value as any)

        if (!isValueNumber) {
          return acc
        }

        return acc + parseFloat((value ?? 0) as any)
      }, 0)

      return (
        <div className={classes.toolTipContainer}>
          <p className={classes.toolTipTitle}>{`${label}`}</p>
          {
            payload.map(({ name, value }) => (
              <p><span className={classes.toolTipColumnValue}>{value}</span> {name}</p>
            ))
          }
          <p><span className={classes.toolTipColumnValue}>{totalSum}</span> no total</p>

        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <RechartBarChart data={data} barCategoryGap={barsGaps} >
        <XAxis dataKey={xAxisLabelDataKey} tickLine={false} tick={isTablet ? false : renderCustomAxisTick} label={labelCommonStyle} />
        <YAxis hide={isTablet} axisLine={false} tickLine={false} tick={renderCustomAxisTick} label={labelCommonStyle} />
        <Tooltip cursor={{ fill: 'transparent' }} content={({ active, payload, label }) => renderCustomTooltip({ active, payload, label })} />
        <Legend align={'center'} iconType='circle' content={renderCustomLegend} />
        {
          Object.values(barsGroupedByStackId)?.map((bars) => (
            bars.map(({ name, stackId, dataKey, textColor, backgroundColor }) => {
              return (
                <Bar name={name} stackId={stackId} dataKey={dataKey} label={undefined} fill={backgroundColor}>
                  <LabelList dataKey={dataKey} style={{ fill: textColor, ...labelCommonStyle }} />
                </Bar>
              )
            })
          ))
        }
      </RechartBarChart>
    </ResponsiveContainer>
  )
}
