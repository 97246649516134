import { useAtomValue } from 'jotai/utils'
import { atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import React from 'react'

export enum SchoolClassManagerActionsEnum {
  view = 'view',
  create = 'create',
  edit = 'edit',
  edit_name = 'edit_name',
  copy_id_class_card = 'copy_id_class_card',
  manage_class = 'manage_class',
  delete = 'delete',
  add_students = 'add_students',
  re_enroll = 're_enroll',
  view_class_id = 'view_class_id',
  select_user = 'select_user',
  select_all_students = 'select_all_students',
  select_all_teachers = 'select_all_teachers',
  student_list_empty_state = 'student_list_empty_state',
  teacher_list_empty_state = 'teacher_list_empty_state'
}

export enum ActionContextEnum {
  schoolClassManager = 'schoolClassManager'
}

interface IActions {
  schoolClassManager: {
    base: Array<keyof typeof SchoolClassManagerActionsEnum>
    full: Array<keyof typeof SchoolClassManagerActionsEnum>
  }
}

const actions: IActions = {
  schoolClassManager: {
    base: [
      'view'
    ],
    full: [
      'view',
      'create',
      'edit',
      'edit_name',
      'copy_id_class_card',
      'manage_class',
      'delete',
      'add_students',
      're_enroll',
      'view_class_id',
      'select_user',
      'select_all_students',
      'select_all_teachers',
      'student_list_empty_state',
      'teacher_list_empty_state'
    ]
  }
}

interface IAdditionalOptions {
  fullFeaturesDisabler?: boolean
}
interface IActionsControlCenterProps {
  children: React.ReactNode
  fallback?: React.ReactNode
  ctx: ActionContextEnum
  action: SchoolClassManagerActionsEnum
  options?: IAdditionalOptions
}

const manageActionsForSchoolClassManagerContext = (action: SchoolClassManagerActionsEnum, options?: IAdditionalOptions) => {
  const schoolPeriod = useAtomValue(atomSchoolPeriod)

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const shouldShowOnlyBasicFeatures = options?.fullFeaturesDisabler || schoolPeriod?.past

  return shouldShowOnlyBasicFeatures
    ? actions.schoolClassManager.base.includes(action)
    : actions.schoolClassManager.full.includes(action)
}

const useVerifyAllowedAction = (ctx: ActionContextEnum, action: SchoolClassManagerActionsEnum, options?: IAdditionalOptions) => {
  if (ctx === ActionContextEnum.schoolClassManager) {
    return manageActionsForSchoolClassManagerContext(action, options)
  }
}

export const ActionsControlCenter = ({ children, ctx, action, fallback, options }: IActionsControlCenterProps) => {
  const verifiedAction = useVerifyAllowedAction(ctx, action, options)

  if (!verifiedAction && fallback) {
    return (
      <React.Fragment>
        {fallback}
      </React.Fragment>
    )
  }

  if (!verifiedAction) return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}
