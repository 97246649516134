import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => createStyles({
  root: {
    '&.MuiToggleButtonGroup-root': {
      '&.tabrole': {
        borderRadius: '0',
        margin: '16px 0',
        boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)'
      }
    }
  }
}))
