import { Grid, Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Skeleton, useTheme } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { ProgressBar } from 'components/common'
import { gradeMarks } from 'utils/chart'
import { useChart } from 'navigation/hooks'
import useStyles from './style'
import { formatNumberPtBr } from 'utils'

export interface IStudentClassComparison {
  classAverage: number
  isLoading: boolean
  studentAverage: number
  studentName: string
  studentSituation: number
}

enum StateMachineEnum {
  LOADING = 'LOADING',
  READY = 'READY'
}

export const StudentClassComparison: React.FC<IStudentClassComparison> = ({ classAverage, isLoading, studentAverage, studentName, studentSituation }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const { getThemeByPerformance, getThemeBySituation } = useChart()
  const studentFeedbackTheme = getThemeBySituation(studentSituation)
  const classFeedbackTheme = getThemeByPerformance(classAverage)

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.LOADING)

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  const states = {
    LOADING: (
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
          <Skeleton variant='text' width={200} height={40} />
        </Grid>
        <Grid container item xs={12} alignItems='center' columnSpacing={1}>
          <Grid item xs='auto'>
            <Skeleton variant='circular' width={32} height={32} />
          </Grid>
          <Grid item xs>
            <Skeleton variant='text' sx={{ width: '100%' }} height={57} />
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems='center' columnSpacing={1}>
          <Grid item xs='auto'>
            <Skeleton variant='circular' width={32} height={32} />
          </Grid>
          <Grid item xs>
            <Skeleton variant='text' sx={{ width: '100%' }} height={57} />
          </Grid>
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Comparação entre as médias de {{studentName}} e da turma', { studentName })}:
          </Text2>
        </Grid>
        <Grid container item xs={12} columnSpacing={1}>
          <Grid item xs='auto'>
            <Avatar className={classes.icon}>
              <PersonOutlineOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <ProgressBar
              feedbacktheme={studentFeedbackTheme}
              marks={gradeMarks}
              max={10}
              step={0.1}
              value={studentAverage}
              valueLabelDisplay='on'
              valueLabelFormat={formatNumberPtBr}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} columnSpacing={1}>
          <Grid item xs='auto'>
            <Avatar className={classes.icon}>
              <GroupsOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <ProgressBar
              feedbacktheme={classFeedbackTheme}
              marks={gradeMarks}
              max={10}
              step={0.1}
              value={classAverage}
              valueLabelDisplay='on'
              valueLabelFormat={formatNumberPtBr}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
