/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

// Dependency imports
import { IDiscipline, DisciplineCodeEnum } from 'services/types/discipline'
import { getDisciplinesByProfile } from 'services/discipline'
import { toast } from 'components/design-system/Toast/manager'

// Local imports
import { ListDisciplines } from './components/disciplines'
import { Error as ErrorComponent } from './components/error'
import { Loading } from './components/loading'
import { Idle } from './components/idle'

interface IDisciplines {
  onChange?: (discipline: any) => void
  actionDataTestId?: string
  currentDiscipline?: IDiscipline
  dataTestid?: string
  showAll?: boolean
  hidden?: boolean
  title?: string
  showAlertOnError?: boolean
  classId?: string | number
  disciplines?: IDiscipline[]
  disabled?: boolean
}

type IStateRender = 'idle' | 'loading' | 'withDisciplines' | 'error'

export const Disciplines: React.FC<IDisciplines> = ({ onChange, actionDataTestId, currentDiscipline, dataTestid, showAll, hidden, title, showAlertOnError = false, classId, disciplines: initDisciplines, disabled }) => {
  const [state, setState] = useState<IStateRender>('loading')
  const [disciplines, setDisciplines] = useState<IDiscipline[]>(initDisciplines ?? [])

  const localDisciplines = Object.assign([] as IDiscipline[], disciplines)

  if (showAll) {
    localDisciplines.unshift({
      id: '0',
      name: 'Todos',
      code: DisciplineCodeEnum.ALL
    })
  }

  const handleDiscipline = (discipline: IDiscipline | undefined): void => {
    onChange?.(discipline)
  }

  const fetchDisciplines = async () => {
    try {
      if (!classId) throw new Error()

      setState('loading')

      const response = await getDisciplinesByProfile(Number(classId))

      if (!response.success) {
        throw new Error('Page info was not found')
      }

      setDisciplines(response.data)
    } catch (err) {
      handleDiscipline(undefined)
      toast.handler({
        content: 'Não foi possível carregar as disciplinas',
        duration: 5000,
        severity: 'warning'
      })
      setState('error')
    }
  }

  const stateRender = {
    idle: <Idle />,
    loading: <Loading />, // Carrega no middleware, tem como ter loading aqui?
    error: showAlertOnError ? <ErrorComponent actionDataTestId={actionDataTestId} handleTryAgain={fetchDisciplines} /> : <></>,
    withDisciplines: <ListDisciplines dataTestid={dataTestid} disciplines={localDisciplines} currentDiscipline={currentDiscipline} onChange={handleDiscipline} hidden={hidden} title={title} disabled={disabled} />
  }

  const init = () => {
    setState('withDisciplines')
    if (!currentDiscipline) {
      handleDiscipline(localDisciplines[0])
      return
    }

    if (currentDiscipline?.code === DisciplineCodeEnum.ALL && disciplines) {
      handleDiscipline(localDisciplines[0])
      return
    }

    const availableDisciplinesIds = disciplines?.map(el => el.id)
    if (!currentDiscipline || !availableDisciplinesIds?.includes(currentDiscipline?.id)) {
      handleDiscipline(localDisciplines[0])
      return
    }

    handleDiscipline(currentDiscipline)
  }

  useEffect(() => {
    if (initDisciplines) return
    fetchDisciplines()
  }, [classId])

  useEffect(() => {
    setDisciplines(initDisciplines ?? [])
  }, [initDisciplines])

  const setInitialState = () => {
    if (disciplines.length || showAll) {
      init()
    } else if (state !== 'loading') {
      setState('error')
    }
  }

  useEffect(() => {
    if (!initDisciplines || disciplines) {
      setInitialState()
    }
  }, [disciplines, initDisciplines])

  return (
    <>
      {stateRender[state]}
    </>
  )
}
