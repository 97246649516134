/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { Grid, useTheme } from '@mui/material'
import useStyle from './styles'
import { IWeeklyUsageResponse } from 'services'
import { formatDayMonthLabel } from 'utils'
import { useTranslation } from 'react-i18next'
import { BarChart } from 'components/common/Charts/BarChart'
import { Alert } from 'components/design-system'

interface ILastWeekAccessChartItem {
  lastWeekData: IWeeklyUsageResponse
}

export const LastWeekAccessChartItem: React.FC<ILastWeekAccessChartItem> = ({ lastWeekData }) => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  if (lastWeekData == null || lastWeekData.length === 0) {
    return <Alert content={t('Sem dados para exibir')} outlined={true} severity='info' style={{ width: '100%' }} />
  }

  const normalizedData = lastWeekData?.map(({ weekFirstDay, weekLastDay, totalAccessNumber, ...rest }) => {
    const weekLabel = `${formatDayMonthLabel(weekFirstDay)} a ${formatDayMonthLabel(weekLastDay)}`

    return {
      ...rest,
      weekLabel
    }
  })

  return (
    <Grid container xs={12} className={classes.chartContainer}>
      <BarChart
        data={normalizedData}
        xAxisLabelDataKey='weekLabel'
        bars={[
          {
            name: t('estudantes'),
            stackId: 'weekBar',
            dataKey: 'studentAccessNumber',
            textColor: theme.colors.neutral.lightBase,
            backgroundColor: theme.colorBrand.medium
          },
          {
            name: t('professores'),
            stackId: 'weekBar',
            dataKey: 'teacherAccessNumber',
            textColor: theme.colorBrand.darkest,
            backgroundColor: theme.colorBrand.light
          }
        ]}
      />
    </Grid>
  )
}
