import { isBefore } from 'date-fns'
import { useUpdateAtom } from 'jotai/utils'
import { atomGradeType, atomGrade, atomOrderBy, atomPage, atomSearchTerm, atomActive, atomSchoolPeriods } from 'pages/ClassManagement/atomStore'
import { ISchoolPeriod, SchoolPeriodType, getAvailableYears } from 'services'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import { useSnackbar } from './useSnackbar'
import { useTranslation } from 'react-i18next'

export const useClassManagement = () => {
  const theme = useTheme<Theme>()
  const { createSnackbar } = useSnackbar()
  const { t } = useTranslation()

  // atoms
  const setGradeType = useUpdateAtom(atomGradeType)
  const setGrade = useUpdateAtom(atomGrade)
  const setOrderBy = useUpdateAtom(atomOrderBy)
  const setPage = useUpdateAtom(atomPage)
  const setSearchTerm = useUpdateAtom(atomSearchTerm)
  const setActive = useUpdateAtom(atomActive)
  const setSchoolPeriods = useUpdateAtom(atomSchoolPeriods)

  const getSchoolPeriods = async (schoolId: number) => {
    const response = await getAvailableYears(schoolId)

    try {
      if (response.success) {
        setSchoolPeriods(response.data)
        return response.data
      } else {
        throw new Error(t('Erro ao carregar anos'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
      return []
    }
  }

  const getSchoolPeriodType = (schoolPeriod: ISchoolPeriod) => {
    if (schoolPeriod?.end_date && !schoolPeriod?.current) {
      let endDate = new Date(schoolPeriod.end_date)
      endDate = new Date(endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000)
      const isPreviousSchoolYear = isBefore(endDate, new Date())
      return isPreviousSchoolYear ? 'past' : 'future'
    }
    return 'current'
  }

  const resetClassFilters = (schoolPeriodType?: SchoolPeriodType) => {
    setPage(1)
    setGradeType(null)
    setGrade(null)
    setOrderBy('asc')
    setSearchTerm('')
    setActive(schoolPeriodType === 'future' ? 1 : -1)
  }

  const schoolPeriodStatusType = {
    past: {
      background: theme.colors.contentType.default,
      color: theme.colors.neutral.lightBase,
      label: 'Passado'
    },
    current: {
      background: theme.colors.positive.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      label: 'Vigente'
    },
    future: {
      background: theme.colors.support.colorSupport04,
      color: theme.colors.neutral.lightBase,
      label: 'Futuro'
    }
  }

  return {
    getSchoolPeriods,
    getSchoolPeriodType,
    resetClassFilters,
    schoolPeriodStatusType
  }
}
