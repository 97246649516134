import { ISchoolPeriodResponse, UserSchoolProfileTypeEnum } from 'services'
import { useLocalStore } from './localProfileStore'
import { useSessionStore } from './sessionProfileStore'
import { useLocalPreferencesStore } from './preferencesStore'

export const useStore = () => {
  const localStore = useLocalStore()
  const sessionStore = useSessionStore()
  const preferencesStore = useLocalPreferencesStore()

  const isStudentProfile = localStore.profile?.type === UserSchoolProfileTypeEnum.student

  const setSchoolPeriod = (schoolPeriod: ISchoolPeriodResponse | null) => {
    sessionStore.setSchoolPeriod(schoolPeriod)
    localStore.setSchoolPeriod(schoolPeriod)
  }

  const schoolPeriod = isStudentProfile ? localStore.schoolPeriod : sessionStore.schoolPeriod

  return ({
    ...localStore,
    ...sessionStore,
    ...preferencesStore,
    schoolPeriod,
    setSchoolPeriod
  })
}

export default useStore
