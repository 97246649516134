import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    alertContainer: {
      '& .MuiAlert-root:not(last-child)': {
        marginBottom: theme.spacingInline.xxxs
      }
    }
  }
})
