import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    icon: {
      color: theme.colorBrand.medium,
      padding: '16px',
      borderLeft: `1px solid ${theme.colors.neutral.light10}`,
      '& svg': {
        fontSize: theme.font.fontSize.xl,
        verticalAlign: 'middle'
      },
      '&.appliedFilters': {
        backgroundColor: theme.colorBrand.medium,
        '& svg': {
          color: theme.colors.neutral.lightBase
        },
        '&:hover': {
          backgroundColor: theme.colorBrand.dark
        }
      }
    },
    searchBar: {
      '& .MuiOutlinedInput-root:hover': {
        border: '0!important',
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0!important'
        }
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '0!important'
      }
    },
    searchBarContainer: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        background: theme.colors.neutral.lightBase,
        borderRadius: '16px 16px 0px 0px',
        bottom: '0px',
        boxShadow: '0px -4px 8px rgba(20, 20, 20, 0.16)',
        display: 'inherit',
        position: 'sticky',
        transition: 'bottom ease-in-out .35s',
        zIndex: '2',

        '&.hidden': {
          bottom: '-80px'
        }
      }
    },
    searchIcon: {
      color: theme.colorBrand.medium,
      padding: '16px 0 16px 16px',
      '& svg': {
        fontSize: theme.font.fontSize.xl,
        verticalAlign: 'middle'
      }
    }
  }
})
