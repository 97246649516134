import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'
import { Alert, Text2 } from 'components/design-system'

import { NewEnrollmentsChart } from './components/NewEnrollmentsChart'
import { NavigationButton } from './components/NavigationButton'

import { PROFILE_TABS } from 'navigation/CONSTANTS'

import { useStore } from 'store'

import { getEnrollmentData } from 'services/cloe-reports'
import { IEnrollmentDataResponse, UserSchoolProfileTypeEnum } from 'services'

import useStyle from './style'
import moment from 'moment'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  READY = 'READY'
}

export const NewEnrollmentsTimeline = () => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  const { school } = useStore()

  // states
  const [enrollmentData, setEnrollmentData] = useState<IEnrollmentDataResponse>()
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const schoolPeriodTitle = school?.school_periods?.find(el => el.current)?.title ?? moment().format('YYYY')

  useEffect(() => {
    fetchEnrollmentData()
  }, [school])

  const fetchEnrollmentData = async () => {
    setStateMachine(StateMachineEnum.LOADING)
    try {
      if (!school?.id) {
        throw new Error('Não foi possivel resgatar as informações da escola')
      }

      const response = await getEnrollmentData({ schoolId: school.id, profileType: UserSchoolProfileTypeEnum.student, frequency: 'month' })

      if (!response || !response.data) {
        throw new Error(response?.message ?? 'Erro ao resgatar informações de matriculas.')
      }

      setEnrollmentData(response.data as unknown as IEnrollmentDataResponse)

      setStateMachine(StateMachineEnum.READY)
    } catch (err: any) {
      setStateMachine(StateMachineEnum.ERROR)
    }
  }

  const states = {
    IDLE: '',
    LOADING: <NewEnrollmentsChart.Skeleton />,
    ERROR: <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} actionDataTestId='data_connection_failure_access' outlined={true} severity='error' onActionClick={fetchEnrollmentData} action={`${t('Recarregar')}`} />,
    READY: <NewEnrollmentsChart.Item enrollmentData={enrollmentData} />
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }
  return (
    <Grid container className={classes.LastWeekAccessContainer}>
      <Grid container>
        <Grid item width='100%' mb={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='lg'
            customColor={theme.colorBrand.medium}
          >
            {t('Matrículas em {{year}}', { year: schoolPeriodTitle })}
          </Text2>
          <Grid item>
            <NavigationButton goTo={PROFILE_TABS('teachers')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {renderMachineState()}
      </Grid>
    </Grid>
  )
}
