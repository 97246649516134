/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Grid, Text2, Card, Button } from 'components/design-system'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../ConfirmationModal'
import { modalActivate, modalDeactivate, modalClassSubsWarn, modalPartialClassSubsWarn } from './CONSTANTS'
import { useStore } from 'store'
import makeStyles from './style'
import { checkClassSubscriptionByUserSchoolProfiles, createOrManageUserProfileActive } from 'services/user-school-profile'
import { ModalCategoryEnum, ListTypeEnum, ICreateOrManageUserProfileActiveRequest, UserSchoolProfileTypeEnum, ModalTextTypesEnum, ActivateActionsEnum, ICheckClassSubscriptionByUserSchoolProfilesErrorResponse, ICheckClassSubscriptionByUserSchoolProfilesRequest, IUserResponse } from 'services'
import { useSnackbar } from 'navigation/hooks'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { useResetAtom, useUpdateAtom } from 'jotai/utils'
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined'
import { useMediaQuery, useTheme } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { ProfileActivationModal, ProfileDeactivationModal } from '../ProfilesListBulkOptions/components'
import { CircleButton } from '../CircleButton'
import { useLinkedProfilesBox } from 'components/modules/LinkedProfilesBox/hooks'
import { useRegisteredProfilesBox } from 'components/modules/RegisteredProfilesBox/hooks'

type ModalStates = 'idle' | 'activate' | 'deactivate' | 'usersWithoutClassError' | 'profilesActivationListError'

interface IElementsTagging {
  activate: string
  deactivate: string
}
interface IButtonVersions {
  both: React.ReactNode
  bothMobile: React.ReactNode
}
export interface IActivateDeactivateButtonsButtons {
  usersbyprofile: IUserResponse[]
  profiletype: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher
  listbycontext: ListTypeEnum
  clearSelection: () => void
  tagging: IElementsTagging
}

export const ActivateDeactivateButtons: React.FC<IActivateDeactivateButtonsButtons> = ({ usersbyprofile, profiletype, listbycontext, clearSelection, tagging }) => {
  const useridsbyprofile = usersbyprofile?.map(user => user.id)
  const { t } = useTranslation()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const [modalState, setModalState] = useState<ModalStates>('idle')
  const [activateBtnDisabled, setActivateBtnDisabled] = useState<boolean>(true)
  const [deactivateBtnDisabled, setDeactivateBtnDisabled] = useState<boolean>(true)
  const [usersWithoutClassSubscription, setUsersWithoutClassSubscription] = useState<ICheckClassSubscriptionByUserSchoolProfilesErrorResponse[] | undefined>(undefined)
  const [idsWhichPassedTheCheck, setIdsWhichPassedTheCheck] = useState<number[]>([])
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const isPlural = useridsbyprofile?.length > 1
  const styles = makeStyles()
  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))
  const { setReload: setReloadLinkedProfilesBox } = useLinkedProfilesBox(profiletype)
  const { setReload: setReloadRegisteredProfilesBox } = useRegisteredProfilesBox(profiletype)

  // atoms
  const resetReloadList = useResetAtom(atomReloadList)
  const setReloadList = useUpdateAtom(atomReloadList)

  const reloadBigNumberBoxes = () => {
    setReloadLinkedProfilesBox(true)
    setReloadRegisteredProfilesBox(true)
  }

  const renderButtonBasedOnUserActiveStatus = (selectedUsers: IUserResponse[]) => {
    const allActiveUsers = selectedUsers.every(user => user.active)
    const allInactiveUsers = selectedUsers.every(user => !user.active)

    if (allActiveUsers) {
      setActivateBtnDisabled(true)
      setDeactivateBtnDisabled(false)
    } else if (allInactiveUsers) {
      setDeactivateBtnDisabled(true)
      setActivateBtnDisabled(false)
    } else {
      setActivateBtnDisabled(false)
      setDeactivateBtnDisabled(false)
    }
  }

  useEffect(() => {
    if (usersbyprofile?.length) {
      renderButtonBasedOnUserActiveStatus(usersbyprofile)
    }

    if (!usersbyprofile.length) {
      setActivateBtnDisabled(true)
      setDeactivateBtnDisabled(true)
    }
  }, [usersbyprofile])

  const handleUserActivationRules = (usersReceivedForUpdate: number[], usersFoundWithoutClass: ICheckClassSubscriptionByUserSchoolProfilesErrorResponse[]) => {
    if (usersFoundWithoutClass?.length === usersReceivedForUpdate?.length) return

    const idsFromUsersWithoutClass = usersFoundWithoutClass.map(user => user.user)

    return usersReceivedForUpdate.filter(user => !idsFromUsersWithoutClass.includes(user))
  }

  const checkIfStudentHasClassSubscription = async (users: number[], schoolId: number) => {
    const payload: ICheckClassSubscriptionByUserSchoolProfilesRequest = {
      users,
      schoolId
    }
    try {
      const response = await checkClassSubscriptionByUserSchoolProfiles(payload)
      if (response?.data?.length > 0) {
        setUsersWithoutClassSubscription(response.data)
        const hasUsersToUpdate = handleUserActivationRules(users, response.data)
        if (!hasUsersToUpdate) {
          setModalState('usersWithoutClassError')
        } else {
          setIdsWhichPassedTheCheck(hasUsersToUpdate)
          setModalState('profilesActivationListError')
        }
      } else {
        return true
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: 'Ocorreu um erro, por favor tente novamente.', severity: 'error' })
      }
    }
  }

  const handleActivateDeactivateProfiles = async (activate: boolean, users: number[], profileType: UserSchoolProfileTypeEnum, schoolId: number) => {
    if (profileType === UserSchoolProfileTypeEnum.student && activate && schoolId) {
      const isAllowToProceed = await checkIfStudentHasClassSubscription(users, schoolId)
      if (!isAllowToProceed) return
    }
    setIsLoadingButton(true)
    await activateDeactivateProfiles(activate, users, profileType, schoolId)
  }

  const getSuccessMessage = (activate: boolean) => {
    let status

    if (isPlural) {
      status = activate ? 'ativados' : 'desativados'
    } else {
      status = activate ? 'ativado' : 'desativado'
    }

    return t(`Perfil ${status} com sucesso.`)
  }

  const getErrorMessage = (activate: boolean) => {
    const status = activate ? 'ativar' : 'desativar'
    const message = isPlural ? 'os usuários selecionados' : 'o usuário selecionado'

    return t(`Não foi possível ${status} ${message}.`)
  }

  const activateDeactivateProfiles = async (activate: boolean, users: number[], profileType: UserSchoolProfileTypeEnum, schoolId: number) => {
    const requestData: ICreateOrManageUserProfileActiveRequest = {
      users,
      activate,
      targetProfile: profileType,
      schoolId
    }

    try {
      const response = await createOrManageUserProfileActive(requestData)
      if (response.success) {
        reloadBigNumberBoxes()
        setReloadList(true)
        createSnackbar({
          content: getSuccessMessage(activate),
          severity: 'success'
        })
      } else {
        throw new Error(getErrorMessage(activate))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: 'Ocorreu um erro, por favor tente novamente.', severity: 'error' })
      }
    } finally {
      setModalState('idle')
      clearSelection()
      resetReloadList()
      setIsLoadingButton(false)
    }
  }

  const chooseModalContent = (typeofmodal: ModalCategoryEnum, listType: ListTypeEnum, profile: UserSchoolProfileTypeEnum, texttype: ModalTextTypesEnum) => {
    let modal
    switch (typeofmodal) {
      case ModalCategoryEnum.activate:
        modal = modalActivate
        break
      case ModalCategoryEnum.deactivate:
        modal = modalDeactivate
        break
      case ModalCategoryEnum.warningNoClass:
        modal = modalClassSubsWarn
        break
      case ModalCategoryEnum.warningNoClassPartial:
        modal = modalPartialClassSubsWarn
        break
    }

    return modal[listType][profile][texttype][isPlural ? 'plural' : 'singular']
  }

  const modalStateMachine = {
    states: {
      idle: null,
      activate: (
        <ProfileActivationModal
          open
          onClose={() => setModalState('idle')}
          onActivateProfile={async () => await handleActivateDeactivateProfiles(true, useridsbyprofile, profiletype, school?.id ?? 0)}
          profile={profiletype}
          isLoading={isLoadingButton}
          list={usersbyprofile}
        />
      ),
      deactivate: (
        <ProfileDeactivationModal
          open
          onClose={() => setModalState('idle')}
          onDeactivateProfile={async () => await handleActivateDeactivateProfiles(false, useridsbyprofile, profiletype, school?.id ?? 0)}
          profile={profiletype}
          isLoading={isLoadingButton}
          list={usersbyprofile}
        />
      ),
      usersWithoutClassError: (
        <ConfirmationModal
          isOpen
          confirmCallback={() => setModalState('idle')}
          isLoading={isLoadingButton}
          title={t(chooseModalContent(ModalCategoryEnum.warningNoClass, listbycontext, profiletype, ModalTextTypesEnum.title))}
          description={t(chooseModalContent(ModalCategoryEnum.warningNoClass, listbycontext, profiletype, ModalTextTypesEnum.description))}
        />
      ),
      profilesActivationListError: (
        <ConfirmationModal
          handleClose={() => setModalState('idle')}
          isOpen
          confirmButtonText={t('Ativar')}
          isLoading={isLoadingButton}
          confirmCallback={async () => await activateDeactivateProfiles(true, idsWhichPassedTheCheck, profiletype, school?.id ?? 0)}
          title={t((chooseModalContent(ModalCategoryEnum.warningNoClassPartial, listbycontext, profiletype, ModalTextTypesEnum.title)))}
          subtitle={t((chooseModalContent(ModalCategoryEnum.warningNoClassPartial, listbycontext, profiletype, ModalTextTypesEnum.subtitle)))}
          description={t(chooseModalContent(ModalCategoryEnum.warningNoClassPartial, listbycontext, profiletype, ModalTextTypesEnum.description))}
          subdescription={t(chooseModalContent(ModalCategoryEnum.warningNoClassPartial, listbycontext, profiletype, ModalTextTypesEnum.subdescription))}
        >
          <Card fullwidth className={styles.card}>
            <Grid container spacing={2} className={styles.cardContainer}>
              {
                usersWithoutClassSubscription?.map((user) => (
                  <Grid container item display='flex' justifyContent='center' key={user.user}>
                    <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' neutral='dark30'>{user.fullname}</Text2>
                  </Grid>
                ))
              }
            </Grid>
          </Card>
        </ConfirmationModal>
      )
    }
  }

  const activateButton = (
    <Button
      variant='link'
      onClick={() => setModalState(ActivateActionsEnum.activate)}
      disabled={activateBtnDisabled}
      className={styles.activateBtn}
      data-testid={tagging.activate}
    >
      <AccountCircleOutlinedIcon /> {t(isPlural ? 'Ativar perfis' : 'Ativar perfil')}
    </Button>
  )

  const activateButtonMobile = (
    <CircleButton
      aria-label='Ativar perfil'
      title='Ativar perfil'
      variant='activate'
      onClick={() => setModalState(ActivateActionsEnum.activate)}
      disabled={activateBtnDisabled}
      data-testid={tagging.activate}
    >
      <AccountCircleOutlinedIcon />
    </CircleButton>
  )

  const deactivateButton = (
    <Button
      variant='link'
      onClick={() => setModalState(ActivateActionsEnum.deactivate)}
      disabled={deactivateBtnDisabled}
      className={styles.deactivateBtn}
      data-testid={tagging.deactivate}
    >
      <NoAccountsOutlinedIcon /> {t(isPlural ? 'Desativar perfis' : 'Desativar perfil')}
    </Button>
  )

  const deactivateButtonMobile = (
    <CircleButton
      aria-label='Desativar perfil'
      title='Desativar perfil'
      variant='deactivate'
      onClick={() => setModalState(ActivateActionsEnum.deactivate)}
      disabled={deactivateBtnDisabled}
      data-testid={tagging.deactivate}
    >
      <NoAccountsOutlinedIcon />
    </CircleButton>
  )

  const buttonVersions: IButtonVersions = {
    both: (
      <>
        <Grid item xs={6} sm='auto'>
          {activateButton}
        </Grid>
        <Grid item xs={6} sm='auto'>
          {deactivateButton}
        </Grid>
      </>
    ),
    bothMobile: (
      <>
        <Grid item xs={6} sm='auto'>
          {activateButtonMobile}
        </Grid>
        <Grid item xs={6} sm='auto'>
          {deactivateButtonMobile}
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container spacing={4} display='flex' justifyContent='flex-end' alignItems='center'>
        {!isLaptop ? buttonVersions.both : buttonVersions.bothMobile}
      </Grid>
      {modalStateMachine.states[modalState]}
    </>
  )
}
