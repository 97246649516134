import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Modal, Text2, TextField } from 'components/design-system'
import useStyle from './style'
import { Formik, Form, Field, FieldProps } from 'formik'
import { SchoolPeriodFormValues, updateSchoolPeriod, ISchoolPeriod } from 'services'
import { UpdateSchoolPeriodSchema } from 'schemas/yup/school-period'
import { add, endOfDay, format, isBefore, isValid, parse } from 'date-fns'
import { useStore } from 'store'
import { DatePicker } from 'components/common'
import { useSnackbar } from 'navigation/hooks'

import { ptBR } from 'date-fns/locale'
import { useAtomValue } from 'jotai/utils'
import { atomSchoolPeriod, atomSchoolPeriods } from 'pages/ClassManagement/atomStore'

interface IEditSchoolPeriodModal {
  handleClose: () => void
  open: boolean
  reloadSchoolPeriods: (schoolId: number) => Promise<void>
}

export const EditSchoolPeriodModal: React.FC<IEditSchoolPeriodModal> = ({ handleClose, open, reloadSchoolPeriods }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()

  // atoms
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const schoolPeriods = useAtomValue(atomSchoolPeriods)

  const formatDate = (dateToFormat: string) => {
    const date = new Date(dateToFormat)
    return format(date.valueOf() + date.getTimezoneOffset() * 60 * 1000, 'dd/MM/yyyy')
  }

  const getMinEndDate = () => {
    const currentSchoolPeriod: ISchoolPeriod | undefined = schoolPeriods.find(sp => sp.current)
    if (currentSchoolPeriod) {
      const endDate = new Date(currentSchoolPeriod.end_date)
      return add(new Date(endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000), { days: 1 })
    }
    return add(new Date(), { days: 1 })
  }

  const initialValues = {
    startDate: schoolPeriod ? formatDate(schoolPeriod.start_date) : '',
    endDate: schoolPeriod ? formatDate(schoolPeriod.end_date) : ''
  }

  const handleSubmit = async ({ endDate }: SchoolPeriodFormValues) => {
    const parsedEndDate = parse(endDate, 'dd/MM/yyyy', new Date())

    const formattedEndDate = format(endOfDay(parsedEndDate), "yyyy-MM-dd'T'HH:mm:ss'Z'")

    try {
      if (school?.id && schoolPeriod?.id) {
        const response = await updateSchoolPeriod(school.id, schoolPeriod.id, formattedEndDate)

        if (response?.success) {
          reloadSchoolPeriods(school?.id)
          handleClose()
          createSnackbar({ content: t('Período escolar alterado com sucesso.'), severity: 'success' })
        } else {
          if (response?.errors && response?.errors[0].message === 'There is already a school period registered between this period') {
            throw new Error(t('O período selecionado já foi cadastrado'))
          } else {
            throw new Error(t('Não foi possível editar o ano letivo'))
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Grid container justifyContent='center' rowSpacing={2}>
        <Grid item>
          <Text2 fontSize='md' fontWeight='semibold' lineHeight='sm' mobile='md' neutral='dark30'>
            {t('Editando período escolar')}
          </Text2>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={UpdateSchoolPeriodSchema}
            onSubmit={async (values) =>
              await handleSubmit(values)
            }
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Grid container item justifyContent='center' rowSpacing={3} columnSpacing={2}>
                  <Grid item xs={10} md={5}>
                    <Field name='startDate'>
                      {({ field }: FieldProps<SchoolPeriodFormValues>) => (
                        <Grid container direction='column'>
                          <Grid item>
                            {(errors?.startDate ?? (errors as any).diffDate) && touched.startDate
                              ? <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' negative='feedbackMedium'>{t('Início')}</Text2>
                              : <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' colorbrand='medium'>{t('Início')}</Text2>
                            }
                          </Grid>
                          <Grid item xs={12} className={classes.gridInput}>
                            <TextField
                              {...field}
                              id='startDate'
                              mask='99/99/9999'
                              variant='filled'
                              error={Boolean((errors?.startDate ?? (errors as any).diffDate) && touched.startDate)}
                              assistivetext={(errors?.startDate ?? (errors as any).diffDate) && touched.startDate
                                ? (errors?.startDate ?? (errors as any).diffDate)
                                : ''
                              }
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('startDate', e.target.value)}
                              className={classes.dateInput}
                              startAdornment={
                                <DatePicker
                                  minDate={getMinEndDate()}
                                  onChange={(date: string) => setFieldValue('startDate', date)}
                                  disabled={isBefore(parse(values.startDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }), new Date())}
                                  initialValue={parse(values.startDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR })}
                                />
                              }
                              disabled={isBefore(parse(values.startDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }), new Date())}
                              dataTestId='menu_class_management_edit_period_start_date'
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} md={5}>
                    <Field name='endDate'>
                      {({ field }: FieldProps<SchoolPeriodFormValues>) => (
                        <Grid container direction='column'>
                          <Grid item>
                            {(errors?.endDate ?? (errors as any).diffDate) && touched.endDate
                              ? <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' negative='feedbackMedium'>{t('Fim')}</Text2>
                              : <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' colorbrand='medium'>{t('Fim')}</Text2>
                            }
                          </Grid>
                          <Grid item xs={12} className={classes.gridInput}>
                            <TextField
                              {...field}
                              id='endDate'
                              mask='99/99/9999'
                              variant='filled'
                              error={Boolean((errors?.endDate ?? (errors as any).diffDate) && touched.endDate)}
                              assistivetext={(errors?.endDate ?? (errors as any).diffDate) && touched.endDate
                                ? (errors?.endDate ?? (errors as any).diffDate)
                                : ''
                              }
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('endDate', e.target.value)}
                              className={classes.dateInput}
                              startAdornment={
                                <DatePicker
                                  minDate={getMinEndDate()}
                                  onChange={(date: string) => setFieldValue('endDate', date)}
                                  initialValue={parse(values.endDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR })}
                                />
                              }
                              dataTestId='menu_class_management_edit_period_year_end_date'
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid container justifyContent='flex-end' columnSpacing={2}>
                      <Grid item>
                        <Button variant='ghost' onClick={handleClose} data-testid='menu_class_management_edit_period_cancel'>
                          {t('Cancelar')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={!(
                            isValid(parse(values.startDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR })) &&
                            isValid(parse(values.endDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }))
                          )}
                          type='submit'
                          data-testid='menu_class_management_edit_period_finish'
                        >
                          {t('Salvar')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Modal>
  )
}
