import { Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { Button, Grid } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import { DetailedPerformanceAccordion } from '..'
import useStyles from './style'
import AvatarWithCrossedArms from 'assets/avatar-with-crossed-arms.svg'
import { EmptyOrErrorMessage } from 'components/common'
import { IUnitStudentDetailedPerformance } from 'services'
import { useTranslation } from 'react-i18next'

enum StateMachineEnum {
  EMPTY = 'EMPTY',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface IDetailedPerformanceList {
  detailedPerformanceList: IUnitStudentDetailedPerformance[]
  isLoading: boolean
  loadMore: () => Promise<void>
  showMorePages: boolean
}

interface ISkeletonAccordion {
  expanded?: boolean
}

export const DetailedPerformanceList: React.FC<IDetailedPerformanceList> = ({ detailedPerformanceList, isLoading, loadMore, showMorePages }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    const state = detailedPerformanceList.length
      ? StateMachineEnum.READY
      : StateMachineEnum.EMPTY

    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : state
    )
  }, [detailedPerformanceList, isLoading])

  const renderRow = () => {
    const row = []
    const columnLimit = isMobile ? 2 : 5

    for (let i = 0; i < columnLimit; i++) {
      row.push(
        <Grid item xs>
          <Skeleton variant='rectangular' sx={{ width: '100%' }} height={40} animation='wave' className={classes.rounded} />
        </Grid>
      )
    }

    return (
      <Grid container item xs={12} columnSpacing={3}>
        {row}
      </Grid>
    )
  }

  const Rows: React.FC = () => {
    const rows = []

    for (let i = 0; i < 5; i++) {
      rows.push(
        renderRow()
      )
    }

    return (
      <Grid container marginTop={1} rowSpacing={6}>
        {rows}
      </Grid>
    )
  }

  const SkeletonAccordion: React.FC<ISkeletonAccordion> = ({ expanded = false }) => (
    <div className={classes.skeletonAccordion}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Skeleton variant='rectangular' sx={{ width: '75%' }} height={64} animation='wave' className={classes.rounded} />
        </Grid>
        {expanded && <Rows />}
      </Grid>
    </div>
  )

  const states = {
    EMPTY: (
      <EmptyOrErrorMessage
        image={AvatarWithCrossedArms}
        message='Ainda não existem unidades com avaliações ou atividades liberadas para a turma. As unidades aparecerão aqui.'
        reverse
      />
    ),
    IDLE: <></>,
    LOADING: (
      <Grid container rowSpacing={3}>
        <Grid container item xs={12}>
          <SkeletonAccordion expanded />
        </Grid>
        <Grid container item xs={12}>
          <SkeletonAccordion />
        </Grid>
        <Grid container item xs={12}>
          <SkeletonAccordion />
        </Grid>
        <Grid container item xs={12}>
          <SkeletonAccordion />
        </Grid>
        <Grid container item justifyContent='center'>
          <Grid item>
            <Skeleton variant='rectangular' width={180} height={56} animation='wave' className={classes.pill} />
          </Grid>
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container rowSpacing={3}>
        {detailedPerformanceList.map((dp, index) => (
          <Grid item xs={12} key={index}>
            <DetailedPerformanceAccordion
              detailedPerformance={dp}
              expanded={index === 0}
            />
          </Grid>
        ))}
        {showMorePages && (
          <Grid container item justifyContent='center'>
            <Grid item>
              <Button
                data-testid='followup_student_activity_evaluation_load_more'
                onClick={loadMore}
                sx={{ margin: '0 auto' }}
                variant='outlined'
              >
                {t('Carregar mais...')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
