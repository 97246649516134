import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    actionButton: {
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    profileList: {
      padding: `${theme.spacingInline.xxxs} 0px`,
      '& > div + div': {
        marginTop: theme.spacingStack.xs
      }
    }
  }
})
