import { atom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { IClassListObject, ISimpleUserProfileResponse, IUserResponse } from 'services'

export const atomProfileStatusFilter = atomWithReset<number>(-1)
export const atomSentCredentialsFilter = atomWithReset<number>(-1)
export const atomPasswordPatternFilter = atomWithReset<number>(-1)
export const atomOrderBy = atom<string>('asc')
export const atomPage = atom<number>(1)
export const atomSearchTerm = atom<string>('')
export const atomClassPropsData = atom<IClassListObject | undefined>(undefined)

export const atomStudentPropsData = atom<ISimpleUserProfileResponse | undefined>(undefined)
export const atomSelectedStudents = atomWithReset<IUserResponse[]>([])
