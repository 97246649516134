import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined'
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import { useTranslation } from 'react-i18next'
import { Grid, useTheme } from '@mui/material'

import { Text, Icon } from 'components/design-system'
import { IStudentPerformance } from 'services/types'

import useStyle from './styles'

interface IStudentSituation {
  status: Exclude<IStudentPerformance['nr_situation'], undefined>
}
export const StudentSituation: React.FC<IStudentSituation> = ({ status }) => {
  const classes = useStyle()
  const theme = useTheme()
  const { t } = useTranslation()

  const Intervention = () => {
    const situationColor = theme.colors.negative.feedbackMedium
    return (
      <Grid container alignItems='center' className={classes.situationContainer}>
        <Icon
          titleAccess={t('Ícone de Intervenção')}
          size='medium'
          iconColor={situationColor}
        >
          <CrisisAlertOutlinedIcon />
        </Icon>

        <Text className={classes.situationText} style={{ color: situationColor }}>
          {t('Intervenção')}
        </Text>
      </Grid>
    )
  }
  const Observation = () => {
    const situationColor = theme.colors.warning.feedbackMedium
    return (
      <Grid container alignItems='center' className={classes.situationContainer}>
        <Icon
          titleAccess={t('Ícone de Observação')}
          size='medium'
          iconColor={situationColor}
        >
          <FaceOutlinedIcon />
        </Icon>

        <Text className={classes.situationText} style={{ color: situationColor }}>
          {t('Observação')}
        </Text>
      </Grid>
    )
  }

  const Challenge = () => {
    const situationColor = theme.colors.positive.feedbackMedium
    return (
      <Grid container alignItems='center' className={`${classes.situationContainer} ${status === 3 ? classes.inverted : ''}`}>
        <Icon
          titleAccess={t('Ícone de Desafios')}
          size='large'
          iconColor={situationColor}
        >
          <PsychologyOutlinedIcon />
        </Icon>

        <Text className={classes.situationText} style={{ color: situationColor }}>
          {t('Desafios')}
        </Text>
      </Grid>
    )
  }

  const states = {
    1: <Intervention />,
    2: <Observation />,
    3: <Challenge />,
    '-1': <Text className={classes.situationText}>-</Text>
  }

  return (
    <>
      {states[status]}
    </>
  )
}
