import { Button, Container, Grid, Link, Text2, Card, Checkbox } from 'components/design-system'
import Divider from '@mui/material/Divider'
import { CLASS_MANAGEMENT, ROOT } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import makeStyles from './style'
import { ClassCardAccordionList, ClassFilterGroup, CreateClassCardPlaceholder } from 'pages/ClassManagement/components'
import { ClassListEnum, deleteClass, deleteClassSubscription, getStudentsGroupingByClass, IClassGetStudentsGroupingByClassRequest, IClassItem, EnrollStudentsThrowExceptionsEnum, EnrollClassesThrowExceptionsEnum } from 'services'
import { useSnackbar } from 'navigation/hooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { atomClassToDelete, atomDestinyClasses, atomDestinySelectedClass, atomOriginClasses, atomReloadDestinyList, atomSchoolPeriod, atomSelectedFiltersOrigin, atomSelectedFiltersDestiny, atomStudentToDelete, atomDisplayCreateClassCard, IManageClassSelectedFilters, atomEnableSaveButton } from 'pages/ClassManagement/atomStore'
import { ConfirmationModal, ScrollableContainer, SearchInput } from 'components/common'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useStore } from 'store'
import AddIcon from '@mui/icons-material/Add'
import { useEnrollStudents, useEnrollClasses, useClassesSelectionControl } from 'pages/ClassManagement/hooks'

export const ClassConfiguration: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const styles = makeStyles()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const { enrollStudentsForNextSchoolYear, clearStudentsEnrollExceptionList, enrollStudentsThrowExceptions, clearEnrollStudentsThrowExceptions, studentsAlreadyInClassException } = useEnrollStudents()
  const { checkStatusClassSubscription, enrollClassesForNextSchoolYear, clearClassEnrollExceptionList, enrollClassesThrowExceptions, clearEnrollClassesThrowExceptions, classesAlreadyClonedException } = useEnrollClasses()
  const { enableStudentsIfAllClassesAreUnchecked } = useClassesSelectionControl()

  // atoms reset
  const resetOriginClasses = useResetAtom(atomOriginClasses)
  const resetDestinyClasses = useResetAtom(atomDestinyClasses)
  const resetReloadDestinyList = useResetAtom(atomReloadDestinyList)
  const resetClassToDelete = useResetAtom(atomClassToDelete)
  const resetStudentToDelete = useResetAtom(atomStudentToDelete)
  const resetEnableSaveButton = useResetAtom(atomEnableSaveButton)
  const resetSelectedFiltersOrigin = useResetAtom(atomSelectedFiltersOrigin)
  const resetSelectedFiltersDestiny = useResetAtom(atomSelectedFiltersDestiny)
  const resetDestinySelectedClass = useResetAtom(atomDestinySelectedClass)

  // atoms
  const [originClasses, setOriginClasses] = useAtom(atomOriginClasses)
  const [destinyClasses, setDestinyClasses] = useAtom(atomDestinyClasses)
  const [displayCreateClassCard, setDisplayCreateClassCard] = useAtom(atomDisplayCreateClassCard)
  const [enableSaveButton, setEnableSaveButton] = useAtom(atomEnableSaveButton)
  const [selectedFiltersOrigin, setSelectedFiltersOrigin] = useAtom(atomSelectedFiltersOrigin)
  const [selectedFiltersDestiny, setSelectedFiltersDestiny] = useAtom(atomSelectedFiltersDestiny)
  const [destinySelectedClass, setDestinySelectedClass] = useAtom(atomDestinySelectedClass)
  const classToDelete = useAtomValue(atomClassToDelete)
  const studentToDelete = useAtomValue(atomStudentToDelete)
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const setReloadDestinyList = useUpdateAtom(atomReloadDestinyList)

  // states
  const [isLoadingOrigin, setIsLoadingOrigin] = useState<boolean>(false)
  const [isLoadingDestiny, setIsLoadingDestiny] = useState<boolean>(false)
  const [searchOrigin, setSearchOrigin] = useState<string>('')
  const [searchDestiny, setSearchDestiny] = useState<string>('')
  const [enrollStudentsEnableButton, setEnrollStudentsEnableButton] = useState<boolean>(false)
  const [enrollClassesEnableButton, setEnrollClassesEnableButton] = useState<boolean>(false)
  const [cancelButtonModal, setCancelButtonModal] = useState<boolean>(false)
  const [allOriginClassSelection, setAllOriginClassSelection] = useState<boolean>(false)

  // display originSearchInput, checkboxSelectAllOriginClasses
  const displayDependentUiElementsOrigin = Boolean(selectedFiltersOrigin.schoolPeriod && selectedFiltersOrigin.gradeType && selectedFiltersOrigin.grade)

  // display createClassCard, createClassBtn, searchInputDestiny
  const displayDependentUiElementsDestiny = Boolean(selectedFiltersDestiny.schoolPeriod && selectedFiltersDestiny.gradeType && selectedFiltersDestiny.grade)

  const handleCleanSearchOrigin = () => setSearchOrigin('')
  const handleCleanSearchDestiny = () => setSearchDestiny('')

  const handleSearchOrigin = (e: ChangeEvent<HTMLInputElement>) => setSearchOrigin(e.target.value)
  const handleSearchDestiny = (e: ChangeEvent<HTMLInputElement>) => setSearchDestiny(e.target.value)

  // filters
  const handleFilters = async (filtersPayload: IClassGetStudentsGroupingByClassRequest, classListType: ClassListEnum) => {
    updateSelectedFilters(filtersPayload, classListType)
    try {
      if (classListType === ClassListEnum.origin) setIsLoadingOrigin(true)
      else setIsLoadingDestiny(true)

      const response = await getStudentsGroupingByClass(filtersPayload)

      if (response?.success && response.data) {
        if (classListType === ClassListEnum.origin) {
          const classList: IClassItem[] = response.data
            .filter(c => c.students && c.students.length > 0)
            .map(c => ({
              ...c,
              checked: false,
              students: c.students?.map(s => ({ ...s, checked: false, disabled: false }))
            }))

          setOriginClasses(classList)
        } else {
          setDestinyClasses(response.data)
        }
      } else {
        throw new Error(t('Não foi possível carregar as turmas.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: 'Ocorreu um erro, por favor tente novamente.', severity: 'error' })
      }
    } finally {
      if (classListType === ClassListEnum.origin) setIsLoadingOrigin(false)
      else setIsLoadingDestiny(false)
      resetReloadDestinyList()
      setDestinySelectedClass(undefined)
    }
  }

  const resetSelectedFiltersObject = (classListType: ClassListEnum) => {
    classListType === ClassListEnum.origin
      ? resetSelectedFiltersOrigin()
      : resetSelectedFiltersDestiny()
  }

  const updateSelectedFilters = (filtersPayload: IClassGetStudentsGroupingByClassRequest, classListType: ClassListEnum) => {
    const filters: IManageClassSelectedFilters = {
      schoolPeriod: filtersPayload.school_period,
      gradeType: filtersPayload.grade_type,
      gradeTypeName: filtersPayload.grade_type_name,
      grade: filtersPayload.grade,
      gradeName: filtersPayload.grade_name
    }

    classListType === ClassListEnum.destiny
      ? setSelectedFiltersDestiny(filters)
      : setSelectedFiltersOrigin(filters)
  }

  const filterClassList = (classList: IClassItem[] | undefined, classListType: ClassListEnum, searchValue: string) => {
    if (classList?.length) {
      classList = classList
        .map(c => ({
          ...c,
          students: c?.students?.filter(s => s.fullname.toLowerCase().includes(searchValue.trim().toLowerCase()))
        }))

      if (classListType === ClassListEnum.origin || searchValue.length) {
        classList = classList.filter(c => c.students?.length)
      }
    }

    return classList
  }

  // modals
  const handleCloseDeleteClassModal = () => resetClassToDelete()
  const handleCloseDeleteStudentModal = () => resetStudentToDelete()
  const exceptionsModalUnderstoodBtn = () => {
    clearClassEnrollExceptionList()
    clearStudentsEnrollExceptionList()
    clearEnrollStudentsThrowExceptions()
    clearEnrollClassesThrowExceptions()
    setReloadDestinyList(true)
  }

  const handleConfirmDirectEnrollmentModal = () => {
    clearEnrollClassesThrowExceptions()
    if (selectedFiltersDestiny.grade) {
      handleEnrollClasses(selectedFiltersDestiny.grade)
    }
  }

  // student actions
  const handleConfirmDeleteStudent = async () => {
    try {
      const response = await deleteClassSubscription(studentToDelete)
      if (response?.success) {
        createSnackbar({ content: 'Estudante removido da turma com sucesso', severity: 'success' })
      } else {
        throw new Error(t('Não foi possível remover o estudante da turma'))
      }
    } catch (error) {
      if (error instanceof Error) {
        createSnackbar({ content: error.message, severity: 'error' })
      }
    } finally {
      resetStudentToDelete()
      setReloadDestinyList(true)
    }
  }

  // class actions
  const handleCreateClass = () => {
    if (selectedFiltersDestiny.schoolPeriod && selectedFiltersDestiny.gradeType && selectedFiltersDestiny.grade) {
      setDisplayCreateClassCard(true)
    }
  }

  const handleConfirmDeleteClass = async () => {
    try {
      if (classToDelete?.id) {
        const response = await deleteClass(classToDelete?.id, selectedFiltersDestiny.grade)

        if (response?.success) {
          setEnableSaveButton(true)
          createSnackbar({ content: 'Turma excluída com sucesso', severity: 'success' })
        } else {
          throw new Error(t('Não foi possível excluir a turma'))
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        createSnackbar({ content: error.message, severity: 'error' })
      }
    } finally {
      resetClassToDelete()
      setReloadDestinyList(true)
    }
  }

  const handleAllOriginClassSelection = () => {
    setAllOriginClassSelection(!allOriginClassSelection)
    const _clearCheckedState: boolean = !allOriginClassSelection

    if (originClasses) {
      const newOriginClassList = [...originClasses]
      for (const originClass of newOriginClassList) {
        originClass.checked = _clearCheckedState
        if (originClass?.students) {
          for (const student of originClass.students) {
            student.checked = _clearCheckedState
          }
          setOriginClasses(newOriginClassList)
          resetDestinySelectedClass()
          if (!_clearCheckedState) enableStudentsIfAllClassesAreUnchecked()
        }
      }
    }
  }

  // validations
  const checksForOriginSelectedStudents = (_originClasses: IClassItem[]) => _originClasses ? _originClasses.some((originClass) => originClass?.students?.some((student) => student.checked)) : false
  const checksForOriginSelectedClass = (_originClasses: IClassItem[]) => _originClasses ? _originClasses.some((originClass) => originClass?.checked === true) : false

  const clearSelectAllIfNoClassesSelected = (_originClasses: IClassItem[]) => {
    const hasNoClassesSelected = _originClasses.filter(cls => cls.checked === true).length === 0
    if (hasNoClassesSelected) setAllOriginClassSelection(false)
  }

  // utils
  const redirectToClassManagementList = () => history.push(CLASS_MANAGEMENT('list'))
  const redirectToRoot = () => history.push(ROOT)

  // enrollment actions
  const handleEnrollmentOptions = () => {
    if (destinySelectedClass) return handleEnrollStudents(destinySelectedClass)
    if (originClasses) {
      const hasSelectedClass = checksForOriginSelectedClass(originClasses)
      if (hasSelectedClass) return handleCheckStatusClasses()
    }
  }

  const handleEnrollStudents = async (classToEnroll: IClassItem) => {
    if (originClasses && school?.id && schoolPeriod?.id && selectedFiltersDestiny.grade) {
      try {
        await enrollStudentsForNextSchoolYear({
          originClasses,
          destinyClass: classToEnroll,
          school_id: school.id,
          schoolPeriod_id: schoolPeriod.id,
          grade_id: selectedFiltersDestiny.grade
        })
      } catch (error) {
        createSnackbar({ content: 'Erro ao adicionar estudantes', severity: 'error' })
        setReloadDestinyList(true)
      }
    }
  }

  const handleCheckStatusClasses = async () => {
    if (originClasses && school?.id && schoolPeriod?.id && selectedFiltersDestiny.grade) {
      const response = await checkStatusClassSubscription({
        originClasses,
        school_id: school.id,
        schoolPeriod_id: schoolPeriod.id,
        grade_id: selectedFiltersDestiny.grade
      })

      if (response) {
        return await handleEnrollClasses(selectedFiltersDestiny.grade)
      }
    }
  }

  const handleEnrollClasses = async (gradeId: number) => {
    if (originClasses && school?.id && schoolPeriod?.id) {
      try {
        createSnackbar({ content: 'Estamos adicionando as turmas selecionadas, por favor, aguarde.', severity: 'info' })

        await enrollClassesForNextSchoolYear({
          originClasses,
          school_id: school.id,
          schoolPeriod_id: schoolPeriod.id,
          grade_id: gradeId
        })
      } catch (error) {
        createSnackbar({ content: 'Erro ao adicionar turmas', severity: 'error' })
        setReloadDestinyList(true)
      }
    }
  }

  // button group handlers
  const handleCancelButton = () => {
    if (!originClasses) {
      redirectToRoot()
    }

    const hasSelectedStudents = checksForOriginSelectedStudents(originClasses as IClassItem[])

    if (hasSelectedStudents || destinySelectedClass) {
      setCancelButtonModal(true)
      return
    }

    redirectToRoot()
  }

  const handleSaveButton = () => {
    redirectToClassManagementList()
    createSnackbar({ content: 'Turmas salvas com sucesso.', severity: 'success' })
  }

  // side effects
  useEffect(() => {
    resetOriginClasses()
    resetDestinyClasses()
    resetEnableSaveButton()
  }, [])

  useEffect(() => {
    if (originClasses) {
      const hasSelectedClass = checksForOriginSelectedClass(originClasses)
      setEnrollClassesEnableButton(!hasSelectedClass ?? true)

      clearSelectAllIfNoClassesSelected(originClasses)
    }
  }, [originClasses])

  useEffect(() => {
    if (originClasses) {
      const hasSelectedStudents = checksForOriginSelectedStudents(originClasses)
      setEnrollStudentsEnableButton(!(hasSelectedStudents && destinySelectedClass) ?? true)
    }
  }, [originClasses, destinySelectedClass])

  return (
    <Container>
      <Link variant='returnArrow' onClick={redirectToClassManagementList} linkStyle data-testid='menu_class_management_import_class_back_list'>
        {t('Voltar para lista')}
      </Link>
      <Grid container className={styles.title}>
        <Text2 fontSize='xl' fontWeight='medium' lineHeight='md' mobile='lg' colorbrand='dark'>
          {t('Rematrícula')}
        </Text2>
      </Grid>
      <Grid item xs={12}>
        <Divider variant='fullWidth' />
      </Grid>
      <Grid container className={styles.description}>
        <Text2 fontSize='sm' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark30'>
          <Trans>
            Para configurar os estudantes nas turmas, selecione o <strong>ano, segmento e ano/série</strong>, na coluna de origem e destino, depois selecione os estudantes na coluna de origem e adicione na de destino.
          </Trans>
        </Text2>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item direction='column' xs={5} spacing={2}>
          <Grid item>
            <Text2 fontSize='md' fontWeight='semibold' lineHeight='xl' mobile='sm' colorbrand='medium'>
              Origem
            </Text2>
          </Grid>
          <div className={styles.scrollContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ClassFilterGroup
                  classListType={ClassListEnum.origin}
                  onFilter={async (filtersPayload) => await handleFilters(filtersPayload, ClassListEnum.origin)}
                  onResetFilters={(classListType: ClassListEnum) => resetSelectedFiltersObject(classListType)}
                />
              </Grid>
              {displayDependentUiElementsOrigin && (
                <Grid item xs={12}>
                  <Card>
                    <SearchInput
                      defaultValue={searchOrigin}
                      label={t('Pesquisar pelo nome do estudante')}
                      onChange={handleSearchOrigin}
                      onClean={handleCleanSearchOrigin}
                      variant='filled'
                    />
                  </Card>
                </Grid>
              )}
              {displayDependentUiElementsOrigin && (
                <Grid item xs={12} className={styles.checkboxSelectAllClassesContainer}>
                  <Checkbox
                    onChange={handleAllOriginClassSelection}
                    label={t('Selecionar todas as turmas')}
                    customClasses={styles.checkboxSelectAllClasses}
                    checked={allOriginClassSelection}
                    aria-checked={allOriginClassSelection}
                    dataTestid='menu_class_management_import_class_origin_all_class'
                  />
                </Grid>
              )}
              <Grid container item justifyContent='center' xs={12} rowSpacing={2}>
                <ClassCardAccordionList
                  classes={filterClassList(originClasses, ClassListEnum.origin, searchOrigin)}
                  classListType={ClassListEnum.origin}
                  isLoading={isLoadingOrigin}
                  search={searchOrigin}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container item direction='column' xs={2} className={styles.addButtonContainer}>
          {
            enrollClassesEnableButton === enrollStudentsEnableButton && (
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark30'>
                {' '} Selecione a turma de destino para habilitar.
              </Text2>
            )
          }
          <Button
            variant='primary'
            role='button'
            onClick={handleEnrollmentOptions}
            disabled={enrollClassesEnableButton === enrollStudentsEnableButton || !(originClasses && destinyClasses)}
            data-testid='menu_class_management_import_class_destiny_class'
            className={styles.addButton}
          >
            <span className={styles.addButtonContent}>
              Adicionar
            </span>
            <ArrowForwardIcon />
          </Button>
        </Grid>
        <Grid container item direction='column' xs={5} spacing={2}>
          <Grid item>
            <Text2 fontSize='md' fontWeight='semibold' lineHeight='xl' mobile='sm' colorbrand='medium'>
              Destino
            </Text2>
          </Grid>
          <div className={styles.scrollContainer}>
            <Grid item xs={12}>
              <ClassFilterGroup
                classListType={ClassListEnum.destiny}
                onFilter={async (filtersPayload) => await handleFilters(filtersPayload, ClassListEnum.destiny)}
                onResetFilters={(classListType: ClassListEnum) => resetSelectedFiltersObject(classListType)}
              />
              <Grid container spacing={2} sx={{ paddingTop: '16px' }}>
                <Grid container item xs={12} className={styles.createClassButtonContainer}>
                  <Button
                    variant='primary'
                    className={styles.createClassButton}
                    onClick={handleCreateClass}
                    disabled={!displayDependentUiElementsDestiny}
                    data-testid='menu_class_management_import_create_new_class'
                  >
                    <AddIcon />
                    {t('Criar turma')}
                  </Button>
                </Grid>
                {displayDependentUiElementsDestiny && (
                  <Grid item xs={12}>
                    <Card>
                      <SearchInput
                        defaultValue={searchDestiny}
                        label={t('Pesquisar pelo nome do estudante')}
                        onChange={handleSearchDestiny}
                        onClean={handleCleanSearchDestiny}
                        variant='filled'
                      />
                    </Card>
                  </Grid>
                )}
                {displayCreateClassCard && (
                  <Grid item xs={12}>
                    <CreateClassCardPlaceholder onCancel={() => setDisplayCreateClassCard(false)} />
                  </Grid>
                )}
                <Grid container item justifyContent='center' xs={12} rowSpacing={2}>
                  <ClassCardAccordionList
                    classes={filterClassList(destinyClasses, ClassListEnum.destiny, searchDestiny)}
                    classListType={ClassListEnum.destiny}
                    isLoading={isLoadingDestiny}
                    search={searchDestiny}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' className={styles.footerButtonGroup} spacing={2}>
        <Grid item>
          <Button variant='outlined' onClick={handleCancelButton} data-testid='menu_class_management_import_cancel_changes'>
            {t('Cancelar')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant='primary' disabled={!enableSaveButton} onClick={handleSaveButton} data-testid='menu_class_management_import_save_changes'>
            {t('Salvar')}
          </Button>
        </Grid>
      </Grid>
      <ConfirmationModal
        handleClose={handleCloseDeleteClassModal}
        isOpen={!!classToDelete}
        confirmCallback={handleConfirmDeleteClass}
        title={t('Excluir turma')}
        description={t('Tem certeza que deseja excluir esta turma?')}
        confirmButtonText={t('Excluir')}
        cancelButtonDataTestid='menu_class_management_import_class_delete_class_cancel'
        confirmButtonDataTestid='menu_class_management_import_class_delete_class_delete'
      />
      <ConfirmationModal
        handleClose={handleCloseDeleteStudentModal}
        isOpen={!!studentToDelete}
        confirmCallback={handleConfirmDeleteStudent}
        title={t('Excluindo perfil do usuário')}
        description={t('Tem certeza que deseja excluir o perfil do usuário nesta turma?')}
        confirmButtonText={t('Excluir')}
        cancelButtonDataTestid='menu_class_management_import_class_not_exclude_student'
        confirmButtonDataTestid='menu_class_management_import_class_exclude_student'
      />
      <ConfirmationModal
        handleClose={() => setCancelButtonModal(false)}
        isOpen={cancelButtonModal}
        confirmCallback={redirectToRoot}
        title={t('Tem certeza que deseja voltar?')}
        description={t('Você está saindo da importação de turmas e as alterações feitas serão perdidas. Você tem certeza que deseja sair?')}
        cancelButtonText={t('Cancelar')}
        confirmButtonText={t('Sair')}
      />
      <ConfirmationModal
        isOpen={enrollStudentsThrowExceptions === EnrollStudentsThrowExceptionsEnum.allStudentsAlreadyRegistered || enrollStudentsThrowExceptions === EnrollStudentsThrowExceptionsEnum.onlyOneStudentAlreadyRegistered}
        confirmButtonText={t('Entendi')}
        confirmCallback={exceptionsModalUnderstoodBtn}
        title={t(enrollStudentsThrowExceptions === EnrollStudentsThrowExceptionsEnum.allStudentsAlreadyRegistered
          ? 'Os usuários já foram adicionados à turma de destino selecionada.'
          : 'O usuário já foi adicionado à turma de destino selecionada.')
        }
        description={t('Os perfis de usuário só podem ser adicionados uma vez por turma.')}
      />
      <ConfirmationModal
        isOpen={enrollStudentsThrowExceptions === EnrollStudentsThrowExceptionsEnum.fewStudentsAlreadyRegistered}
        confirmButtonText={t('Entendi')}
        confirmCallback={exceptionsModalUnderstoodBtn}
        title={t('Os usuários abaixo já foram adicionados à turma de destino selecionada.')}
        description={t('Os perfis de usuário só podem ser adicionados uma vez por turma.')}
      >
        <ScrollableContainer>
          {studentsAlreadyInClassException?.map((user) => (
            <Grid container item display='flex' justifyContent='center' sx={{ width: '100%' }} key={user.id}>
              <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' neutral='dark30'>{user.id}</Text2>
            </Grid>
          ))}
        </ScrollableContainer>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={enrollClassesThrowExceptions === EnrollClassesThrowExceptionsEnum.allClassesAlreadyCloned}
        confirmButtonText={t('Entendi')}
        confirmCallback={exceptionsModalUnderstoodBtn}
        title={t('As turmas selecionadas já foram adicionadas no ano seguinte.')}
        description={t('As turmas só podem ser adicionadas no ano seguinte uma vez.')}
      />
      <ConfirmationModal
        isOpen={enrollClassesThrowExceptions === EnrollClassesThrowExceptionsEnum.fewClassesAlreadyCloned}
        confirmButtonText={t('Entendi')}
        confirmCallback={handleConfirmDirectEnrollmentModal}
        title={t('As turmas abaixo, já foram adicionadas no ano seguinte.')}
        description={t('As turmas só podem ser adicionadas para o ano seguinte uma vez.')}
      >
        <ScrollableContainer>
          {classesAlreadyClonedException?.map((cls) => (
            <Grid container item display='flex' justifyContent='center' sx={{ width: '100%' }} key={cls.id}>
              <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' neutral='dark30'>{cls.message}</Text2>
            </Grid>
          ))}
        </ScrollableContainer>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={enrollClassesThrowExceptions === EnrollClassesThrowExceptionsEnum.cannotBeItinerary}
        confirmButtonText={t('Entendi')}
        confirmCallback={exceptionsModalUnderstoodBtn}
        title={t('As turmas selecionadas não podem ser cadastradas em itinerários.')}
        description={t('São permitidas apenas turmas do Ensino Fundamental (9º ano), Ensino Médio (1ª, 2ª e 3ª série) e Itinerários Formativos.')}
      />
      <ConfirmationModal
        isOpen={enrollClassesThrowExceptions === EnrollClassesThrowExceptionsEnum.skippingGrade}
        handleClose={exceptionsModalUnderstoodBtn}
        cancelButtonText={t('Revisar destino')}
        confirmButtonText={t('Confirmar importação')}
        confirmCallback={handleConfirmDirectEnrollmentModal}
        title={`Você tem certeza que deseja importar as turmas da origem ${selectedFiltersOrigin.gradeName.replace('EF ', '')} do ${selectedFiltersOrigin.gradeTypeName} para o destino ${selectedFiltersDestiny.gradeName.replace('EF ', '')} do ${selectedFiltersDestiny.gradeTypeName}?`}
        description={t('Sua seleção de destino não corresponde ao ano subsequente ao de origem.')}
      />
    </Container>
  )
}
