import { Grid, Link, Text2, Card, Button } from 'components/design-system'
import { ProfileRegisterPageContainer, Tooltip } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useStyles from './style'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CLASS_MANAGEMENT, PROFILE_TABS, USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { useParams } from 'react-router'

interface IManageClass {
  classId?: string
}

export const CreateStudentOptions: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()
  const { classId } = useParams<IManageClass>()

  const handleBackButtonLink = () => history.push(classId
    ? CLASS_MANAGEMENT(`view/${classId}`)
    : PROFILE_TABS('students')
  )

  const handleBulkRegistration = () => {
    const _classId = classId ? `/${classId}` : ''
    history.push(USER_MANAGEMENT(`students/bulk-registration${_classId}`))
  }

  return (
    <ProfileRegisterPageContainer
      link={
        <Link variant='returnArrow' onClick={handleBackButtonLink} linkStyle>
          {t(`Voltar para ${classId ? 'turma' : 'lista'}`)}
        </Link>
      }
      title={
        <Grid container className={styles.heading}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='md' colorbrand='dark'>
            {t('Cadastrando novos estudantes')}
          </Text2>
        </Grid>
      }
      description={
        <Text2 fontSize='md' fontWeight='medium' lineHeight='xxl' mobile='sm' neutral='dark40'>
          {t('Selecione a forma de cadastro')}
        </Text2>
      }
    >
      <Grid container rowSpacing={2} className={styles.rightBoxContent}>
        <Grid item sm={12} md={5} className={styles.cardContainer}>
          <Card onClick={() => history.push(USER_MANAGEMENT('students/single-registration'))} clickable extraClasses={styles.card}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='sm' mobilelineheight='lg' neutral='dark30' cursor='pointer'>
              {t('Individual')}
            </Text2>
          </Card>
        </Grid>
        <Grid item sm={12} md={5} className={styles.cardContainer}>
          <Card onClick={handleBulkRegistration} clickable extraClasses={styles.card}>
            <div className={styles.infoIconBox}>
              <Tooltip
                className={styles.tooltip}
                title='Cadastro de vários usuários usando uma planilha em Excel'
                arrow
              >
                <InfoOutlinedIcon sx={{ color: '#DADADA' }}/>
              </Tooltip>
            </div>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='sm' mobilelineheight='lg' neutral='dark30' cursor='pointer'>
              {t('Em massa')}
            </Text2>
          </Card>
        </Grid>
        <Grid container item sm={12} justifyContent='flex-end' className={styles.cancelBtn}>
          <Button variant='outlined' onClick={handleBackButtonLink}>{t('Cancelar')}</Button>
        </Grid>
      </Grid>
    </ProfileRegisterPageContainer>
  )
}
