import { useUpdateAtom } from 'jotai/utils'
import { useState } from 'react'
import {
  IStudentsAlreadyInClassException,
  IEnrollStudentNextYearClassSubscriptionRequest,
  EnrollStudentsThrowExceptionsEnum,
  enrollStudentNextYearClassSubscription,
  IClassItem
} from 'services'
import { atomEnableSaveButton, atomReloadDestinyList } from '../atomStore'
import { useSnackbar } from 'navigation/hooks'

interface IPayloadBuilder {
  originClasses: IClassItem[]
  destinyClass: IClassItem
  school_id: number
  schoolPeriod_id: number
  grade_id: number
}

export const useEnrollStudents = () => {
  const { createSnackbar } = useSnackbar()

  const [studentsAlreadyInClassException, setStudentsAlreadyInClassException] = useState<IStudentsAlreadyInClassException[]>([])
  const [enrollStudentsThrowExceptions, setEnrollStudentsThrowExceptions] = useState<keyof typeof EnrollStudentsThrowExceptionsEnum | undefined>(undefined)

  const setEnableSaveButton = useUpdateAtom(atomEnableSaveButton)
  const setReloadDestinyList = useUpdateAtom(atomReloadDestinyList)

  const clearStudentsEnrollExceptionList = () => {
    setStudentsAlreadyInClassException([])
  }

  const clearEnrollStudentsThrowExceptions = () => {
    setEnrollStudentsThrowExceptions(undefined)
  }

  const extractSelectedStudentIdsFromOriginClasses = (originClasses: IClassItem[]) => {
    const studentIds: number[] = []
    if (originClasses?.length) {
      for (const originClass of originClasses) {
        if (originClass.students) {
          originClass.students.forEach((st) => {
            if (st.checked) {
              studentIds.push(st.user_id)
            }
          })
        }
      }
    }
    return studentIds
  }

  const enrollStudentsForNextSchoolYear = async (payloadReq: IPayloadBuilder) => {
    const studentIds = extractSelectedStudentIdsFromOriginClasses(payloadReq.originClasses)

    const payload: IEnrollStudentNextYearClassSubscriptionRequest = {
      schoolId: payloadReq.school_id,
      classId: payloadReq.destinyClass.id,
      students: studentIds,
      school_period: payloadReq.schoolPeriod_id,
      grade_id: payloadReq.grade_id
    }
    try {
      createSnackbar({ content: 'Estamos adicionando os estudantes selecionados, por favor, aguarde.', severity: 'info' })

      const response = await enrollStudentNextYearClassSubscription(payload)
      if (response?.success) {
        if (response?.data?.length && response?.data?.length > 0) {
          const studentsAlreadyRegisteredInClass = response.data

          if (studentsAlreadyRegisteredInClass.length === payload.students.length) {
            return setEnrollStudentsThrowExceptions(studentsAlreadyRegisteredInClass.length === 1 && studentIds.length === 1
              ? EnrollStudentsThrowExceptionsEnum.onlyOneStudentAlreadyRegistered
              : EnrollStudentsThrowExceptionsEnum.allStudentsAlreadyRegistered
            )
          } else {
            setStudentsAlreadyInClassException(studentsAlreadyRegisteredInClass)
            return setEnrollStudentsThrowExceptions(EnrollStudentsThrowExceptionsEnum.fewStudentsAlreadyRegistered)
          }
        }

        setEnableSaveButton(true)
        createSnackbar({ content: 'Estudantes adicionados com sucesso', severity: 'success' })
        setReloadDestinyList(true)
      }
      if (response?.errors) {
        createSnackbar({ content: 'Erro ao adicionar estudantes', severity: 'error' })
        setReloadDestinyList(true)
      }
    } catch (error) {
      createSnackbar({ content: 'Erro ao adicionar estudantes', severity: 'error' })
      setReloadDestinyList(true)
    }
  }

  return {
    studentsAlreadyInClassException,
    enrollStudentsThrowExceptions,
    clearStudentsEnrollExceptionList,
    clearEnrollStudentsThrowExceptions,
    enrollStudentsForNextSchoolYear
  }
}
