import React from 'react'
import Loading from '../Loading'
import makeStyles from './style'

interface ILoadingWrapperProps {
  loading: boolean
  type?: 'circular' | 'linear'
  fillContainer?: boolean
}

export const LoadingWrapper: React.FC<ILoadingWrapperProps> = ({ loading, type, fillContainer = false, children }) => {
  const classes = makeStyles()

  const loadingWrapperClass = fillContainer
    ? classes.fillContainerStyle
    : classes.fullWidth

  return (
    loading
      ? (
        <div className={loadingWrapperClass}>
          <Loading type={type} />
        </div>
      )
      : (
        <>
          {children}
        </>
      )
  )
}

export default LoadingWrapper
