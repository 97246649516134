import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => {
  return {
    fieldGrid: {
      '& .MuiFormControl-root': {
        width: '100%'
      }
    },
    helpText: {
      '& .MuiTypography-root': {
        paddingLeft: '12px'
      }
    },
    title: {
      paddingTop: '32px',
      paddingBottom: '40px'
    }
  }
})
