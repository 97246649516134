import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from './ProvideAuth'
import { LOGIN, TERMS_ACCEPTANCE } from 'navigation/CONSTANTS'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const auth = useAuth()

  const getRedirectWithAcceptedTerms = (acceptTerms: boolean | undefined) => acceptTerms
    ? (children)
    : <Redirect to={TERMS_ACCEPTANCE} />

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.user
          ? getRedirectWithAcceptedTerms(auth.user.accept_terms)
          : (
            <Redirect
              to={{
                pathname: LOGIN,
                state: { from: location }
              }}
            />
          )
      }
    />
  )
}

export default PrivateRoute
