import { useResetAtom, useUpdateAtom } from 'jotai/utils'
import { atomStudentClassSubscriptions } from 'pages/ClassManagement/atomStore'
import { atomOrderBy, atomPage, atomSearchTerm, atomProfileStatusFilter, atomSentCredentialsFilter, atomPasswordPatternFilter, atomSelectedStudents } from 'pages/ProfileTabs/StudentTab/atomStore'

export const useStudentManagement = () => {
  const setOrderBy = useUpdateAtom(atomOrderBy)
  const setPage = useUpdateAtom(atomPage)
  const setSearchTerm = useUpdateAtom(atomSearchTerm)

  const resetProfileStatusFilter = useResetAtom(atomProfileStatusFilter)
  const resetSentCredentialsFilter = useResetAtom(atomSentCredentialsFilter)
  const resetPasswordPatternFilter = useResetAtom(atomPasswordPatternFilter)

  const resetStudentClassSubscriptions = useResetAtom(atomStudentClassSubscriptions)
  const resetSelectedStudents = useResetAtom(atomSelectedStudents)

  const resetStudentFilters = () => {
    setPage(1)
    setOrderBy('asc')
    setSearchTerm('')
    resetProfileStatusFilter()
    resetSentCredentialsFilter()
    resetPasswordPatternFilter()
  }

  const resetStudentLists = () => {
    resetStudentClassSubscriptions()
    resetSelectedStudents()
  }

  return { resetStudentFilters, resetStudentLists }
}
