import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  relatedIcons: {
    color: theme.colorBrand.medium,
    marginRight: theme.spacingInset.xxs
  },
  title: {
    color: theme.colorBrand.medium,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacingInset.xs} ${theme.spacingInset.xs} 0 ${theme.spacingInset.xs}`,
    gap: theme.spacingInline.nano
  },
  checkboxLabel: {
    color: theme.colors.neutral.darkBase,
    fontSize: theme.font.fontSize.xs,
    marginTop: theme.spacingInline.nano
  },
  termsText: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    height: '300px',
    overflow: 'auto',
    position: 'relative',

    '& > div': {
      width: '100%'
    },

    '& p': {
      margin: 0
    }
  },
  termsTextContainer: {
    position: 'relative',
    height: '300px'

  },
  termsTextFade: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '50px',
    bottom: 0,
    background: 'linear-gradient(0deg, #FFF 31.5%, rgba(255, 255, 255, 0.00) 100%)'
  },
  termsLink: {
    color: `${theme.colorBrand.medium}!important`,
    textDecorationColor: `${theme.colorBrand.medium}!important`,
    '-webkit-text-decoration-color': `${theme.colorBrand.medium}!important`
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',

    '& .MuiFormControlLabel-root': {
      marginRight: theme.spacingInline.nano
    }
  }
}))
