export const formattedProfiles = {
  admin: 'Administrador',
  coordinator: 'Coordenador',
  student: 'Estudante',
  teacher: 'Professor'
}

export const lowerCaseFormattedProfiles = {
  admin: 'administrador',
  coordinator: 'coordenador',
  student: 'estudante',
  teacher: 'professor'
}

export const pluralFormattedProfiles = {
  admin: 'Administradores',
  coordinator: 'Coordenadores',
  student: 'Estudantes',
  teacher: 'Professores'
}

export const pluralLowerCaseFormattedProfiles = {
  admin: 'administradores',
  coordinator: 'coordenadores',
  student: 'estudantes',
  teacher: 'professores'
}
