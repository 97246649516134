/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'
import { BigNumbersLoading, BigNumbersReady } from './components'
import { useParams } from 'react-router'
import { useStore } from 'store'
import { IClassPerformance, IDiscipline, ISubscriptedTeacherProfile } from 'services/types'
import { getClassPerformance } from 'services/cloe-reports'
import { useTranslation } from 'react-i18next'
import { getClassSubscriptedProfiles, UserSchoolProfileTypeEnum } from 'services'
import { bigNumbersDataAtom } from './atomStore'
import { useAtom } from 'jotai'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

export interface IBigNumbersData {
  data: IClassPerformance | null
  error: string | null
}

interface IBigNumbersProps {
  currentDiscipline?: IDiscipline | null
}

export const BigNumbers: React.FC<IBigNumbersProps> = ({ currentDiscipline }) => {
  const { profile } = useStore()
  const { t } = useTranslation()
  const { classId } = useParams<{ classId?: string }>()

  // atoms
  const [bigNumbersData, setBigNumbersData] = useAtom(bigNumbersDataAtom)

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [classTeachers, setClassTeachers] = useState<ISubscriptedTeacherProfile[]>([])

  useEffect(() => {
    fetchClassReports()
  }, [currentDiscipline])

  const tryAgain = async () => {
    await fetchClassReports()
  }

  const fetchClassReports = async () => {
    setStateMachine(StateMachineEnum.LOADING)

    if (typeof currentDiscipline === 'undefined') {
      return
    }

    try {
      if (!currentDiscipline?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const teacherResponse = await getClassSubscriptedProfiles({ classId: Number(classId), filterByProfile: UserSchoolProfileTypeEnum.teacher })
      const bigNumbersResponse = await getClassPerformance({ schoolId: profile?.school?.id, classId: Number(classId), disciplineId: Number(currentDiscipline.id) })

      if (!bigNumbersResponse.success || !teacherResponse.success) {
        if (!bigNumbersResponse.status || !teacherResponse.status || ![400, 404].includes(bigNumbersResponse.status)) { // TODO: CHANGE THIS 400 to only 404
          throw new Error(bigNumbersResponse.message)
        }
        bigNumbersResponse.data = null as unknown as IClassPerformance
      }

      setClassTeachers([...new Map(teacherResponse.data.teacher.map(item => [item.user_id, item])).values()])

      const newClassData: IClassPerformance | null = bigNumbersResponse.data
      setBigNumbersData({ data: newClassData, error: null })
    } catch (err: any) {
      setBigNumbersData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    } finally {
      setStateMachine(StateMachineEnum.READY)
    }
  }

  const states = {
    IDLE: <></>,
    LOADING: <BigNumbersLoading />,
    READY: <BigNumbersReady bigNumbersData={bigNumbersData} tryAgainCallback={tryAgain} classTeachers={classTeachers} currentDiscipline={currentDiscipline} />
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
