import React from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import useStyle from './style'

export const LastWeekAccessChartSkeleton: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const chartItemWidth = isMobile ? 30 : 45
  const chartItemsHeight = [109, 87, 44, 159, 106, 168, 130, 156]

  return (
    <Grid container item xs={12} className={classes.loadingContainer}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Skeleton variant='text' className={classes.loadingLines} sx={{ width: '100%' }} height={45} animation='wave' />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='text' className={classes.loadingLines} sx={{ width: '80%' }} height={45} animation='wave' />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.loadingChart}>
        {chartItemsHeight.map(height => (
          <Skeleton variant='rectangular' width={chartItemWidth} height={height} animation='wave' />
        ))}
      </Grid>
    </Grid>
  )
}
