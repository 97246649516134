/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { IStudentsListPerformance } from 'services/types'

import { ReportRow, ReportsHeader } from './components'

interface IReportsTableReady {
  studentsListData: IStudentsListPerformance | null
}

const ReportsTableItem: React.FC<IReportsTableReady> = ({ studentsListData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container rowSpacing={3}>
      {!isMobile && (
        <Grid item xs={12}>
          <ReportsHeader.Item />
        </Grid>
      )}
      {studentsListData?.result?.map(studentPerformanceAndData => studentPerformanceAndData && (
        <Grid item xs={12}>
          <ReportRow.Item studentPerformanceAndData={studentPerformanceAndData} />
        </Grid>
      ))}
    </Grid>
  )
}

const ReportsTableError: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container>
      {!isMobile && <ReportsHeader.Item />}
      <ReportRow.Error error />
    </Grid>
  )
}

const ReportsTableSkeleton: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Grid container rowSpacing={3}>
        {!isMobile && (
          <Grid item xs={12}>
            <ReportsHeader.Skeleton />
          </Grid>
        )}
        <Grid item xs={12}>
          <ReportRow.Skeleton />
        </Grid>
        <Grid item xs={12}>
          <ReportRow.Skeleton />
        </Grid>
        <Grid item xs={12}>
          <ReportRow.Skeleton />
        </Grid>
      </Grid>
    </>
  )
}

export const ReportsTable = {
  Item: ReportsTableItem,
  Skeleton: ReportsTableSkeleton,
  Error: ReportsTableError
}
