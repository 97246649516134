export const getFileExtension = (fileName: string): string => {
  return (fileName.split('.').pop() ?? '').toLowerCase()
}

export const isAudioFile = (fileName: string): boolean => {
  if (!fileName) return false

  const extension = getFileExtension(fileName ?? '')
  const audioExtensions = ['mp3', 'wav', 'm4a', 'ogg']

  return audioExtensions.includes(extension)
}

export const extractFilename = (contentDisposition: string | null): string => {
  if (!contentDisposition) return 'download.pdf'

  // Try UTF-8 filename first
  const filenameUtf8 = /filename\*=UTF-8''([\w%.-]+)/.exec(contentDisposition)
  if (filenameUtf8?.[1]) {
    return decodeURIComponent(filenameUtf8[1])
  }

  // Fallback to regular filename
  const filenameRegex = /filename=([\w%.-]+)/.exec(contentDisposition)
  if (filenameRegex?.[1]) {
    return filenameRegex[1].replace(/['"]/g, '')
  }

  return 'download.pdf'
}
