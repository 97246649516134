export enum CLOE_TERMS_TYPES {
  STANDARD = 'STANDARD',
  SUPER = 'SUPER'
}

export interface IAcceptanceTermsResponse {
  UserId: number
  schoolId: string | number
}

export interface ITermsResponse {
  id: number
  privacy_policy: string
  usage_terms: string
  policy_summary: string
  terms_summary: string
}
