import { useResetAtom, useUpdateAtom } from 'jotai/utils'
import { atomTeacherClassSubscriptions } from 'pages/ClassManagement/atomStore'
import { atomOrderBy, atomPage, atomSearchTerm, atomSelectedTeachers } from 'pages/ProfileTabs/TeacherTab/atomStore'

export const useTeacherManagement = () => {
  const setOrderBy = useUpdateAtom(atomOrderBy)
  const setPage = useUpdateAtom(atomPage)
  const setSearchTerm = useUpdateAtom(atomSearchTerm)

  const resetTeacherClassSubscriptions = useResetAtom(atomTeacherClassSubscriptions)
  const resetSelectedTeachers = useResetAtom(atomSelectedTeachers)

  const resetTeacherFilters = () => {
    setPage(1)
    setOrderBy('asc')
    setSearchTerm('')
  }

  const resetTeacherLists = () => {
    resetTeacherClassSubscriptions()
    resetSelectedTeachers()
  }

  return { resetTeacherFilters, resetTeacherLists }
}
