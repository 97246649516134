import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClassListEnum, IClassItem, IClassProfileItem } from 'services'
import { StudentSelectionItem } from 'pages/ClassManagement/components'
import { Grid } from '@mui/material'
import { Text2 } from 'components/design-system'

interface IStudentSelectionListProps {
  classItem: IClassItem
  classListType: ClassListEnum
  students: IClassProfileItem[]
}

type StudentSelectionListStates = 'idle' | 'list' | 'empty'

export const StudentSelectionList: React.FC<IStudentSelectionListProps> = ({ classItem, classListType, students }) => {
  const { t } = useTranslation()

  // states
  const [studentSelectionListState, setStudentSelectionListState] = useState<StudentSelectionListStates>('idle')

  useEffect(() => {
    if (students.length) {
      setStudentSelectionListState('list')
    } else {
      setStudentSelectionListState(classListType === ClassListEnum.destiny ? 'empty' : 'idle')
    }
  }, [students])

  const stateMachine = {
    states: {
      idle: null,
      list: (
        <Grid container direction='column' rowSpacing={2}>
          {students.map((student: IClassProfileItem) =>
            <Grid item key={student.user_id}>
              <StudentSelectionItem classItem={classItem} classListType={classListType} student={student} />
            </Grid>
          )}
        </Grid>
      ),
      empty: (
        <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>
          {t('Nenhum estudante foi adicionado nessa turma.')}
        </Text2>
      )
    }
  }

  return stateMachine.states[studentSelectionListState]
}
