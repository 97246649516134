import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      borderRadius: '16px 16px 0px 0px',
      backgroundColor: theme.colors.neutral.lightBase,
      width: '100%',
      height: 'auto',
      boxShadow: '0 0 1em #DDD',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        left: '0',
        width: '100%',
        borderRadius: '0',
        boxShadow: 'none'
      }
    },
    calendarImgContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: `${theme.spacingStack.xxxs} auto`,
      '&.picture': {
        maxWidth: '500px',
        '& img': {
          textAlign: 'center',
          width: '100%'
        }
      }
    }
  }
})
