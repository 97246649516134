import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { IGradeType, IDisciplineResponse, IRegistrationTokenResponse, ISchoolPeriod } from 'services'

export const oneGradeType = atomWithStorage<IGradeType | null>('oneGradeType', null)

export const registrationTokenData = atomWithStorage<IRegistrationTokenResponse[] | null>('registrationToken', null)

export const classDiscipline = atomWithStorage<IDisciplineResponse[] | null>('classDiscipline', null)

export const currentSchoolPeriodAtom = atom<ISchoolPeriod | null>(null)
