import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    containerWrapper: {
      background: theme.colors.neutral.lightBase,
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
      boxShadow: theme.shadowLevels.level5,
      padding: theme.spacingInset.sm,
      height: 'auto',
      // mobile
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'unset',
        paddingInline: theme.spacingInline.xxxs
      }
    },
    reportEmbed: {
      width: '100%',
      height: '100vh',

      '& iframe': {
        border: '0'
      }
    }
  }
})
