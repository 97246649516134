import { EmptyOrErrorMessage, ProfileClassCard, ProfilesListBulkOptions } from 'components/common'
import addFiles from 'assets/add-files.svg'
import { createRef, useCallback, useEffect, useState } from 'react'
import makeStyles from './style'
import { useParams } from 'react-router'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { atomClassSelectingTeachers } from 'pages/BaseScreen/atomStore'
import { classTeacherProfileBulkOptionsTags, getProfileListByClass, ListTypeEnum, UserSchoolProfileTypeEnum } from 'services'
import { Button, Grid, Loading, Text2 } from 'components/design-system'
import { useSnackbar } from 'navigation/hooks'
import { useStore } from 'store'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { atomClassGradeTypeWithGrades, atomCurrentClass, atomNoFetchData, atomTeacherClassSubscriptions } from 'pages/ClassManagement/atomStore'
import { ActionContextEnum, ActionsControlCenter, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PrintableUserList } from 'components/templates'
import { removeIlegalCharacteresFromFileName } from 'utils'

interface IManageClass {
  classId?: string
}

type TeacherListStates = 'idle' | 'list' | 'emptyList' | 'loading'

export const ClassTeacherList: React.FC = () => {
  const styles = makeStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { classId: classIdParam } = useParams<IManageClass>()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const printListButtonRef = createRef<HTMLDivElement>()

  // atoms
  const currentTeacherClass = useAtomValue(atomClassSelectingTeachers)
  const [reloadList, setReloadList] = useAtom(atomReloadList)
  const [classSubscriptionList, setClassSubscriptionList] = useAtom(atomTeacherClassSubscriptions)
  const classGradeTypeWithGrades = useAtomValue(atomClassGradeTypeWithGrades)
  const noFetchData = useAtomValue(atomNoFetchData)
  const resetNoFetchData = useResetAtom(atomNoFetchData)
  const schoolClass = useAtomValue(atomCurrentClass)

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [teacherListState, setTeacherListState] = useState<TeacherListStates>('idle')

  const getProfileList = async (profileType: UserSchoolProfileTypeEnum, schoolId: number | undefined, classId: number) => {
    try {
      if (schoolId && classId) {
        setIsLoading(true)
        setTeacherListState('loading')
        const response = await getProfileListByClass(
          profileType,
          schoolId,
          classId,
          'user_school_profile.active:DESC,user_id.name:ASC,user_id.surname:ASC',
          true
        )
        if (response.success) {
          if (!noFetchData || response.data.length) {
            setClassSubscriptionList(response.data.map(p => ({
              ...p,
              checked: false
            })))
          }
          setTeacherListState(response.data.length ? 'list' : 'emptyList')
        } else {
          throw new Error('Erro ao tentar carregar professores')
        }
      } else {
        throw new Error('Erro ao tentar carregar professores')
      }
    } catch (e) {
      setTeacherListState('idle')
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      resetNoFetchData()
      setIsLoading(false)
      setReloadList(false)
    }
  }

  const fetchData = async () => await getProfileList(UserSchoolProfileTypeEnum.teacher, school?.id, classIdParam ? parseInt(classIdParam) : 0)

  useEffect(() => {
    if (isLoading) {
      setTeacherListState('loading')
    }
  }, [isLoading])

  useEffect(() => {
    if (!reloadList) fetchData()
  }, [])

  useEffect(() => {
    if (reloadList) fetchData()
  }, [reloadList])

  const onChange = useCallback((classSubscriptionId: number, checked: boolean) => {
    setClassSubscriptionList(prevItems => prevItems.map(classSubscription => {
      const index = prevItems.findIndex(item => item.id === classSubscriptionId)
      if (index > -1) prevItems[index].checked = checked
      return classSubscription
    }))
  }, [])

  const clearOrSelectAll = (checked: boolean) => setClassSubscriptionList(
    classSubscriptionList?.map(classSubscription => ({
      ...classSubscription,
      checked
    }))
  )

  const allListSelected = classSubscriptionList?.every(classSubscription => classSubscription.checked)

  useEffect(() => {
    if (classIdParam !== currentTeacherClass) clearOrSelectAll(false)
  }, [classIdParam])

  const handleClickPrintListButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    printListButtonRef?.current?.click()
  }

  const stateMachine = {
    states: {
      idle: null,
      list: (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Text2
                fontSize='lg'
                fontWeight='semibold'
                lineHeight='xs'
                mobile='lg'
                customColor={theme.colorBrand.medium}
              >
                {t('Professores')}
              </Text2>
            </Grid>
            {schoolClass && (
              <Grid item>
                <PDFDownloadLink
                  className={styles.actionButton}
                  document={
                    <PrintableUserList
                      classGradeTypeWithGrades={classGradeTypeWithGrades}
                      currentClass={schoolClass}
                      profile={UserSchoolProfileTypeEnum.teacher}
                      totalUsers={classSubscriptionList.length}
                      users={classSubscriptionList.map(cs => ({ ...cs.user_id, active: cs.user_school_profile.active }))}
                    />
                  }
                  fileName={`${removeIlegalCharacteresFromFileName(schoolClass.name)} - Professores`}
                >
                  <div ref={printListButtonRef} data-testid='manage_class_teachers_print_list' hidden />
                  <Button onClick={handleClickPrintListButton} startIcon={<PrintOutlinedIcon />} variant='ghost'>
                    {t('Imprimir listagem')}
                  </Button>
                </PDFDownloadLink>
              </Grid>
            )}
          </Grid>
          <div className={styles.profileList}>
            <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.select_all_teachers} options={{ fullFeaturesDisabler: !schoolClass?.active }}>
              <ProfilesListBulkOptions
                selectAllListItems={() => clearOrSelectAll(true)}
                clearListItemsSelection={() => clearOrSelectAll(false)}
                tagging={classTeacherProfileBulkOptionsTags}
                selectionList={classSubscriptionList.filter(cs => cs.checked).map(cs => ({ ...cs.user_id, active: cs.user_school_profile.active }))}
                hasSelection={!!classSubscriptionList.find(cs => cs.checked)}
                profile={UserSchoolProfileTypeEnum.teacher}
                listbycontext={ListTypeEnum.classList}
                allListSelected={allListSelected}
              />
            </ActionsControlCenter>
            {classSubscriptionList?.map((classSubscription, index) =>
              <ProfileClassCard
                checked={classSubscription.checked}
                classId={classIdParam ?? ''}
                key={index}
                onChange={onChange}
                classSubscription={classSubscription}
              />
            )}
          </div>
        </>
      ),
      emptyList: (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Text2
                fontSize='lg'
                fontWeight='medium'
                lineHeight='xs'
                mobile='lg'
                customColor={theme.colorBrand.medium}
              >
                {t('Professores')}
              </Text2>
            </Grid>
          </Grid>
          <ActionsControlCenter
            ctx={ActionContextEnum.schoolClassManager}
            action={SchoolClassManagerActionsEnum.teacher_list_empty_state}
            fallback={
              <EmptyOrErrorMessage
                message='Não há professores associados à esta turma'
                image={addFiles}
              />
            }
          >
            <EmptyOrErrorMessage
              message='Turma criada com sucesso!<br/>Adicione estudantes à turma ou vincule professores à ela.'
              image={addFiles}
            />
          </ActionsControlCenter>
        </>
      ),
      loading: <Loading type='linear' />
    }
  }

  return stateMachine.states[teacherListState]
}
