import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bottom: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    bottom: '-28px',
    height: '30px',
    left: '0',
    position: 'absolute',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    width: '30px',
    '&::before': {
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.light}`,
      content: '""',
      height: '200%',
      left: '0',
      position: 'absolute',
      width: '200%',
      zIndex: '0'
    }
  },
  inLineBrandMobile: {
    color: theme.colors.neutral.lightBase,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '14px 14px 24px 14px',
      columnGap: '24px',
      cursor: 'pointer'
    }
  },
  topBevel: {
    '&::before': {
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.light}`
    }
  }
}))
