import { Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { SearchInput } from 'components/common'
import { Grid, Select, Text2 } from 'components/design-system'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { currentStudentPerformanceAndDataAtom, searchAtom, sortByAtom } from 'pages/ClassManagement/atomStore'
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { IDiscipline, IOrderByOption, IUnitStudentDetailedPerformance, orderByOptions } from 'services'
import { getDetailedPerformance } from 'services/cloe-reports'
import { useStore } from 'store'
import { DetailedPerformanceList } from '..'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface IDetailedPerformance {
  currentDiscipline: IDiscipline | undefined
  isLoading: boolean
}

export const DetailedPerformance: React.FC<IDetailedPerformance> = ({ currentDiscipline, isLoading }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { classId } = useParams<{ classId?: string }>()
  const { school } = useStore()

  const PAGE_SIZE = 8

  // atoms
  const [search, setSearch] = useAtom(searchAtom)
  const resetSearch = useResetAtom(searchAtom)
  const [sortBy, setSortBy] = useAtom(sortByAtom)
  const resetSortBy = useResetAtom(sortByAtom)
  const currentStudentPerformanceAndData = useAtomValue(currentStudentPerformanceAndDataAtom)

  // states
  const [detailedPerformanceList, setDetailedPerformanceList] = useState<IUnitStudentDetailedPerformance[]>([])
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)
  const [limit, setLimit] = useState(PAGE_SIZE)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [showMorePages, setShowMorePages] = useState<boolean>(false)
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const options: IOrderByOption[] = orderByOptions.map(op => ({ ...op, dataTestId: 'followup_student_activity_order_by' }))

  const resetPage = () => {
    setLimit(PAGE_SIZE)
    setOffset(0)
    setCurrentPage(0)
  }

  useEffect(() => {
    setStateMachine(
      (isLoadingList ?? isLoading)
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading, isLoadingList])

  useEffect(() => {
    if (currentDiscipline?.id && Number(currentDiscipline?.id) !== 0) {
      resetPage()
      resetSearch()
      resetSortBy()
      setDetailedPerformanceList([])
      fetchDetailedPerformance('', 'desc')
    }
  }, [currentDiscipline])

  const fetchDetailedPerformance = async (searchParam: string, sortParam: 'desc' | 'asc' | undefined, isLoadMore?: boolean) => {
    if (school?.id && classId && currentDiscipline?.id && currentStudentPerformanceAndData?.user_school_profile?.id) {
      setIsLoadingList(true)

      let nextLimit = limit
      let nextOffset = offset
      let nextPage = currentPage

      if (isLoadMore) {
        nextPage = currentPage + 1
        nextOffset = nextPage * PAGE_SIZE
        nextLimit = PAGE_SIZE

        setOffset(nextOffset)
        setLimit(nextLimit)
        setCurrentPage(nextPage)
      }

      const resDetailedPerformance = await getDetailedPerformance({
        classId: Number(classId),
        disciplineId: Number(currentDiscipline.id),
        schoolId: school.id,
        studentProfileId: currentStudentPerformanceAndData.user_school_profile.id,
        limit: nextLimit,
        offset: nextOffset,
        search: searchParam,
        sortBy: sortParam
      })

      if (resDetailedPerformance?.data?.units?.length) {
        const newList = isLoadMore
          ? [...(detailedPerformanceList ?? []), ...(resDetailedPerformance?.data.units ?? [])]
          : resDetailedPerformance?.data.units

        setDetailedPerformanceList(newList)
      } else {
        resetPage()
        setShowMorePages(false)
        setDetailedPerformanceList([])
      }

      if (!resDetailedPerformance?.data?.hasMore) {
        resetPage()
      }

      setShowMorePages(resDetailedPerformance?.data?.hasMore)
      setIsLoadingList(false)
    }
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setSearch(e.target.value)
  }

  const handleSearch = () => {
    setDetailedPerformanceList([])
    fetchDetailedPerformance(search, sortBy)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setDetailedPerformanceList([])
      fetchDetailedPerformance(search, sortBy)
    }
  }

  const handleSortBy = (e: ChangeEvent<HTMLInputElement>) => {
    const _sort = e.target.value === 'asc' ? 'asc' : 'desc'
    setSortBy(_sort)
    resetPage()
    setDetailedPerformanceList([])
    fetchDetailedPerformance(search, _sort)
  }

  const loadMore = async () => await fetchDetailedPerformance(search, sortBy, true)

  const states = {
    IDLE: <></>,
    LOADING: (
      <Grid container marginTop={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Desempenho detalhado de {{studentName}}', { studentName: currentStudentPerformanceAndData?.user?.name ?? '' })}
          </Text2>
        </Grid>
        <Grid container item direction={isMobile ? 'column-reverse' : 'row'} columnSpacing={3} rowSpacing={2}>
          <Grid item xs={isMobile ? 'auto' : 12} md={9}>
            <Skeleton animation='wave' height={80} />
          </Grid>
          <Grid item xs={isMobile ? 'auto' : 12} md={3}>
            <Skeleton animation='wave' height={80} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DetailedPerformanceList
            detailedPerformanceList={detailedPerformanceList}
            isLoading={isLoadingList ?? isLoading}
            loadMore={loadMore}
            showMorePages={showMorePages}
          />
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container marginTop={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Desempenho detalhado de {{studentName}}', { studentName: currentStudentPerformanceAndData?.user?.name ?? '' })}
          </Text2>
        </Grid>
        <Grid container item direction={isMobile ? 'column-reverse' : 'row'} columnSpacing={3} rowSpacing={2}>
          <Grid item xs={isMobile ? 'auto' : 12} md={9}>
            <SearchInput
              helperText={t(isMobile ? 'Pressione ENTER para pesquisar' : 'Pressione ENTER ou clique na lupa para pesquisar')}
              id='searchStudent'
              label={t('Pesquisar unidade')}
              onChange={handleChangeSearch}
              onClick={handleSearch}
              onKeyDown={handleKeyDown}
              searchDataTestId='followup_student_activity_search_student'
              value={search}
              variant='filled'
            />
          </Grid>
          <Grid item xs={isMobile ? 'auto' : 12} md={3}>
            <Select
              dataTestId='followup_student_activity_expand_list_order_by'
              id='order-by'
              label={t('Ordenar por')}
              options={options}
              value={sortBy}
              onChange={handleSortBy}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DetailedPerformanceList
            detailedPerformanceList={detailedPerformanceList}
            isLoading={isLoadingList ?? isLoading}
            loadMore={loadMore}
            showMorePages={showMorePages}
          />
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
