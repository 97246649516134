import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'

import { Alert } from 'components/design-system'

type IStateRender = 'idle' | 'maxAttempts' | 'tryAgain'

interface IErrorProps {
  actionDataTestId?: string
  handleTryAgain: () => Promise<void>
}

export const Error: React.FC<IErrorProps> = ({ actionDataTestId, handleTryAgain }) => {
  const [attempts, setAttempts] = useState<number>(0)
  const [state, setState] = useState<IStateRender>('idle')
  const { t } = useTranslation()

  const { subscription } = useStore()

  const { classId } = useParams<{ classId: string }>()

  useEffect(() => {
    setState('tryAgain')
  }, [])

  const newAttempt = () => {
    setState('tryAgain')
    setAttempts(attempts + 1)

    if (attempts > 4 || !(classId || subscription)) {
      setState('maxAttempts')
      return
    }

    handleTryAgain()
  }

  const idle = () => ''

  const tryAgain = () => <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} outlined={true} severity='error' onActionClick={newAttempt} actionDataTestId={actionDataTestId} action={`${t('Recarregar')}`} sx={{ alignSelf: 'flex-start' }} />

  const maxAttempts = () => <Alert content={t('Houve uma falha ao buscar as disciplinas da escola. Será necessário fazer o login novamente.')} outlined={true} severity='error' sx={{ alignSelf: 'flex-start' }} />

  const stateRender = {
    idle,
    tryAgain,
    maxAttempts
  }

  return (
    <>
      {stateRender[state]()}
    </>
  )
}
