import { createClass, editOneClass, getClassByNameAndSchoolAndSchoolPeriod, IClassRequest, IClassResponse } from 'services'
import { useState } from 'react'

interface IEditClassData {
  schoolId: number
  classId: number
}
interface ICreateClassData {
  schoolId: number
}

export const useEditSchoolClass = () => {
  const [error, setError] = useState<string | undefined>(undefined)

  const identicalClassFinder = async (schoolId: number, data: IClassRequest): Promise<IClassResponse | undefined> => {
    let haveFoundIdenticalClass: IClassResponse | undefined
    try {
      const classesListResponse = (await getClassByNameAndSchoolAndSchoolPeriod(data.name, schoolId, data.school_period)).data
      if (classesListResponse.length) {
        const classesWithInputedName = classesListResponse.filter((cname) => cname.name === data.name)
        if (classesWithInputedName.length) {
          haveFoundIdenticalClass = classesWithInputedName.find(currentClass => currentClass.grades.every(grade => {
            if (currentClass.grades.length === data.grades.length) {
              return data.grades.includes(grade.id)
            }
            return undefined
          }))
        }
      }
    } catch (err) {
      if (err instanceof Error) setError(err.message)
      setError('Erro ao validar informações')
    }
    return haveFoundIdenticalClass
  }

  const editClassHandler = async (payload: IClassRequest, validators: IEditClassData) => {
    const { classId, schoolId } = validators

    try {
      const identicalClassAlreadyExists = await identicalClassFinder(schoolId, payload)

      if (identicalClassAlreadyExists) {
        setError('Turma já existente.')
        return
      }

      const response = await editOneClass(classId, payload)

      if (response.errors) setError('Erro ao editar turma')

      return response
    } catch (err) {
      if (err instanceof Error) setError(err.message)
      setError('Erro ao editar turma')
    }
  }

  const createClassHandler = async (payload: IClassRequest, validators: ICreateClassData) => {
    const { schoolId } = validators

    try {
      const identicalClassAlreadyExists = await identicalClassFinder(schoolId, payload)

      if (identicalClassAlreadyExists) {
        setError('Turma já existente.')
        return
      }

      const response = await createClass(payload)

      if (response.errors) setError('Erro ao criar turma')

      return response
    } catch (err) {
      if (err instanceof Error) setError(err.message)
      setError('Erro ao criar turma')
    }
  }

  return {
    error,
    editClassHandler,
    createClassHandler
  }
}
