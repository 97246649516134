import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    helperText: {
      '&.MuiFormHelperText-root': {
        fontFamily: theme.font.fontFamily,
        fontWeight: theme.font.fontWeight.medium,
        letterSpacing: '0.01em',
        marginLeft: '16px'
      }
    },
    icon: {
      color: theme.colorBrand.medium,
      height: '32px!important',
      width: '32px!important'
    },
    searchInput: {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      borderRadius: theme.borderRadius.sm,
      width: '100%',
      '&:focus-within': {
        outline: `2px solid ${theme.colorBrand.medium}!important`
      },
      '& .MuiInputBase-input': {
        paddingLeft: '16px'
      },
      '& .MuiInputLabel-root': {
        color: '#141414',
        fontFamily: theme.font.fontFamily,
        fontWeight: theme.font.fontWeight.regular,
        left: '4px',
        letterSpacing: '0.01em',
        '&.Mui-focused': {
          color: theme.colorBrand.medium
        }
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        fontFamily: theme.font.fontFamily,
        letterSpacing: '0.01em',
        '&.Mui-focused, &:hover, &.Mui-disabled': {
          backgroundColor: 'transparent'
        }
      }
    },
    searchBox: {
      display: 'flex',
      alignItems: 'flex-end'
    }
  }
})
