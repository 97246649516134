import Box from '@mui/material/Box'
import { ContainerProps } from '@mui/material'
import MUIContainer from '@mui/material/Container'
import makeStyles from './style'

export interface IContainerProps extends Omit<ContainerProps, 'variant'> {
  children?: React.ReactNode
  extraclasses?: string
}

export const Container: React.FC<IContainerProps> = ({ children, ...props }) => {
  const classes = makeStyles()

  return (
    <MUIContainer {...props} maxWidth={false} className={`${classes.root} ${props.extraclasses ?? ''}`}>
      <Box>
        {children}
      </Box>
    </MUIContainer>
  )
}
