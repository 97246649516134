import { Box, CircularProgressProps, CircularProgress as CircularProgressMUI, Theme } from '@mui/material'
import { Text } from 'components/design-system'

import useStyles from './style'

export interface ICircularProgress extends CircularProgressProps {
  value: number
  showPercentageSymbol?: boolean
  circleSize?: string
  circleThickness?: number
  textFontSize?: string
  customText?: string
  color1?: string
  color2?: string
  theme: Theme
  customIcon?: React.ReactNode
  customLabelClassName?: string
}
// TODO: REMOVE THEME PROP
export const CircularProgress: React.FC<ICircularProgress> = ({
  value,
  customText,
  showPercentageSymbol = false,
  circleSize = '5rem',
  circleThickness = 5,
  color1,
  color2,
  customIcon,
  customLabelClassName
}) => {
  // TODO: FIX MUI THEMES NOT BEIGN APPLIED TO STYLE TS
  const classes = useStyles()

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgressMUI
        variant='determinate'
        sx={{
          color: (theme: Theme) => (color1 ?? theme.colorBrand.light)
        }}
        size={circleSize}
        thickness={circleThickness}
        value={100}
      />
      <CircularProgressMUI
        variant='determinate'
        sx={{
          color: (theme: Theme) => (color2 ?? theme.colors.neutral.lightBase),
          position: 'absolute',
          left: 0
        }}
        size={circleSize}
        thickness={circleThickness}
        value={value}
      />
      <Box
        className={classes.labelBox}
      >
        {customIcon}
        <Text
          type='subtitle'
          size='medium'
          className={`${classes.circularProgressCaption} ${customLabelClassName ?? ''}`}
        >
          {customText ?? `${Math.round(value)}${showPercentageSymbol ? '%' : ''}`}
        </Text>
      </Box>
    </Box>
  )
}
