import { ProfileRegisterPageContainer, SpreadsheetStatus, SpreadsheetDownload } from 'components/common'
import { Grid, Link, Text2 } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import FileUpload from 'components/common/FileUpload'
import { CLASS_MANAGEMENT, PROFILE_TABS } from 'navigation/CONSTANTS'
import { useParams } from 'react-router'
import { UserSchoolProfileTypeEnum } from 'services'

interface IManageClass {
  classId?: string
}

export const BulkCreateStudent: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()
  const { classId } = useParams<IManageClass>()

  const handleBackButtonLink = () => history.push(classId
    ? CLASS_MANAGEMENT(`view/${classId}`)
    : PROFILE_TABS('students')
  )

  return (
    <ProfileRegisterPageContainer
      link={
        <Link data-testid='list_students_bulk_registration_back_to_class' variant='returnArrow' onClick={handleBackButtonLink} linkStyle>
          {t(`Voltar para ${classId ? 'turma' : 'lista'}`)}
        </Link>}
      title={
        <Grid container className={styles.heading}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
            {t('Cadastro de estudantes em massa')}
          </Text2>
        </Grid>
      }
      description={
        <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
          {t('Baixe a planilha modelo, preencha com os dados e depois suba a planilha.')}
        </Text2>
      }
      additionalBlockOfContent={<SpreadsheetDownload dataTestid='list_students_bulk_registration_download_template_spreadsheet' spreadSheetVersion='estudantes' />}
    >
      <Grid container>
        <Grid container item sm={12}>
          <FileUpload dataTestid='list_students_bulk_registration_click_here_upload_spreadsheet' target={UserSchoolProfileTypeEnum.student} accept='.xls, .xlsx, .csv' />
        </Grid>
        <Grid container item sm={12}>
          <SpreadsheetStatus dataTestidCancel='list_students_bulk_registration_cancel_spreadsheet_upload' dataTestidArrow='list_students_bulk_registration_show_comments' target={UserSchoolProfileTypeEnum.student} />
        </Grid>
      </Grid>
    </ProfileRegisterPageContainer>
  )
}
