import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    heading: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacingStack.xxs,
        marginBottom: theme.spacingStack.xxs,
        '& > span:first-child': {
          lineHeight: 0
        }
      }
    },
    requiredLabel: {
      marginBottom: theme.spacingStack.xxxs
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        margin: '0'
      },
      '& > .MuiLink-root': {
        color: theme.colorBrand.medium,
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacingStack.sm
        },
        '& svg:first-child': {
          color: theme.colorBrand.medium
        }
      }
    },
    classNameInputBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      marginTop: theme.spacingStack.xxs
    },
    classNameInput: {
      maxWidth: '563px',
      width: '100%',
      height: '56px'
    },

    schoolYear: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacingStack.sm,
      '& > label': {
        marginTop: theme.spacingStack.nano
      }
    },
    yearSection: {
      marginTop: theme.spacingStack.sm,
      '& > span:first-child': {
        marginBottom: theme.spacingStack.sm,
        fontWeight: theme.font.fontWeight.semibold
      },
      '& label': {
        cursor: 'pointer',
        transition: 'all .2s',
        '&:hover': {
          background: theme.colorBrand.lightest
        }
      }
    },
    yearSectionGrades: {
      '& > div + div': {
        marginTop: theme.spacingStack.xxs
      }
    },
    buttonGroup: {
      justifyContent: 'flex-end',
      width: '100%',
      '& > div': {
        marginLeft: theme.spacingStack.xxxs
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-around',
        margin: theme.spacingSquish.quarck
      }
    },
    selectedTab: {
      background: theme.colorBrand.lightest
    }
  }
})
