import { Button, Text2 } from 'components/design-system'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import useStyles from './style'
import { useUserSendDefaultCredentials } from 'navigation/hooks'
import { UserSchoolProfileTypeEnum } from 'services'

interface ISendNotificationsButtonProps {
  children: React.ReactNode
  transactionId?: string
  recipientsids?: number[]
  recipienttype: UserSchoolProfileTypeEnum.teacher | UserSchoolProfileTypeEnum.student | undefined
  notificationtype: 'credentials' | undefined
  dataTestid?: string
}

export const SendNotificationsButton: React.FC<ISendNotificationsButtonProps> = ({ children, ...props }) => {
  const styles = useStyles()
  const { sendCredentials } = useUserSendDefaultCredentials()

  const handleSendNotification = async () => {
    if (props.notificationtype === 'credentials') {
      await handleUserSendDefaultCredentials()
    }
  }

  const handleUserSendDefaultCredentials = async () => {
    if (props.transactionId) {
      await sendCredentials({ massTransactionId: props.transactionId })
    }
  }

  return (
    children
      ? (
        <Button data-testid={props.dataTestid} variant='primary' onClick={handleSendNotification} {...props} className={styles.sendNotificationBtn}>
          <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' cursor='pointer'>
            {children}
          </Text2>
          <SendOutlinedIcon/>
        </Button>
      )
      : null
  )
}
