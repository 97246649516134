import { Card, Grid, Text2 } from 'components/design-system'
import React from 'react'
import useStyles from './style'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { IStudentsPerformance } from 'services'
import { formatPhone, isDoNotSendEmail, isUnformattedPhone } from 'utils'
import { CardContent } from '@mui/material'

export interface IStudentCardInfo {
  studentPerformanceAndData: IStudentsPerformance
  noBorder?: boolean
  noPadding?: boolean
}

export const StudentCardInfo: React.FC<IStudentCardInfo> = ({ ...props }) => {
  const classes = useStyles(props)

  const { studentPerformanceAndData } = props
  const { user } = studentPerformanceAndData
  const { email, phone, username } = user

  return (
    <Card extraClasses={classes.studentCard} noPadding>
      <CardContent className={classes.cardContent}>
        <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.studentCardContent}>
          <Grid container item columnSpacing={1}>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' colorbrand='medium'>
                <AccountCircleOutlinedIcon />
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' neutral='dark10'>
                {username}
              </Text2>
            </Grid>
          </Grid>
          {phone && isUnformattedPhone(phone) && (
            <Grid container item columnSpacing={1}>
              <Grid item>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' colorbrand='medium'>
                  <LocalPhoneOutlinedIcon />
                </Text2>
              </Grid>
              <Grid item>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' neutral='dark10'>
                  {formatPhone(phone)}
                </Text2>
              </Grid>
            </Grid>
          )}
          {email && !isDoNotSendEmail(email) && (
            <Grid container item columnSpacing={1}>
              <Grid item>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' colorbrand='medium'>
                  <EmailOutlinedIcon />
                </Text2>
              </Grid>
              <Grid item>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' neutral='dark10'>
                  {email}
                </Text2>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
