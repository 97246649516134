import { FC, ReactElement } from 'react'
import { render, RenderOptions, configure } from '@testing-library/react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from 'styles/theme'
import { BrowserRouter } from 'react-router-dom'
import 'i18n'
import { UserSchoolProfileTypeEnum } from 'services'

configure({ defaultHidden: true })

const AllTheProviders: FC = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <ThemeProvider theme={theme(UserSchoolProfileTypeEnum.student)}>
          {children}
        </ThemeProvider>
      </BrowserRouter>
    </>
  )
}

export const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>
) => render(ui, { wrapper: AllTheProviders, ...options })

export * from '@testing-library/react'
