import { ButtonHTMLAttributes, ReactNode } from 'react'
import useStyles from './style'

interface ICircleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'activate' | 'deactivate'
  children: ReactNode
}

export const CircleButton = ({ variant = 'primary', children, ...props }: ICircleButtonProps) => {
  const styles = useStyles()

  return (
    <button
      {...props}
      className={`${styles.root} ${variant}`}
    >
      {children}
    </button>
  )
}
