import { useCommonStyles } from '../../style'
import useStyles from './style'
import { SideBarItem } from '../SideBarItem'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

interface ILowerMenuProps {
  activeMenuSidebar: boolean
}

export const LowerMenu: React.FC<ILowerMenuProps> = ({ activeMenuSidebar }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={`${commonClasses.box} ${classes.downBox} ${!activeMenuSidebar && commonClasses.beforeMobile}`}>
      <div className={`${commonClasses.top} ${classes.topBevel}`} />
      <SideBarItem
        dataTestId='menu_help_center_faq'
        href='https://centraldoconhecimento.cloeapp.com/perguntas-frequentes'
        icon={<HelpOutlineOutlinedIcon />}
        label='FAQ'
        target
        variant='neutral'
      />
      <SideBarItem
        dataTestId='menu_help_center_whatsapp'
        href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_SUPPORT_NUMBER ?? '5511986588112'}&text&type=phone_number&app_absent=0`}
        icon={<WhatsAppIcon />}
        label='Whatsapp'
        target
        variant='neutral'
      />
    </div>
  )
}
