import { CustomTheme } from 'styles/types/CustomTheme'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  '&.MuiTabs-root': {
    borderBottom: `5px solid ${theme.colorBrand.medium}`,
    width: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent'
    }
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    ...styleDefault(theme)
  }
}))
