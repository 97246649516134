import React from 'react'
import useStyle from '../style'
import { Grid, Text2 } from 'components/design-system'
import { ClipBoardCopy, Tooltip } from 'components/common'
import { useTranslation } from 'react-i18next'
import { UserSchoolProfileTypeEnum } from 'services'
import { formatPhone } from 'utils'

export interface IContactProps {
  contact: {
    type: string
    value: string
  }
  profiletype: UserSchoolProfileTypeEnum
  copyButtonDataTestId?: string
}

export const Contact: React.FC<IContactProps> = ({ contact, profiletype, copyButtonDataTestId }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container item direction='column' xs={12} md={6} lg={3}>
      <Grid item>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
          {t(profiletype === UserSchoolProfileTypeEnum.student
            ? 'Contato do responsável'
            : 'Contato'
          )}
        </Text2>
      </Grid>
      <Grid container item alignItems='center' wrap='nowrap' className={classes.info}>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40' className={contact.type === 'email' ? classes.ellipsis : ''}>
          {contact.type === 'email'
            ? (
              <Tooltip title={contact.value} arrow>
                <span>{contact.value}</span>
              </Tooltip>
            )
            : formatPhone(contact.value)
          }
        </Text2>
        <ClipBoardCopy dataTestId={copyButtonDataTestId} copyItem={contact.value} />
      </Grid>
    </Grid>
  )
}
