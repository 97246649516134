import {
  request,
  IApiResponse,
  IGradeType,
  IGradeResponse,
  IGradeTypesResponse
} from 'services'
import {
  GRADE_TYPES_GET,
  GRADE_TYPE_BASE_BY_PROGRAM,
  GRADE_FROM_GRADE_TYPE,
  GRADE_TYPE_BY_SCHOOL
} from './CONSTANTS'

export const getGradeTypes = async (): Promise<IApiResponse<IGradeType[]>> =>
  await request({ url: GRADE_TYPES_GET() })

export const getGradeType = async (id?: number): Promise<IApiResponse<IGradeType>> =>
  await request({ url: GRADE_TYPES_GET(id) })

export const getGradeFromGradeType = async (id: number | string, schoolPeriodId?: number | 'current'): Promise<IApiResponse<IGradeResponse[]>> =>
  await request({ url: GRADE_FROM_GRADE_TYPE(id, schoolPeriodId) })

export const getGradeTypesByProgram = async (): Promise<IApiResponse<IGradeTypesResponse[]>> =>
  await request({ url: GRADE_TYPE_BASE_BY_PROGRAM() })

export const getGradeTypesBySchool = async (schoolId: number): Promise<IApiResponse<IGradeType[]>> =>
  await request({ url: GRADE_TYPE_BY_SCHOOL(schoolId) })
