import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    autonomousCodeContainer: {
      display: 'flex !important',
      gap: theme.spacingInline.nano,
      flexDirection: 'column !important' as 'column',
      alignItems: 'flex-start',

      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        flexDirection: 'row !important' as 'row'
      }
    },
    code: {
      background: theme.colorBrand.lightest,
      padding: `${theme.spacingInline.quarck} ${theme.spacingInline.nano} !important`,
      borderRadius: theme.borderRadius.xs,
      textAlign: 'center !important' as 'center'
    },
    alignMiddle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    countdownText: {
      minWidth: '36px !important'
    },
    customButton: {
      padding: '0 !important'
    }
  }
})
