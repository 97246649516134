import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { getProperProfilePhraseForDeactivation } from '../../utils'

interface IProfileDeactivationModalProps {
  open: boolean
  onClose: () => void
  onDeactivateProfile: () => void
  list?: IUserResponse[]
  profile: UserSchoolProfileTypeEnum
  isLoading: boolean
}

export const ProfileDeactivationModal = ({ open, onClose, onDeactivateProfile, list, profile, isLoading }: IProfileDeactivationModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onDeactivateProfile}
      confirmButtonBackground='feedbackNegative'
      cancelButtonVariant='outlined'
      confirmButtonText={list ? 'Desativar perfis' : 'Desativar perfil'}
      isLoading={isLoading}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' negative='feedbackDark'>
            {t('Confirmação de desativação de perfil')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {t(`${getProperProfilePhraseForDeactivation(profile, list) as string}?`)}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {t(`${list ? 'Eles perderão' : 'Ele perderá'} o acesso a plataforma`)}
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
