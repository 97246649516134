import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    buttonGroup: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start'
      }
    },
    credentialsCard: {
      padding: `${theme.spacingSquish.xs}!important`,
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: theme.borderRadius.sm,
      margin: `${theme.spacingStack.xs} ${theme.spacingStack.sm}!important`
    },
    filter: {
      width: '100%'
    },
    filtersButton: {
      border: `solid ${theme.borderThickness.hairline}!important`
    },
    header: {
      background: theme.colors.neutral.lightBase,
      padding: '32px 24px 12px 24px',
      [theme.breakpoints.down('sm')]: {
        top: '61px',
        zIndex: 1
      }
    },
    helperText: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    labelContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      paddingTop: '32px',
      paddingBottom: '4px',
      width: 'auto',
      '& label:last-child': {
        marginRight: theme.spacingInline.xs
      },
      [theme.breakpoints.down('sm')]: {
        '& > .MuiGrid-item': {
          paddingTop: '0!important'
        },
        '& .MuiFormControl-root': {
          display: 'none!important'
        }
      }
    },
    loginPattern: {
      [theme.breakpoints.down('sm')]: {
        wordBreak: 'break-all'
      }
    },
    modalTitle: {
      textAlign: 'center',
      margin: `${theme.spacingStack.xxs} 0`
    },
    orderBy: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0!important',
        '& .MuiFormControl-root': {
          display: 'none!important'
        }
      }
    },
    pagination: {
      [theme.breakpoints.up('sm')]: {
        lineHeight: '55px'
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiPagination-root': {
          margin: '0 auto',
          width: 'fit-content'
        }
      }
    },
    search: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    tagBox: {
      '& > div': {
        flex: 'none',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacingInline.nano,
          '&:last-child': {
            marginRight: '0'
          }
        }
      }
    }
  }
})
