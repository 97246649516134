import { ReactComponent as LogotypeWhite } from './assets/logotype_white.svg'
import { ReactComponent as Logotype } from './assets/logotype.svg'
import { ReactComponent as Symbol } from './assets/symbol.svg'
import { ReactComponent as SymbolWhite } from './assets/symbol_white.svg'
import useStyles from './style'

interface IBrandProps {
  type: 'symbol' | 'logo'
  variant?: 'default' | 'white'
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const Brand: React.FC<IBrandProps> = ({ type, variant, size = 'md' }) => {
  const classes = useStyles()
  const className = `${classes.root} size-${size}`

  if (type === 'symbol') {
    if (variant === 'white') {
      return <SymbolWhite className={className} />
    } else {
      return <Symbol className={className} />
    }
  } else {
    if (variant === 'white') {
      return <LogotypeWhite className={className} />
    } else {
      return <Logotype className={className} />
    }
  }
}
