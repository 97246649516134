import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import CloeWormBackground from 'assets/cloe-worm-content-unit.svg'

export default makeStyles((theme: Theme) => createStyles({
  contentContainer: ({ isOpenContent }: { isOpenContent: boolean }) => ({
    display: 'flex',
    maxWidth: isOpenContent ? '780px' : '0',
    boxShadow: theme.shadowLevels.level3,
    transition: 'all 0.1s ease-in-out',
    opacity: isOpenContent ? '1' : '0',
    borderRadius: theme.borderRadius.md,
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '3',
    top: theme.spacingInline.quarck,
    height: 'fit-content',
    maxHeight: '98vh',
    // width: isOpenContent ? '100%' : '0px',
    [theme.breakpoints.up('sm')]: {
      position: 'sticky'
    }
  }),
  sectionTitle: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xxs,
    color: theme.colors.neutral.dark20
  },
  customSkillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxs
  },
  overviewSection: {
    margin: `${theme.spacingStack.nano} 0`,
    '& div': {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark10,

      // mobile
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxs,
    transition: 'all 0.3s linear',
    backgroundImage: `url(${CloeWormBackground})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    }
  },
  smallContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInline.nano
  },
  bnccContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: theme.spacingInline.nano,
    rowGap: '13px'
  },
  tag: {
    fontSize: theme.font.fontSize.xxs,
    background: theme.colorBrand.lightest,
    color: theme.colorBrand.dark,
    '&.disabled': {
      cursor: 'not-allowed'
    },
    '&.highlight': {
      backgroundColor: `${theme.colors.warning.feedbackLight} !important`
    }
  },
  cardButtonContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      alignItems: 'center',
      left: 0,
      padding: '16px',
      backgroundColor: '#F7F7F7'
    }
  },
  cardButton: {
    height: theme.spacingInset.lg + '!important',
    fontSize: theme.font.fontSize.xs + '!important',
    width: '100%'
  },
  closeButton: {
    fontSize: theme.font.fontSize.xxs + '!important',
    padding: '0 !important',
    height: theme.spacingInset.md + '!important'
    // '& svg': {
    //   fontSize: theme.font.fontSize.xxs + '!important'
    // }
  },
  tabsButtons: {
    width: '100%',
    height: '100% !important',
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0 !important`,
    fontSize: theme.font.fontSize.sm + '!important',
    border: '0px !important'
  },
  tabsButtonContainer: {
    width: '100%',
    height: '100%'
  },
  tabsContainer: {
    display: 'flex',
    width: '100%',
    height: '56px',
    alignItems: 'center',
    borderBottom: `${theme.spacingInset.nano} solid ${theme.colorBrand.medium}`
  },
  session: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInline.xs,
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacingStack.xxl
    }
  }
}))
