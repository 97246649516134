import { Text } from 'components/design-system'
import Ops from 'components/common/Ops'

interface IBusinessErrorProps {
  error: string
}

export const BusinessError: React.FC<IBusinessErrorProps> = ({ error, children }) => {
  return (
    <Ops>
      <Text type='heading'>{error}</Text>
      <div>
        {children}
      </div>
    </Ops>
  )
}
