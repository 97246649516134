import { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Loading } from 'components/design-system'
import { NotFound } from 'navigation/NotFound'
import Storage from 'utils/storage'
import PrivateRoute from './PrivateRoute'
import { useAuth } from './ProvideAuth'
import {
  CLASS_MANAGEMENT,
  ENABLE_FEATURE_TOGGLE,
  LOGIN,
  PROFILE_TABS,
  CONTENT_MANAGEMENT,
  REPORT,
  ROOT,
  USER_MANAGEMENT,
  TERMS_EXPANDED
} from './CONSTANTS'
import {
  BaseScreen,
  Home,
  ProfileTabs,
  ContentManagement,
  Report
} from 'pages'
import { useAppcues } from '../components/AppCues/useAppcues'
import {
  BulkCreateStudent,
  CreateStudent,
  CreateStudentOptions,
  EditStudent,
  EditStudentMultiClass,
  StudentSuccess
} from 'pages/ProfileTabs/StudentTab/StudentActions'
import { EditTeacher } from 'pages/ProfileTabs/TeacherTab/TeacherActions'
import ClassManagement from 'pages/ClassManagement'
import { CreateOrUpdateClass, ManageClass, ClassConfiguration } from 'pages/ClassManagement/components'
import EnableFeatureToggle from './components/EnableFeatureToggle'
import Expanded from 'pages/Terms/expanded'

export const Routes: React.FC = () => {
  const auth = useAuth()
  const location = useLocation()
  const { verifyIdentity } = useAppcues()
  const [loading, setLoading] = useState(true)

  const isValidCurrentUser = async () => {
    if (Storage.token.get()) {
      await auth?.currentUser(Storage.token.get())
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    isValidCurrentUser()
  }, [])

  useEffect(() => {
    if (auth?.user) {
      verifyIdentity(auth?.user)
    }
  }, [location])

  if (loading) {
    return <Loading />
  }

  if (!auth?.user) {
    auth?.signout()
    return <Loading />
  }

  return (
    <Switch>
      <Route path={LOGIN} render={() => <Redirect to={{ pathname: ROOT }} />} />
      {/* private/auth routes */}
      <BaseScreen>
        <PrivateRoute exact path={ROOT}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={PROFILE_TABS()}>
          <ProfileTabs />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('teachers/:userId/edit/:classId?')}>
          <EditTeacher />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/bulk-registration/:classId?')}>
          <BulkCreateStudent />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/single-registration')}>
          <CreateStudent />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/success-registration')}>
          <StudentSuccess />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/create-options/:classId?')}>
          <CreateStudentOptions />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/:userId/edit/:classId?')}>
          <EditStudent />
        </PrivateRoute>
        <PrivateRoute path={USER_MANAGEMENT('students/:userId/multiclass/edit/:classId?')}>
          <EditStudentMultiClass />
        </PrivateRoute>
        <PrivateRoute path={CLASS_MANAGEMENT('list')}>
          <ClassManagement />
        </PrivateRoute>
        <PrivateRoute path={CLASS_MANAGEMENT('config-class')}>
          <ClassConfiguration />
        </PrivateRoute>
        <PrivateRoute path={CLASS_MANAGEMENT('edit/:classId')}>
          <CreateOrUpdateClass />
        </PrivateRoute>
        <PrivateRoute path={CLASS_MANAGEMENT('create')}>
          <CreateOrUpdateClass />
        </PrivateRoute>
        <PrivateRoute path={CLASS_MANAGEMENT('view/:classId')}>
          <ManageClass />
        </PrivateRoute>
        <PrivateRoute path={CONTENT_MANAGEMENT()}>
          <ContentManagement />
        </PrivateRoute>
        <PrivateRoute path={TERMS_EXPANDED()}>
          <Expanded />
        </PrivateRoute>
        <PrivateRoute path={REPORT}>
          <Report />
        </PrivateRoute>
        <PrivateRoute path={ENABLE_FEATURE_TOGGLE()}>
          <EnableFeatureToggle />
        </PrivateRoute>
      </BaseScreen>
      {/* 404-Not Found route */}
      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  )
}
