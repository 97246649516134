/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Text2 } from 'components/design-system'
import { Grid, Skeleton, useTheme } from '@mui/material'

import useStyle from './styles'
import { Student } from 'pages/ClassManagement/components/ManageClass/components/ReportsTab/types/students'

const StudentCardItem: React.FC<Student> = ({
  studentName
  // ,studentNick
}) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()

  return (
    <>
      <Grid container className={classes.studentCardContainer}>
        <Avatar fullname={studentName} size='large' className={classes.customAvatar} />
        <Grid item className={classes.studentCardNameKey}>
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xxs'
            mobile='sm'
            customColor={theme.colors.neutral.dark40}
            className={classes.studentName}
          >
            {studentName ?? t('Sem Nome')}
          </Text2>
        </Grid>
      </Grid>
    </>
  )
}

const StudentCardSkeleton: React.FC = () => {
  const classes = useStyle()
  return (
    <Grid container justifyContent='flex-start' className={classes.studentCardContainer}>
      <Grid item>
        <Skeleton variant='circular' width={60} height={60} animation='wave' />
      </Grid>
      <Grid item className={classes.studentCardNameKey}>
        <Skeleton variant='text' width={70} height={40} animation='wave' />
      </Grid>
    </Grid>
  )
}

export const StudentCard = {
  Item: StudentCardItem,
  Skeleton: StudentCardSkeleton
}
