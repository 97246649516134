import { ChangeEvent } from 'react'
import { MenuItem } from '@mui/material'
import { Select, Text2 } from 'components/design-system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ClassListEnum, IGradeType } from 'services'
import { useTranslation } from 'react-i18next'

interface ISegmentFilter {
  classListType: ClassListEnum
  gradeTypes: IGradeType[] | undefined
  handleSelectGradeType: (e: ChangeEvent<HTMLInputElement>) => void
  selectedGradeTypeId: string
  originDataTestId?: string
  destinyDataTestId?: string
}

export const SegmentFilter = ({ classListType, gradeTypes, handleSelectGradeType, selectedGradeTypeId, originDataTestId, destinyDataTestId }: ISegmentFilter) => {
  const { t } = useTranslation()

  return (
    <>
      <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xl' mobile='sm' neutral='dark30'>
        {t('Segmento')}
      </Text2>
      <Select
        id='gradeType'
        variant='filled'
        value={selectedGradeTypeId}
        IconComponent={ExpandMoreIcon}
        label={selectedGradeTypeId ? '' : 'selecione'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelectGradeType(e)}
        dataTestId={classListType === ClassListEnum.origin ? originDataTestId : destinyDataTestId}
      >
        {
          gradeTypes?.map(gradeType => (
            <MenuItem key={gradeType.id} value={gradeType.id}>
              {gradeType.name}
            </MenuItem>
          ))
        }
      </Select>
    </>
  )
}
