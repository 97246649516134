import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  legendTable: {
    listStyle: 'none',
    paddingLeft: theme.spacingInline.xxxs,
    display: 'flex',
    gap: theme.spacingStack.nano,
    justifyContent: 'center'
  },
  legendTableItem: {
    fontSize: theme.font.fontSize.lg,
    verticalAlign: 'middle',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  legendBullet: {
    content: '',
    position: 'absolute',
    left: 0,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  toolTipContainer: {
    backgroundColor: theme.colorBrand.darkest,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInset.xs,
    boxSizing: 'border-box',
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xxs,

    '& p': {
      margin: 0
    }
  },
  toolTipTitle: {
    fontWeight: theme.font.fontWeight.bold
  },
  toolTipColumnValue: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm
  }
}))
