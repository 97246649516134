import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    tabPanel: {
      background: theme.colors.neutral.lightBase
    },
    tabs: {
      [theme.breakpoints.down('sm')]: {
        background: theme.colorBrand.medium,
        position: 'sticky',
        top: '0',
        zIndex: 2
      }
    }
  }
})
