import { Grid, Tag } from 'components/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISimpleProfile, UserSchoolProfileTypeEnum } from 'services'
import { formattedProfiles } from 'utils'

export interface ILinkedProfilesProps {
  profiles: ISimpleProfile[]
  profiletype: UserSchoolProfileTypeEnum
}

export const LinkedProfiles: React.FC<ILinkedProfilesProps> = ({ profiles, profiletype }) => {
  const { t } = useTranslation()

  const uniqueProfiles = [...new Set(profiles.filter(p => p.active).map(p => p.type))]

  return (uniqueProfiles?.length && profiletype !== UserSchoolProfileTypeEnum.student)
    ? (
      <Grid container item xs={12} md='auto' spacing={1}>
        {uniqueProfiles?.map((uniqueProfile, index) =>
          <Grid item xs='auto' key={`${uniqueProfile}-${index}`}>
            <Tag label={t(formattedProfiles[uniqueProfile])} />
          </Grid>
        )}
      </Grid>
    )
    : null
}
