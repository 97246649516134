/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as PowrBIClient from 'powerbi-client'
import { useMediaQuery, useTheme, Grid } from '@mui/material'
import makeStyles from './style'
import { useStore } from 'store'

import { generateReportEmbedToken, IApiResponse, IReportEmbedTokenRequest, IReportEmbedTokenResponse } from 'services'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { BusinessError } from 'navigation/BusinessError'
import { Loading } from 'components/common'

enum ComponentState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  READY = 'READY'
}

export const ReportView: React.FC = () => {
  const classes = makeStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { profile } = useStore()
  const [state, setState] = useState<ComponentState>(ComponentState.LOADING)
  const [report, setReport] = useState<IApiResponse<IReportEmbedTokenResponse[]> | null>(null)
  const [embed, setEmbed] = useState<PowrBIClient.Report | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    handleGenerateReportEmbedToken()
  }, [profile])

  useEffect(() => {
    const powerbi = new PowrBIClient.service.Service(
      PowrBIClient.factories.hpmFactory,
      PowrBIClient.factories.wpmpFactory,
      PowrBIClient.factories.routerFactory
    )

    const embedData = report?.data[0]
    const reportContainer = document.getElementById('dashboardContainer')
    if (powerbi && embedData && reportContainer) {
      const embedConfiguration = {
        ...embedData,
        tokenType: PowrBIClient.models.TokenType.Embed,
        type: 'report'
      }

      powerbi.reset(reportContainer)
      powerbi.embed(reportContainer, embedConfiguration)
    }
  }, [report, profile])

  const postReportEmbedToken = async (json: IReportEmbedTokenRequest) => {
    setState(ComponentState.LOADING)
    const resReportEmbedToken = await generateReportEmbedToken(json)

    if (!resReportEmbedToken.success) {
      setState(ComponentState.ERROR)
      return
    }

    setReport(resReportEmbedToken)
    await embed?.setAccessToken(resReportEmbedToken.data[0].embedToken)
    await embed?.refresh()
    setState(ComponentState.READY)
  }

  const handleGenerateReportEmbedToken = () => {
    const json: IReportEmbedTokenRequest = {
      profileId: profile?.id,
      schoolId: profile?.school.id
    }

    postReportEmbedToken(json)
  }

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  const renderStates = {
    IDLE: <></>,
    LOADING: <div style={{ width: '100%' }}><Loading type='linear' /></div>,
    ERROR: <Grid container alignItems='center' justifyContent='space-between' ><BusinessError error={t('Houve um problema com o relatório').toString()} /></Grid>,
    READY: <></>
  }

  return (
    <Grid container className={classes.containerWrapper} flexDirection='column'>
      {isMobile && (
        <Alert sx={{ width: '100%', marginBottom: '10px' }} severity={'warning'}>
          {'Para melhorar sua experiência acesse o Relatório através de um computador'}
        </Alert>
      )}
      {
        renderStates[state]
      }
      <div id='dashboardContainer' className={classes.reportEmbed} />

    </Grid>
  )
}

export default ReportView
