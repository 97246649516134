/* eslint-disable @typescript-eslint/no-use-before-define */
import MUITooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { styled } from '@mui/material'
import { CloeTag } from 'components/design-system'

export interface ITooltipProps {
  text: string
  className?: string
  component?: JSX.Element
  label?: string
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start'
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colorBrand.darkest,
    color: theme.colors.neutral.lightBase,
    maxWidth: '253px',
    fontSize: theme.font.fontSize.xs,
    fontFamily: theme.font.fontFamily,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.fontSize.lg,
    padding: theme.spacingInset.xs,
    borderRadius: theme.borderRadius.sm
  }
}))

export const CloeTooltip = (props: ITooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { text, component, label, placement } = props

  return (
    <CustomTooltip
      className={props.className}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={label ? <><b>{label}: </b>{text}</> : <>{text}</>}
      placement={placement ?? 'top-start'}
    >
      <a onClick={() => setShowTooltip(!showTooltip)}>
        {
          label &&
          <CloeTag>{label}</CloeTag>
        }
        {
          component ?? <React.Fragment />
        }
      </a>
    </CustomTooltip>
  )
}
