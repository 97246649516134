import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery, useTheme, Grid } from '@mui/material'
import { useStore } from 'store'
import { useHistory } from 'react-router'

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined'

import { Text2, Icon, Button } from 'components/design-system'

import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'

import CloeAvatar from 'assets/cloe-avatar-joinha.svg'
import CloeAvatarDesk from 'assets/cloe-avatar-desk.svg'
import CloeWorm1 from 'assets/cloe-worm1.svg'
import CloeWorm2 from 'assets/cloe-worm2.svg'
import CloeWorm3 from 'assets/cloe-worm3.svg'

import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { atomSchoolPeriod, atomSchoolPeriods } from 'pages/ClassManagement/atomStore'

import useStyle from './styles'
import { createRef } from 'react'

interface IExpandedProps {
  handleHeaderExpand: () => void
}

export const Expanded: React.FC<IExpandedProps> = ({ handleHeaderExpand }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const { session } = useStore()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const showWelcomeSubtitle = useMediaQuery('(min-width:1010px)')

  // atoms
  const schoolPeriods = useAtomValue(atomSchoolPeriods)
  const setSchoolPeriod = useUpdateAtom(atomSchoolPeriod)

  const reEnrollTutorialLink = 'https://cloe.vc/rematricula'
  const tagginRef = createRef<HTMLDivElement>()

  const triggerDataTestId = () => {
    tagginRef?.current?.click()
  }

  // re-enroll students temporary banner actions
  const reEnrollBannerAlertRedirectAndSwitchSchoolPeriod = () => {
    triggerDataTestId()

    const has2025Year = schoolPeriods.find(scp => scp.year === '2025')
    if (has2025Year) {
      history.push(CLASS_MANAGEMENT('config-class'))
      return setSchoolPeriod(has2025Year)
    }

    history.push(CLASS_MANAGEMENT('list'))
  }

  const HideButton = () => (
    <Grid item
      className={classes.hideButton}
      onClick={handleHeaderExpand}
      data-testid='home_page_coordinator_quick_access_hide_show'
    >
      <Text2 fontSize='xs' cursor='pointer' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='lightBase'>
        {t('Esconder')}
      </Text2>
      <Icon size='medium'>
        <KeyboardArrowUpOutlinedIcon />
      </Icon>
    </Grid>
  )

  const CloeAvatarComponent = () => {
    if (isMobile) {
      return <img src={CloeAvatar} className={classes.cloeAvatar} style={{ marginTop: `-${theme.spacingInline.xxs}` }} />
    }
    return <img src={CloeAvatarDesk} className={classes.cloeAvatar} style={{ marginTop: `-${theme.spacingInline.nano}` }} />
  }

  return (
    <>
      <div data-testid={'home_page_coordinator_reenrollment'} ref={tagginRef} />

      <Grid container className={classes.quickAccessContainer}>
        <Grid className={`${classes.contentSeparator} ${classes.welcomeSection}`}>
          <CloeAvatarComponent />
          <Grid className={classes.welcomeTextContainer}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='lg' neutral='lightBase' className={classes.welcomeText}>
              <Trans i18nKey='WelcomeText'>
                Olá, <b>{{ name: session?.name ?? 'Professor(a)' }}</b>!
              </Trans>
            </Text2>
            {(!isMobile && showWelcomeSubtitle) && (
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxs' mobile='lg' neutral='lightBase'>
                <Trans i18nKey='WelcomeSubtitle'>
                  Tudo pronto para <b>2025?</b>
                </Trans>
              </Text2>
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid className={`${classes.contentSeparator} ${classes.interactiveSection}`}>
            <Grid className={`${classes.contentSeparator} ${classes.linksContainerHeader}`}>
              <HideButton />
            </Grid>
            <Grid className={`${classes.contentSeparator} ${classes.linksContainer}`}>
              <Button
                variant='primary'
                className={classes.bannerAlertButton}
                onClick={reEnrollBannerAlertRedirectAndSwitchSchoolPeriod}>
                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' colorbrand='dark' cursor='pointer'>
                  <BackpackOutlinedIcon />
                  {t('Rematricular estudantes')}
                </Text2>
              </Button>
            </Grid>
            <Grid className={`${classes.contentSeparator} ${classes.bottomText}`}>
              <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='lightBase' style={{ display: 'flex', alignItems: 'center' }}>
                {t('Dúvidas')}?
              </Text2>
              <a target='_blank' href={reEnrollTutorialLink} data-testid={'home_page_coordinator_reenrollment_see_tutorial'}>
                <Text2 cursor='pointer' fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='lightBase' className={classes.tutorialLink}>
                  {t('Veja o tutorial')}
                </Text2>
              </a>
            </Grid>
            <Grid className={`${classes.contentSeparator} ${classes.wormsContainer}`}>

            </Grid>
          </Grid>
        )}
        {isMobile
          ? (
            <>
              <img src={CloeWorm3} className={classes.cloeWorm3} />
            </>
          )
          : (
            <>
              <img src={CloeWorm1} className={classes.cloeWorm1} />
              <img src={CloeWorm2} className={classes.cloeWorm2} />
            </>
          )}
      </Grid>
    </>
  )
}
