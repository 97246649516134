
import { Image, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { commonStyles } from '../../style'
import { tipsAndUpdatesOutlinedIcon } from 'assets/base64Icons'
import { styles } from './style'

export const PrintCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View style={[styles.printCard, commonStyles.flex1, commonStyles.outlinedCard, commonStyles.outlinedContentCard]}>
      <View style={commonStyles.sectionTitle}>
        <Image style={commonStyles.titleIcon} src={tipsAndUpdatesOutlinedIcon} />
        <Text style={commonStyles.infoTitle}>{t('Impressão')}</Text>
      </View>
      <View style={commonStyles.displayFlexRow}>
        <Text>{t('Escolha o formato A4, na posição retrato (vertical), impressão frente e verso')}</Text>
      </View>
    </View>
  )
}
