import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiSwitch-root': {
      '& .Mui-checked': {
        color: theme.colorBrand.medium,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.colorBrand.light
        }
      }
    }
  }
}))
