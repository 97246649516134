import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const modalStyles = makeStyles((theme: Theme) => {
  return {
    actionButtons: {
      '& .MuiGrid-root': {
        padding: '0 16px'
      }
    },
    submitButton: {
      width: '100%',
      background: theme.colorBrand.medium,
      '&.primary': {
        background: theme.colorBrand.medium,
        '&:hover': {
          background: theme.colorBrand.dark
        }
      }
    },
    cancelButton: {
      width: '100%',
      color: theme.colorBrand.medium,
      '&.ghost': {
        color: theme.colorBrand.medium,
        '&:hover': {
          background: theme.colorBrand.lightest
        }
      }
    },
    modalTitle: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.semibold,
      letterSpacing: '0.01em',
      lineHeight: '32px',
      textAlign: 'center'
    },
    modalDescription: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: '24px',
      padding: '24px 0',
      textAlign: 'center'
    },
    modalSubdescription: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: '24px',
      paddingBottom: '24px',
      textAlign: 'center'
    }
  }
})
