import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    teacherList: {
      padding: '12px 24px 24px',
      '& > div + div': {
        marginTop: theme.spacingStack.xs
      }
    }
  }
})
