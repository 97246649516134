import * as Yup from 'yup'
import { isAfter, isBefore, isFuture, isValid, parse, sub } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { regexPhone } from 'utils'

type Field = string | null | undefined

// form validation schema
export const StudentSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Campo obrigatório'),
  surname: Yup.string()
    .trim()
    .required('Campo obrigatório'),
  birthDate: Yup.string()
    .required('Campo obrigatório')
    .test(
      'invalid-date',
      'Data inválida',
      (value: Field) => value
        ? isValid(parse(value, 'dd/MM/yyyy', new Date(), { locale: ptBR }))
        : false
    )
    .test(
      'very-early-date',
      'Data inválida',
      (value: Field) => value
        ? isAfter(
          parse(value, 'dd/MM/yyyy', new Date(), { locale: ptBR }),
          new Date(1901, 1, 1)
        )
        : false
    )
    .test(
      'future-birth-date-validation',
      'Não pode ser uma data futura',
      (value: Field) => value
        ? !isFuture(parse(value, 'dd/MM/yyyy', new Date(), { locale: ptBR }))
        : false
    )
    .test(
      'below-one-year-birth-date-validation',
      'Idade inferior a 1 ano',
      (value: Field) => value
        ? isBefore(
          parse(value, 'dd/MM/yyyy', new Date(), { locale: ptBR }),
          sub(new Date(), { years: 1 })
        )
        : false
    ),
  email: Yup.string()
    .trim()
    .email('E-mail inválido'),
  phone: Yup.string()
    .matches(regexPhone, 'Número de celular inválido'),
  emailOrPhone: Yup.bool().when(['email', 'phone'], {
    is: (email: any, phone: any) => (!email && !phone),
    then: Yup.bool().required('Preencha pelo menos um dos campos de E-mail ou Telefone'),
    otherwise: Yup.bool()
  }),
  anchorGrade: Yup.string()
    .required('Campo obrigatório')
})
