import MUiPagination, { PaginationRenderItemParams } from '@mui/material/Pagination'
import { PaginationProps } from '@mui/material'
import useStyles from './style'
import MUiPaginationItem from '@mui/material/PaginationItem'
import { createRef } from 'react'

export interface IPaginationProps extends PaginationProps {
  dataTestIdElements?: {
    previous: string
    page: string
    next: string
  }
}

export const Pagination: React.FC<IPaginationProps> = ({ dataTestIdElements, ...props }) => {
  const classes = useStyles()
  const navIconBackRef = createRef<HTMLAnchorElement>()
  const navIconForwardRef = createRef<HTMLAnchorElement>()

  const handleDataTaging = (e: any, action: string) => {
    e.stopPropagation()
    action === 'previous'
      ? navIconBackRef?.current?.click()
      : navIconForwardRef?.current?.click()
  }

  const renderPaginationItem = (item: PaginationRenderItemParams) => {
    const type = item.type
    switch (type) {
      case 'previous':
        return <a data-testid={dataTestIdElements?.previous} ref={navIconBackRef} onClick={(e: any) => handleDataTaging(e, type)}><MUiPaginationItem {...item}/></a>
      case 'page':
        return <MUiPaginationItem {...item} data-testid={dataTestIdElements?.page} />
      case 'next':
        return <a data-testid={dataTestIdElements?.next} ref={navIconForwardRef} onClick={(e: any) => handleDataTaging(e, type)}><MUiPaginationItem {...item} /></a>
      default:
        return <MUiPaginationItem {...item} />
    }
  }

  return (
    <MUiPagination
      className={`${classes.root} ${props.className as string}`}
      {...props}
      renderItem={(item) => renderPaginationItem(item)}
    />
  )
}
