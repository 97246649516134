
import { Image, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { commonStyles } from '../../style'
import { tipsAndUpdatesOutlinedIcon } from 'assets/base64Icons'
import { styles } from './style'

export const CredentialsOutlinedCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View style={[styles.credentialsCard, commonStyles.flex3, commonStyles.outlinedCard, commonStyles.outlinedContentCard]}>
      <View style={commonStyles.sectionTitle}>
        <Image style={commonStyles.titleIcon} src={tipsAndUpdatesOutlinedIcon} />
        <Text style={commonStyles.infoTitle}>{t('Padrão de credenciais')}</Text>
      </View>
      <View style={styles.credentialInfo}>
        <Text>
          {t('O padrão de nome de usuário é ')}
          <Text style={commonStyles.bold}>{t('primeiro nome.último sobrenome.ano de nascimento.5 números aleatórios.')}</Text>
        </Text>
      </View>
      <View style={[styles.credentialInfo, commonStyles.displayFlexRow]}>
        <Text>{t('Exemplo')}: </Text>
        <Text style={commonStyles.bold}>adriana.borges.2015.15342</Text>
      </View>
      <View style={[styles.credentialInfo, commonStyles.displayFlexRow]}>
        <Text>
          {t('O padrão de senha é ')}
          <Text style={commonStyles.bold}>{t('data de nascimento, sem barras (ddmmaaaa).')}</Text>
        </Text>
      </View>
      <View style={[styles.credentialInfo, commonStyles.displayFlexRow]}>
        <Text>{t('Exemplo')}: </Text>
        <Text style={commonStyles.bold}>16042015</Text>
      </View>
    </View>
  )
}
