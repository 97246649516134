import { DoubleColumnContainer, LeftColumnContent, RightColumnContent } from 'components/common'
import { Grid } from 'components/design-system'

interface IProfileRegisterPageContainerProps {
  link?: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  additionalBlockOfContent?: React.ReactNode
  children: React.ReactNode
}

export const ProfileRegisterPageContainer: React.FC<IProfileRegisterPageContainerProps> = ({ link, title, description, additionalBlockOfContent, children }) => {
  return (
    <DoubleColumnContainer rounded>
      <LeftColumnContent>
        <Grid container>
          {link}
        </Grid>
        <Grid container>
          {title}
        </Grid>
        <Grid container>
          {description}
        </Grid>
        {additionalBlockOfContent &&
          <Grid container>
            {additionalBlockOfContent}
          </Grid>
        }
      </LeftColumnContent>
      <RightColumnContent>
        {children}
      </RightColumnContent>
    </DoubleColumnContainer>
  )
}
