import { ChangeEvent, createRef, KeyboardEvent, MouseEvent, useEffect, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import makeStyles from './style'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import FilledInput from '@mui/material/FilledInput'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

interface ISearchInputProps {
  id?: string
  className?: string
  cleanDataTestId?: string
  defaultValue?: string
  disabled?: boolean
  helperText?: string
  helperTextClassName?: string
  label: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClean?: (e: MouseEvent<HTMLElement>) => void
  onClick?: (e: MouseEvent<HTMLElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  searchDataTestId?: string
  variant?: 'filled' | 'outlined'
  value?: string
}

export const SearchInput: React.FC<ISearchInputProps> = ({ className, cleanDataTestId, helperText, helperTextClassName, id, label, onClean, onClick, searchDataTestId, variant = 'filled', ...props }) => {
  const classes = makeStyles()
  const cleanButtonRef = createRef<HTMLDivElement>()
  const searchButtonRef = createRef<HTMLDivElement>()
  const searchRef = createRef<HTMLInputElement>()

  // states
  const [isFocused, setFocus] = useState(false)

  useEffect(() => {
    setFocus(!!(props.defaultValue?.length ?? props.value?.length))
  }, [])

  const handleClean = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    cleanButtonRef?.current?.click()
    if (onClean) onClean(e)
    if (searchRef?.current) {
      searchRef.current.value = ''
      searchRef.current.blur()
      setInputFocus(false)
    }
  }

  const setInputFocus = (state: boolean) => {
    const notEmpty = !!searchRef.current?.value

    if (notEmpty) return setFocus(true)

    setFocus(state)
  }

  const handleSearch = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    searchButtonRef?.current?.click()
    if (onClick) onClick(e)
  }

  const endAdornment = (!!onClean && (props.defaultValue?.length ?? props.value?.length))
    ? (
      <InputAdornment position='end'>
        <div ref={cleanButtonRef} data-testid={cleanDataTestId} hidden />
        <IconButton
          aria-label='clean'
          onClick={handleClean}
          edge='end'
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      </InputAdornment>
    )
    : (
      <InputAdornment position='end'>
        <div ref={searchButtonRef} data-testid={searchDataTestId} hidden />
        <IconButton
          aria-label='search'
          onClick={handleSearch}
          edge='end'
        >
          <SearchIcon className={classes.icon} />
        </IconButton>
      </InputAdornment>
    )

  return (
    <>
      <FormControl variant={variant} className={`${className as string} ${classes.searchInput}`} disabled={props.disabled}>
        <InputLabel htmlFor={id} shrink={isFocused} variant={variant}>
          {label}
        </InputLabel>
        <div className={classes.searchBox}>
          {variant === 'filled'
            ? <FilledInput {...props} endAdornment={endAdornment} inputRef={searchRef} onBlur={() => setInputFocus(false)} onFocus={() => setInputFocus(true)} disableUnderline fullWidth />
            : <OutlinedInput {...props} endAdornment={endAdornment} inputRef={searchRef} onBlur={() => setInputFocus(false)} onFocus={() => setInputFocus(true)} fullWidth />
          }
        </div>
      </FormControl>
      {helperText && (
        <FormHelperText className={`${classes.helperText} ${helperTextClassName as string}`}>
          {helperText}
        </FormHelperText>
      )}
    </>
  )
}

export default SearchInput
