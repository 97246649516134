import { getSession, IUserMeResponse, UserSchoolProfileTypeEnum, userMe, IUpdateLastUsedProfileRequest, updateLastUsedProfile as updateLastUsedProfileService } from 'services'
import { useState } from 'react'
import Storage from 'utils/storage'
import { useStore } from 'store'
import { CLOE_WEB, LOGIN } from 'navigation/CONSTANTS'
import { toast } from 'components/design-system/Toast/manager'
import Analytics from 'utils/analytics'
import { useUpdateAtom } from 'jotai/utils'
import { atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import { useSnackbar } from './useSnackbar'
import { useTranslation } from 'react-i18next'

export const errorToastMessage = (message: string) => {
  return (
    toast.handler({
      content: message,
      duration: 10000,
      severity: 'error'
    })
  )
}

export interface IGoToCloeWebProps {
  goToLogin?: boolean
}

export function useProvideAuth() {
  const { setUserSession, setSubscription, setProfile, setSchool } = useStore()
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()

  // states
  const [user, setUser] = useState<IUserMeResponse | null>(null)

  // atoms
  const setSchoolPeriod = useUpdateAtom(atomSchoolPeriod)

  const currentUser = async (token: string) => {
    if (token === null || token === undefined || !token) {
      signout()
    }

    Storage.token.set(token)

    const response = await userMe()

    if (response.success) {
      setUser(response.data)
      await setSession()
    } else {
      signout()
    }
    return response
  }

  const updateLastUsedProfile = async (profileId: number) => {
    try {
      const updateLastUsedProfileRequest: IUpdateLastUsedProfileRequest = { profileId }
      const response = await updateLastUsedProfileService(updateLastUsedProfileRequest)

      if (response.errors) throw new Error(t('Erro ao tentar atualizar a data de último acesso do perfil.'))
    } catch (e) {
      if (e instanceof Error) createSnackbar({ content: e.message, severity: 'error' })
    }
  }

  interface ISetSessionProps {
    eventDispatch?: boolean
  }

  const setSession = async (options?: ISetSessionProps) => {
    const session = await getSession()
    setUserSession(session.data)

    // filtrando somente profiles que podem acessar o admin
    const allowProfiles = session.data.profiles.filter(cur => [UserSchoolProfileTypeEnum.admin, UserSchoolProfileTypeEnum.coordinator].includes(cur.type))

    if (allowProfiles.length > 0) {
      const currentProfile = allowProfiles[0]

      setSchool(allowProfiles[0]?.school)
      setProfile(allowProfiles[0])

      await updateLastUsedProfile(allowProfiles[0].id)

      const subscription = session.data.subscriptions.find(cur => cur.user_school_profile.id === currentProfile?.id)
      subscription && setSubscription(subscription)

      if (currentProfile) {
        Analytics.recordEventClick({
          name: 'set_session',
          attributes: {
            profile_type: currentProfile?.type,
            school_id: currentProfile?.school?.id,
            user_id: currentProfile?.user
          }
        })
      }

      if (currentProfile && options?.eventDispatch) {
        Analytics.recordEventClick({
          name: 'login',
          attributes: {
            origin: UserSchoolProfileTypeEnum.admin,
            profile_type: currentProfile?.type,
            school_id: currentProfile?.school?.id,
            user_id: currentProfile?.user
          }
        })
      }
    } else if (session.data.profiles?.length > 0) {
      // TODO: quando existir uma tela de login com seleção de profile/subscription essa logica deverá sair daqui
      // consultar o time de dados quando essa tela de login for executada
      const currentProfile = session.data?.profiles?.filter(cur => [UserSchoolProfileTypeEnum.teacher, UserSchoolProfileTypeEnum.student].includes(cur.type))
      if (currentProfile?.length && options?.eventDispatch) {
        const firstProfile = currentProfile[0]

        await updateLastUsedProfile(firstProfile.id)

        Analytics.recordEventClick({
          name: 'login',
          attributes: {
            origin: UserSchoolProfileTypeEnum.admin,
            profile_type: firstProfile?.type,
            school_id: firstProfile?.school?.id,
            user_id: firstProfile?.user
          }
        })
      }
      goToCloeWeb()
    } else {
      signout()
    }
  }

  const clearCookies = () => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    }
  }

  const resetStates = () => {
    setUser(null)
    setSubscription(null)
    setUserSession(null)
    setProfile(null)
    setUser(null)
    setSchoolPeriod(null)
    setSchool(null)
  }

  const clean = async () => {
    resetStates()

    localStorage.clear()
    sessionStorage.clear()
    clearCookies()

    Storage.token.remove()
    Storage.props.remove()
    Storage.disciplines.remove()
  }

  const goToCloeWeb = ({ goToLogin = false }: IGoToCloeWebProps = {}) => {
    let path = CLOE_WEB

    if (goToLogin) {
      path += LOGIN
    }

    window.location.href = path
  }

  const signout = () => {
    clean()
    goToCloeWeb({ goToLogin: true })
  }

  return {
    user,
    currentUser,
    signout,
    clean,
    setSession,
    goToCloeWeb,
    updateLastUsedProfile
  }
}
