import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    width: theme.spacingInset.lg,
    height: theme.spacingInset.lg,
    borderRadius: theme.borderRadius.pill,
    cursor: 'pointer',
    border: 'none',
    '&:disabled': {
      color: `${theme.colors.neutral.lightBase}!important`,
      background: `${theme.colors.neutral.light40}!important`,
      cursor: 'not-allowed'
    },
    '&.primary': {
      color: `${theme.colors.neutral.lightBase}!important`,
      background: theme.colorBrand.medium
    },
    '&.activate': {
      color: `${theme.colors.neutral.lightBase}!important`,
      background: theme.colors.positive.feedbackMedium
    },
    '&.deactivate': {
      color: `${theme.colors.neutral.lightBase}!important`,
      background: theme.colors.negative.feedbackMedium
    }
  }
})
)
