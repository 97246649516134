import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    modalDescription: {
      padding: '0px 32px 32px 32px'
    },
    modalTitle: {
      paddingBottom: '32px',
      textAlign: 'center'
    },
    studentList: {
      padding: '12px 24px 24px',
      '& > div + div': {
        marginTop: theme.spacingStack.xs
      }
    },
    optionsBtns: {
      marginLeft: 'auto!important',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    sendCredentialsBtnMobilbe: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px',
      width: theme.spacingInset.lg,
      height: theme.spacingInset.lg,
      borderRadius: '999px',
      color: `${theme.colors.neutral.lightBase}!important`,
      background: theme.colorBrand.dark,
      cursor: 'pointer'
    }
  }
})
