import React, { useEffect } from 'react'
import { useCommonStyles } from './style'
import { atomActiveMenuSidebar, atomCurrentActiveMenuSidebar } from 'navigation/atomStore'
import { useAtom } from 'jotai'
import { useClassManagement } from 'navigation/hooks'
import { LowerMenu } from './components/LowerMenu'
import { UpperMenu } from './components/UpperMenu'

export const Sidebar: React.FC = () => {
  const { resetClassFilters } = useClassManagement()
  const classes = useCommonStyles()
  const pathname = window.location.pathname

  // atoms
  const [activeMenuSidebar, setActiveMenuSidebar] = useAtom(atomActiveMenuSidebar)
  const [currentActiveMenuSidebar, setCurrentActiveMenuSidebar] = useAtom(atomCurrentActiveMenuSidebar)

  const handleActiveMenuSideBarViaRoutes = (path: string) => {
    if (path === '/') setCurrentActiveMenuSidebar('home')
    if (path.split('/').includes('profile-tabs') || path.split('/').includes('user-management')) setCurrentActiveMenuSidebar('user-management')
    if (path.split('/').includes('class')) setCurrentActiveMenuSidebar('class-management')
    if (path.split('/').includes('program')) setCurrentActiveMenuSidebar('program-management')
    if (path.split('/').includes('report')) setCurrentActiveMenuSidebar('report')
  }

  useEffect(() => {
    handleActiveMenuSideBarViaRoutes(pathname)
  }, [pathname])

  useEffect(() => {
    if (currentActiveMenuSidebar !== 'class-management') {
      resetClassFilters()
    }
  }, [currentActiveMenuSidebar])

  const toggleActiveMenuSidebar = () => setActiveMenuSidebar(!activeMenuSidebar)

  return (
    <div onClick={toggleActiveMenuSidebar} className={`${classes.overlay} ${!activeMenuSidebar && classes.beforeMobile}`} data-testid='global-sidebar'>
      <UpperMenu activeMenuSidebar={activeMenuSidebar} toggleActiveMenuSidebar={toggleActiveMenuSidebar} />
      <LowerMenu activeMenuSidebar={activeMenuSidebar} />
    </div>
  )
}
