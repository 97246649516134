import React, { useState } from 'react'
import useStyle from './style'
import { Button, Grid, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined'
import { changeActiveUserSchoolProfile, getClassSubscriptionsByUserTypeSchool, IProfileResponse, UserSchoolProfileTypeEnum } from 'services'
import { ProfileActivationModal, ProfileDeactivationModal, StudentWithoutClassModal } from 'components/common/ProfilesListBulkOptions/components'
import { useManageSchoolProfile, useSnackbar } from 'navigation/hooks'
import { useStore } from 'store'
import { useUpdateAtom } from 'jotai/utils'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { useLinkedProfilesBox } from 'components/modules/LinkedProfilesBox/hooks'
import { useRegisteredProfilesBox } from 'components/modules/RegisteredProfilesBox/hooks'
import { useProfilesAccessedLastWeekBox } from 'components/modules/ProfilesAccessedLastWeekBox/hooks'

export interface IProfileStatusProps {
  disabled: boolean
  profiletype: UserSchoolProfileTypeEnum
  userId: number
  activateDataTestId?: string
  deactivateDataTestId?: string
  profile?: IProfileResponse
  reloadBoxes?: boolean
}

export const ProfileStatus: React.FC<IProfileStatusProps> = ({ activateDataTestId, deactivateDataTestId, disabled, profile, profiletype, reloadBoxes = false, userId }) => {
  const { t } = useTranslation()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const { updateTeacherProfile } = useManageSchoolProfile()
  const classes = useStyle()

  // atoms
  const setReloadList = useUpdateAtom(atomReloadList)

  // states
  const [currentProfile, setCurrentProfile] = useState<IProfileResponse>()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenActivateProfileModal, setIsOpenActivateProfileModal] = useState<boolean>(false)
  const [isOpenDeactivateProfileModal, setIsOpenDeactivateProfileModal] = useState<boolean>(false)
  const [isOpenStudentWithoutClassModal, setIsOpenStudentWithoutClassModal] = useState<boolean>(false)

  const { setReload: setReloadBox1 } = useLinkedProfilesBox(profiletype)
  const { setReload: setReloadBox2 } = useRegisteredProfilesBox(profiletype)
  const { setReload: setReloadBox3 } = useProfilesAccessedLastWeekBox(profiletype)

  const reloadBigNumberBoxes = () => {
    setReloadBox1(true)
    setReloadBox2(true)
    setReloadBox3(true)
  }

  const changeActiveProfile = async () => {
    try {
      setIsLoading(true)

      if (currentProfile?.type === UserSchoolProfileTypeEnum.teacher && school?.id) {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await updateTeacherProfile({ profile: { active: currentProfile.active, users: [userId] }, schoolId: school.id })
      }

      if (currentProfile?.id) {
        const response = await changeActiveUserSchoolProfile(currentProfile.id, !currentProfile.active)

        if (response.success) {
          createSnackbar({ content: `Perfil ${currentProfile.active ? 'desativado' : 'ativado'} com sucesso.`, severity: 'success' })
        } else {
          throw new Error(t(`Ocorreu um erro ao tentar ${currentProfile.active ? 'desativar' : 'ativar'} o perfil.`))
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setIsLoading(false)
      setIsOpenActivateProfileModal(false)
      setIsOpenDeactivateProfileModal(false)
      if (reloadBoxes) reloadBigNumberBoxes()
      setReloadList(true)
    }
  }

  const getUserClassSubscriptions = async () => {
    if (userId && school?.id) {
      try {
        const response = await getClassSubscriptionsByUserTypeSchool(userId, UserSchoolProfileTypeEnum.student, school.id, true)
        if (response.success) {
          if (response.data.length > 0) {
            setIsOpenActivateProfileModal(true)
          } else {
            setIsOpenStudentWithoutClassModal(true)
          }
        } else {
          throw new Error(t('Não foi possível obter as informações do estudante. Tente novamente'))
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      }
    }
  }

  const handleOpenActivateOrDeactivateProfileModal = async () => {
    setCurrentProfile(profile)
    if (profile?.active) {
      setIsOpenDeactivateProfileModal(true)
    } else if (profile?.type === UserSchoolProfileTypeEnum.student) {
      await getUserClassSubscriptions()
    } else {
      setIsOpenActivateProfileModal(true)
    }
  }

  return (
    <>
      {disabled
        ? (
          <>
            <Grid container item xs='auto' className={classes.negativeIcon} alignItems='center'>
              <Grid item>
                <NoAccountsOutlinedIcon fontSize='medium' />
              </Grid>
              <Grid item>
                <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='xxs' negative='feedbackMedium'>
                  {t('Desativado')}
                </Text2>
              </Grid>
            </Grid>
            <Grid item xs>
              <Button data-testid={activateDataTestId} onClick={async () => await handleOpenActivateOrDeactivateProfileModal()} variant='link'>
                {t('Ativar')}
              </Button>
            </Grid>
          </>
        )
        : (
          <>
            <Grid container item xs='auto' className={classes.positiveIcon} alignItems='center'>
              <Grid item>
                <AccountCircleOutlinedIcon fontSize='medium' />
              </Grid>
              <Grid item>
                <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='xxs' positive='feedbackMedium'>
                  {t('Ativo')}
                </Text2>
              </Grid>
            </Grid>
            <Grid item xs>
              <Button data-testid={deactivateDataTestId} onClick={async () => await handleOpenActivateOrDeactivateProfileModal()} variant='link'>
                {t('Desativar')}
              </Button>
            </Grid>
          </>
        )
      }

      <ProfileActivationModal
        open={isOpenActivateProfileModal}
        onClose={() => setIsOpenActivateProfileModal(!isOpenActivateProfileModal)}
        onActivateProfile={changeActiveProfile}
        profile={profiletype}
        isLoading={isLoading}
      />
      <ProfileDeactivationModal
        open={isOpenDeactivateProfileModal}
        onClose={() => setIsOpenDeactivateProfileModal(!isOpenDeactivateProfileModal)}
        onDeactivateProfile={changeActiveProfile}
        profile={profiletype}
        isLoading={isLoading}
      />
      <StudentWithoutClassModal
        onClose={() => setIsOpenStudentWithoutClassModal(false)}
        open={isOpenStudentWithoutClassModal}
        userId={userId}
      />
    </>
  )
}
