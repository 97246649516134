import {
  IApiResponse,
  IProfileResponse,
  ISimpleUserProfileResponse,
  IUserSchoolProfileRequest,
  IBlockedProfileResponse,
  ICheckClassSubscriptionByUserSchoolProfilesRequest,
  ICheckClassSubscriptionByUserSchoolProfilesErrorResponse,
  IUpdateLastUsedProfileRequest,
  ICreateOrManageUserProfileActiveRequest,
  IRegisteredProfilesBoxResponse,
  IProfilesAccessedLastWeekBoxResponse
} from 'services/types'
import { UserSchoolProfileTypeEnum, request } from 'services'
import {
  GET_BLOCKED_USER_SCHOOL_PROFILE_CONFIRMATION,
  UPDATE_LAST_USED_PROFILE,
  USER_SCHOOL_PROFILE_BASE,
  USER_SCHOOL_PROFILE_BY_SCHOOL_USER,
  USER_SCHOOL_PROFILE_GET_ENRICHED,
  USER_SCHOOL_PROFILE_UPDATE,
  USER_SCHOOL_PROFILE_CHECK_CLASS_SUBSCRIPTIONS,
  USER_SCHOOL_PROFILE_GET_ALL_BY_SCHOOL_AND_USER,
  CREATE_OR_MANAGE_USER_PROFILE_ACTIVE,
  USER_SCHOOL_PROFILE_REGISTERED_PROFILES_BOX,
  USER_SCHOOL_PROFILE_PROFILES_ACCESSED_LAST_WEEK_BOX
} from './CONSTANTS'

export const getProfiles = async (profile: string, schoolId: number, page: number, sort?: string, search?: string, profileStatusFilter?: number, sentCredentialsFilter?: number, passwordPatternFilter?: number): Promise<IApiResponse<ISimpleUserProfileResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_GET_ENRICHED(schoolId, profile, page - 1, sort, search ?? '', profileStatusFilter, sentCredentialsFilter, passwordPatternFilter), method: 'GET' })

export const changeActiveUserSchoolProfile = async (userSchoolProfileId: number, active: boolean): Promise<IApiResponse<IProfileResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_UPDATE(userSchoolProfileId), method: 'PUT', data: { active: active } })

export const createUserSchoolProfile = async (userSchoolProfileRequest: IUserSchoolProfileRequest): Promise<IApiResponse<IProfileResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_BASE(), method: 'POST', data: userSchoolProfileRequest })

export const getUserSchoolProfileBySchoolAndUser = async (schoolId: number, profile: string, userId: number, active?: boolean): Promise<IApiResponse<IProfileResponse[]>> =>
  await request({ url: USER_SCHOOL_PROFILE_BY_SCHOOL_USER(schoolId, profile, userId, active), method: 'GET' })

export const getAllUserSchoolProfilesBySchoolAndUser = async (schoolId: number, userId: number): Promise<IApiResponse<IProfileResponse[]>> =>
  await request({ url: USER_SCHOOL_PROFILE_GET_ALL_BY_SCHOOL_AND_USER(schoolId, userId), method: 'GET' })

export const updateUserSchoolProfile = async (userSchoolProfileId: number, userSchoolProfileRequest: IUserSchoolProfileRequest): Promise<IApiResponse<IProfileResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_UPDATE(userSchoolProfileId), method: 'PUT', data: userSchoolProfileRequest })

export const getUserProfileBlockedConfirmation = async (userSchoolProfileId: number): Promise<IApiResponse<IBlockedProfileResponse>> =>
  await request({ url: GET_BLOCKED_USER_SCHOOL_PROFILE_CONFIRMATION(userSchoolProfileId), removeBearer: true })

export const checkClassSubscriptionByUserSchoolProfiles = async (userSchoolProfiles: ICheckClassSubscriptionByUserSchoolProfilesRequest): Promise<IApiResponse<ICheckClassSubscriptionByUserSchoolProfilesErrorResponse[]>> =>
  await request({ url: USER_SCHOOL_PROFILE_CHECK_CLASS_SUBSCRIPTIONS(), method: 'POST', data: userSchoolProfiles })

export const updateLastUsedProfile = async (updateLastUsedProfileRequest: IUpdateLastUsedProfileRequest): Promise<IApiResponse<IProfileResponse>> =>
  await request({ url: UPDATE_LAST_USED_PROFILE(), method: 'PATCH', data: updateLastUsedProfileRequest })

export const createOrManageUserProfileActive = async (createOrManageUserProfileActiveRequest: ICreateOrManageUserProfileActiveRequest): Promise<IApiResponse<void>> =>
  await request({ url: CREATE_OR_MANAGE_USER_PROFILE_ACTIVE(), method: 'PATCH', data: createOrManageUserProfileActiveRequest })

export const getRegisteredProfilesBoxData = async (schoolId: number, type: UserSchoolProfileTypeEnum): Promise<IApiResponse<IRegisteredProfilesBoxResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_REGISTERED_PROFILES_BOX(schoolId, type) })

export const getProfilesAccessedLastWeekBoxData = async (schoolId: number, schoolPeriodId: number, type: UserSchoolProfileTypeEnum): Promise<IApiResponse<IProfilesAccessedLastWeekBoxResponse>> =>
  await request({ url: USER_SCHOOL_PROFILE_PROFILES_ACCESSED_LAST_WEEK_BOX(schoolId, schoolPeriodId, type) })
