/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useStore } from 'store'
import { IStudentsListPerformance, IDiscipline } from 'services/types'
import { getStudentsListPerformance } from 'services/cloe-reports'
import { toast } from 'components/design-system/Toast/manager'
import { ReportsListHeader, ReportsListHeaderLoading, ReportsListLoading, ReportsListReady } from './components'
import { atomOrderByReports, atomSearchTermReports } from 'pages/ClassManagement/atomStore'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { Grid } from '@mui/material'

enum PageStateMachineEnum {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  LOADING = 'LOADING',
  READY = 'READY',
}

export enum AuxStateMachineEnum {
  IDLE = 'IDLE',
  NOT_FOUND = 'NOT_FOUND',
  SEARCH_NOT_FOUND = 'SEARCH_NOT_FOUND',
  READY_TO_LOAD_MORE = 'READY_TO_LOAD_MORE'
}

export interface IStudentsListData {
  data: IStudentsListPerformance | null
  error: string | null
}

interface IReportsListProps {
  currentDiscipline?: IDiscipline | null
}

interface IFetchClassReportsProps {
  pageCount: number
  _studentsListData: IStudentsListData
  isLoading?: boolean
  search?: string
  _orderBy?: string
}

export const ReportsList: React.FC<IReportsListProps> = ({ currentDiscipline }) => {
  const StudentsListDataInitialState: IStudentsListData = {
    data: null,
    error: null
  }

  const { profile } = useStore()
  const { t } = useTranslation()
  const { classId } = useParams<{ classId?: string }>()

  // atoms
  const orderBy = useAtomValue(atomOrderByReports)
  const resetOrderBy = useResetAtom(atomOrderByReports)
  const searchTerm = useAtomValue(atomSearchTermReports)
  const resetSearchTerm = useResetAtom(atomSearchTermReports)

  // states
  const [pageStateMachine, setPageStateMachine] = useState<PageStateMachineEnum>(PageStateMachineEnum.IDLE)
  const [auxStateMachine, setAuxStateMachine] = useState<AuxStateMachineEnum>(AuxStateMachineEnum.IDLE)
  const [studentsListData, setStudentsListData] = useState<IStudentsListData>(StudentsListDataInitialState)
  const [page, setPage] = useState(0)

  const PAGE_SIZE = 20

  useEffect(() => {
    reset()
  }, [currentDiscipline])

  const tryAgain = async () => {
    await fetchClassReports({ pageCount: page, _studentsListData: studentsListData, isLoading: false })
  }

  const reset = async () => {
    resetOrderBy()
    resetSearchTerm()
    setPage(0)
    setStudentsListData(StudentsListDataInitialState)
    await fetchClassReports({ pageCount: 0, _studentsListData: StudentsListDataInitialState, isLoading: true })
  }

  const loadMore = async () => {
    await fetchClassReports({ pageCount: page + 1, _studentsListData: studentsListData, isLoading: false })
    setPage(oldPage => oldPage + 1)
  }

  const searchCallback = async (search: string) => {
    await fetchClassReports({ pageCount: 0, _studentsListData: StudentsListDataInitialState, isLoading: false, search })
  }

  const orderByCallback = async (orderBy: string) => {
    await fetchClassReports({ pageCount: 0, _studentsListData: StudentsListDataInitialState, isLoading: false, _orderBy: orderBy })
  }

  const fetchClassReports = async ({ pageCount, _studentsListData, isLoading, search, _orderBy }: IFetchClassReportsProps) => {
    setPageStateMachine(isLoading ? PageStateMachineEnum.LOADING : PageStateMachineEnum.FETCHING)

    if (typeof currentDiscipline === 'undefined') {
      return
    }

    try {
      if (!currentDiscipline?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const offset = pageCount * PAGE_SIZE

      const response = await getStudentsListPerformance({
        schoolId: profile?.school?.id,
        classId: Number(classId),
        disciplineId: Number(currentDiscipline.id),
        limit: PAGE_SIZE + 1,
        offset,
        search: search ?? searchTerm,
        sortBy: _orderBy ?? orderBy
      })

      if (!response.success) {
        if (pageCount === 0) {
          toast.handler({
            content: 'Não foi possível carregar mais dados, tente novamente mais tarde',
            duration: 5000,
            severity: 'warning'
          })
        }

        throw new Error(response.message)
      }

      const newStudentsListData = response.data

      setAuxStateMachine(newStudentsListData.result.length >= PAGE_SIZE + 1
        ? AuxStateMachineEnum.READY_TO_LOAD_MORE
        : AuxStateMachineEnum.IDLE
      )

      if (!newStudentsListData.result.length) {
        if (search ?? searchTerm) {
          setAuxStateMachine(AuxStateMachineEnum.SEARCH_NOT_FOUND)
        } else if (pageCount === 0) {
          setAuxStateMachine(AuxStateMachineEnum.NOT_FOUND)
        } else {
          return
        }
      }

      if (newStudentsListData.result.length > PAGE_SIZE) {
        newStudentsListData.result.pop()
      }

      newStudentsListData.result = [...(_studentsListData.data?.result ?? []), ...(newStudentsListData?.result ?? [])]

      setStudentsListData({ data: newStudentsListData, error: null })
    } catch (err: any) {
      console.log('ERRO ', err.message)
      setStudentsListData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    } finally {
      setPageStateMachine(PageStateMachineEnum.READY)
    }
  }

  const states = {
    IDLE: <></>,
    FETCHING: (
      <>
        <ReportsListHeader orderByCallback={orderByCallback} studentsListData={studentsListData} tryAgainCallback={tryAgain} searchCallback={searchCallback} />
        <ReportsListLoading />
      </>
    ),
    LOADING: (
      <>
        <ReportsListHeaderLoading />
        <ReportsListLoading />
      </>
    ),
    READY: (
      <>
        <ReportsListHeader orderByCallback={orderByCallback} studentsListData={studentsListData} tryAgainCallback={tryAgain} searchCallback={searchCallback} />
        <ReportsListReady studentsListData={studentsListData} loadMore={loadMore} auxState={auxStateMachine} />
      </>
    )
  }

  const renderStateMachine = () => states[pageStateMachine]

  return (
    <Grid container rowSpacing={3}>
      {renderStateMachine()}
    </Grid>
  )
}
