import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { USER_MANAGEMENT } from 'navigation/CONSTANTS'

interface IStudentWithoutClassModalProps {
  open: boolean
  onClose: () => void
  userId: number
}

export const StudentWithoutClassModal = ({ open, onClose, userId }: IStudentWithoutClassModalProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()

  const handleUserWithoutClassRedirect = () => {
    const route = userId
      ? `students/${userId}/multiclass/edit`
      : 'students'

    history.push(USER_MANAGEMENT(route))
  }

  return (
    <ConfirmationModal
      handleClose={onClose}
      confirmCallback={handleUserWithoutClassRedirect}
      confirmButtonText='Ok'
      cancelButtonVariant='outlined'
      confirmButtonBackground='feedbackPositive'
      isOpen={open}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' positive='feedbackMedium'>
            {t('Ativando perfil do estudante')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {t('Para ativar o perfil, você precisa vincular o estudante a uma turma primeiro.')}
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
