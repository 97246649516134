import { useState } from 'react'
import { Button, Card } from 'components/design-system'
import { StatusClassOption } from 'services'
import FilterListIcon from '@mui/icons-material/FilterList'
import useStyles from './style'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

export interface ISmallDropdownClassFilterProps {
  options: StatusClassOption[]
  selected: number
  setSelected: (value: number) => void
}

export const SmallDropdownClassFilter: React.FC<ISmallDropdownClassFilterProps> = ({ options, selected, setSelected }) => {
  const styles = useStyles()

  // states
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleExpand = (value: number) => {
    setSelected(value)
    setExpanded(false)
  }

  return (
    <>
      <Button onClick={() => setExpanded(!expanded)} className={styles.filterButton} variant='ghost'>
        <FilterListIcon/>
      </Button>
      {expanded && (
        <Card extraClasses={styles.dropdown}>
          <FormControl>
            <RadioGroup defaultValue={selected}>
              {options.map(
                opt => <FormControlLabel key={opt.id} value={opt.value} control={<Radio />} label={opt.label} onChange={() => handleExpand(opt.value)} />
              )}
            </RadioGroup>
          </FormControl>
        </Card>
      )}
    </>
  )
}
