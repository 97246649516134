/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumber: {
    alignItems: 'center',
    aspectRatio: '1',
    border: `2px solid ${theme.colors.neutral.lightBase}`,
    borderRadius: '2rem',
    display: 'flex',
    justifyContent: 'center',
    height: '4rem',
    letterSpacing: '0.05em'
  },
  card: {
    background: theme.colorBrand.medium,
    borderRadius: theme.borderRadius.lg,
    boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',
    height: '100%',
    padding: theme.spacingInset.sm
  },
  skeletonCard: {
    background: `${theme.colors.neutral.light10}52`,
    borderRadius: theme.borderRadius.lg,
    height: '100%',
    padding: theme.spacingInset.sm,
    '& .MuiSkeleton-root': {
      transform: 'inherit',
      transformOrigin: '0'
    },
    '& .MuiSkeleton-text': {
      background: theme.colors.neutral.light10,
      borderRadius: theme.borderRadius.md
    }
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.xs
    }
  }
}))
