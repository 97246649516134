import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    classSection: {
      marginTop: theme.spacingInset.sm,
      marginBottom: theme.spacingInset.sm
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        margin: '0'
      },
      '& > .MuiLink-root': {
        color: theme.colorBrand.medium,
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacingStack.sm
        },
        '& svg:first-child': {
          color: theme.colorBrand.medium
        }
      }
    },
    heading: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacingStack.xxs,
        marginBottom: theme.spacingStack.xxs,
        '& > span:first-child': {
          lineHeight: 0
        }
      }
    },
    helperText: {
      '& .MuiTypography-root': {
        paddingLeft: theme.spacingInset.xxs
      }
    },
    info: {
      '& svg': {
        marginLeft: '12px'
      }
    },
    inputWidth: {
      '& .MuiFormControl-root': {
        maxWidth: '563px',
        width: '100%'
      }
    },
    loadingContainer: {
      '&.MuiContainer-root': {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    modalDescription: {
      padding: '0px 32px 32px 32px'
    },
    modalTitle: {
      paddingBottom: '32px',
      textAlign: 'center'
    },
    requiredLabel: {
      marginBottom: theme.spacingStack.xxxs
    }
  }
})
