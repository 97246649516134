import { IApiResponse, ICheckSchoolPeriod } from 'services/types'
import { CHECK_SCHOOL_PERIOD, SCHOOL_PERIODS_AVAILABLE_YEAR, SCHOOL_PERIODS_BASE, SCHOOL_PERIODS_UPDATE } from './CONSTANTS'
import { request } from 'services/base'

export const createSchoolPeriod = async (schoolId: number, title: string): Promise<IApiResponse<any>> =>
  await request({ url: SCHOOL_PERIODS_BASE(), method: 'POST', data: { school: schoolId, title } })

export const getAvailableYears = async (schoolId: number): Promise<IApiResponse<any>> =>
  await request({ url: SCHOOL_PERIODS_AVAILABLE_YEAR(schoolId) })

export const updateSchoolPeriod = async (schoolId: number, schoolPeriodId: number, endDate: string): Promise<IApiResponse<any>> =>
  await request({ url: SCHOOL_PERIODS_UPDATE(schoolPeriodId), method: 'PUT', data: { school: schoolId, end_date: endDate } })

export const checkSchoolPeriod = async (schoolId: number): Promise<IApiResponse<ICheckSchoolPeriod[]>> =>
  await request({ url: CHECK_SCHOOL_PERIOD(schoolId) })
