import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist } from 'zustand/middleware'
import {
  IUserSessionResponse,
  ISubscriptionsSessionResponse,
  IProfilesSessionResponse,
  ISchoolProfileResponse,
  ISchoolPeriodResponse
} from 'services'

interface ILocalStoreProps {
  session: IUserSessionResponse | null
  setUserSession: (user: IUserSessionResponse | null) => void
  subscription: ISubscriptionsSessionResponse | null
  setSubscription: (subscription: ISubscriptionsSessionResponse | null) => void
  profile: IProfilesSessionResponse | null
  setProfile: (school: IProfilesSessionResponse | null) => void
  school: ISchoolProfileResponse | null
  setSchool: (school: ISchoolProfileResponse | null) => void
  schoolPeriod: ISchoolPeriodResponse | null
  setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => void
}

export const useLocalStore = create<
ILocalStoreProps,
SetState<ILocalStoreProps>,
GetState<ILocalStoreProps>,
StoreApiWithPersist<ILocalStoreProps>
>(
  persist(
    devtools((set) => ({
      session: null,
      setUserSession: (session: IUserSessionResponse | null) =>
        set({ session }),
      subscription: null,
      setSubscription: (subscription: ISubscriptionsSessionResponse | null) => set({ subscription }),
      profile: null,
      setProfile: (profile: IProfilesSessionResponse | null) => set({ profile }),
      school: null,
      setSchool: (school: ISchoolProfileResponse | null) => set({ school }),
      schoolPeriod: null,
      setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => set({ schoolPeriod })
    })),
    { name: 'cloe::web::props', version: 1, getStorage: () => localStorage }
  )
)
