import { useEffect, useState } from 'react'
import { Container } from 'components/design-system'
import { useParams } from 'react-router'
import { Grid } from '@mui/material'
import makeStyles from './style'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { getOneClass } from 'services'
import { atomClassGradeTypeWithGrades, atomCurrentClass, atomCurrentTab } from 'pages/ClassManagement/atomStore'
import { atomClassSelectingStudents, atomClassSelectingTeachers } from 'pages/BaseScreen/atomStore'
import { ClassHeader, ClassStudentList, ClassTabs, ClassTeacherList, ReportsTab } from './components'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'

export interface IManageClass {
  classId?: string
}

export const ManageClass: React.FC = () => {
  const { classId } = useParams<IManageClass>()
  const classes = makeStyles()
  const { createSnackbar } = useSnackbar()
  const { allGradeTypeData } = useFetchAllGradeTypes()

  // atoms
  const [currentClass, setCurrentClass] = useAtom(atomCurrentClass)
  const currentTab = useAtomValue(atomCurrentTab)
  const setCurrentStudentClass = useUpdateAtom(atomClassSelectingStudents)
  const setCurrentTeacherClass = useUpdateAtom(atomClassSelectingTeachers)
  const setClassGradeTypeWithGrades = useUpdateAtom(atomClassGradeTypeWithGrades)
  const resetCurrentClass = useResetAtom(atomCurrentClass)
  const resetCurrentTab = useResetAtom(atomCurrentTab)
  const resetClassGradeTypeWithGrades = useResetAtom(atomClassGradeTypeWithGrades)

  // states
  const [classGradeTypeIds, setClassGradeTypeIds] = useState<number[]>([])

  const getClass = async () => {
    try {
      const resOneClass = await getOneClass(Number(classId))
      if (resOneClass.success) {
        setCurrentClass(resOneClass.data)
        setCurrentStudentClass(resOneClass.data?.id.toString())
        setCurrentTeacherClass(resOneClass.data?.id.toString())
      }
    } catch (error) {
      createSnackbar({ content: 'Erro ao buscar informações da turma', severity: 'error' })
    }
  }

  useEffect(() => {
    resetCurrentClass()
    resetClassGradeTypeWithGrades()
    resetCurrentTab()
    if (classId) {
      getClass()
    }
  }, [classId])

  useEffect(() => {
    if (allGradeTypeData && currentClass) {
      const _classGradeTypeWithGrades = allGradeTypeData
        .filter(gt => classGradeTypeIds.includes(gt.id))
        .map(gt => ({
          gradeType: gt.name,
          gradeNames: currentClass.grades.map(g => g.name)
        }))
      setClassGradeTypeWithGrades(_classGradeTypeWithGrades)
    }
  }, [allGradeTypeData, classGradeTypeIds])

  useEffect(() => {
    if (currentClass) {
      setClassGradeTypeIds([...new Set(currentClass?.grades.map(g => g.grade_type))])
    }
  }, [currentClass])

  const stateMachine = {
    states: {
      teacher: <ClassTeacherList />,
      student: <ClassStudentList />,
      reports: <ReportsTab />
    }
  }

  return (
    <Container>
      <ClassHeader classId={classId} />
      <ClassTabs />
      <Grid container className={classes.tabContent}>
        <Grid item xs={12}>
          {stateMachine.states[currentTab]}
        </Grid>
      </Grid>
    </Container>
  )
}
