import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import { CircularProgress } from 'components/common/Charts/CircularProgress'
import useStyle from './styles'

interface IBigNumberItemProps {
  title: string
  fullHeight?: boolean
  percentage?: number
  valueText?: string
  subtitle?: string
}

export const BigNumberItem: React.FC<IBigNumberItemProps> = ({ title, fullHeight, percentage, valueText, subtitle }) => {
  const classes = useStyle()
  const theme = useTheme()

  return (
    <Grid container direction='column' className={`${classes.bigNumbersItem} ${fullHeight ? 'fullHeight' : ''} BigNumber${title.replace(/\s/g, '')}`}>
      <Grid container item>
        {typeof percentage !== 'undefined' && !isNaN(percentage)
          ? (
            <CircularProgress
              value={percentage}
              showPercentageSymbol
              customText={percentage === null && !valueText ? '-' : valueText}
              circleSize={'4rem'}
              textFontSize={'1rem'}
              theme={theme}
            />
          )
          : (
            <div className={classes.bigNumbersCircleContainer} style={{ height: '4rem', borderRadius: '2rem' }}>
              <Text2
                fontSize='sm'
                fontWeight='semibold'
                lineHeight='xs'
                mobile='sm'
                customColor={theme.colors.neutral.lightBase}
              >
                {valueText ?? '-'}
              </Text2>
            </div>
          )
        }
      </Grid>
      <Grid container item marginTop={2}>
        <Grid item xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            noWrap
            className={classes.boxTitle}
            customColor={theme.colors.neutral.lightBase}
          >
            {title}
          </Text2>
        </Grid>
        {subtitle && (
          <Grid item xs={12}>
            <Text2
              fontSize='xxs'
              fontWeight='medium'
              lineHeight='xxs'
              mobile='xxs'
              noWrap
              className={classes.boxSubTitle}
              customColor={theme.colors.neutral.lightBase}
            >
              {subtitle}
            </Text2>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
