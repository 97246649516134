import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { IMessageErrors, IAuthResetDefaultPassword, authResetDefaultPassword, IUserResponse } from 'services'

type Errors = string | Error | IMessageErrors[]

export const useAuthResetDefaultPassword = () => {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()

  // states
  const [resetDefaultPasswordError, setResetDefaultPasswordError] = useState<Errors>('')
  const [enableRestorePasswordButton, setEnableRestorePasswordButton] = useState<boolean>(false)

  const verifyConditionsToActivateRestorePasswordButton = (users: IUserResponse[]) => {
    const inactiveUsers = users.every(user => user?.profiles?.every(prof => !prof.active))
    if (inactiveUsers) return setEnableRestorePasswordButton(true)

    const usersWithPersonalPassword = users.every(user => user?.sentDefaultCredentials && !user?.isDefaultPass)
    if (usersWithPersonalPassword) return setEnableRestorePasswordButton(false)

    if (!usersWithPersonalPassword) {
      const usersWithDefaultPasswordAndSentCredentials = users.every(user => user?.sentDefaultCredentials && user?.isDefaultPass)
      if (usersWithDefaultPasswordAndSentCredentials) return setEnableRestorePasswordButton(true)

      const usersWithDefaultPassword = users.every(user => (!user?.sentDefaultCredentials && user?.isDefaultPass) || user?.isDefaultPass)
      if (usersWithDefaultPassword) return setEnableRestorePasswordButton(true)
      else return setEnableRestorePasswordButton(false)
    }
  }

  const resetDefaultPassword = async (requestData: IAuthResetDefaultPassword) => {
    try {
      const response = await authResetDefaultPassword(requestData)
      setResetDefaultPasswordError(response?.errors ?? '')
      if (response.success) {
        createSnackbar({ content: 'Senha restaurada com sucesso', severity: 'success' })
      } else {
        throw new Error(t('Erro ao restaurar senha'))
      }
    } catch (e) {
      if (e instanceof Error) {
        setResetDefaultPasswordError(e)
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  return {
    resetDefaultPasswordError,
    enableRestorePasswordButton,
    resetDefaultPassword,
    verifyConditionsToActivateRestorePasswordButton
  }
}
