import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  navationButtonWrapper: {
    '& button': {
      color: `${theme.colorBrand.medium} !important`,
      '&:hover': {
        background: 'rgba(219, 223, 238, 0.24)'
      }
    }
  },
  menuItemContainer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '32px !important'
    }
  },
  navationButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: theme.font.lineHeight.xxs,
    '&.disabled': {
      color: theme.colors.neutral.light30
    },
    '& > svg': {
      marginRight: theme.spacingInline.nano,
      height: '18px'
    }
  }
}))
