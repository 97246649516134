import { Grid, Text2 } from 'components/design-system'
import { ClassCard, EmptyOrErrorMessage, Tooltip } from 'components/common'
import makeStyles from './style'
import { useHistory } from 'react-router'
import readingListImage from 'assets/reading-list.svg'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'
import { useAtomValue } from 'jotai/utils'
import noSearchResults from 'assets/search.svg'
import { atomClassPropsData, atomSearchTerm } from 'pages/ClassManagement/atomStore'
import { IClassList, ISimpleGrade, ISimpleGradeType } from 'services'
import { useSnackbar } from 'navigation/hooks'

export const ClassList: React.FC = () => {
  const styles = makeStyles()
  const history = useHistory()
  const { createSnackbar } = useSnackbar()

  // atoms
  const searchTerm = useAtomValue(atomSearchTerm)
  const classPropsData = useAtomValue(atomClassPropsData)

  const handleClick = (selectedClass: IClassList) => {
    if (selectedClass?.id) {
      history.replace(CLASS_MANAGEMENT(`view/${selectedClass?.id}`))
    } else {
      createSnackbar({ content: 'Erro ao acessar turma. Por favor, tente novamente.', severity: 'error' })
    }
  }

  if (classPropsData?.classes.length) {
    return (
      <div className={styles.classList}>
        <Grid container spacing={2}>
          {classPropsData.classes.map((currentClass, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <Tooltip
                key={currentClass.id}
                title={
                  <Grid container direction='column' rowSpacing={0.5}>
                    <Grid item>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='lightBase'>
                        <b>Segmento: </b>{currentClass.gradeTypes.map((gt: ISimpleGradeType, i: number) => currentClass.gradeTypes.length === i + 1 ? gt.name : `${gt.name ?? ''}, `)}
                      </Text2>
                    </Grid>
                    <Grid item>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='lightBase'>
                        <b>Ano/Série: </b>{currentClass.grades.map((g: ISimpleGrade, i: number) => currentClass.grades.length === i + 1 ? g.name : `${g.name ?? ''}, `)}
                      </Text2>
                    </Grid>
                  </Grid>
                }
                arrow
              >
                <ClassCard
                  name={currentClass.name}
                  classId={currentClass.id}
                  studentCounter={currentClass.students ?? 0}
                  teacherCounter={currentClass.teachers ?? 0}
                  onClick={() => handleClick(currentClass)}
                  disabled={!currentClass.active}
                  classGrades={currentClass.grades}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }

  return (
    classPropsData?.classes.length === 0 && searchTerm
      ? <EmptyOrErrorMessage
        message='Não encontramos o nome digitado na lista de turmas. <b>Verifique e tente novamente.</b>'
        image={noSearchResults}
      />
      : <EmptyOrErrorMessage
        message={'A lista de turmas está vazia. Para adicionar novas turmas, clique no botão <b>Rematrícula</b> ou em <b>Criar turma</b>.'}
        image={readingListImage}
      />
  )
}
