import React from 'react'
import useStyles from './style'

export const Ops: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.containerEmptyDisciplines}>
      <p className={classes.titleEmptyDisciplines}>Ops!</p>
      <div className={classes.image404} />
      <React.Fragment>
        {children}
      </React.Fragment>
    </div>
  )
}

export default Ops
