import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  dropdown: {
    '& .MuiList-root': {
      padding: '0'
    },
    '& .MuiPaper-root': {
      background: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.sm,
      boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',
      maxWidth: '288px'
    }
  },
  dropdownContent: {
    padding: theme.spacingInset.xs
  },
  dropdownFooter: {
    background: '#F7F7F7',
    padding: theme.spacingInset.xs
  },
  filterButton: {
    '&.MuiButton-root': {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      borderRadius: '8px!important',
      minWidth: 'auto!important',
      padding: '16px!important',
      '&.appliedFilters': {
        backgroundColor: theme.colorBrand.medium,
        '& svg': {
          color: theme.colors.neutral.lightBase
        },
        '&:hover': {
          backgroundColor: theme.colorBrand.dark
        }
      },
      '&:hover': {
        backgroundColor: 'rgba(230, 230, 230, 0.64)'
      },
      '& svg': {
        color: theme.colorBrand.dark
      }
    }
  },
  filterTitle: {
    fontSize: '18px!important'
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      marginTop: '8px',
      '&:first-child': {
        marginTop: '0'
      }
    }
  },
  radioOption: {
    border: `2px solid ${theme.colors.neutral.light10}`,
    borderRadius: '8px',
    color: theme.colors.neutral.dark30,
    padding: '3px 0px 3px 3px',
    '& .Mui-checked': {
      color: `${theme.colorBrand.medium}!important`
    },
    '&.checked': {
      background: `${theme.colorBrand.lightest}A3`,
      border: `2px solid ${theme.colorBrand.medium}`,
      color: theme.colorBrand.medium
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.font.fontWeight.medium
    },
    '&.MuiFormControlLabel-root': {
      marginLeft: '0',
      marginRight: '0'
    },
    '& .MuiRadio-root': {
      margin: '0px 7px 0px 0px'
    }
  }
}))
