import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  quickAccessContainer: {
    position: 'relative',
    background: 'linear-gradient(92.2deg, #499ACE -4.69%, #4B61AA 58.54%)',
    borderRadius: theme.borderRadius.md,
    padding: `0 ${theme.spacingInline.xxxs}`,
    margin: `0 0 ${theme.spacingInline.xs} 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    height: '58px',

    color: theme.colors.neutral.lightBase,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row'
    }
  },
  cloeAvatar: {
    position: 'absolute',
    marginRight: theme.spacingInline.xxxs,
    top: '-9px',
    width: '90px'
  },
  hideButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  welcomeText: {
    position: 'relative',
    display: 'block',
    marginLeft: '104px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
