import { ClassListEnum, IClassItem } from 'services'
import { Grid } from '@mui/material'
import { ClassCardAccordion } from 'pages/ClassManagement/components'
import { Loading } from 'components/design-system'
import { useEffect, useState } from 'react'
import { EmptyOrErrorMessage } from 'components/common'
import noSearchResults from 'assets/search.svg'
import { atomDestinySelectedClass, atomOriginClasses } from 'pages/ClassManagement/atomStore'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { useClassesSelectionControl } from 'pages/ClassManagement/hooks'
interface IClassCardAccordionListProps {
  classes: IClassItem[] | undefined
  classListType: ClassListEnum
  isLoading: boolean
  search: string
}

type ClassCardAccordionListStates = 'idle' | 'list' | 'loading' | 'empty' | 'noSearchResults'

export const ClassCardAccordionList: React.FC<IClassCardAccordionListProps> = ({ classes, classListType, isLoading, search }) => {
  const { enableStudentsIfAllClassesAreUnchecked, unselectClassWhichHasNoInnerSelection, disableStudentsFromNonSelectedClasses } = useClassesSelectionControl()

  // atoms
  const originClasses = useAtomValue(atomOriginClasses)
  const [destinySelectedClass, setDestinySelectedClass] = useAtom(atomDestinySelectedClass)

  // atoms setters
  const setOriginClasses = useUpdateAtom(atomOriginClasses)

  // atoms reset
  const resetDestinySelectedClass = useResetAtom(atomDestinySelectedClass)

  // states
  const [classCardAccordionListState, setClassCardAccordionListState] = useState<ClassCardAccordionListStates>('idle')

  const handleSelectWholeClass = (classItem: IClassItem) => {
    const _checkState: boolean = classItem.checked !== true

    const newClassItem = classItem
    if (newClassItem?.students && originClasses) {
      const selectedClass = originClasses.find(slc => slc.id === classItem.id)
      if (selectedClass?.students) {
        selectedClass.checked = _checkState
        for (const student of selectedClass.students) {
          student.checked = _checkState
        }
        const newClassList = [...originClasses]
        const classItemIndex = newClassList.findIndex(cls => cls.id === classItem.id)
        newClassList[classItemIndex] = selectedClass
        setOriginClasses(newClassList)
        resetDestinySelectedClass()
        disableStudentsFromNonSelectedClasses(selectedClass)

        if (!_checkState) enableStudentsIfAllClassesAreUnchecked()
      }
    }
  }

  const handleSelectDestinyClass = (classItem: IClassItem) => {
    setDestinySelectedClass(classItem)
  }

  const renderClassCardAccordionList = () => {
    if (isLoading) {
      setClassCardAccordionListState('loading')
    } else if (classes?.length) {
      setClassCardAccordionListState('list')
    } else if (classes?.length === 0) {
      if ((classListType === ClassListEnum.origin || classListType === ClassListEnum.destiny) && search?.length) {
        setClassCardAccordionListState('noSearchResults')
      } else {
        setClassCardAccordionListState('empty')
      }
    } else {
      setClassCardAccordionListState('idle')
    }
  }

  useEffect(() => {
    renderClassCardAccordionList()
  }, [classes, isLoading])

  useEffect(() => {
    unselectClassWhichHasNoInnerSelection()
  }, [originClasses])

  const stateMachine = {
    states: {
      idle: null,
      empty: (
        <EmptyOrErrorMessage
          message='Não encontramos nenhuma turma no segmento e ano selecionado. <b>Verifique e tente novamente.</b>'
          image={noSearchResults}
        />
      ),
      list: (
        <>
          {classes?.map((classItem: IClassItem) => (
            <Grid item xs={12} key={classItem.id}>
              <ClassCardAccordion
                classItem={classItem}
                classListType={classListType}
                selectedOriginClass={!!classItem?.checked && classListType === ClassListEnum.origin}
                onSelectOriginClass={(_classItem: IClassItem) => handleSelectWholeClass(_classItem)}
                selectedDestinyClass={destinySelectedClass?.id === classItem?.id && classListType === ClassListEnum.destiny}
                onSelectDestinyClass={(_classItem: IClassItem) => handleSelectDestinyClass(_classItem)}
              />
            </Grid>
          ))}
        </>
      ),
      loading: (
        <Grid item>
          <Loading />
        </Grid>
      ),
      noSearchResults: (
        <EmptyOrErrorMessage
          message='Não econtramos o nome digitado na lista de turmas. <b>Verifique e tente novamente.</b>'
          image={noSearchResults}
        />
      )
    }
  }

  return stateMachine.states[classCardAccordionListState]
}
