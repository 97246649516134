export const PROGRAM_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/programs`

export const PROGRAM_GET = (
  schoolId: number,
  gradeTypeId: number,
  gradeId: number,
  disciplineId: number
) => `${PROGRAM_BASE()}?school.id=${schoolId}&cloe_program=false&grade_type.id=${gradeTypeId}&grade.id=${gradeId}&discipline.id=${disciplineId}`

export const PROGRAM_COUNT = (
  schoolId: number,
  gradeTypeId: number,
  gradeId: number
) => `${PROGRAM_BASE()}/count?school.id=${schoolId}&cloe_program=false&grade_type.id=${gradeTypeId}&grade.id=${gradeId}`

export const PROGRAM_SCHOOL_GET_BY_GRADETYPE_GRADE = (
  gradeTypeId: number,
  gradeId: number,
  schoolId: number,
  disciplineId?: number
) => {
  const _disciplineId = disciplineId ? `?discipline=${disciplineId}` : ''
  return `${PROGRAM_BASE()}/grade/${gradeId}/gradeType/${gradeTypeId}/school/${schoolId}${_disciplineId}`
}

export const PROGRAM_SCHOOL_CREATE = () => `${PROGRAM_BASE()}/create`
