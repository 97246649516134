import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.MuiAlert-root': {
      padding: '8px 16px',
      ' &.MuiAlert-filledError': {
        color: theme.colors.negative.feedbackMedium,
        backgroundColor: theme.colors.negative.feedbackLightest
      },
      ' &.MuiAlert-filledWarning': {
        color: theme.colors.warning.feedbackMedium,
        backgroundColor: theme.colors.warning.feedbackLightest
      },
      ' &.MuiAlert-filledInfo': {
        color: theme.colors.information.feedbackMedium,
        backgroundColor: theme.colors.information.feedbackLightest
      },
      ' &.MuiAlert-filledSuccess': {
        color: theme.colors.positive.feedbackMedium,
        backgroundColor: theme.colors.positive.feedbackLightest
      },
      ' &.MuiAlert-outlinedError': {
        color: theme.colors.negative.feedbackMedium,
        borderColor: theme.colors.negative.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .MuiAlert-icon': {
          color: theme.colors.negative.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedWarning': {
        color: theme.colors.warning.feedbackMedium,
        borderColor: theme.colors.warning.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .MuiAlert-icon': {
          color: theme.colors.warning.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedInfo': {
        color: theme.colors.information.feedbackMedium,
        borderColor: theme.colors.information.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .MuiAlert-icon': {
          color: theme.colors.information.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedSuccess': {
        color: theme.colors.positive.feedbackMedium,
        borderColor: theme.colors.positive.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .MuiAlert-icon': {
          color: theme.colors.positive.feedbackMedium
        }
      },
      '&.MuiAlert-outlinedWhite': {
        color: theme.colors.neutral.lightBase,
        borderColor: theme.colors.neutral.lightBase,
        fontWeight: theme.font.fontWeight.semibold,
        '& .MuiAlert-icon': {
          color: `${theme.colors.neutral.lightBase} !important`
        }
      },
      '& .MuiAlert-action': {
        paddingTop: '2px'
      }
    }
  }
}))
