import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    accessContentButton: {
      padding: `${theme.spacingSquish.nano}!important`
    },
    switch: {
      '& .MuiTypography-root': {
        color: theme.colors.neutral.dark10,
        fontSize: theme.font.fontSize.xs,
        fontWeight: theme.font.fontWeight.semibold,
        lineHeight: theme.font.lineHeight.xs,
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.font.fontSize.sm
        }
      },
      '& .MuiSwitch-root .Mui-checked': {
        color: theme.colors.positive.feedbackMedium
      },
      '& .MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.colors.positive.feedbackLightest
      }
    },
    tag: {
      '&.MuiChip-root': {
        width: '100%',
        background: 'rgba(230, 230, 230, 0.32)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: theme.colorBrand.lightest
        }
      }
    },
    loadingBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '500px'
    },
    skillContainer: {
      paddingTop: '8px',
      width: '100%',
      height: '200px'
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    }
  }
})
