import React from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Text } from 'components/design-system'

import useStyle from '../../styles'
import { CustomInfoTooltip } from 'components/common/CustomInfoTooltip'

const ReportsHeaderItem: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container alignItems='center' className={classes.bigNumbesReportsContainer}>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
        <Text className={classes.listHeaderText}>
          {t('Estudante')}
        </Text>
      </Grid>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'} columnSpacing={2}>
        <Grid item>
          <Text className={classes.listHeaderText}>
            {t('Participação')}
          </Text>
        </Grid>
        <Grid item>
          <CustomInfoTooltip text={t('Considera todas as atividades e avaliações entregues pelo estudante.')} />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'} columnSpacing={2}>
        <Grid item>
          <Text className={classes.listHeaderText}>
            {t('Média')}
          </Text>
        </Grid>
        <Grid item>
          <CustomInfoTooltip text={t('Considera todas as notas das avaliações aplicadas e corrigidas.')} />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} justifyContent={isMobile ? 'space-between' : 'center'} columnSpacing={2}>
        <Grid item>
          <Text className={classes.listHeaderText}>
            {t('Situação')}
          </Text>
        </Grid>
        <Grid item>
          <CustomInfoTooltip text={t('Considera apenas a média do estudante.')} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReportsHeaderSkeleton: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container alignItems='center' className={classes.bigNumbesReportsContainer}>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
        <Grid item>
          <Skeleton variant='text' sx={{ width: '86px' }} height={60} animation='wave' />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
        <Grid item>
          <Skeleton variant='text' sx={{ width: '161px' }} height={60} animation='wave' />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
        <Grid item>
          <Skeleton variant='text' sx={{ width: '107px' }} height={60} animation='wave' />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} justifyContent={isMobile ? 'space-between' : 'center'}>
        <Grid item>
          <Skeleton variant='text' sx={{ width: '128px' }} height={60} animation='wave' />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ReportsHeader = {
  Item: ReportsHeaderItem,
  Skeleton: ReportsHeaderSkeleton
}
