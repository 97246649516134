import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  color: theme.colors.neutral.darkBase,
  fontFamily: theme.font.fontFamily,
  fontSize: theme.font.fontSize.sm,
  letterSpacing: theme.font.spacing.distant,
  lineHeight: theme.font.lineHeight.xs,
  fontWeight: theme.font.fontWeight.medium,
  borderWidth: theme.borderThickness.thin,
  borderColor: theme.colors.neutral.darkBase,
  '&:hover': {
    background: theme.colorBrand.lightest,
    cursor: 'pointer'
  },
  '&:active': {
    background: theme.colorBrand.light,
    color: theme.colors.neutral.darkBase,
    borderWidth: theme.borderThickness.thin,
    border: '3px',
    borderRadius: theme.borderRadius.none,
    borderColor: theme.colors.support.colorSupport01
  },
  '&.Mui-selected': {
    background: theme.colorBrand.light,
    color: theme.colors.neutral.darkBase,
    borderWidth: theme.borderThickness.thin
  },
  '&.Mui-disabled': {
    color: theme.colors.neutral.dark30,
    borderColor: theme.colors.neutral.dark30
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.primary': {
      ...styleDefault(theme),
      textDecorationColor: theme.colorBrand.medium
    },
    '&.returnArrow': {
      ...styleDefault(theme),
      '& .MuiSvgIcon-root': {
        color: theme.colors.neutral.darkBase,
        width: '12px',
        height: '12px'
      },
      '&.linkStyle': {
        color: theme.colorBrand.medium,
        cursor: 'pointer',
        textDecoration: 'none',
        '& .MuiSvgIcon-root': {
          color: theme.colorBrand.medium
        }
      }
    },
    '&.nextArrow': {
      ...styleDefault(theme),
      '& .MuiSvgIcon-root': {
        color: theme.colors.neutral.darkBase,
        width: '12px',
        height: '12px'
      },
      '&.linkStyle': {
        color: theme.colorBrand.medium,
        cursor: 'pointer',
        textDecoration: 'none',
        '& .MuiSvgIcon-root': {
          color: theme.colorBrand.medium
        }
      }
    },
    '&.MuiTypography-root': {
      '&.link': {
        color: theme.colorBrand.dark,
        cursor: 'pointer',
        textDecoration: 'none',
        margin: '0 16px',
        '&.noMargin': {
          margin: '0'
        }
      },
      '&.badlink': {
        color: theme.colors.negative.feedbackMedium,
        cursor: 'pointer',
        textDecoration: 'none',
        margin: '0 16px',
        '&.noMargin': {
          margin: '0'
        }
      }
    }
  }
}))
