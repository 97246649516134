import { memo } from 'react'
import useStyles from './style'
import { Card, Checkbox, Grid, Text2 } from 'components/design-system'
import { IClassSubscriptionResponse, profileClassCardTags } from 'services'
import { returnEmailOrPhone } from 'utils'
import { useAtomValue } from 'jotai/utils'
import { ActionContextEnum, ActionsControlCenter, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { atomCurrentClass } from 'pages/ClassManagement/atomStore'
import { Contact, DateOfBirth, EditProfileButton, Password, ProfileStatus, SendingCredentials, Username } from '../ProfileCard/components'

interface IProfileClassCard {
  checked: boolean
  classId?: string
  onChange: (id: number, checked: boolean) => void
  classSubscription: IClassSubscriptionResponse
}

export const ProfileClassCard: React.FC<IProfileClassCard> = memo(({ checked, classId, onChange, classSubscription }) => {
  const styles = useStyles()

  const {
    id: userId,
    birth_date: birthDate,
    email,
    name,
    phone,
    surname,
    username,
    isDefaultPass,
    sentDefaultCredentials
  } = classSubscription.user_id

  const fullname = `${name} ${surname}`

  const contact = returnEmailOrPhone(email, phone)

  const {
    active,
    type: profiletype
  } = classSubscription.user_school_profile

  const contactDataTestId = contact.type === 'email' ? profileClassCardTags[profiletype]?.EMAIL_TAG : profileClassCardTags[profiletype]?.PHONE_TAG

  // atoms
  const schoolClass = useAtomValue(atomCurrentClass)

  return (
    <Card extraClasses={`${styles.root} ${checked ? 'checked' : ''} ${active ? '' : 'disabled'}`}>
      <Grid container rowSpacing={1}>
        <Grid container item justifyContent='space-between' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Grid container item xs alignItems='center'>
            <Grid item xs={10} md='auto' zeroMinWidth>
              <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
                <ActionsControlCenter
                  ctx={ActionContextEnum.schoolClassManager}
                  action={SchoolClassManagerActionsEnum.select_user}
                  fallback={<Grid item sx={{ marginRight: '8px' }}>{fullname}</Grid>}
                  options={{ fullFeaturesDisabler: !schoolClass?.active }}
                >
                  <Checkbox onChange={e => onChange(classSubscription.id, e.target.checked)} checked={checked} label={fullname} customClasses={styles.checkbox} dataTestid={profileClassCardTags[profiletype]?.SELECT_TAG} />
                </ActionsControlCenter>
              </Text2>
            </Grid>
            <Grid item xs={2} md='auto' zeroMinWidth>
              <EditProfileButton
                classId={classId}
                dataTestId={profileClassCardTags[profiletype]?.EDIT_TAG}
                profiletype={profiletype}
                userId={userId}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto' className='status'>
            <Grid container alignItems='center' columnSpacing={2}>
              <ProfileStatus
                activateDataTestId={profileClassCardTags[profiletype]?.ACTIVATE_PROFILE}
                deactivateDataTestId={profileClassCardTags[profiletype]?.DEACTIVATE_PROFILE}
                disabled={!active}
                profile={classSubscription.user_school_profile}
                profiletype={profiletype}
                userId={userId}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justifyContent='space-between' spacing={1}>
          <Username username={username} copyButtonDataTestId={profileClassCardTags[profiletype]?.USERNAME_TAG} />

          <DateOfBirth birthDate={birthDate} copyButtonDataTestId={profileClassCardTags[profiletype]?.BIRTH_DATE_TAG} />

          <Contact contact={contact} profiletype={profiletype} copyButtonDataTestId={contactDataTestId} />

          <SendingCredentials
            disabled={!active}
            isDefaultPass={isDefaultPass}
            profiletype={profiletype}
            sentDefaultCredentials={sentDefaultCredentials}
            userId={userId}
          />

          <Password
            disabled={!active}
            isDefaultPass={isDefaultPass}
            profiletype={profiletype}
            userId={userId}
          />
        </Grid>
      </Grid>
    </Card>
  )
})
