import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    '& .MuiBox-root': {
      margin: '0 auto',
      maxWidth: '358px',
      width: '100%'
    }
  },
  schoolYearCard: {
    color: theme.colors.neutral.dark30,
    cursor: 'pointer',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: '24px',
    marginBottom: theme.spacingInline.xxxs,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacingInline.xxxs
    }
  }
}))
