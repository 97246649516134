import { Text2, Snackbar } from 'components/design-system'
import useStyles from './style'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { GetFiles, getS3UploadUrl, Item, UploadFile, UserSchoolProfileTypeEnum } from 'services'
import { useState, createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { useAtom } from 'jotai'
import { allUploadedFiles } from 'pages/ProfileTabs/TeacherTab/TeacherActions/atomStore'
import { fileExtension } from 'utils'

interface FileUploadProps {
  target: UserSchoolProfileTypeEnum.teacher | UserSchoolProfileTypeEnum.student
  accept: string
  dataTestid?: string
}

const FileUpload: React.FC<FileUploadProps> = ({ accept, ...props }) => {
  const styles = useStyles()
  const [files, setFiles] = useState<any[]>([])
  const [snackBar, setSnackBar] = useState(false)
  const [snackBarMessage, setSnackbarMessage] = useState('')
  const [draging, setDraging] = useState(false)
  const { t } = useTranslation()
  const { school } = useStore()
  const [listOfFiles, setListOfFiles] = useAtom(allUploadedFiles)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const uploadRef = createRef<HTMLAnchorElement>()

  const handleFileList = (file: File, controller: AbortController) => {
    const currentList = [...listOfFiles ?? []]

    // TODO add a const/enum/env to this statusId instead
    const newItem: Item = {
      filename: file.name,
      createdAt: new Date().toString(),
      status: 'Enviando',
      statusId: '202f07a1f640454bd532e4e1e6a75715',
      abortController: controller
    }

    currentList?.unshift(newItem)

    setListOfFiles(currentList)
  }

  const handleUpload = async (file: File) => {
    const controller = new AbortController()

    const getUrlResponse = await getS3UploadUrl(file, file.name, 'massRegistration', school?.id.toString() ?? '', props.target)
    // TODO add a else when success = false
    if (getUrlResponse.success) {
      handleFileList(file, controller)
      const response = await UploadFile(file, getUrlResponse.data.url, controller)

      if (response) {
        setTimeout(() => {
          getUploadedFiles()
        }, 5000)
      } else {
        setSnackBar(true)
        setSnackbarMessage(t('Erro ao realizar upload!'))
        setFiles([])
        setDraging(false)
      }
    }
  }

  const getUploadedFiles = async () => {
    const response = await GetFiles(school?.id ?? 0)

    // TODO add a else when success = false
    if (response.success) {
      const items = response.data.data?.Items?.filter(f => f.extra?.uploadtype === props.target).sort(function(a, b) {
        const dateA = new Date(a.createdAt).getTime()
        const dateB = new Date(b.createdAt).getTime()

        return dateA > dateB ? 1 : -1
      }).reverse()

      setListOfFiles(items)
    }
  }

  const handleSelectedFiles = (wasDropped: boolean, event: any) => {
    const currentFiles = [...files]

    const items = !wasDropped ? event.target : event.dataTransfer

    const fromInput: FileList = items.files

    for (const file of fromInput) {
      let exists = false
      let validFileExtension = true

      const extension = fileExtension(file.name)

      if (extension !== 'xlsx') {
        validFileExtension = false
        setSnackBar(true)
        setSnackbarMessage(t('A extensão do arquivo deve ser XLSX!'))
      }

      if (validFileExtension) {
        for (const current of currentFiles) {
          if (file.name === current.name) {
            exists = true
            setSnackBar(true)
            setSnackbarMessage('Arquivo já foi submetido!')
          }
        }
      }

      if (!exists && validFileExtension) {
        handleUpload(file)
        //  registering file to avoid upload the same more than once
        currentFiles.push(file)
      }
    }

    setFiles(currentFiles)

    document.getElementById('resetButton')?.click()
  }

  const clickedToUpload = () => {
    uploadRef?.current?.click()
    document.getElementById('uploadOfItems')?.click()
  }

  const handleDrop = (event: any) => {
    event.preventDefault()

    setDraging(false)

    handleSelectedFiles(true, event)
  }

  const handleDrag = (inOrOut: boolean, event: any) => {
    event.preventDefault()

    if (inOrOut) {
      setDraging(true)
    } else {
      setDraging(false)
    }
  }

  const sendForm = (event: any) => {
    event.preventDefault()
  }

  return (
    <div className={styles.container}>
      <a ref={uploadRef} data-testid={props.dataTestid}/>
      <div className={styles.dragArea} onClick={() => clickedToUpload()} onDrop={(e) => handleDrop(e)} onDragOver={(event) => handleDrag(true, event)} onDragLeave={(event) => handleDrag(false, event)}>
        <Grid container direction='column' alignItems='center' justifyContent='center' style={{ minHeight: '100%' }}>
          {!draging
            ? <>
              <Grid item md={12}>
                <Text2 fontWeight='black' fontSize='xxl' lineHeight='xs' mobile='xxl' mobilelineheight='xxs' iconscolor>
                  <UploadOutlinedIcon fontSize='inherit' className={styles.icon}/>
                </Text2>
              </Grid>
              {!isMobile &&
                    <Grid item md={12}>
                      <Text2 fontWeight='semibold' fontSize='sm' lineHeight='xs' neutral='light40' mobile='xs' mobilelineheight='xxs'>{t('Arraste sua planilha para cá ou')}</Text2>
                    </Grid>
              }
              <Grid item md={12}>
                <Text2 fontWeight='semibold' fontSize='sm' lineHeight='xs' neutral='light40' mobile='xs' mobilelineheight='xxs'>
                  <Text2 fontWeight='semibold' fontSize='sm' lineHeight='xs' colorbrand='medium' mobile='xs' mobilelineheight='xxs'>{t('clique aqui')} </Text2>
                  {t('para selecionar')}
                </Text2>
              </Grid>
              {isMobile &&
                  <Grid item md={12}>
                    <Text2 fontWeight='semibold' fontSize='sm' lineHeight='xs' neutral='light40' mobile='xs' mobilelineheight='xxs'>
                      {t('sua planilha')}</Text2>
                  </Grid>
              }
            </>
            : <>
              <Grid item md={12}>
                <Text2 fontWeight='semibold' fontSize='xl' lineHeight='xs' mobile='lg' mobilelineheight='xxs' iconscolor>
                  <UploadOutlinedIcon fontSize='inherit' className={styles.icon}/>
                </Text2>
              </Grid>
              <Grid item md={12}>
                <Text2 fontWeight='semibold' fontSize='sm' lineHeight='xs' neutral='light40' mobile='xs' mobilelineheight='xxs'>
                  {t('Agora é só soltar')}
                </Text2>
              </Grid>
            </>
          }
        </Grid>
      </div>
      <form onSubmit={(e) => sendForm(e)} id='uploadForm'>
        <input
          type='file'
          id='uploadOfItems'
          className={styles.hideInput}
          onChange={(event: any) => handleSelectedFiles(false, event)}
          multiple
          accept={accept}
        />
        <button type='reset' id='resetButton' className={styles.hideInput} />
      </form>
      <Snackbar
        open={snackBar}
        horizontal='left'
        vertical='top'
        onClose={() => setSnackBar(false)}
        content={snackBarMessage}
        severity='error'
      />
    </div>
  )
}

export default FileUpload
