import MUITooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useState } from 'react'
import { styled } from '@mui/material'

interface ITooltipProps extends TooltipProps {
  label?: string
  variant?: 'colored'
}

const CustomTooltip = styled(({ className, ...props }: ITooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.neutral.dark20
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.neutral.dark20,
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xxxs,
    fontFamily: theme.font.fontFamily,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.fontSize.xxs,
    padding: theme.spacingSquish.quarck,
    borderRadius: theme.borderRadius.sm
  },
  '&.colored': {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.colorBrand.darkest
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.colorBrand.darkest,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.fontSize.lg,
      maxWidth: '253px',
      minWidth: '253px',
      padding: theme.spacingInset.xs
    }
  }
}))

export const Tooltip = (props: ITooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { arrow, children, label, placement, title, variant } = props
  return (
    <CustomTooltip
      className={variant}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={label ? <><b>{label}: </b>{title}</> : <>{title}</>}
      placement={placement ?? 'top'}
      arrow={arrow}
    >
      <a onClick={() => setShowTooltip(!showTooltip)}>
        {children}
      </a>
    </CustomTooltip>
  )
}
