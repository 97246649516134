import MUiButton from '@mui/material/Button'
import { ButtonProps } from '@mui/material'
import makeStyles, { ripple } from './style'
import { IconImage } from 'components/design-system'

export interface IButtonProps extends Omit<ButtonProps, 'variant' | 'size'> {
  variant?: 'primary' | 'outlined' | 'ghost' | 'link' | 'feedbackNegative' | 'feedbackPositive' | 'feedbackWarning' | 'feedbackInformation' | 'feedbackNegativeGhost' | 'feedbackPositiveGhost' | 'feedbackWarningGhost' | 'feedbackInformationGhost'
  component?: string
  icon?: string
  size?: 'small' | 'medium' | 'large'
}

export const Button: React.FC<IButtonProps> = ({ variant = 'primary', children, icon, size = 'large', ...props }) => {
  const classes = makeStyles()

  return (
    <MUiButton
      {...props}
      className={`${props.className as string} ${classes.root} ${variant}`}
      TouchRippleProps={{ classes: ripple[variant]() }}
      size={size}
    >
      {icon && <IconImage image={icon} size='medium'/>}
      {children}
    </MUiButton>
  )
}
