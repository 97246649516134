import { createRef } from 'react'
import MUICard, { CardProps } from '@mui/material/Card'
import MUICardContent from '@mui/material/CardContent'
import useStyles from './style'

type CardState = 'hover' | 'focused' | 'selected'

export interface ICardProps extends CardProps {
  cardState?: CardState
  children?: React.ReactNode
  clickable?: boolean
  extraClasses?: string
  fullwidth?: boolean | number
  onClick?: any
  selected?: boolean
  dataTestId?: string
  noPadding?: boolean
}

export const Card: React.FC<ICardProps> = ({ children, extraClasses, selected, clickable, dataTestId, ...props }) => {
  const classes = useStyles(props)
  const cardRef = createRef<HTMLDivElement>()
  const clickableClass = clickable ? 'clickable' : ''
  const selectedClass = selected ? 'selected' : ''

  const handleDataTaging = () => {
    cardRef?.current?.click()
  }

  return (
    <div data-testid={dataTestId} ref={cardRef} onClick={() => handleDataTaging()}>
      <MUICard
        className={`${classes.root} ${clickableClass} ${selectedClass} ${extraClasses ?? 'not suplied'}`}
        {...props}
        variant='outlined'
      >
        <MUICardContent>
          {children}
        </MUICardContent>
      </MUICard>
    </div>
  )
}
