import { Grid, Skeleton, useTheme } from '@mui/material'
import { BigNumberItem, BigNumberSkeleton } from '../BigNumbers/components/BigNumber/components'
import { useTranslation } from 'react-i18next'
import { IStudentPerformance } from 'services'
import { bigNumbersDataAtom } from '../BigNumbers/atomStore'
import { useAtomValue } from 'jotai/utils'
import { useEffect, useState } from 'react'
import { Text2 } from 'components/design-system'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface IBigNumbersStudentProps {
  isLoading: boolean
  studentName: string
  studentPerformance: IStudentPerformance | undefined
}

export const BigNumbersStudent = ({ isLoading, studentName, studentPerformance }: IBigNumbersStudentProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.READY)

  const bigNumbersData = useAtomValue(bigNumbersDataAtom)

  const studentParticipation = (studentPerformance?.per_participation ?? 0) * 100
  const answeredActivities = String(`${studentPerformance?.nr_answered_activity ?? '-'}/${studentPerformance?.nr_released_activity ?? '-'}` ?? '-')
  const answeredEvaluations = String(`${studentPerformance?.nr_answered_evaluation ?? '-'}/${studentPerformance?.nr_released_evaluation ?? '-'}` ?? '-')
  const studentEvaluation = studentPerformance?.dec_performance !== undefined && studentPerformance?.nr_corrected_evaluation ? studentPerformance?.dec_performance : '-'

  const bigNumbersStudentData = {
    studentParticipation,
    answeredActivities,
    answeredEvaluations,
    studentEvaluation
  }

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  const states = {
    IDLE: <></>,
    LOADING: (
      <Grid container item xs={12} rowSpacing={2}>
        <Grid item>
          <Skeleton variant='text' width={350} height={40} animation='wave' />
        </Grid>
        <Grid container item>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberSkeleton />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberSkeleton />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberSkeleton />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberSkeleton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container item xs={12} rowSpacing={2}>
        <Grid item>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Visão geral de {{studentName}}', { studentName: studentName })}
          </Text2>
        </Grid>
        <Grid container item>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberItem
                title={t('Participação do Estudante')}
                percentage={bigNumbersStudentData.studentParticipation}
                valueText={studentPerformance?.per_participation === null || typeof studentPerformance?.per_participation === 'undefined' ? '-' : undefined}
                subtitle={`Turma: ${bigNumbersData?.data?.per_participation ? (((bigNumbersData?.data?.per_participation ?? 0) * 100).toFixed(0) + ' %') : '-'}`}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberItem
                title={t('Atividades realizadas')}
                valueText={bigNumbersStudentData.answeredActivities}
                fullHeight
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberItem
                title={t('Avaliações realizadas')}
                valueText={bigNumbersStudentData.answeredEvaluations}
                fullHeight
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BigNumberItem
                percentage={typeof bigNumbersStudentData.studentEvaluation === 'number' ? (bigNumbersStudentData.studentEvaluation * 10) : 0}
                valueText={`${bigNumbersStudentData.studentEvaluation.toLocaleString('pt-BR') ?? '-'}`}
                title={t('Média do estudante')}
                subtitle={String(`Turma: ${bigNumbersData?.data?.nr_corrected_student ? (bigNumbersData?.data?.dec_performance?.toLocaleString('pt-BR') ?? 0) : '-'}`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
