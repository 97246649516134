import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  downBox: {
    backgroundColor: theme.colors.neutral.light20,
    bottom: 0,
    '&:hover': {
      color: theme.colors.neutral.dark20,
      '& .label, svg': {
        color: theme.colors.neutral.dark20
      }
    },
    '& .navButton': {
      color: theme.colors.neutral.dark20,
      '&:hover': {
        backgroundColor: theme.colors.neutral.lightBase,
        '& .label, svg': {
          color: theme.colors.neutral.dark20
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '0',
      height: 'fit-content'
    }
  },
  topBevel: {
    '&::before': {
      boxShadow: `10px 10px 5px 100px ${theme.colors.neutral.light20}`
    }
  }
}))
