// All user related database operations can be defined here.
import {
  USER_SEND_DEFAULT_CREDENTIALS,
  SESSION,
  USER_BASE,
  USER_EMAIL,
  USER_GET_BY_ID,
  USER_ME,
  USER_PHONE,
  USER_TOKEN_VALIDATION,
  USER_UPDATE
} from 'services/user/CONSTANTS'
import {
  IApiResponse,
  IUserMeResponse,
  IUserStudentRegisterRequest,
  IUserTeacherRegisterRequest,
  IUserRegisterResponse,
  IUserSessionResponse,
  IUserTokenResponse,
  ISimpleResponse,
  IUserUpdateRequest,
  request,
  IUserSendDefaultCredentials
} from 'services'

/**
* Function to get users by email
*/
export const getUserByEmail = async (email: string): Promise<IApiResponse<IUserRegisterResponse[]>> =>
  await request({ url: USER_EMAIL(email), method: 'get', isLogin: true })

/**
* Function to get users by phone
*/
export const getUserByPhone = async (email: number): Promise<IApiResponse<IUserRegisterResponse[]>> =>
  await request({ url: USER_PHONE(email), method: 'get', isLogin: true })

/**
* Function to get users by userId
*/
export const getUserById = async (userId: number): Promise<IApiResponse<IUserRegisterResponse>> =>
  await request({ url: USER_GET_BY_ID(userId), method: 'get' })

/**
 * Function to fetch the details of the logged in user
 */
export const userMe = async (): Promise<IApiResponse<IUserMeResponse>> =>
  await request({ url: USER_ME() })

/**
 * Function to register a user
 */
export const userRegister = async (user: IUserStudentRegisterRequest | IUserTeacherRegisterRequest): Promise<IApiResponse<IUserRegisterResponse>> =>
  await request({ url: USER_BASE(), method: 'post', data: user, isLogin: true })

/**
* Function to validate token and return profile data
*/
export const userTokenValidation = async (token: string): Promise<IApiResponse<IUserTokenResponse>> =>
  await request({ url: USER_TOKEN_VALIDATION(token), method: 'post', data: token, isLogin: true })

/**
* Function to get the user session data
*/
export const getSession = async (): Promise<IApiResponse<IUserSessionResponse>> =>
  await request({ url: SESSION() })

export const userUpdate = async (userId: number, data: IUserUpdateRequest): Promise<IApiResponse<IUserRegisterResponse>> =>
  await request({ url: USER_UPDATE(userId), method: 'put', data: data })

export const userSendDefaultCredentials = async ({ users, massTransactionId }: IUserSendDefaultCredentials): Promise<IApiResponse<ISimpleResponse>> => {
  const json = {
    users,
    mass_transaction_id: massTransactionId
  }

  return await request({ url: USER_SEND_DEFAULT_CREDENTIALS(), method: 'post', data: json, isLogin: false })
}
