import { useEffect, useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Grid } from 'components/design-system'

interface ISideBarItemProps {
  dataTestId?: string
  href?: string
  icon: React.ReactNode
  label: string
  onClick?: () => void
  selected?: boolean
  target?: boolean
  variant?: 'colorBrand' | 'neutral'
}

type SideBarItemStates = 'idle' | 'path' | 'targetBlank'

export const SideBarItem: React.FC<ISideBarItemProps> = ({ dataTestId, href = '', icon, label, onClick, selected = false, target = false, variant = 'colorBrand' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // states
  const [sideBarItemState, setSideBarItemState] = useState<SideBarItemStates>('idle')

  useEffect(() => {
    setSideBarItemState(target ? 'targetBlank' : 'path')
  }, [])

  const stateMachine = {
    states: {
      idle: null,
      path: (
        <NavLink className={`${classes.root} ${variant} ${selected && 'selected'}`} to={href} onClick={onClick} data-testid={dataTestId ?? ''}>
          <Grid container alignItems='center'>
            <Grid item>
              {icon}
            </Grid>
            <Grid item xs className='label'>
              {t(label)}
            </Grid>
          </Grid>
        </NavLink>
      ),
      targetBlank: (
        <a className={`${classes.root} ${variant} ${selected && 'selected'}`} href={href} onClick={onClick} data-testid={dataTestId ?? ''} target='_blank' rel='noopener noreferrer'>
          <Grid container alignItems='center'>
            <Grid item>
              {icon}
            </Grid>
            <Grid item xs className='label'>
              {t(label)}
            </Grid>
          </Grid>
        </a>
      )
    }
  }

  return stateMachine.states[sideBarItemState]
}
