import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  loadingContainer: {
    background: theme.colors.neutral.light10,
    borderRadius: theme.borderRadius.lg,
    padding: theme.spacingInset.sm,
    gap: theme.spacingInset.xs
  },
  loadingLines: {
    borderRadius: `${theme.borderRadius.md} !important`,
    overflow: 'hidden'
  },
  loadingChart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    '& span': {
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0 !important`
    }
  }
}))
