import { createRef, useState } from 'react'
import { IconButton, useTheme } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

export interface IClipBoardCopyProps {
  copyItem: string
  dataTestId?: string
  className?: string
}

export const ClipBoardCopy: React.FC<IClipBoardCopyProps> = ({ copyItem, dataTestId, className }) => {
  const theme = useTheme()
  const [coping, setCoping] = useState(false)
  const copyButtonRef = createRef<HTMLDivElement>()

  const handleClipBoard = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setCoping(true)
    navigator.clipboard.writeText(copyItem).then(() => console.log('success')).catch(err => console.log(err))
    copyButtonRef?.current?.click()
    setTimeout(() => {
      setCoping(false)
    }, 1000)
  }

  return (
    <IconButton onClick={handleClipBoard} className={className}>
      <div ref={copyButtonRef} data-testid={dataTestId} hidden />
      {!coping
        ? <ContentCopyOutlinedIcon fontSize='small' sx={{ color: theme.colorBrand.medium }} />
        : <CheckCircleOutlineOutlinedIcon fontSize='small' sx={{ color: theme.colors.positive.feedbackMedium }} />
      }
    </IconButton>
  )
}
