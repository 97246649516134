import { EmptyOrErrorMessage, ProfileClassCard, ProfilesListBulkOptions } from 'components/common'
import addFiles from 'assets/add-files.svg'
import { createRef, useCallback, useEffect, useState } from 'react'
import { Grid, Text2, Button, Loading } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import makeStyles from './style'
import { useParams } from 'react-router'
import { useAtom } from 'jotai'
import { atomClassSelectingStudents } from 'pages/BaseScreen/atomStore'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useHistory } from 'react-router-dom'
import { USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { classStudentProfileBulkOptionsTags, getProfileListByClass, ListTypeEnum, UserSchoolProfileTypeEnum } from 'services'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { useSnackbar } from 'navigation/hooks'
import { useStore } from 'store'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { atomClassGradeTypeWithGrades, atomCurrentClass, atomNoFetchData, atomStudentClassSubscriptions } from 'pages/ClassManagement/atomStore'
import { ActionContextEnum, ActionsControlCenter, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { useTheme } from '@mui/material'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PrintableUserList } from 'components/templates'
import { removeIlegalCharacteresFromFileName } from 'utils'

interface IManageClass {
  classId?: string
}

type StudentListStates = 'idle' | 'list' | 'emptyList' | 'loading'

export const ClassStudentList: React.FC = () => {
  const { t } = useTranslation()
  const styles = makeStyles()
  const theme = useTheme()
  const { classId: classIdParam } = useParams<IManageClass>()
  const history = useHistory()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const addNewButtonRef = createRef<HTMLDivElement>()
  const printListButtonRef = createRef<HTMLDivElement>()

  // atoms
  const currentStudentClass = useAtomValue(atomClassSelectingStudents)
  const [reloadList, setReloadList] = useAtom(atomReloadList)
  const [classSubscriptionList, setClassSubscriptionList] = useAtom(atomStudentClassSubscriptions)
  const classGradeTypeWithGrades = useAtomValue(atomClassGradeTypeWithGrades)
  const noFetchData = useAtomValue(atomNoFetchData)
  const resetNoFetchData = useResetAtom(atomNoFetchData)
  const schoolClass = useAtomValue(atomCurrentClass)

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [studentListState, setStudentListState] = useState<StudentListStates>('idle')

  const getProfileList = async (profileType: UserSchoolProfileTypeEnum, schoolId: number | undefined, classId: number) => {
    try {
      if (schoolId && classId) {
        setIsLoading(true)
        setStudentListState('loading')
        const profileCurrentList = await getProfileListByClass(
          profileType,
          schoolId,
          classId,
          'user_school_profile.active:DESC,user_id.name:ASC,user_id.surname:ASC',
          true
        )
        if (profileCurrentList.success) {
          if (!noFetchData || profileCurrentList.data.length) {
            setClassSubscriptionList(profileCurrentList.data.map(p => ({
              ...p,
              checked: false
            })))
          }
          setStudentListState(profileCurrentList.data.length ? 'list' : 'emptyList')
        } else {
          throw new Error('Erro ao tentar carregar estudantes')
        }
      } else {
        throw new Error('Erro ao tentar carregar estudantes')
      }
    } catch (e) {
      setStudentListState('idle')
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      resetNoFetchData()
      setIsLoading(false)
      setReloadList(false)
    }
  }

  const fetchData = async () => await getProfileList(UserSchoolProfileTypeEnum.student, school?.id, classIdParam ? parseInt(classIdParam) : 0)

  useEffect(() => {
    if (isLoading) {
      setStudentListState('loading')
    }
  }, [isLoading])

  useEffect(() => {
    if (classIdParam) setReloadList(true)
    if (classIdParam !== currentStudentClass) clearOrSelectAll(false)
  }, [classIdParam])

  useEffect(() => {
    if (reloadList) fetchData()
  }, [reloadList])

  const onChange = useCallback((classSubscriptionId: number, checked: boolean) => {
    setClassSubscriptionList(prevItems => prevItems.map(classSubscription => {
      const index = prevItems.findIndex(item => item.id === classSubscriptionId)
      if (index > -1) prevItems[index].checked = checked
      return classSubscription
    }))
  }, [])

  const clearOrSelectAll = (checked: boolean) => setClassSubscriptionList(
    classSubscriptionList?.map(classSubscription => ({
      ...classSubscription,
      checked
    }))
  )

  const allListSelected = classSubscriptionList?.every(classSubscription => classSubscription.checked)

  const handleClickAddNewButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    addNewButtonRef?.current?.click()
    const _classIdParam = classIdParam ? `/${classIdParam}` : ''
    history.push(USER_MANAGEMENT(`students/create-options${_classIdParam}`))
  }
  const handleClickPrintListButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    printListButtonRef?.current?.click()
  }

  const stateMachine = {
    states: {
      idle: null,
      list: (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Text2
                fontSize='lg'
                fontWeight='semibold'
                lineHeight='xs'
                mobile='lg'
                customColor={theme.colorBrand.medium}
              >
                {t('Estudantes')}
              </Text2>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.add_students} options={{ fullFeaturesDisabler: !schoolClass?.active }}>
                  <Grid item>
                    <div ref={addNewButtonRef} data-testid='manage_class_student_add students' hidden />
                    <Button onClick={handleClickAddNewButton} startIcon={<AddOutlinedIcon />} variant='ghost'>
                      {t('Adicionar novo')}
                    </Button>
                  </Grid>
                </ActionsControlCenter>
                {schoolClass && (
                  <Grid item>
                    <PDFDownloadLink
                      className={styles.actionButton}
                      document={
                        <PrintableUserList
                          classGradeTypeWithGrades={classGradeTypeWithGrades}
                          currentClass={schoolClass}
                          profile={UserSchoolProfileTypeEnum.student}
                          totalUsers={classSubscriptionList.length}
                          users={classSubscriptionList.map(cs => ({ ...cs.user_id, active: cs.user_school_profile.active }))}
                        />
                      }
                      fileName={`${removeIlegalCharacteresFromFileName(schoolClass.name)} - Estudantes`}
                    >
                      <div ref={printListButtonRef} data-testid='manage_class_student_print_list' hidden />
                      <Button onClick={handleClickPrintListButton} startIcon={<PrintOutlinedIcon />} variant='ghost'>
                        {t('Imprimir listagem')}
                      </Button>
                    </PDFDownloadLink>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <div className={styles.profileList}>
            <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.select_all_students} options={{ fullFeaturesDisabler: !schoolClass?.active }}>
              <ProfilesListBulkOptions
                selectAllListItems={() => clearOrSelectAll(true)}
                clearListItemsSelection={() => clearOrSelectAll(false)}
                tagging={classStudentProfileBulkOptionsTags}
                selectionList={classSubscriptionList.filter(cs => cs.checked).map(cs => ({ ...cs.user_id, active: cs.user_school_profile.active }))}
                hasSelection={!!classSubscriptionList.find(cs => cs.checked)}
                profile={UserSchoolProfileTypeEnum.student}
                listbycontext={ListTypeEnum.classList}
                allListSelected={allListSelected}
              />
            </ActionsControlCenter>
            {classSubscriptionList.map((classSubscription, index) => (
              <ProfileClassCard
                checked={classSubscription.checked}
                classId={classIdParam ?? ''}
                key={index}
                onChange={onChange}
                classSubscription={classSubscription}
              />
            ))}
          </div>
        </>
      ),
      emptyList: (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Text2
                fontSize='lg'
                fontWeight='medium'
                lineHeight='xs'
                mobile='lg'
                customColor={theme.colorBrand.medium}
              >
                {t('Estudantes')}
              </Text2>
            </Grid>
            <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.add_students} options={{ fullFeaturesDisabler: !schoolClass?.active }}>
              <Grid item>
                <div ref={addNewButtonRef} data-testid='manage_class_student_add students' hidden />
                <Button onClick={handleClickAddNewButton} startIcon={<AddOutlinedIcon />} variant='ghost'>
                  {t('Adicionar novo')}
                </Button>
              </Grid>
            </ActionsControlCenter>
          </Grid>
          <ActionsControlCenter
            ctx={ActionContextEnum.schoolClassManager}
            action={SchoolClassManagerActionsEnum.student_list_empty_state}
            fallback={
              <EmptyOrErrorMessage
                message='Não há estudantes matriculados nesta turma.'
                image={addFiles}
              />
            }
          >
            <EmptyOrErrorMessage
              message='Turma criada com sucesso!<br/>Adicione estudantes à turma ou vincule professores à ela.'
              image={addFiles}
            />
          </ActionsControlCenter>
        </>
      ),
      loading: <Loading type='linear' />
    }
  }

  return stateMachine.states[studentListState]
}
