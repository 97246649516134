import { Divider, useMediaQuery, useTheme } from '@mui/material'
import { Grid, Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { useReport } from 'pages/ClassManagement/hooks/useReport'
import { EmptyOrErrorMessage } from 'components/common'
import AvatarWithCrossedArms from 'assets/avatar-with-crossed-arms.svg'
import { IActivityPerformance } from 'services'
import dayjs from 'dayjs'

enum StateMachineEnum {
  EMPTY = 'EMPTY',
  IDLE = 'IDLE',
  MOBILE = 'MOBILE',
  READY = 'READY'
}

interface IDetailedPerformanceActivity {
  activities: IActivityPerformance[]
}

export const DetailedPerformanceActivity: React.FC<IDetailedPerformanceActivity> = ({ activities }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { getDeliveryStatus } = useReport()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    const state = isMobile
      ? StateMachineEnum.MOBILE
      : StateMachineEnum.READY

    setStateMachine(
      activities.length
        ? state
        : StateMachineEnum.EMPTY
    )
  }, [activities, isMobile])

  const states = {
    EMPTY: (
      <EmptyOrErrorMessage
        image={AvatarWithCrossedArms}
        message='Ainda não existem atividades liberadas para a turma nesta unidade. As atividades liberadas aparecerão aqui.'
        reverse
      />
    ),
    IDLE: <></>,
    MOBILE: activities.map((a, index) => (
      <Grid container marginTop={1} rowSpacing={3} key={a.id_activity}>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {dayjs(a.dt_end_activity).format('DD/MM/YYYY')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {a.title}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {getDeliveryStatus(a.activity_submitted_status)}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da atividade')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {a.activity_correction_status}
            </Text2>
          </Grid>
        </Grid>
        {((activities.length - 1) !== index) && (
          <Grid container item xs={12}>
            <Divider className={classes.divider} variant='fullWidth' />
          </Grid>
        )}
      </Grid>
    )),
    READY: (
      <Grid container className={classes.tabPanel} rowSpacing={2}>
        <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
          <Grid item xs={2} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}
            </Text2>
          </Grid>
          <Grid item xs={4} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da atividade')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Divider className={classes.divider} variant='fullWidth' />
        </Grid>
        {activities.map((a, index) => (
          <React.Fragment key={a.id_activity}>
            <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
              <Grid item xs={2} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {dayjs(a.dt_end_activity).format('DD/MM/YYYY')}
                </Text2>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {a.title}
                </Text2>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                {getDeliveryStatus(a.activity_submitted_status)}
              </Grid>
              <Grid item xs={4} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {a.activity_correction_status}
                </Text2>
              </Grid>
            </Grid>
            {((activities.length - 1) !== index) && (
              <Grid container item xs={12}>
                <Divider className={classes.divider} variant='fullWidth' />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
