import { Theme, CircularProgress, LinearProgress, useTheme, CircularProgressProps } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'
import { createStyles, makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme: Theme) => createStyles({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  root: {
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.colorBrand.medium
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.colorBrand.lightest
    }
  }
}))

export interface ILoadingProps extends CircularProgressProps {
  light?: boolean
  size?: number
  type?: 'circular' | 'linear'
}

export const Loading: React.FC<ILoadingProps> = ({ light = false, size = 40, type = 'circular', ...rest }) => {
  const theme = useTheme()
  const classes = useStyle()
  return (
    type === 'circular'
      ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress
            sx={{ color: light ? theme.colorBrand.light : theme.colorBrand.medium }}
            size={size}
            { ...rest }
          />
        </div>
      )
      : (
        <div>
          <LinearProgress className={classes.root} { ...rest }/>
        </div>
      )
  )
}

export default Loading
