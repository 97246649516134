import * as Yup from 'yup'
import { add, differenceInMonths, isAfter, isBefore, isValid, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'

// form validation schema
export const UpdateSchoolPeriodSchema = Yup.object().shape({
  startDate: Yup.string()
    .required('Campo obrigatório')
    .test(
      'invalid-date',
      'Data inválida',
      (value: string | undefined) => isValid(parse(value ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }))
    )
    .test(
      'very-early-date',
      'Data inválida',
      (value: string | undefined) => isAfter(
        parse(value ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }),
        new Date(1901, 1, 1)
      )
    ),
  endDate: Yup.string()
    .required('Campo obrigatório')
    .test(
      'invalid-date',
      'Data inválida',
      (value: string | undefined) => isValid(parse(value ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }))
    )
    .test(
      'very-early-date',
      'Data inválida',
      (value: string | undefined) => isAfter(
        parse(value ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR }),
        new Date(1901, 1, 1)
      )
    ),
  diffDate: Yup.bool().when(['startDate', 'endDate'], {
    is: (startDate: string, endDate: string) => {
      const parsedStartDate = parse(startDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR })
      const parsedEndDate = parse(endDate ?? '', 'dd/MM/yyyy', new Date(), { locale: ptBR })

      const startDateIsEarlierThanEndDate = isBefore(parsedStartDate, parsedEndDate)

      const diffBetweenDates = differenceInMonths(add(parsedEndDate, { days: 1 }), parsedStartDate)

      return !(startDateIsEarlierThanEndDate && diffBetweenDates >= 12)
    },
    then: Yup.bool().required('O período deve ser igual ou superior a 1 ano'),
    otherwise: Yup.bool()
  })
})
