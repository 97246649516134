import { useEffect, useState } from 'react'
import { BigNumberBox, BigNumberBoxSubtitleProps } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ISchoolPeriod, UserSchoolProfileTypeEnum } from 'services'
import { useProfilesAccessedLastWeekBox } from '../hooks'
import { usePill } from 'components/common/Pill/hooks'
import { useClassManagement } from 'navigation/hooks'
import { useStore } from 'store'

export const ProfilesAccessedLastWeekBoxStudent: React.FC = () => {
  const { t } = useTranslation()
  const { getArrowIconByValue, getLimitedValue, getVariantByValue } = usePill()
  const { data, fetchData, error, setReload, loading, hasNoContent } = useProfilesAccessedLastWeekBox(UserSchoolProfileTypeEnum.student)
  const { school } = useStore()
  const { getSchoolPeriods } = useClassManagement()

  // states
  const [subtitles, setSubtitles] = useState<BigNumberBoxSubtitleProps[]>([])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    getSchoolPeriods(school?.id ?? 0).then((sps: ISchoolPeriod[]) => fetchData(school?.id ?? 0, sps.find(sp => sp.current)?.id ?? 0))
  }, [])

  const getSubtitleLabel = () => t(data?.sameAsLastWeek ? 'igual à semana anterior' : 'em relação à semana anterior')

  useEffect(() => {
    setSubtitles([
      data
        ? {
          label: getSubtitleLabel(),
          pill: {
            icon: getArrowIconByValue(data?.percentual),
            isEmpty: data?.sameAsLastWeek,
            isPercentage: true,
            label: getLimitedValue(data?.percentual),
            twoDigitsFormat: false,
            variant: getVariantByValue(data?.percentual)
          }
        }
        : {
          label: getSubtitleLabel(),
          pill: {
            label: undefined
          }
        }
    ])
  }, [data])

  const tryAgainCallback = () => {
    setReload(true)
  }

  return (
    <BigNumberBox
      bigNumber={data?.accessLastWeek}
      loading={loading}
      subtitles={subtitles}
      title={t('Acessos na última semana')}
      tooltipText={t('Os acessos são contabilizados uma vez ao dia por usuários, mesmo que ele faça vários logins diários.')}
      hasError={error}
      hasNoContent={hasNoContent}
      tryAgainCallback={tryAgainCallback}
    />
  )
}
