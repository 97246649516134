import { Button, Card, Grid, Modal, Text2 } from 'components/design-system'
import React from 'react'
import useStyle from './style'
import { useTranslation } from 'react-i18next'
import { IOrderByOption, orderByNameOptions } from 'services'

interface IMobileOrderByModal {
  handleClose: () => void
  handleSelectOrder: (optionValue: string) => void
  open: boolean
  selectedOrderBy: string
}

export const MobileOrderByModal: React.FC<IMobileOrderByModal> = ({ handleClose, handleSelectOrder, open = false, selectedOrderBy }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size='sm'
      className={classes.orderBySelectionModal}
    >
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Text2
            fontSize='md'
            fontWeight='semibold'
            lineHeight='xs'
            neutral='dark30'
            mobile='md'
          >
            {t('Ordenar por')}
          </Text2>
        </Grid>
        <Grid container item rowSpacing={2}>
          {orderByNameOptions?.map((option: IOrderByOption) => (
            <Grid item xs={12}>
              <Card
                key={option.id}
                onClick={() => handleSelectOrder(option.value)}
                extraClasses={classes.optionCard}
                selected={option.value === selectedOrderBy}
                clickable
              >
                {option.label}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} justifyContent='flex-end'>
          <Grid item xs='auto'>
            <Button variant='outlined' onClick={handleClose}>
              {t('cancelar')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
