/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { Checkbox, Grid, Text2 } from 'components/design-system'
import { IUserResponse, ListTypeEnum, UserSchoolProfileTypeEnum } from 'services'
import { useState, useEffect } from 'react'

import { SendCredentialsOptionButton } from './components/SendCredentialsOptionButton'
import { ResetPasswordOptionButton } from './components/ResetPasswordOptionButton'
import { RemoveTeacherFromClassOptionButton } from './components/RemoveTeacherFromClassOptionButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ActivateDeactivateButtons } from '../ActivateDeactivateButtons'

type IStateMachine = 'default' | 'userManagement' | 'classManagement'

interface IAvailableBulkOptions {
  sendCredentialsOptionButton: React.ReactNode
  resetPasswordOptionButton: React.ReactNode
  removeTeacherFromClassOptionButton: React.ReactNode
  activateDeactivateButtons: React.ReactNode
}

interface IElementsTagging {
  selectAll: string
  clearSelection: string
  resetDefaultPassword: string
  sendCredentials: string
  activateProfile: string
  deactivateProfile: string
}
export interface IProfilesListBulkOptionsProps {
  selectAllListItems: () => void
  clearListItemsSelection: () => void
  tagging: IElementsTagging
  selectionList: IUserResponse[]
  hasSelection?: boolean
  allListSelected?: boolean
  profile: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher
  listbycontext: ListTypeEnum
}

export const ProfilesListBulkOptions: React.FC<IProfilesListBulkOptionsProps> = ({
  selectAllListItems,
  clearListItemsSelection,
  tagging,
  selectionList,
  hasSelection,
  allListSelected,
  profile,
  listbycontext
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  // state machine
  const [stateMachine, setStateMachine] = useState<IStateMachine>('default')

  const handleUserManagementSelectAllItems = () => {
    selectAllListItems()
    setStateMachine('userManagement')
  }

  const handleUserManagementClearSelection = () => {
    clearListItemsSelection()
    setStateMachine('default')
  }

  useEffect(() => {
    if (listbycontext === ListTypeEnum.classList) {
      setStateMachine('classManagement')
    }

    if (listbycontext === ListTypeEnum.list && selectionList.length > 0) {
      setStateMachine('userManagement')
    }

    if (listbycontext === ListTypeEnum.list && selectionList.length === 0) {
      setStateMachine('default')
    }
  }, [listbycontext, selectionList])

  const handleClassManagementSelectAllItems = () => {
    if (!hasSelection) return selectAllListItems()

    if (hasSelection && !allListSelected) return clearListItemsSelection()
  }

  const bulkOptions: IAvailableBulkOptions = {
    sendCredentialsOptionButton: (
      <SendCredentialsOptionButton
        recipients={selectionList}
        clearRecipients={clearListItemsSelection}
        profile={profile}
        dataTestId={tagging.sendCredentials}
      />
    ),
    resetPasswordOptionButton: (
      <ResetPasswordOptionButton
        recipients={selectionList}
        clearRecipients={clearListItemsSelection}
        profile={profile}
        dataTestId={tagging.resetDefaultPassword}
      />),
    removeTeacherFromClassOptionButton: (
      <RemoveTeacherFromClassOptionButton
        recipients={selectionList}
        clearRecipients={clearListItemsSelection}
        profile={profile}
      />
    ),
    activateDeactivateButtons: (
      <ActivateDeactivateButtons
        usersbyprofile={selectionList}
        profiletype={profile}
        listbycontext={listbycontext}
        clearSelection={clearListItemsSelection}
        tagging={{
          activate: tagging.activateProfile,
          deactivate: tagging.deactivateProfile
        }}
      />
    )
  }

  const stateMachineStates = {
    states: {
      default: (
        <Grid item>
          <Text2 onClick={handleUserManagementSelectAllItems} fontSize='xxs' fontWeight='semibold' lineHeight='xs' mobile='xxs' colorbrand='medium' cursor='pointer' data-testid={tagging.selectAll}>
            {t('Selecionar todos')}
          </Text2>
        </Grid>
      ),
      userManagement: (
        <>
          <Grid item display='flex' alignItems='center' xs='auto'>
            <Grid item>
              <Text2 onClick={handleUserManagementSelectAllItems} fontSize='xxs' fontWeight='semibold' lineHeight='xs' mobile='xxs' colorbrand='medium' cursor='pointer' data-testid={tagging.selectAll}>
                {t('Selecionar todos')}
              </Text2>
            </Grid>
            <Grid className={styles.separator}><span>|</span></Grid>
            <Grid item>
              <Text2 onClick={handleUserManagementClearSelection} fontSize='xxs' fontWeight='semibold' lineHeight='xs' mobile='xxs' colorbrand='medium' cursor='pointer' data-testid={tagging.clearSelection}>
                {t('Limpar seleção')}
              </Text2>
            </Grid>
          </Grid>
          <Grid container item className={styles.optionsBtns} spacing={4} xs='auto'>
            <Grid item>
              {bulkOptions.sendCredentialsOptionButton}
            </Grid>
            <Grid item>
              {bulkOptions.resetPasswordOptionButton}
            </Grid>
            <Grid item>
              {bulkOptions.activateDeactivateButtons}
            </Grid>
          </Grid>
        </>
      ),
      classManagement: (
        <>
          <Grid item display='flex' alignItems='center' xs='auto' className={styles.selectAllContainer}>
            <Grid item>
              <Text2 fontSize='xxs' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40' colorbrand='medium'>
                {
                  !allListSelected
                    ? <FormControlLabel
                      label={t('Selecionar todos')}
                      className={styles.checkboxLabel}
                      control={
                        <Checkbox
                          label=''
                          onClick={handleClassManagementSelectAllItems}
                          size='medium'
                          checked={hasSelection && !allListSelected}
                          indeterminate={hasSelection && !allListSelected}
                          customClasses={styles.checkboxLabel}
                          defaultChecked={false}
                          dataTestid={tagging.selectAll}
                        />
                      }
                    />
                    : <Checkbox
                      onClick={clearListItemsSelection}
                      size='medium'
                      checked={allListSelected}
                      label={t('Limpar seleção')}
                      customClasses={styles.checkboxLabel}
                      defaultChecked={false}
                      dataTestid={tagging.clearSelection}
                    />
                }
              </Text2>
            </Grid>
          </Grid>
          <Grid container item className={styles.optionsBtns} spacing={4} xs='auto'>
            <Grid item>
              {bulkOptions.sendCredentialsOptionButton}
            </Grid>
            <Grid item>
              {bulkOptions.resetPasswordOptionButton}
            </Grid>
            {
              profile === UserSchoolProfileTypeEnum.teacher &&
              <Grid item>
                {bulkOptions.removeTeacherFromClassOptionButton}
              </Grid>
            }
            <Grid item>
              {bulkOptions.activateDeactivateButtons}
            </Grid>
          </Grid>
        </>
      )
    }
  }

  return (
    <Grid container columnSpacing={4} className={styles.btnContainer}>
      {stateMachineStates.states[stateMachine]}
    </Grid>
  )
}
