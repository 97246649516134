export interface IOrderByOption {
  id: number
  label: string
  value: string
  dataTestId?: string
}

export const orderByOptions: IOrderByOption[] = [
  { id: 1, label: 'Ordem crescente', value: 'asc' },
  { id: 2, label: 'Ordem decrescente', value: 'desc' }
]

export const orderByNameOptions: IOrderByOption[] = [
  { id: 1, label: 'Nome - crescente (A - Z)', value: 'asc' },
  { id: 2, label: 'Nome - decrescente (Z - A)', value: 'desc' }
]

export interface StatusClassOption {
  id: number
  label: string
  value: number
}

export const statusClassOptions: StatusClassOption[] = [
  { id: 1, label: 'Todas as turmas', value: -1 },
  { id: 2, label: 'Turmas ativas', value: 1 },
  { id: 3, label: 'Turmas inativas', value: 0 }
]

export const profileStatusOptions: StatusClassOption[] = [
  { id: 1, value: -1, label: 'Todos' },
  { id: 2, value: 1, label: 'Ativos' },
  { id: 3, value: 0, label: 'Desativados' }
]

export const sentCredentialsOptions: StatusClassOption[] = [
  { id: 1, value: -1, label: 'Todos' },
  { id: 2, value: 1, label: 'Realizado' },
  { id: 3, value: 0, label: 'Pendente' }
]

export const passwordPatternOptions: StatusClassOption[] = [
  { id: 1, value: -1, label: 'Todas' },
  { id: 2, value: 1, label: 'Padrão' },
  { id: 3, value: 0, label: 'Pessoal' }
]

export const orderByReportsOptions: IOrderByOption[] = [
  { id: 1, label: 'Nome do estudante - A a Z', value: 'asc' },
  { id: 2, label: 'Nome do estudante - Z a A', value: 'desc' }
]

export const orderByReportsOptionsWithSituation: IOrderByOption[] = [
  { id: 1, label: 'Situação', value: 'situation' },
  { id: 2, label: 'Nome do estudante - A a Z', value: 'asc' },
  { id: 3, label: 'Nome do estudante - Z a A', value: 'desc' }
]
