import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IFontProps } from './index'

const defineColor = (props: IFontProps, theme: Theme) => {
  if (props.neutral) {
    return theme.colors.neutral[props.neutral]
  }
  if (props.positive) {
    return theme.colors.positive[props.positive]
  }
  if (props.negative) {
    return theme.colors.negative[props.negative]
  }
  if (props.warning) {
    return theme.colors.warning[props.warning]
  }
  if (props.information) {
    return theme.colors.information[props.information]
  }
  if (props.colorbrand) {
    return theme.colorBrand[props.colorbrand]
  }
  if (props.support) {
    return theme.colors.support[props.support]
  }

  if (props.customColor) {
    return props.customColor
  }

  return '#DDD'
}

export default makeStyles((theme: Theme) => createStyles({
  root: (props: IFontProps) => {
    const lineHeight = props.lineHeight ? theme.font.lineHeight[props.lineHeight] : 'normal'
    return {
      '&.MuiTypography-root': {
        fontFamily: theme.font.fontFamily,
        letterSpacing: theme.font.spacing.distant,
        fontSize: theme.font.fontSize[props.fontSize],
        fontWeight: theme.font.fontWeight[props.fontWeight],
        lineHeight: lineHeight,
        color: defineColor(props, theme),
        cursor: props.onClick || props.cursorpointer ? 'pointer' : 'default',
        paddingLeft: props.paddingLeft ? theme.spacingStack[props.paddingLeft] : '0',
        [theme.breakpoints.down('lg')]: {
          fontSize: props.mobile ? theme.font.fontSize[props.mobile] : theme.font.fontSize[props.fontSize],
          lineHeight: props.mobilelineheight ? theme.font.lineHeight[props.mobilelineheight] : lineHeight
        },
        '& .MuiSvgIcon-root': {
          color: props.iconscolor ? '' : defineColor(props, theme),
          display: 'inline-flex',
          verticalAlign: 'middle',
          paddingBottom: '3px'
        }
      }
    }
  }
}))
