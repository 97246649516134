import {
  IApiResponse,
  IClassesAlreadyClonedException,
  IClassSubscriptionRequest,
  IClassSubscriptionResponse,
  IStudentsAlreadyInClassException,
  IEnrollClassesNextYearClassSubscriptionRequest,
  IEnrollStudentNextYearClassSubscriptionRequest,
  request,
  ICheckClassSubscriptionRequest,
  ICheckClassSubscriptionResponse,
  ISubscriptedProfilesResponse,
  UserSchoolProfileTypeEnum,
  IClassSubscriptionInactivateRequest
} from 'services'
import {
  CLASS_SUBCRIPTED_PROFILES,
  CLASS_SUBSCRIPTIONS_BASE,
  CLASS_SUBSCRIPTIONS_BY_PROFILE_CLASS,
  CLASS_SUBSCRIPTIONS_BY_SCHOOL_CLASS_USER,
  CLASS_SUBSCRIPTIONS_BY_USER_TYPE_SCHOOL,
  CLASS_SUBSCRIPTIONS_CHECK,
  CLASS_SUBSCRIPTIONS_DELETE,
  CLASS_SUBSCRIPTIONS_INACTIVATE,
  CLASS_SUBSCRIPTIONS_UPDATE,
  CREATE_STUDENT_NEXT_YEAR_CLASS_SUBSCRIPTION
} from './CONSTANTS'

export const getProfileListByClass = async (profileType: string, schoolId: number, classId: number, sort?: string, active?: boolean): Promise<IApiResponse<IClassSubscriptionResponse[]>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_BY_PROFILE_CLASS(profileType, schoolId, classId, sort, active) })

export const getClassSubscriptionsByUserTypeSchool = async (userId: number, userType: string, schoolId: number, active?: boolean, schoolPeriodId?: number): Promise<IApiResponse<IClassSubscriptionResponse[]>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_BY_USER_TYPE_SCHOOL(userId, userType, schoolId, active, schoolPeriodId) })

export const createClassSubscription = async (classSubscriptionRequest: IClassSubscriptionRequest): Promise<IApiResponse<IClassSubscriptionResponse>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_BASE(), method: 'POST', data: classSubscriptionRequest })

export const getClassSubscriptionBySchoolClassUser = async (schoolId: number, classId: number, userId: number, active?: boolean): Promise<IApiResponse<IClassSubscriptionResponse[]>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_BY_SCHOOL_CLASS_USER(schoolId, classId, userId, active), method: 'GET' })

export const updateClassSubscription = async (classSubscriptionId: number, classSubscriptionRequest: IClassSubscriptionRequest): Promise<IApiResponse<IClassSubscriptionResponse>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_UPDATE(classSubscriptionId), method: 'PUT', data: classSubscriptionRequest })

export const checkClassSubscription = async (data: ICheckClassSubscriptionRequest): Promise<IApiResponse<ICheckClassSubscriptionResponse[]>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_CHECK(), method: 'POST', data: data })

export const deleteClassSubscription = async (classSubscriptionId: number): Promise<IApiResponse<IClassSubscriptionResponse>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_DELETE(classSubscriptionId), method: 'DELETE' })

export const enrollStudentNextYearClassSubscription = async (data: IEnrollStudentNextYearClassSubscriptionRequest): Promise<IApiResponse<IStudentsAlreadyInClassException[] | null>> =>
  await request({ url: CREATE_STUDENT_NEXT_YEAR_CLASS_SUBSCRIPTION(), method: 'POST', data: data })

export const enrollClassesNextYearClassSubscription = async (data: IEnrollClassesNextYearClassSubscriptionRequest): Promise<IApiResponse<IClassesAlreadyClonedException[] | null>> =>
  await request({ url: CREATE_STUDENT_NEXT_YEAR_CLASS_SUBSCRIPTION(), method: 'POST', data: data })

interface IGetClassSubscriptedProfilesParams {
  classId: number
  filterByProfile?: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher
}

export const getClassSubscriptedProfiles = async ({ classId, filterByProfile }: IGetClassSubscriptedProfilesParams): Promise<IApiResponse<ISubscriptedProfilesResponse>> =>
  await request({ url: CLASS_SUBCRIPTED_PROFILES(classId, filterByProfile) })

export const inactivateClassSubscription = async (inactivateClassSubscriptionRequest: IClassSubscriptionInactivateRequest): Promise<IApiResponse<void>> =>
  await request({ url: CLASS_SUBSCRIPTIONS_INACTIVATE(), method: 'PATCH', data: inactivateClassSubscriptionRequest })
