import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IStudentCardInfo } from '.'

export default makeStyles((theme: Theme) => createStyles({
  cardContent: {
    height: '168px',
    padding: (props: IStudentCardInfo) => `${props.noPadding ? '0' : theme.spacingInline.xxs}!important`,
    [theme.breakpoints.down('lg')]: {
      height: '100%'
    }
  },
  studentCard: {
    borderRadius: '16px!important',
    '&.MuiCard-root': {
      border: (props: IStudentCardInfo) => props.noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.12)'
    }
  },
  studentCardContent: {
    height: '100%'
  }
}))
