import { StyleSheet } from '@react-pdf/renderer'

// unit used is pt
export const styles = StyleSheet.create({
  credentialsCard: {
    margin: '24 18 0 0'
  },
  credentialInfo: {
    margin: '2 0'
  }
})
