import { Grid, useTheme } from '@mui/material'
import { CloeTooltip } from 'components/common'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface ICustomInfoTooltip {
  text: string
}

export const CustomInfoTooltip: React.FC<ICustomInfoTooltip> = ({ text }) => {
  const theme = useTheme()
  return (
    <CloeTooltip
      component={
        <Grid
          item
          sx={{
            height: '100%',
            display: 'flex'
          }}
        >
          <InfoOutlinedIcon
            style={{
              alignSelf: 'center',
              color: theme.colorBrand.medium
            }}
          />
        </Grid>
      }
      text={text}
    />
  )
}
