import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalDescription: {
    padding: '0px 32px 32px 32px'
  },
  modalTitle: {
    paddingBottom: '32px',
    textAlign: 'center'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacingSquish.quarck,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column-reverse!important'
    }
  },
  separator: {
    color: theme.colorBrand.dark,
    margin: `0 ${theme.spacingStack.sm}`,
    fontWeight: theme.font.fontWeight.semibold
  },
  optionsBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0!important'
    }
  },
  selectAllContainer: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'start!important'
    }
  },
  checkboxLabel: {
    '&.MuiFormControlLabel-root': {
      margin: 0
    },
    '& .MuiTypography-root': {
      color: `${theme.colorBrand.medium}!important`,
      fontSize: `${theme.font.fontSize.xxs}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      paddingLeft: theme.spacingInline.nano,
      fontFamily: 'inherit'
    }
  }
}))
