export enum ActivateActionsEnum {
  activate = 'activate',
  deactivate = 'deactivate'
}

export enum ClassListEnum {
  origin = 'origin',
  destiny = 'destiny'
}

export enum ListTypeEnum {
  classList = 'classList',
  list = 'list'
}

export enum ManageClassTabs {
  student = 'student',
  teacher = 'teacher',
  reports = 'reports'
}

export enum ModalCategoryEnum {
  activate = 'activate',
  deactivate = 'deactivate',
  warningNoClass = 'warningNoClass',
  warningNoClassPartial = 'warningNoClassPartial'
}

export enum ModalTextTypesEnum {
  title = 'title',
  description = 'description',
  subtitle = 'subtitle',
  subdescription = 'subdescription'
}

export enum UserSchoolProfileTypeEnum {
  admin = 'admin',
  coordinator = 'coordinator',
  student = 'student',
  teacher = 'teacher'
}

export enum EnrollStudentsThrowExceptionsEnum {
  allStudentsAlreadyRegistered = 'allStudentsAlreadyRegistered',
  fewStudentsAlreadyRegistered = 'fewStudentsAlreadyRegistered',
  onlyOneStudentAlreadyRegistered = 'onlyOneStudentAlreadyRegistered'
}

export enum EnrollClassesThrowExceptionsEnum {
  fewClassesAlreadyCloned = 'fewClassesAlreadyCloned',
  allClassesAlreadyCloned = 'allClassesAlreadyCloned',
  skippingGrade = 'skippingGrade',
  cannotBeItinerary = 'cannotBeItinerary'
}
