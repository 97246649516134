import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { getProperProfilePhraseForActivation } from '../../utils'

interface IProfileActivationModalProps {
  open: boolean
  onClose: () => void
  onActivateProfile: () => void
  list?: IUserResponse[]
  profile: UserSchoolProfileTypeEnum
  isLoading: boolean
}

export const ProfileActivationModal = ({ open, onClose, onActivateProfile, list, profile, isLoading }: IProfileActivationModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onActivateProfile}
      confirmButtonBackground='feedbackPositive'
      cancelButtonVariant='outlined'
      confirmButtonText={list ? 'Ativar perfis' : 'Ativar perfil'}
      isLoading={isLoading}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' positive='feedbackMedium'>
            {t('Confirmação de ativação de perfil')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {t(`${getProperProfilePhraseForActivation(profile, list) as string}?`)}
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
