import { atomWithReset, atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { IGrade, IGradeType, IDisciplineResponse, IProgramDataResponse } from 'services'

export const atomProfileTab = atomWithReset<number>(0)
export const atomReloadList = atomWithReset<boolean>(false)

export const atomGradeType = atom<IGradeType | null>(null)
export const atomGrade = atom<IGrade | null>(null)
export const atomCurrentSelectedProgram = atom<IProgramDataResponse | null>(null)
export const atomDiscipline = atom<IDisciplineResponse | null>(null)
export const atomDisciplineProgramList = atom<IDisciplineResponse[] | null>(null)

export const atomSelectingProgramDateCard = atomWithStorage<number | undefined>('atomSelectingProgramDateCard', undefined)
