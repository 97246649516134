import { useMediaQuery, useTheme } from '@mui/material'
import { Grid } from 'components/design-system'
import { useAtomValue } from 'jotai/utils'
import { currentStudentPerformanceAndDataAtom } from 'pages/ClassManagement/atomStore'
import React from 'react'
import { StudentCardInfo, StudentCardInfoWithAvatar } from '..'
import useStyles from './style'

export const StudentCards: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()

  const showTwoCards = useMediaQuery(theme.breakpoints.up('lg'))
  const showTwoCardsFullWidth = useMediaQuery('(max-width:1229px)')

  // atoms
  const currentStudentPerformanceAndData = useAtomValue(currentStudentPerformanceAndDataAtom)

  return (
    <Grid container item columnSpacing={3} rowSpacing={showTwoCardsFullWidth ? 2 : 0} className={classes.studentCardsGrid}>
      {currentStudentPerformanceAndData && (
        <Grid item xs={12} lg={6} xl={showTwoCardsFullWidth ? 12 : 6}>
          <StudentCardInfoWithAvatar studentPerformanceAndData={currentStudentPerformanceAndData} />
        </Grid>
      )}
      {showTwoCards && currentStudentPerformanceAndData && (
        <Grid item xs={12} lg={6} xl={showTwoCardsFullWidth ? 12 : 6}>
          <StudentCardInfo studentPerformanceAndData={currentStudentPerformanceAndData} />
        </Grid>
      )}
    </Grid>
  )
}
