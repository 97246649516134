import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  negativeIcon: {
    color: theme.colors.negative.feedbackMedium,
    '& svg': {
      margin: '8px 4px 0px'
    }
  },
  positiveIcon: {
    color: theme.colors.positive.feedbackMedium,
    '& svg': {
      margin: '8px 4px 0px'
    }
  }
}))
