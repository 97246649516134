import { useEffect, useState } from 'react'
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Select, Text2, Search } from 'components/design-system'

import useStyle from './styles'
import { IStudentsListData } from '../..'
import { KnowMoreModal } from './components'
import { useAtom } from 'jotai'
import { atomOrderByReports, atomSearchTermReports } from 'pages/ClassManagement/atomStore'
import { IOrderByOption, orderByReportsOptions, orderByReportsOptionsWithSituation } from 'services'
import { SelectChangeEvent } from '@mui/material/Select'

interface IReportsListHeaderProps {
  orderByCallback: (sort: string) => void
  searchCallback: (search: string) => void
  studentsListData: IStudentsListData
  tryAgainCallback: () => Promise<void>
}

export const ReportsListHeader: React.FC<IReportsListHeaderProps> = ({ orderByCallback, searchCallback, studentsListData, tryAgainCallback }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // atoms
  const [orderBy, setOrderBy] = useAtom(atomOrderByReports)
  const [, setSearchTerm] = useAtom(atomSearchTermReports)

  // states
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false)
  const [options, setOptions] = useState<IOrderByOption[]>(orderByReportsOptionsWithSituation.map(op => ({ ...op, dataTestId: 'followup_order_by' })))

  const someStudentsHaveAverage = studentsListData.data?.result.some(s => s.performance !== null)

  useEffect(() => {
    if (!someStudentsHaveAverage) {
      setOrderBy('asc')
      setOptions(orderByReportsOptions.map(op => ({ ...op, dataTestId: 'followup_order_by' })))
      orderByCallback('asc')
    }
  }, [])

  const handleSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm)
    searchCallback(searchTerm)
  }

  const handleOrderBy = (e: SelectChangeEvent) => {
    setOrderBy(e.target.value)
    orderByCallback(e.target.value)
  }

  return (
    <>
      <Grid item xs={12}>
        <Text2
          customColor={theme.colorBrand.medium}
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xxs'
          mobile='sm'
        >
          {t('Desempenho por estudante')}
        </Text2>
      </Grid>
      {((!!studentsListData.data?.error && studentsListData.data?.error.length > 0) || !!studentsListData.error) && (
        <Grid item xs={12}>
          <Alert
            action={`${t('Recarregar')}`}
            actionDataTestId='followup_reload'
            content={studentsListData.error ?? t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')}
            onActionClick={tryAgainCallback}
            outlined={true}
            severity='error'
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Text2
          customColor={theme.colors.neutral.dark10}
          fontSize='sm'
          fontWeight='medium'
          lineHeight='xs'
          mobile='sm'
        >
          <div dangerouslySetInnerHTML={{ __html: t('Aqui você encontra o desempenho individual de todos os estudantes da turma. O campo <b>situação</b> indica a necessidade do estudante com base na média individual. A partir dele, é possível definir próximos passos para auxiliar no desempenho de cada um.', { interpolation: { escapeValue: false } }) }} />
        </Text2>
      </Grid>
      <Grid item xs={12}>
        <Button className={classes.knowMoreButton} data-testid='followup_know_more' onClick={() => setOpenKnowMore(true)} size='small'>
          {t('Saiba mais')}
        </Button>
      </Grid>
      <Grid container item direction={isMobile ? 'column-reverse' : 'row'} columnSpacing={3} rowSpacing={2}>
        <Grid item xs={isMobile ? 'auto' : 12} md={9}>
          <Search
            placeholder={t('Pesquisar estudante')}
            onSubmit={handleSearchTerm}
            onClear={() => handleSearchTerm('')}
            dataTestId={{
              submit: 'followup_search_student',
              clear: 'followup_close_search'
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 'auto' : 12} md={3}>
          <Select
            dataTestId='followup_expand_list_order_by'
            id='order-by'
            label={t('Ordenar por')}
            options={options}
            value={orderBy}
            onChange={handleOrderBy}
          />
        </Grid>
      </Grid>

      <KnowMoreModal open={openKnowMore} setOpen={setOpenKnowMore} />
    </>
  )
}
