import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  schoolCardContainer: {
    marginLeft: '-4px',
    marginRight: '-12px!important',
    maxHeight: '350px',
    overflowY: 'scroll',
    padding: `0px ${theme.spacingInline.quarck} ${theme.spacingInline.nano}`,
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.neutral.light20,
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.colors.neutral.light30
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '337px'
    }
  },
  schoolCard: {
    color: theme.colors.neutral.dark30,
    cursor: 'pointer',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: '24px',
    minHeight: '80px'
  },
  searchSchoolByName: {
    margin: `0px ${theme.spacingInline.quarck}`
  },
  schoolSelectionModal: {
    '& .MuiBox-root': {
      left: 'auto',
      maxHeight: '560px',
      right: '100px',
      transform: 'inherit',
      top: '80px',
      '&:focus': {
        outline: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        left: '50%',
        right: 'auto',
        transform: 'translate(-50%, -50%)',
        top: '50%'
      }
    }
  },
  noResultsFound: {
    textAlign: 'center'
  }
}))
