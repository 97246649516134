import { ChangeEvent, KeyboardEvent, MouseEvent, createRef } from 'react'
import {
  Button,
  Grid,
  Pagination,
  Select,
  Text2
} from 'components/design-system'
import { SearchInput } from 'components/common'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import makeStyles from './style'
import { useHistory } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { UserSchoolProfileTypeEnum, orderByNameOptions } from 'services'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import {
  atomOrderBy,
  atomPage,
  atomSearchTerm,
  atomTeacherPropsData
} from '../../atomStore'
import { useTheme } from '@mui/material'
import { LinkedProfilesBox, ProfilesAccessedLastWeekBox, RegisteredProfilesBox } from 'components/modules'
import { AutonomousRegistrationCodeButton } from './components'

interface ITeacherHeader {
  handleSearch: (event: KeyboardEvent<HTMLInputElement>) => void
  handleSearchClick: () => void
  handleSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void
}

export const TeacherHeader: React.FC<ITeacherHeader> = ({ handleSearch, handleSearchClick, handleSearchTerm }) => {
  const { t } = useTranslation()
  const classes = makeStyles()
  const history = useHistory()
  const theme = useTheme()
  const addNewButtonRef = createRef<HTMLDivElement>()
  const profileType = UserSchoolProfileTypeEnum.teacher

  // atoms
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const [page, setPage] = useAtom(atomPage)
  const searchTerm = useAtomValue(atomSearchTerm)
  const teacherPropsData = useAtomValue(atomTeacherPropsData)

  const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => setPage(value)

  const handleOrderBy = (event: SelectChangeEvent) => setOrderBy(event.target.value)

  const handleAddNew = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    addNewButtonRef?.current?.click()
    history.push(PROFILE_TABS('teachers/create-options'))
  }

  return (
    <Grid container className={classes.header}>
      <Grid container item alignItems='center' justifyContent='space-between' rowSpacing={2}>
        <Grid item xs={12} md='auto'>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='lg'
            customColor={theme.colorBrand.medium}
          >
            {t('Professores')}
          </Text2>
        </Grid>
        <Grid container item xs={12} md='auto' alignItems='center' columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md='auto'>
            <div ref={addNewButtonRef} data-testid='manage_teachers_link_to_register' hidden />
            <Button onClick={handleAddNew} size='medium' startIcon={<AddOutlinedIcon />}>
              {t('Cadastrar')}
            </Button>
          </Grid>
          <AutonomousRegistrationCodeButton dataTestId='manage_teachers_generate_autonomous_registration_code' />
        </Grid>
      </Grid>

      <Grid container item marginTop={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <RegisteredProfilesBox selectedProfile={profileType} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <LinkedProfilesBox selectedProfile={profileType} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <ProfilesAccessedLastWeekBox selectedProfile={profileType} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item columnSpacing={3} marginTop={3}>
        <Grid item xs={12} md className={classes.search}>
          <SearchInput
            id='searchTeacher'
            variant='filled'
            label={t('Pesquisar por nome')}
            defaultValue={searchTerm}
            helperText={t('Pressione ENTER ou clique na lupa para pesquisar')}
            helperTextClassName={classes.helperText}
            onChange={handleSearchTerm}
            onClick={handleSearchClick}
            onKeyDown={handleSearch}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} className={classes.orderBy}>
          <Select
            dataTestId='manage_teachers_order_by'
            id='order-by'
            label='Ordenar por'
            options={orderByNameOptions}
            value={orderBy}
            onChange={handleOrderBy}
          />
        </Grid>
        {!!teacherPropsData?.total && (
          <Grid item xs={12} md={12} lg='auto' className={classes.pagination}>
            <Pagination
              count={teacherPropsData?.lastPage}
              page={page}
              size='small'
              onChange={handleChangePage}
              dataTestIdElements={{
                previous: 'manage_teachers_back_previous_page',
                page: 'manage_teachers_page_selection',
                next: 'manage_teachers_advance_next_page'
              }}
            />
          </Grid>
        )}
      </Grid>

      <Grid container item justifyContent='flex-end'>
        <Grid item xs='auto'>
          <Text2 fontSize='xxs' fontWeight='medium' lineHeight='md' mobile='xxs' neutral='light40'>
            {t(
              `{{totalTeachers}} professor${teacherPropsData?.total === 1 ? '' : 'es'}`,
              { totalTeachers: teacherPropsData?.total }
            )}
          </Text2>
        </Grid>
      </Grid>
    </Grid>
  )
}
