import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { Trans, useTranslation } from 'react-i18next'

interface IRemoveTeacherFromClassModalProps {
  open: boolean
  onClose: () => void
  onRemoveTeacherFromClass: () => void
  isLoading: boolean
}
export const RemoveTeacherFromClassModal = ({ open, onClose, onRemoveTeacherFromClass, isLoading }: IRemoveTeacherFromClassModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onRemoveTeacherFromClass}
      confirmButtonText='Remover'
      isLoading={isLoading}
      cancelButtonDataTestid='confirm_teachers_removal_cancel'
      confirmButtonDataTestid='confirm_teachers_removal_remove'
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='md' neutral='darkBase'>
            {t('Confirmação de remoção de professores(as)')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark20'>
            <Trans>Você tem certeza de que deseja remover os(as) professores(as) selecionados(as)? <br /></Trans>
            <Trans>Eles perderão o acesso a turma</Trans>
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
