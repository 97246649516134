import { StyleSheet } from '@react-pdf/renderer'

// unit used is pt
export const styles = StyleSheet.create({
  cloeLogo: {
    height: 30.8,
    padding: 1, // used to prevent image cropping
    width: 108
  },
  header: {
    marginBottom: 24
  }
})
