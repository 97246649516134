import MUiIconButton from '@mui/material/IconButton'
import { IconButtonProps } from '@mui/material'
import makeStyles, { ripple } from './style'

export interface IIconButtonProps extends Omit<IconButtonProps, 'variant'> {
  variant?: 'primary' | 'outlined' | 'ghost' | 'link' | 'disabled' | 'feedbackNegative' | 'feedbackPositive' | 'feedbackWarning' | 'feedbackInformation'
}

export const IconButton: React.FC<IIconButtonProps> = ({ variant = 'primary', children, ...props }) => {
  const classes = makeStyles()

  return (
    <MUiIconButton
      {...props}
      className={`${props.className as string} ${classes.root} ${variant}`}
      TouchRippleProps={{ classes: ripple[variant]() }}
    >
      {children}
    </MUiIconButton>
  )
}
