import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from 'components/design-system'
import { ReportsTable, SearchTermNotFound } from '../'
import { AuxStateMachineEnum, IStudentsListData } from '../..'
import { atomSearchTermReports } from 'pages/ClassManagement/atomStore'
import { useAtomValue } from 'jotai/utils'

interface IReportsListReadyProps {
  auxState: AuxStateMachineEnum
  loadMore: () => Promise<void>
  studentsListData: IStudentsListData
}

export const ReportsListReady: React.FC<IReportsListReadyProps> = ({ auxState, loadMore, studentsListData }) => {
  const { t } = useTranslation()

  const searchTerm = useAtomValue(atomSearchTermReports)

  const LoadMoreButton = () => (
    <Button
      data-testid='followup_load_more'
      onClick={loadMore}
      sx={{ margin: '0 auto' }}
      variant='outlined'
    >
      {t('Carregar mais...')}
    </Button>
  )

  const NotFoundAlert = () => (
    <Alert
      content={t('No momento não há alunos para exibir.')}
      severity='info'
      style={{ marginTop: '20px', width: '100%' }}
    />
  )

  const renderAuxStates = {
    IDLE: <></>,
    NOT_FOUND: <NotFoundAlert />,
    SEARCH_NOT_FOUND: <SearchTermNotFound term={searchTerm} />,
    READY_TO_LOAD_MORE: <LoadMoreButton />
  }

  return (
    <>
      <Grid item xs={12}>
        {studentsListData.error
          ? <ReportsTable.Error />
          : <ReportsTable.Item studentsListData={studentsListData.data} />
        }
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <Grid item>
          {renderAuxStates[auxState]}
        </Grid>
      </Grid>
    </>
  )
}
