import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  nonEmbeddedResource: {
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light30}`,
    margin: '0 auto',
    maxWidth: '607px',
    padding: theme.spacingInline.xxxs
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& span': {
      marginLeft: theme.spacingInline.quarck
    }
  },
  title: {
    margin: `${theme.spacingInset.xxs} 0`
  },
  description: {
    fontSize: theme.font.fontSize.xs,
    '& p': {
      margin: `${theme.spacingInline.nano} 0`
    }
  },
  source: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.bold
  }
}))
