import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const cloeOverflow = 25
const worm1Overflow = 35
const worm2Overflow = 35

// const defaultDesktopBannerHeight = '130px'

export default makeStyles((theme: Theme) => createStyles({
  quickAccessContainer: {
    position: 'relative',
    background: 'linear-gradient(92.2deg, #499ACE -4.69%, #4B61AA 58.54%)',
    borderRadius: theme.borderRadius.md,
    padding: theme.spacingInline.xxxs,
    margin: `0 0 ${theme.spacingInline.md} 0`,
    flexDirection: 'column !important' as 'column',
    color: theme.colors.neutral.lightBase,

    [theme.breakpoints.up('md')]: {
      height: '157px',
      flexDirection: 'row !important' as 'row'
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`
    }
  },
  interactiveSection: {
    flexDirection: 'column !important' as 'column',
    gap: theme.spacingInline.nano
  },
  contentSeparator: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1,
    zIndex: 10
  },
  wormsContainer: {
    zIndex: 0
  },
  cloeAvatar: {
    position: 'relative',
    marginLeft: '1px',
    marginRight: theme.spacingInline.xxxs,
    height: `calc(${theme.spacingInline.xs} + ${cloeOverflow + 100}%)`,

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      top: `calc(-${theme.spacingInline.xxs})`
    },
    [theme.breakpoints.down('md')]: {
      height: '128px',
      marginRight: theme.spacingInline.nano,
      marginBottom: `-${theme.spacingInline.xs}`
    }
  },
  cloeWorm1: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.nano} - ${theme.spacingInline.xs})`,
    height: `calc(${theme.spacingInline.xs} + 184px)`,
    right: `calc(${theme.spacingInline.xxxs} + 350px)`,
    zIndex: 0
  },
  cloeWorm2: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.nano}  - ${theme.spacingInline.xs})`,
    height: `calc(${theme.spacingInline.lg} + ${worm2Overflow + 100}%)`,
    right: `calc(-${theme.spacingInline.xs})`,
    zIndex: 0
  },
  // cloeWorm1: {
  //   position: 'absolute',
  //   top: `calc(-${theme.spacingInline.nano} - ${defaultDesktopBannerHeight} - ${theme.spacingInline.xs})`,
  //   height: `calc(${theme.spacingInline.xs} + 184px)`,
  //   right: '50%',
  //   zIndex: 0
  // },
  // cloeWorm2: {
  //   position: 'absolute',
  //   top: `calc(-${theme.spacingInline.nano} - ${defaultDesktopBannerHeight} - ${theme.spacingInline.xs})`,
  //   height: `calc(${theme.spacingInline.xs} + 220px)`,
  //   right: `calc(-${theme.spacingInline.md})`,
  //   zIndex: 0
  // },
  cloeWorm3: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.xxxs} - ${worm1Overflow / 4}%)`,
    height: `calc(${theme.spacingInline.xs} + ${worm1Overflow + 20}%)`,
    right: `calc(-${theme.spacingInline.xxxs} - 2%)`,
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      height: `calc(${theme.spacingInline.xs} + ${175}%)`,
      right: `-${theme.spacingInline.xxs}`,
      top: `calc(-${theme.spacingInline.xxxs} - ${60}%)`
    }
  },
  hideButton: {
    display: 'flex',
    cursor: 'pointer !important',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  linksContainerHeader: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    zIndex: 2,
    width: '100%'
  },
  linksContainer: {
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacingInline.xxxs,
    flexDirection: 'column !important' as 'column'
  },
  welcomeSection: {
    position: 'relative',
    zIndex: 2,

    [theme.breakpoints.up('md')]: {
      maxWidth: '36%'
    }
  },
  welcomeTextContainer: {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  welcomeText: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word'
  },
  welcomeSubtitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word'
  },
  bannerAlertButton: {
    background: `${theme.colors.neutral.lightBase}!important`,
    '& svg': {
      marginRight: '4px'
    }
  },
  tutorialLink: {
    textDecoration: 'underline',
    color: theme.colors.neutral.lightBase
  },
  bottomText: {
    display: 'flex',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    gap: theme.spacingInline.nano
  }
}))
