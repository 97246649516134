import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiContainer-root': {
      position: 'absolute',
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} ${theme.borderRadius.none} ${theme.borderRadius.none}`,
      backgroundColor: theme.colors.neutral.lightBase,
      width: '100%',
      minHeight: '100vh',
      padding: `${theme.spacingInline.xxs} ${theme.spacingInline.xxs} ${theme.spacingInline.lg} ${theme.spacingInline.xxs}`,
      boxShadow: theme.shadowLevels.level5,
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.borderRadius.none,
        boxShadow: 'none',
        left: '0',
        padding: '16px'
      }
    }
  }
}))
