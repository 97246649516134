import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { IMessageErrors, IUserResponse, IUserSendDefaultCredentials, userSendDefaultCredentials } from 'services'

type Errors = string | Error | IMessageErrors[]

export const useUserSendDefaultCredentials = () => {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()

  // states
  const [credentialsSentError, setCredentialsSentError] = useState<Errors>('')
  const [enableSendCredentialsButton, setEnableSendCredentialsButton] = useState<boolean>(false)

  const verifyConditionsToActivateSendCredentialsButton = (users: IUserResponse[]) => {
    const inactiveUsers = users.every(user => user?.profiles?.every(prof => !prof.active))
    if (inactiveUsers) return setEnableSendCredentialsButton(true)

    const sentCredentialsPendingUsers = users.every(user => !user.sentDefaultCredentials && user.isDefaultPass)
    if (sentCredentialsPendingUsers) return setEnableSendCredentialsButton(false)

    const usersWithoutDefaultPassword = users.every(user => !user.sentDefaultCredentials && !user.isDefaultPass)
    if (usersWithoutDefaultPassword) return setEnableSendCredentialsButton(true)

    if (!sentCredentialsPendingUsers) {
      const sentCredentialsUsers = users.every(user => user.sentDefaultCredentials && !user.isDefaultPass)
      if (sentCredentialsUsers) return setEnableSendCredentialsButton(true)
      else return setEnableSendCredentialsButton(false)
    }
  }

  const sendCredentials = async (requestData: IUserSendDefaultCredentials) => {
    try {
      const response = await userSendDefaultCredentials(requestData)
      setCredentialsSentError(response?.errors ?? '')
      if (response.success) {
        createSnackbar({ content: 'Credenciais enviadas com sucesso', severity: 'success' })
      } else {
        throw new Error(t('Erro ao enviar as credenciais'))
      }
    } catch (e) {
      if (e instanceof Error) {
        setCredentialsSentError(e)
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  return {
    credentialsSentError,
    enableSendCredentialsButton,
    sendCredentials,
    verifyConditionsToActivateSendCredentialsButton
  }
}
