import { UserSchoolProfileTypeEnum } from 'services/enums'

export const USER_SCHOOL_PROFILE_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/user-school-profiles`

export const USER_SCHOOL_PROFILE_V1_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/v1/user-school-profiles`

export const USER_SCHOOL_PROFILE_GET = (schoolId: number, type: string) => `${USER_SCHOOL_PROFILE_BASE()}?school.id=${schoolId}&type=${type}`

export const USER_SCHOOL_PROFILE_GET_ENRICHED = (schoolId: number, type: string, page = 0, sort = 'asc', search?: string, profileStatusFilter?: number, sentCredentialsFilter?: number, passwordPatternFilter?: number) => {
  let baseUrl = `${USER_SCHOOL_PROFILE_BASE()}/enriched/school/${schoolId}/type/${type}`

  if (search) baseUrl += `/${search.toLowerCase()}`

  const url = new URL(baseUrl)

  url.searchParams.append('sort', sort)
  url.searchParams.append('page', String(page))
  url.searchParams.append('size', '30')

  if (profileStatusFilter === 0 || profileStatusFilter === 1) url.searchParams.append('profileStatus', profileStatusFilter.toString())
  if (sentCredentialsFilter === 0 || sentCredentialsFilter === 1) url.searchParams.append('sentCredentials', sentCredentialsFilter.toString())
  if (passwordPatternFilter === 0 || passwordPatternFilter === 1) url.searchParams.append('passwordPattern', passwordPatternFilter.toString())

  return url.toString()
}

export const USER_SCHOOL_PROFILE_UPDATE = (userSchoolProfileId: number) => `${USER_SCHOOL_PROFILE_BASE()}/${userSchoolProfileId}`

export const GET_BLOCKED_USER_SCHOOL_PROFILE_CONFIRMATION = (userSchoolProfileId: number) => `${USER_SCHOOL_PROFILE_BASE()}/blocked-user/${userSchoolProfileId}`

export const USER_SCHOOL_PROFILE_BY_SCHOOL_USER = (schoolId: number, type: string, userId: number, active?: boolean) => {
  const baseUrl = `${USER_SCHOOL_PROFILE_GET(schoolId, type)}&user.id=${userId}`

  const url = new URL(baseUrl)

  if (active) url.searchParams.append('active', active.toString())

  return url.toString()
}

export const USER_SCHOOL_PROFILE_CHECK_CLASS_SUBSCRIPTIONS = () => `${USER_SCHOOL_PROFILE_BASE()}/checkClassSubscription`

export const UPDATE_LAST_USED_PROFILE = () => `${USER_SCHOOL_PROFILE_BASE()}/updateLastUsedProfile`

export const CREATE_OR_MANAGE_USER_PROFILE_ACTIVE = () => `${USER_SCHOOL_PROFILE_BASE()}/manageUserProfile`

export const USER_SCHOOL_PROFILE_GET_ALL_BY_SCHOOL_AND_USER = (schoolId: number, userId: number) => `${USER_SCHOOL_PROFILE_BASE()}?school.id=${schoolId}&user.id=${userId}`

export const USER_SCHOOL_PROFILE_REGISTERED_PROFILES_BOX = (schoolId: number, type: UserSchoolProfileTypeEnum) => `${USER_SCHOOL_PROFILE_V1_BASE()}/profiles/counter/school/${schoolId}/type/${type}`

export const USER_SCHOOL_PROFILE_PROFILES_ACCESSED_LAST_WEEK_BOX = (schoolId: number, schoolPeriodId: number, type: UserSchoolProfileTypeEnum) =>
  `${process.env.REACT_APP_SERVICES_URL as string}/data-integration/school/${schoolId}/school-period/${schoolPeriodId}/access-history?profileType=${type}`
