import { Grid } from 'components/design-system'
import useStyles from './style'

export interface IDoubleColumnContainerProps {
  rounded?: boolean
}

export const DoubleColumnContainer: React.FC<IDoubleColumnContainerProps> = ({ children, ...props }) => {
  const styles = useStyles(props)
  return (
    <Grid container className={styles.container}>
      {children}
    </Grid>
  )
}

export const LeftColumnContent: React.FC = ({ children, ...props }) => {
  const styles = useStyles(props)
  return (
    <Grid item lg={4} sm={6} md={4} className={styles.leftColumnContent}>
      {children}
    </Grid>
  )
}

export const RightColumnContent: React.FC = ({ children, ...props }) => {
  const styles = useStyles(props)
  return (
    <Grid item lg={8} sm={6} md={8} className={styles.rightColumnContent}>
      {children}
    </Grid>
  )
}
