import MUiToggleButton from '@mui/material/ToggleButton'
import { ToggleButtonProps } from '@mui/material'
import makeStyles from './style'

export interface IToggleButtonProps extends Omit<ToggleButtonProps, 'variant'> {
  variant?: 'primary'
  icon?: string
  tabRole?: boolean
}

export const ToggleButton: React.FC<IToggleButtonProps> = ({ variant = 'primary', tabRole = false, children, ...props }) => {
  const classes = makeStyles()

  return (
    <MUiToggleButton
      {...props}
      className={`${props.className as string} ${classes.root} ${variant} ${tabRole ? 'tabrole' : ''}`}
    >
      {children}
    </MUiToggleButton>
  )
}
