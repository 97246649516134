import MUIDatePicker, { DatePickerProps } from '@mui/lab/DatePicker'
import DateAdapter from '@mui/lab/AdapterDateFns'
import MUILocalizationProvider from '@mui/lab/LocalizationProvider'
import { ptBR } from 'date-fns/locale'
import { useState } from 'react'
import makeStyles from './style'
import { format } from 'date-fns'

interface IDatePickerProps extends Omit<DatePickerProps, 'onChange' | 'value' | 'renderInput'> {
  maxDate?: Date
  minDate?: Date
  onChange: (date: string) => void
  initialValue?: Date
}
export const DatePicker: React.FC<IDatePickerProps> = ({ ...props }) => {
  const [value, setValue] = useState<Date | null>(null)
  const styles = makeStyles()

  const handleOnChange = (newValue: Date) => {
    const formattedDate = format(newValue, 'dd/MM/yyyy', { locale: ptBR })
    setValue(newValue)
    props.onChange(formattedDate)
  }

  return (
    <MUILocalizationProvider dateAdapter={DateAdapter} locale={ptBR}>
      <MUIDatePicker
        { ...props }
        value={props.initialValue ?? value as Date}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={(newValue) => handleOnChange(newValue as Date)}
        renderInput={(params) => (
          <span
            className={styles.root}
            ref={params.inputRef}>
            {params?.InputProps?.endAdornment}
          </span>
        )}
      />
    </MUILocalizationProvider>
  )
}
