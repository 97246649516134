import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  titleContainer: {
    marginBlock: theme.spacingInline.nano,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.xs,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.sm
    },
    '& > p': {
      margin: 0
    }
  },
  titleDrop: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    display: 'flex',
    '& > p': {
      margin: 0
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tagsContainer: {
    display: 'flex',
    fontSize: theme.font.fontSize.sm,
    flexFlow: 'inherit',
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  tagSelected: {
    color: theme.colors.neutral.lightBase
  }
}))
