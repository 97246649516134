import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { getProperProfilePhrase } from '../../utils'

interface IResetDefaultPasswordModalProps {
  open: boolean
  onClose: () => void
  onResetPassword: () => void
  list?: IUserResponse[]
  profile: UserSchoolProfileTypeEnum
  isLoading: boolean
}
export const ResetDefaultPasswordModal = ({ open, onClose, onResetPassword, list, profile, isLoading }: IResetDefaultPasswordModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onResetPassword}
      confirmButtonText='Restaurar'
      isLoading={isLoading}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
            {t('Confirmação de restauração de senha')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {
              list?.length
                ? t(`Você tem certeza que deseja restaurar as senhas pessoais dos ${getProperProfilePhrase(profile, list) as string} selecionados para a senha padrão da escola?`)
                : t(`Você tem certeza que deseja restaurar a senha pessoal do ${getProperProfilePhrase(profile, list) as string} para a senha padrão da escola?`)
            }
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {t('Após a confirmação, a senha pessoal se tornará inválida.')}
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
