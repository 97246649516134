import { UserSchoolProfileTypeEnum } from 'services/enums'
import { convertArrToGETParams } from 'utils/url'

export const CLASS_SUBSCRIPTIONS_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/class-subscriptions`

export const SUBSCRIPTIONS_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/subscriptions`

export const CLASS_SUBSCRIPTIONS_BY_PROFILE_CLASS = (profileType: string, schoolId: number, classId: number, sort?: string, active?: boolean) => {
  const baseUrl = `${CLASS_SUBSCRIPTIONS_BASE()}?user_school_profile.type=${profileType}&user_school_profile.school=${schoolId}&class_id=${classId}`
  const url = new URL(baseUrl)

  if (sort) url.searchParams.append('_sort', sort)
  if (active) url.searchParams.append('active', active.toString())

  return url.toString()
}

export const CLASS_SUBSCRIPTIONS_UPDATE = (classSubscriptionId: number) => `${CLASS_SUBSCRIPTIONS_BASE()}/${classSubscriptionId}`

export const CLASS_SUBSCRIPTIONS_INACTIVATE = () => `${CLASS_SUBSCRIPTIONS_BASE()}/manage`

export const CLASS_SUBSCRIPTIONS_BY_USER_TYPE_SCHOOL = (userId: number, userType: string, schoolId: number, active?: boolean, schoolPeriodId?: number) => {
  const baseUrl = `${CLASS_SUBSCRIPTIONS_BASE()}?user_school_profile.user=${userId}&user_school_profile.type=${userType}&user_school_profile.school=${schoolId}`
  const url = new URL(baseUrl)

  if (active) url.searchParams.append('active', active.toString())
  if (schoolPeriodId) url.searchParams.append('class_id.school_period', schoolPeriodId.toString())

  return url.toString()
}

export const CLASS_SUBSCRIPTIONS_BY_SCHOOL_CLASS_USER = (schoolId: number, classId: number, userId: number, active?: boolean) => {
  const baseUrl = `${CLASS_SUBSCRIPTIONS_BASE()}?class_id.school.id=${schoolId}&class_id.id=${classId}&user_id.id=${userId}`
  const url = new URL(baseUrl)

  if (active) url.searchParams.append('active', active.toString())

  return url.toString()
}

export const CLASS_SUBSCRIPTIONS_CHECK = () => `${SUBSCRIPTIONS_BASE()}/class_subscription_check`

export const CLASS_SUBSCRIPTIONS_DELETE = (classSubscriptionId: number) => `${CLASS_SUBSCRIPTIONS_BASE()}/${classSubscriptionId}`

export const CREATE_STUDENT_NEXT_YEAR_CLASS_SUBSCRIPTION = () => `${SUBSCRIPTIONS_BASE()}/class_subscription_management`

export const CLASS_SUBCRIPTED_PROFILES = (classId: number, filterByProfile?: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/class-subscriptions/class/${classId}`
  const paramsObj = { filter_by_profile_type: filterByProfile }

  return convertArrToGETParams({ rawURL, paramsObj })
}
