import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  accordion: {
    '&.MuiAccordion-root': {
      backgroundColor: theme.colors.neutral.lightBase,
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: `${theme.spacingInline.nano}!important`,
      boxShadow: 'none',
      '&.selected': {
        backgroundColor: 'rgba(233, 243, 249, 1)',
        border: `2px solid ${theme.colorBrand.medium}`
      }
    },
    // to fix divider visualization before expand
    '&.MuiAccordion-root:before': {
      height: '0px'
    }
  },
  accordionSummaryGrid: {
    padding: '8px'
  },
  divider: {
    marginBottom: '16px!important'
  },
  expandMoreIcon: {
    color: theme.colorBrand.medium
  },
  checkboxOrigin: {
    '& .MuiCheckbox-root': {
      display: 'flex'
    },
    '& .MuiTypography-root': {
      color: `${theme.colorBrand.medium}!important`,
      fontSize: `${theme.font.fontSize.sm}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      paddingLeft: theme.spacingInline.nano
    }
  },
  radioDestinyLabel: {
    '& .MuiTypography-root': {
      color: `${theme.colorBrand.medium}!important`,
      fontSize: `${theme.font.fontSize.sm}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      paddingLeft: theme.spacingInline.nano,
      fontFamily: 'inherit'
    }
  },
  studentClassNameInput: {
    width: '100%'
  }
}))
