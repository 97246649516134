import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacingInline.xxs
    },
    gap: theme.spacingInline.xs,
    height: 'fit-content'
  }
}))
