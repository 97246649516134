import { PROGRAM_SCHOOL_DELETE_CONTENT_UNIT, PROGRAM_SCHOOL_ACTIVATE_CONTENT_UNIT } from './CONSTANTS'
import { IApiResponse, request, IProgramSetupResponse } from 'services'

export const deleteProgramSetup = async (programSetupId: number): Promise<IApiResponse<IProgramSetupResponse>> =>
  await request({ url: PROGRAM_SCHOOL_DELETE_CONTENT_UNIT(programSetupId), method: 'DELETE' })

export const activateProgramContentUnit = async (gradeTypeId: number, gradeId: number, schoolId: number, setupId: number): Promise<IApiResponse<IProgramSetupResponse>> => {
  const baseUrl = PROGRAM_SCHOOL_ACTIVATE_CONTENT_UNIT()
  const url = new URL(baseUrl)

  const requestBody = {
    gradeTypeId,
    gradeId,
    schoolId,
    setupId
  }

  return await request({ url: url.toString(), method: 'POST', data: requestBody })
}
