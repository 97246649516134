import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    imageContainer: {
      '& img': {
        marginTop: '150px',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          marginTop: '0px'
        }
      }
    }
  }
})
