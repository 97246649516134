import { UserSchoolProfileTypeEnum } from 'services/enums'

export interface IProfileCardTagging {
  SELECT_TAG?: string
  EDIT_TAG?: string
  BIRTH_DATE_TAG?: string
  PHONE_TAG?: string
  EMAIL_TAG?: string
  USERNAME_TAG?: string
  ACTIVATE_PROFILE?: string
  DEACTIVATE_PROFILE?: string
}
export interface IProfileBulkOptionsTags {
  selectAll: string
  clearSelection: string
  resetDefaultPassword: string
  sendCredentials: string
  activateProfile: string
  deactivateProfile: string
}

export type ITag = {
  [key in UserSchoolProfileTypeEnum]: IProfileCardTagging | undefined
}

export const profileClassCardTags: ITag = {
  student: {
    SELECT_TAG: 'manage_class_student_select_student',
    EDIT_TAG: 'manage_class_student_edit_profile',
    BIRTH_DATE_TAG: 'manage_class_student_copy_date_of_birth',
    EMAIL_TAG: 'manage_class_student_copy_responsible_email',
    PHONE_TAG: 'manage_class_student_copy_responsible_phone',
    USERNAME_TAG: 'manage_class_student_copy_username',
    ACTIVATE_PROFILE: 'manage_class_student_active_profile',
    DEACTIVATE_PROFILE: 'manage_class_student_disable_profile'
  },
  teacher: {
    SELECT_TAG: 'manage_class_teachers_select_teacher',
    EDIT_TAG: 'manage_class_teachers_edit_profile',
    BIRTH_DATE_TAG: 'manage_class_teachers_copy_date_of_birth',
    PHONE_TAG: 'manage_class_teachers_copy_phone',
    EMAIL_TAG: 'manage_class_teachers_copy_email',
    USERNAME_TAG: 'manage_class_teachers_copy_username',
    ACTIVATE_PROFILE: 'manage_class_teachers_active_profile',
    DEACTIVATE_PROFILE: 'manage_class_teachers_disable_profile'
  },
  admin: undefined,
  coordinator: undefined
}

// student tags
export const studentTags: IProfileCardTagging = {
  SELECT_TAG: 'menu_studentes_list_students_select_user',
  EDIT_TAG: 'menu_studentes_list_students_student_edit_profile',
  BIRTH_DATE_TAG: 'menu_studentes_list_students_copy_date_of_birth',
  EMAIL_TAG: 'menu_studentes_list_students_copy_e-mail',
  PHONE_TAG: 'menu_studentes_list_students_copy_phone',
  USERNAME_TAG: 'menu_studentes_list_students_copy_username'
}

export const classStudentProfileBulkOptionsTags: IProfileBulkOptionsTags = {
  selectAll: 'manage_class_student_select_all_studentes',
  clearSelection: 'manage_class_student_clear_selection',
  activateProfile: 'manage_class_student_active_profile_students',
  deactivateProfile: 'manage_class_student_disable_profile_students',
  sendCredentials: 'manage_class_student_send_credentials',
  resetDefaultPassword: 'manage_class_student_recovery_password'
}

export const studentListProfileBulkOptionsTags: IProfileBulkOptionsTags = {
  selectAll: 'menu_studentes_list_students_select_all_users',
  clearSelection: 'menu_studentes_list_students_clear_user_selection',
  activateProfile: '',
  deactivateProfile: '',
  sendCredentials: '',
  resetDefaultPassword: ''
}

// teacher tags
export const teacherTags: IProfileCardTagging = {
  SELECT_TAG: 'manage_teacher_select_user',
  EDIT_TAG: 'manage_teacher_edit_profile',
  BIRTH_DATE_TAG: 'manage_teacher_copy_date_of_birth',
  PHONE_TAG: 'manage_teacher_copy_phone',
  EMAIL_TAG: 'manage_teacher_copy_email',
  USERNAME_TAG: 'manage_teacher_copy_username'
}

export const classTeacherProfileBulkOptionsTags: IProfileBulkOptionsTags = {
  selectAll: 'manage_class_teachers_select_all_teachers',
  clearSelection: 'manage_class_teachers_clear_selection',
  activateProfile: 'manage_class_teachers_active_profile_teachers',
  deactivateProfile: 'manage_class_teachers_disable_profile_teachers',
  sendCredentials: 'manage_class_teachers_send_credentials',
  resetDefaultPassword: 'manage_class_teachers_recovery_password'
}

export const teacherListProfileBulkOptionsTags: IProfileBulkOptionsTags = {
  selectAll: 'manage_teacher_select_all_users',
  clearSelection: 'manage_teacher_clear_user_selection',
  activateProfile: '',
  deactivateProfile: '',
  sendCredentials: '',
  resetDefaultPassword: ''
}
