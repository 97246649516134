import MUICard from '@mui/material/Card'
import MUICardContent from '@mui/material/CardContent'
import MUICardMedia from '@mui/material/CardMedia'
import makeStyles from './style'
import { Tag } from 'components/design-system'
import { DisciplineCodeEnum } from 'services'

export interface IContentfulCardProps {
  additionalClasses?: string
  image: {
    src: string
    alt: string
    height: string
    disabled?: boolean
  }
  tag?: {
    label: string | undefined
    discipline?: DisciplineCodeEnum
  }
}

export const ContentfulCard: React.FC<IContentfulCardProps> = ({ children, ...props }) => {
  const styles = makeStyles()
  const disabled = props.image.disabled ? styles.cardMediaRootDisabled : ''

  return (
    <MUICard className={`${styles.root} ${props.additionalClasses ?? ''}`} {...props}>
      <MUICardMedia className={`${styles.cardMediaRoot} ${disabled}`}
        component='img'
        image={props.image.src}
        alt={props.image.alt}
        height={props.image.height}
      />
      {
        props.tag &&
          <Tag
            active
            className={`${styles.tag}`}
            discipline={props.tag.discipline}
            label={props.tag.label}
          />
      }
      <MUICardContent>
        { children }
      </MUICardContent>
    </MUICard>
  )
}
