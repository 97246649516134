/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alert, Text2 } from 'components/design-system'

import useStyle from './styles'
import { BigNumber } from '../'
import { IBigNumbersData } from '../..'
import { TeacherCard } from './components'
import { IDiscipline, ISubscriptedTeacherProfile } from 'services'

interface IBigNumbersReady {
  bigNumbersData: IBigNumbersData
  tryAgainCallback: () => Promise<void>
  classTeachers: ISubscriptedTeacherProfile[]
  currentDiscipline: IDiscipline | null | undefined
}

enum StateMachineEnum {
  READY = 'READY',
  ERROR = 'ERROR',
}

export const BigNumbersReady: React.FC<IBigNumbersReady> = ({ bigNumbersData, tryAgainCallback, classTeachers, currentDiscipline }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()

  const currentState: StateMachineEnum = bigNumbersData.error ? StateMachineEnum.ERROR : StateMachineEnum.READY

  const classData = bigNumbersData.data

  const classParticipation = (classData?.per_participation ?? 0) * 100
  const releasedActivities = String(classData?.nr_released_activity ?? '-')
  const releasedEvaluations = String(classData?.nr_released_evaluation ?? '-')
  const classEvaluation = classData?.dec_performance !== undefined ? 10 * classData?.dec_performance : '-'

  const bigNumbersClassData = {
    classParticipation,
    releasedActivities,
    releasedEvaluations,
    classEvaluation
  }

  const ReadyState = () => (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            percentage={bigNumbersClassData.classParticipation}
            title={t('Participação')}
            valueText={classData?.per_participation === null || typeof classData?.per_participation === 'undefined' ? '-' : undefined}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            title={t('Atividades aplicadas')}
            valueText={bigNumbersClassData.releasedActivities}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            title={t('Avaliações aplicadas')}
            valueText={bigNumbersClassData.releasedEvaluations}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            percentage={typeof bigNumbersClassData.classEvaluation === 'number' ? bigNumbersClassData.classEvaluation : 0}
            title={t('Média da turma')}
            valueText={classData?.dec_performance?.toLocaleString('pt-BR') ?? '-'}
          />
        </Grid>
      </Grid>
    </>
  )

  const ErrorState = () => (
    <>
      <Grid container>
        <Alert content={bigNumbersData.error ?? ''} actionDataTestId='followup_reload' outlined={true} severity='error' onActionClick={tryAgainCallback} action={`${t('Recarregar')}`} />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            title={t('Participação')}
            valueText={'!'}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            title={t('Atividades aplicadas')}
            valueText={'!'}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            title={t('Avaliações aplicadas')}
            valueText={'!'}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BigNumber.Item
            valueText={'!'}
            title={t('Média da turma')}
          />
        </Grid>
      </Grid>
    </>
  )

  const renderDisciplineTeacher = useCallback(() => {
    if (!currentDiscipline) {
      return <></>
    }

    const disciplineTeachers = classTeachers.filter(({ taught_disciplines }) => taught_disciplines.includes(Number(currentDiscipline.id)))

    if (!disciplineTeachers?.length) {
      return <></>
    }

    return (
      <Grid container item xs={12} className={classes.classTeachersContainer}>
        {disciplineTeachers.map(teacher => (
          <Box>
            <TeacherCard.Item teacherName={`${teacher.name} ${teacher.surname}`} />
          </Box>
        ))}
      </Grid>
    )
  }, [currentDiscipline])

  const states = {
    READY: <ReadyState />,
    ERROR: <ErrorState />
  }

  const renderStateMachine = () => states[currentState]

  return (
    <Grid container item xs={12} rowSpacing={3}>
      <Grid item xs={12}>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='sm'
          customColor={theme.colorBrand.medium}
        >
          {t('Visão geral')}
        </Text2>
      </Grid>

      {renderDisciplineTeacher()}

      <Grid container item justifyContent='space-between' className={classes.bigNumbesReportsContainer}>
        {renderStateMachine()}
      </Grid>
    </Grid>
  )
}
