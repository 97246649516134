export const gradeMarks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 2.5,
    label: '2,5'
  },
  {
    value: 5,
    label: '5'
  },
  {
    value: 7.5,
    label: '7,5'
  },
  {
    value: 10,
    label: '10'
  }
]
