import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => createStyles({
  modalDescription: {
    padding: '0px 32px 32px 32px',
    textAlign: 'center'
  },
  modalTitle: {
    paddingBottom: '32px',
    textAlign: 'center'
  }
}))
