import { CustomTheme } from 'styles/types/CustomTheme'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  '&.MuiButton-root': {
    minWidth: '97px',
    textTransform: 'none',
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    borderRadius: theme.borderRadius.pill,
    padding: theme.spacingSquish.xs,
    height: 'auto',
    '&:active': {
      transition: '.1s',
      boxShadow: `0px 0px 0px 3px ${theme.colorBrand.medium}`
    },
    '&.MuiButton-sizeSmall': {
      textTransform: 'none',
      fontFamily: theme.font.fontFamily,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      lineHeight: theme.font.lineHeight.xxs,
      border: theme.borderThickness.none,
      borderRadius: theme.borderRadius.pill,
      padding: theme.spacingSquish.nano,
      height: 'auto',
      '&:active': {
        transition: '.1s',
        boxShadow: `0px 0px 0px 3px ${theme.colorBrand.medium}`
      }
    }
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.primary': {
      ...styleDefault(theme),
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        background: theme.colorBrand.dark
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10
      }
    },
    '&.outlined': {
      ...styleDefault(theme),
      color: theme.colorBrand.medium,
      border: `solid ${theme.borderThickness.hairline}`,
      borderColor: theme.colorBrand.medium,
      '&:hover': {
        background: theme.colorBrand.lightest,
        borderColor: theme.colorBrand.lightest
      },
      '&:disabled': {
        color: theme.colors.neutral.light40,
        background: theme.colors.neutral.light10,
        borderColor: theme.colors.neutral.light40
      }
    },
    '&.ghost': {
      ...styleDefault(theme),
      color: theme.colorBrand.medium,
      '&:hover': {
        background: theme.colorBrand.lightest
      },
      '&:disabled': {
        color: theme.colors.neutral.light40
      }
    },
    '&.link': {
      color: theme.colorBrand.dark,
      textTransform: 'none',
      fontFamily: theme.font.fontFamily,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      padding: 0,
      '&:hover': {
        background: 'transparent'
      },
      '&:disabled': {
        color: theme.colors.neutral.light40
      }
    },
    '&.disabled': {
      ...styleDefault(theme),
      color: theme.colors.neutral.dark40,
      border: `solid ${theme.borderThickness.hairline}`,
      borderColor: theme.colors.neutral.light10,
      background: theme.colors.neutral.light10,
      '&:hover': {
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase
      },
      '&:disabled': {
        color: theme.colors.neutral.light40,
        background: theme.colors.neutral.light10,
        borderColor: theme.colors.neutral.light40
      }
    },
    '&.feedbackNegative': {
      ...styleDefault(theme),
      background: theme.colors.negative.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        background: theme.colors.negative.feedbackDarkest
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10
      },
      '&:active': {
        boxShadow: `0px 0px 0px 3px ${theme.colors.negative.feedbackDarkest}!important`
      }
    },
    '&.feedbackPositive': {
      ...styleDefault(theme),
      background: theme.colors.positive.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        background: theme.colors.positive.feedbackDarkest
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10
      },
      '&:active': {
        boxShadow: `0px 0px 0px 3px ${theme.colors.positive.feedbackDarkest}!important`
      }
    },
    '&.feedbackWarning': {
      ...styleDefault(theme),
      background: theme.colors.warning.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        background: theme.colors.warning.feedbackDarkest
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10
      },
      '&:active': {
        boxShadow: `0px 0px 0px 3px ${theme.colors.warning.feedbackDarkest}!important`
      }
    },
    '&.feedbackInformation': {
      ...styleDefault(theme),
      background: theme.colors.information.feedbackMedium,
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        background: theme.colors.information.feedbackDarkest
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10
      },
      '&:active': {
        boxShadow: `0px 0px 0px 3px ${theme.colors.information.feedbackDarkest}!important`
      }
    }
  }
}))

export const ripple = {
  primary: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.darkest,
      filter: 'brightness(50%)'
    }
  })),
  outlined: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.light,
      filter: 'brightness(50%)'
    }
  })),
  ghost: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.lightest,
      filter: 'brightness(50%)'
    }
  })),
  outlinedGhost: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.lightest,
      filter: 'brightness(50%)'
    }
  })),
  link: makeStyles((_theme: CustomTheme) => ({
    child: {
      filter: 'brightness(50%)'
    }
  })),
  disabled: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colors.neutral.light10,
      filter: 'brightness(50%)'
    }
  })),
  feedbackNegative: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colors.negative.feedbackLightest,
      filter: 'brightness(50%)'
    }
  })),
  feedbackPositive: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colors.positive.feedbackLightest,
      filter: 'brightness(50%)'
    }
  })),
  feedbackWarning: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colors.warning.feedbackLightest,
      filter: 'brightness(50%)'
    }
  })),
  feedbackInformation: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colors.information.feedbackLightest,
      filter: 'brightness(50%)'
    }
  }))
}
