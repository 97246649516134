import React from 'react'
import { useTranslation } from 'react-i18next'
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined'

import { Grid, useTheme } from '@mui/material'
import { Alert, Icon, Text2 } from 'components/design-system'

import { IEnrollmentDataDailyResponse } from 'services'

import useStyle from './styles'
import { CircularProgress } from 'components/common/Charts/CircularProgress'
import { NavigationButton } from '../../NavigationButton'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'

interface INewEnrollmentsChartItem {
  enrollmentData: IEnrollmentDataDailyResponse | undefined
}

export const NewEnrollmentsChartItem: React.FC<INewEnrollmentsChartItem> = ({ enrollmentData }) => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  if (!enrollmentData || enrollmentData == null) {
    return <Alert content={t('Sem dados para exibir')} outlined={true} severity='info' style={{ width: '100%' }} />
  }

  const customProgressIcon = (
    <Icon
      size='large'
      iconColor={theme.colorBrand.medium}
    >
      <BackpackOutlinedIcon />
    </Icon>
  )

  return (
    <Grid container className={classes.chartContainer}>
      <Grid container item className={classes.chartDataContainer}>
        <Grid>
          <CircularProgress
            value={enrollmentData?.per_reenrollment ?? 0}
            showPercentageSymbol
            customIcon={customProgressIcon}
            customText={String(enrollmentData?.nr_student ?? 0)}
            circleSize={'150px'}
            textFontSize={'1rem'}
            circleThickness={3}
            theme={theme}
            color1={theme.colors.information.feedbackMedium}
            color2={theme.colors.support.colorSupport02}
            customLabelClassName={classes.customLabel}
          />
        </Grid>
        <Grid className={classes.summaryBulletList}>
          <Text2 className={`${classes.bulletPerReenrollment} ${classes.bulletsItem}` as unknown as string} fontSize='xxs' fontWeight='regular' lineHeight='xs' mobile='xxs'>
            <div className='bulletPoint' />
            {enrollmentData?.nr_reenrollment} rematrículas ({enrollmentData?.per_reenrollment * 100}%)
          </Text2>
          <Text2 className={`${classes.bulletPerEnrollment} ${classes.bulletsItem}` as unknown as string} fontSize='xxs' fontWeight='regular' lineHeight='xs' mobile='xxs'>
            <div className='bulletPoint' />
            {enrollmentData?.nr_enrollment} novas matrículas ({enrollmentData?.per_enrollment * 100}%)
          </Text2>
        </Grid>
      </Grid>
      <Grid container item sx={{ alignSelf: 'flex-start', flex: 0 }}>
        <NavigationButton goTo={CLASS_MANAGEMENT('list')} />
      </Grid>
    </Grid>
  )
}
