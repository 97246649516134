import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => {
  return {
    studentCard: {
      '&.MuiCard-root': {
        background: 'rgba(230, 230, 230, 0.32)'
      }
    }
  }
})
