import { FilledInput, MenuItem } from '@mui/material'
import { Select, Text2 } from 'components/design-system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ClassListEnum, ISchoolPeriod } from 'services'
import { useTranslation } from 'react-i18next'
import makeStyles from './style'
import { useAtomValue } from 'jotai/utils'
import { atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import { ChangeEvent, useEffect } from 'react'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router'

interface ISchoolYearFilter {
  classListType: ClassListEnum
  handleSelectSchoolPeriod: (e: ChangeEvent<HTMLInputElement>) => void
  schoolPeriods: ISchoolPeriod[] | undefined
  selectedSchoolPeriodId: string
  originDataTestId?: string
  destinyDataTestId?: string
}

export const SchoolYearFilter = ({ classListType, handleSelectSchoolPeriod, schoolPeriods, selectedSchoolPeriodId, originDataTestId, destinyDataTestId = 'destinyDataTestId' }: ISchoolYearFilter) => {
  const { t } = useTranslation()
  const classes = makeStyles()
  const history = useHistory()

  // atoms
  const schoolPeriod = useAtomValue(atomSchoolPeriod)

  useEffect(() => {
    if (!schoolPeriod) {
      history.push(CLASS_MANAGEMENT('list'))
    }
  }, [])

  return (
    <>
      <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xl' mobile='sm' neutral='dark30'>
        {t('Ano escolar')}
      </Text2>
      {
        classListType === ClassListEnum.origin
          ? (
            <Select
              id='year'
              variant='filled'
              IconComponent={ExpandMoreIcon}
              value={selectedSchoolPeriodId}
              label={!selectedSchoolPeriodId ? 'selecione' : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelectSchoolPeriod(e)}
              dataTestId={originDataTestId}
            >
              {
                schoolPeriods?.map(sp => (
                  <MenuItem key={sp.id} value={sp.id}>
                    {sp.year}
                  </MenuItem>
                ))
              }
            </Select>
          )
          : (
            <FilledInput
              className={classes.yearInput}
              data-testid={destinyDataTestId}
              disableUnderline
              value={schoolPeriod?.year}
              fullWidth
              hiddenLabel
              readOnly
            />
          )
      }
    </>
  )
}
