import {
  Card,
  Checkbox,
  Text2
} from 'components/design-system'
import Grid from '@mui/material/Grid'
import { IProfileResponse, IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import useStyles from './style'
import { returnEmailOrPhone } from 'utils'
import { IProfileCardTagging } from 'services/types/tag'
import { Contact, DateOfBirth, EditProfileButton, LinkedProfiles, Password, ProfileStatus, SendingCredentials, Username } from './components'

interface IProfileCardProps {
  checked: boolean
  classId?: string
  disabled?: boolean
  onClick: () => void
  profile: IProfileResponse | undefined
  profiletype: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher
  user: IUserResponse
  tags?: IProfileCardTagging
}

export const ProfileCard: React.FC<IProfileCardProps> = ({
  checked = false,
  classId,
  disabled = false,
  onClick,
  profile,
  profiletype,
  user: {
    id: userId,
    birth_date: birthDate,
    email,
    name,
    phone,
    surname,
    username,
    profiles,
    fullname,
    isDefaultPass,
    sentDefaultCredentials
  },
  tags
}) => {
  const styles = useStyles()
  const contact = returnEmailOrPhone(email, phone)
  const contactDataTestId = contact.type === 'email' ? tags?.EMAIL_TAG : tags?.PHONE_TAG

  fullname = fullname || `${name} ${surname}`

  return (
    <Card extraClasses={`${styles.root} ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
      <Grid container rowSpacing={1}>
        <Grid container item justifyContent='space-between' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Grid container item xs alignItems='center'>
            <Grid item xs={10} md='auto' zeroMinWidth>
              <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
                <Checkbox onClick={onClick} checked={checked} label={fullname} customClasses={styles.checkbox} dataTestid={tags?.SELECT_TAG} />
              </Text2>
            </Grid>
            <Grid item xs={2} md='auto' zeroMinWidth>
              <EditProfileButton classId={classId} dataTestId={tags?.EDIT_TAG} profiletype={profiletype} userId={userId} />
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto' className='status'>
            <Grid container alignItems='center' columnSpacing={2}>
              <LinkedProfiles profiles={profiles} profiletype={profiletype} />

              <ProfileStatus
                disabled={disabled}
                profile={profile}
                profiletype={profiletype}
                reloadBoxes
                userId={userId}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justifyContent='space-between' spacing={1}>
          <Username username={username} copyButtonDataTestId={tags?.USERNAME_TAG} />

          <DateOfBirth birthDate={birthDate} copyButtonDataTestId={tags?.BIRTH_DATE_TAG} />

          <Contact contact={contact} profiletype={profiletype} copyButtonDataTestId={contactDataTestId} />

          <SendingCredentials
            disabled={disabled}
            isDefaultPass={isDefaultPass}
            profiletype={profiletype}
            sentDefaultCredentials={sentDefaultCredentials}
            userId={userId}
          />

          <Password
            disabled={disabled}
            isDefaultPass={isDefaultPass}
            profiletype={profiletype}
            userId={userId}
          />
        </Grid>
      </Grid>
    </Card>
  )
}
