import {
  Modal,
  Grid,
  Text2,
  Button,
  Loading
} from 'components/design-system'
import makeStyles from './style'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import learningImage from 'assets/learning.svg'
import { Trans, useTranslation } from 'react-i18next'
import { createRef, useState } from 'react'
import { getSchoolProgramByGradetypeAndGrade, createSchoolProgram } from 'services'
import { SCHOOL_PROGRAM_LIST } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

interface ICreateSchoolProgramModalProps {
  programData: {
    gradeId: number
    schoolId: number
    gradeTypeId: number
  }
  open: boolean
  onOpen: (state: boolean) => void
}

export const CreateSchoolProgramModal: React.FC<ICreateSchoolProgramModalProps> = ({ open, onOpen, programData }) => {
  const [loadingCreatingProgram, setLoadingCreatingProgram] = useState<boolean>(false)
  const history = useHistory()
  const { createSnackbar } = useSnackbar()

  const classes = makeStyles()
  const { t } = useTranslation()

  const closeRef = createRef<any>()

  const handleClose = () => {
    closeRef?.current?.click()
    onOpen(false)
  }

  const handleCreateProgramSchool = async () => {
    if (programData.gradeId && programData.schoolId) {
      const gradeId = programData.gradeId
      const schoolId = Number(programData.schoolId)
      try {
        setLoadingCreatingProgram(true)
        createSnackbar({ content: 'Aguarde. Estamos criando o programa para a sua escola.', severity: 'info' })
        const response = await createSchoolProgram(gradeId, schoolId)
        if (response.success) {
          createSnackbar({ content: 'Programa criado com sucesso', severity: 'success' })
          if (programData.gradeTypeId) {
            const gradeTypeId = programData.gradeTypeId
            getSchoolProgramByGradetypeAndGrade(
              gradeTypeId,
              gradeId,
              schoolId
            )
              .then(() => {
                history.push(SCHOOL_PROGRAM_LIST)
              })
              .catch(() => createSnackbar({ content: 'Não foi possível criar o programa para a escola. Tente novamente', severity: 'error' }))
              .finally(() => setLoadingCreatingProgram(false))
          }
        }
      } catch (err) {
        createSnackbar({ content: 'Não foi possível criar o programa para a escola. Tente novamente', severity: 'error' })
      }
    }
  }

  return (
    <Modal open={open} onClose={() => onOpen(false)} className={classes.modal}>
      <>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <span ref={closeRef} data-testid='create_school_program_cancel_program_creation' />
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon className={classes.closeIconModal} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='sm' colorbrand='medium'>
              {t('Seja Bem-vindo ao espaço de edição do conteúdo!')}
            </Text2>
          </Grid>
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' neutral='dark30'>
              <Trans>
                  Você pode: <strong>ocultar a visualização de conteúdos*.</strong> Para executar essas alterações, será necessário <strong>criar um programa</strong> personalizado para a sua escola e <strong>este programa será aplicado a todas as turmas do ano que você selecionou.</strong>
              </Trans>
            </Text2>
          </Grid>
          <Grid item xs={12} md={12}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='xxs' neutral='dark30'>
              <img src={learningImage} />
            </Text2>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button className={classes.btn} onClick={handleCreateProgramSchool} data-testid='create_school_program_create_school_program'>
              {
                loadingCreatingProgram
                  ? <Loading type='circular' />
                  : t('Criar programa da escola')
              }
            </Button>
          </Grid>
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark40'>
              {t('*Ao ocultar as unidades de conteúdo, você altera o que foi planejado pela Cloe.')}
            </Text2>
          </Grid>
        </Grid>
      </>
    </Modal>
  )
}
