import React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import useStyle from './styles'
import { twoDigitFormatter } from 'utils'
import { Text2 } from 'components/design-system'
import { useTheme } from '@mui/material'

export interface IPillProps extends Omit<ChipProps, 'variant'> {
  isEmpty?: boolean
  isPercentage?: boolean
  label?: string
  twoDigitsFormat?: boolean
  variant?: 'default' | 'error' | 'info' | 'success' | 'warning'
}

export const Pill: React.FC<IPillProps> = ({ isEmpty = false, isPercentage = false, label, twoDigitsFormat = true, variant = 'default', ...props }) => {
  const classes = useStyle(props)
  const theme = useTheme()

  const formatLabel = () => {
    if (isEmpty) return ''

    if (label && label !== '') {
      let formattedLabel = twoDigitsFormat ? twoDigitFormatter(label) : label
      if (isPercentage) formattedLabel += '%'
      return formattedLabel
    }

    return label
  }

  return isEmpty || (label)
    ? (
      <Chip
        {...props}
        className={`${classes.root} ${variant}`}
        label={formatLabel()}
      />
    )
    : (
      <Text2
        customColor={theme.colors.neutral.lightBase}
        fontSize='xxs'
        fontWeight='medium'
        lineHeight='xs'
        mobile='xxs'
      >
        -
      </Text2>
    )
}
