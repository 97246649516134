import { Switch, Route } from 'react-router-dom'
import { REPORT_VIEW } from 'navigation/CONSTANTS'
import {
  ReportView
} from './components'

export const Report: React.FC = () => {
  return (
    <Switch>
      <Route exact path={REPORT_VIEW}>
        <ReportView/>
      </Route>
    </Switch>
  )
}

export default Report
