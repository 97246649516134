import { enabledFeaturesList } from '../enabledFeatures'

export const _localStorageRef = 'camino-admin::enabled-features'

export const getEnabledFeaturesFromLocalStorage = () => {
  const localStorageData = localStorage.getItem(_localStorageRef)

  if (localStorageData) return JSON.parse(localStorageData)

  return enabledFeaturesList
}

export const hasFeatureFlag = (featureFlag: string) => {
  const localStorageData = localStorage.getItem(_localStorageRef)
  const featureFlagArray: string[] = localStorageData ? JSON.parse(localStorageData) : []

  return !!featureFlagArray.find(ff => ff === featureFlag)
}
