import { UserSchoolProfileTypeEnum } from 'services/enums'
import moment from 'moment'

export const REGISTRATION_TOKEN_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/registration-tokens`

export const REGISTRATION_TOKEN_CREATE = () => `${REGISTRATION_TOKEN_BASE()}`

export const REGISTRATION_TOKEN_GET = (type: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher, classId?: number, schoolId?: number) => {
  const currentDate = moment().toISOString()
  const baseUrl = `${REGISTRATION_TOKEN_BASE()}?active=true&type=${type}&expire_at_gte=${currentDate}`

  const url = new URL(baseUrl)

  if (classId) url.searchParams.append('class.id', classId.toString())
  if (schoolId) url.searchParams.append('school.id', schoolId.toString())

  return url.toString()
}

export const REGISTRATION_TOKEN_UPDATE = (id: number) =>
  `${REGISTRATION_TOKEN_BASE()}/${id}`
