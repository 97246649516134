import React, { useEffect } from 'react'
import { Tabs, Tab, TabPanel } from 'components/design-system'
import { TabProps } from '@material-ui/core/Tab'
import { TeacherTab } from './TeacherTab'
import { StudentTab } from './StudentTab'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined'
import makeStyles from './style'
import { useParams, Link, LinkProps, Switch, Route } from 'react-router-dom'
import { BulkCreateStudent, CreateStudentOptions } from 'pages/ProfileTabs/StudentTab/StudentActions'
import { CreateTeacher, CreateTeacherOptions, BulkCreateNewTeacher, TeacherSuccess } from 'pages/ProfileTabs/TeacherTab/TeacherActions'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import { useAtom } from 'jotai'
import { atomProfileTab } from './atomStore'

interface TabType {
  label: string
  link: string
  icon: React.ReactNode
  dataTestid?: string
}

interface IProfileTabs {
  tab?: string
}

const tabs: TabType[] = [
  { label: 'Professores', link: 'teachers', icon: <BookOutlinedIcon />, dataTestid: 'user_management_coordinator_menu_teachers' },
  { label: 'Estudantes', link: 'students', icon: <BackpackOutlinedIcon />, dataTestid: 'user_management_coordinator_menu_students' }
]

export const ProfileTabs: React.FC = () => {
  const classes = makeStyles()
  // hooks
  const { tab: tabParam } = useParams<IProfileTabs>()

  const [profileTab, setProfileTab] = useAtom(atomProfileTab)

  const LinkTab: React.ComponentType<TabProps & LinkProps> = Tab as React.ComponentType<TabProps & LinkProps>

  const handleCurrentProfileTab = (_event: React.SyntheticEvent, newValue: number) => {
    setProfileTab(newValue)
    const getPositionLink = tabs[newValue].link
    window.history.replaceState(null, '', PROFILE_TABS(getPositionLink))
  }

  useEffect(() => {
    const getTab = tabParam ?? undefined
    if (getTab) {
      const index = tabs.findIndex(f => f.link === getTab)
      setProfileTab(index)
    } else {
      const getPositionLink = tabs[0].link
      window.history.replaceState(null, '', PROFILE_TABS(getPositionLink))
    }
  }, [])

  return (
    <>
      <Tabs className={classes.tabs} value={profileTab} onChange={handleCurrentProfileTab}>
        {
          tabs.map((tab, index) =>
            <LinkTab
              component={Link}
              to={PROFILE_TABS(tab.link)}
              key={index}
              label={<div>{tab.icon}<span>{tab.label}</span></div>}
              data-testid={tab.dataTestid}
            />
          )
        }
      </Tabs>
      <TabPanel value={profileTab} index={0}>
        <div className={classes.tabPanel}>
          <Switch>
            <Route exact path={PROFILE_TABS('teachers')}>
              <TeacherTab />
            </Route>
            <Route exact path={PROFILE_TABS('teachers/create-options')}>
              <CreateTeacherOptions />
            </Route>
            <Route exact path={PROFILE_TABS('teachers/single-registration')}>
              <CreateTeacher/>
            </Route>
            <Route path={PROFILE_TABS('teachers/success-registration')}>
              <TeacherSuccess />
            </Route>
            <Route exact path={PROFILE_TABS('teachers/bulk-registration')}>
              <BulkCreateNewTeacher/>
            </Route>
          </Switch>
        </div>
      </TabPanel>
      <TabPanel value={profileTab} index={1}>
        <div className={classes.tabPanel}>
          <Switch>
            <Route exact path={PROFILE_TABS('students')}>
              <StudentTab />
            </Route>
            <Route exact path={PROFILE_TABS('students/create-options')}>
              <CreateStudentOptions />
            </Route>
            <Route exact path={PROFILE_TABS('students/bulk-registration')}>
              <BulkCreateStudent/>
            </Route>
          </Switch>
        </div>
      </TabPanel>
    </>
  )
}

export default ProfileTabs
