/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  circularProgress: {
    color: `${theme.colorBrand.medium}!important`
  },
  circularProgressCaption: {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    color: `${theme.colors.neutral.lightBase}!important`
  },
  labelBox: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}))
