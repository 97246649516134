import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    '&.MuiCard-root': {
      '& .MuiCardContent-root': {
        padding: '0'
      },
      width: '100%',
      height: '500px',
      borderRadius: '8px',
      border: `1px solid ${theme.colors.neutral.light10}`
    }
  },
  root: {
    width: '100%',
    height: '500px',
    '& .PrivatePickersToolbar-root': {
      borderRadius: '8px 8px 0px 0px',
      background: theme.colorBrand.medium,
      '& > span': {
        color: theme.colors.neutral.lightBase
      },
      '& .PrivatePickersToolbar-dateTitleContainer': {
        color: theme.colors.neutral.lightBase,
        '& > div > button > span': {
          color: theme.colors.neutral.lightBase
        }
      }
    },
    '& .MuiDateRangePickerDay-day.Mui-selected:active': {
      background: theme.colorBrand.medium
    },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight.Mui-selected': {
      background: theme.colorBrand.medium
    },
    '& .MuiDateRangePickerDay-rangeIntervalPreview.Mui-selected': {
      background: theme.colorBrand.medium
    },
    '& .MuiDateRangePickerDay-day.Mui-selected:focus': {
      background: theme.colorBrand.medium,
      '&:hover': {
        background: theme.colorBrand.dark
      }
    },
    '& .MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
      background: theme.colorBrand.medium
    },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      background: theme.colorBrand.lightest
    }
  },
  textField: {
    color: theme.colors.neutral.lightBase
  },
  buttonGroup: {
    '& > div:last-child': {
      margin: '0 8px'
    },
    width: '302px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '-70px'
  }
})
)
