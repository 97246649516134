import { createContext } from 'react'

interface IBaseScreenContext {
  activeMenu: boolean
  setActiveMenu: (value: boolean) => void
  activeMenuClass: boolean
  setActiveMenuClass: (value: boolean) => void
}

const BaseScreenContext = createContext<IBaseScreenContext>({
  activeMenu: false,
  setActiveMenu: () => {
    // This is intentional
  },
  activeMenuClass: false,
  setActiveMenuClass: () => {
    // This is intentional
  }
})

export default BaseScreenContext
