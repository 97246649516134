import { useTranslation } from 'react-i18next'
import { CardProps } from '@mui/material'
import { Card, Grid, Tag, Text2 } from 'components/design-system'
import { IClassSubscriptionResponse, IUserRegisterResponse } from 'services'
import useStyles from './style'

interface IStudentClassCard extends CardProps {
  extraClasses: string
  classSubscriptions?: IClassSubscriptionResponse[]
  onDelete: (classSubscription: IClassSubscriptionResponse) => void
  user?: IUserRegisterResponse
}

export const StudentClassCard: React.FC<IStudentClassCard> = ({ classSubscriptions, onDelete, user, ...props }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <Card {...props} extraClasses={`${props.extraClasses} ${styles.studentCard}`} fullwidth={+true}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark30'>
            {(user?.name && user?.surname) && `${user.name} ${user.surname}`}
          </Text2>
        </Grid>
        <Grid container item xs={12} md={12} spacing={2}>
          <Grid item>
            <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
              {t('Turmas')}:
            </Text2>
          </Grid>
          {classSubscriptions?.length
            ? classSubscriptions.map(cs =>
              <Grid item key={cs.class_id.id}>
                <Tag
                  cancelIconDataTestId='add_another_class_to_the_student_delete_student_class'
                  label={cs.class_id.name}
                  onDelete={() => onDelete(cs)}
                />
              </Grid>
            )
            : (
              <Grid item>
                <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                  {t('Nenhuma turma vinculada')}
                </Text2>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Card>
  )
}
