import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ICardProps } from './index'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiCard-root': {
      borderRadius: theme.borderRadius.sm,
      width: (props: ICardProps) => props.fullwidth ? '100%' : 'auto',
      '&.clickable': {
        cursor: 'pointer',
        '&.selected': {
          background: theme.colorBrand.medium,
          color: theme.colors.neutral.lightBase
        },
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)'
        },
        '&:focus-within': {
          borderColor: theme.colors.support.colorSupport02,
          borderWidth: '3px',
          borderStyle: 'solid'
        }
      },
      '& .MuiCardContent-root': {
        padding: (props: ICardProps) => props.noPadding ? '0px' : '16px'
      }
    }
  }
}))
