import { SendCredentialsModal } from '../SendCredentialsModal'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { useEffect, useState } from 'react'
import { useUserSendDefaultCredentials } from 'navigation/hooks'
import { useUpdateAtom } from 'jotai/utils'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { useMediaQuery, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { CircleButton } from 'components/common/CircleButton'
interface ISendCredentialsOptionButton {
  recipients: IUserResponse[]
  clearRecipients: () => void
  profile: UserSchoolProfileTypeEnum
  dataTestId: string
}

export const SendCredentialsOptionButton = ({ recipients, clearRecipients, profile, dataTestId }: ISendCredentialsOptionButton) => {
  const { t } = useTranslation()

  const [isOpenCredentialsModal, setIsOpenCredentialsModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const { sendCredentials, verifyConditionsToActivateSendCredentialsButton, enableSendCredentialsButton } = useUserSendDefaultCredentials()

  const setReloadList = useUpdateAtom(atomReloadList)

  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))

  const handleSendCredentials = async () => {
    if (recipients.length) {
      setIsLoading(true)
      await sendCredentials({
        users: [...recipients.map(student => student.id)]
      })
    }

    setIsLoading(false)
    setIsOpenCredentialsModal(false)
    clearRecipients()
    setReloadList(true)
  }

  const handleOpenCredentialsModal = () => {
    setIsOpenCredentialsModal(true)
  }

  useEffect(() => {
    if (recipients.length) {
      verifyConditionsToActivateSendCredentialsButton(recipients)
    }
  }, [recipients])

  const handleCloseModal = () => {
    setIsOpenCredentialsModal(!isOpenCredentialsModal)
  }

  return (
    <>
      {!isLaptop
        ? <Button variant='link' disabled={!recipients.length || enableSendCredentialsButton} onClick={handleOpenCredentialsModal} data-testid={dataTestId}>
          <SendOutlinedIcon sx={{ marginRight: '5px' }} /> {t('Enviar credenciais')}
        </Button>
        : <CircleButton disabled={!recipients.length || enableSendCredentialsButton} aria-label='Enviar credenciais' title='Enviar credenciais' onClick={handleOpenCredentialsModal} data-testid={dataTestId}>
          <SendOutlinedIcon />
        </CircleButton>}

      <SendCredentialsModal
        open={isOpenCredentialsModal}
        onClose={handleCloseModal}
        onSendCredentials={handleSendCredentials}
        list={recipients}
        profile={profile}
        isLoading={isLoading}
      />
    </>
  )
}
