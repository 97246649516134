import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    textDecoration: 'none',
    // common variant styles
    '&.colorBrand, &.neutral': {
      '& svg': {
        marginRight: '14px'
      },
      '& .label': {
        fontFamily: theme.font.fontFamily,
        fontSize: theme.font.fontSize.xs,
        fontWeight: theme.font.fontWeight.black
      },
      '& .MuiGrid-container': {
        backgroundColor: 'transparent',
        borderRadius: '500px',
        cursor: 'pointer',
        height: '40px',
        lineHeight: theme.font.lineHeight.xxs,
        margin: theme.spacingStack.xxxs,
        padding: '8px',
        transition: 'all ease-in-out 0.2s',
        width: 'auto',
        '&:hover': {
          width: 'calc(100% - 32px)'
        },
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 32px)'
        }
      }
    },
    '&.colorBrand': {
      '& .MuiGrid-container': {
        color: theme.colors.neutral.lightBase,
        '&:hover': {
          backgroundColor: theme.colors.neutral.lightBase,
          boxShadow: `0px 8px 16px ${theme.colorBrand.medium}52`,
          color: theme.colorBrand.medium,
          [theme.breakpoints.down('sm')]: {
            color: theme.colorBrand.medium
          }
        }
      },
      '&.selected .MuiGrid-container': {
        backgroundColor: theme.colors.neutral.lightBase,
        boxShadow: `0px 8px 16px ${theme.colorBrand.medium}52`,
        color: theme.colorBrand.medium,
        width: 'calc(100% - 32px)',
        '& svg, .label': {
          [theme.breakpoints.down('sm')]: {
            opacity: '1',
            transition: 'visibility 0s, opacity 0.2s linear',
            visibility: 'visible'
          }
        }
      }
    },
    '&.neutral': {
      '& .MuiGrid-container': {
        color: theme.colors.neutral.dark20,
        '&:hover': {
          backgroundColor: theme.colors.neutral.lightBase,
          boxShadow: `0px 8px 16px ${theme.colors.neutral.dark20}52`,
          color: theme.colors.neutral.dark20,
          [theme.breakpoints.down('sm')]: {
            color: theme.colors.neutral.dark20
          }
        }
      },
      '&.selected .MuiGrid-container': {
        backgroundColor: theme.colors.neutral.lightBase,
        boxShadow: `0px 8px 16px ${theme.colors.neutral.dark20}52`,
        color: theme.colors.neutral.dark20,
        width: 'calc(100% - 32px)',
        '& svg, .label': {
          [theme.breakpoints.down('sm')]: {
            opacity: '1',
            transition: 'visibility 0s, opacity 0.2s linear',
            visibility: 'visible'
          }
        }
      }
    }
  }
}))
