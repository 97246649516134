import { createContext, ReactNode, useEffect, useState } from 'react'
import { ToggleFeaturesEnum } from '../enabledFeatures'
import { getEnabledFeaturesFromLocalStorage, _localStorageRef } from '../store'

export interface IFeatureToggleContext {
  enabledFeatures: string[]
  validateEnabledFeatures: (feature: ToggleFeaturesEnum) => boolean
}

interface IFeatureToggleContextProviderProps {
  children: ReactNode
}

export const FeatureToggleContext = createContext<IFeatureToggleContext>({} as const as IFeatureToggleContext)

export const FeatureToggleProvider = ({ children }: IFeatureToggleContextProviderProps) => {
  const [enabledFeatures] = useState<string[]>(() => getEnabledFeaturesFromLocalStorage())

  useEffect(() => {
    localStorage.setItem(_localStorageRef, JSON.stringify(enabledFeatures))
  }, [enabledFeatures])

  const validateEnabledFeatures = (feature: ToggleFeaturesEnum) => enabledFeatures.includes(feature)

  return (
    <FeatureToggleContext.Provider value={{
      enabledFeatures,
      validateEnabledFeatures
    }}>
      {children}
    </FeatureToggleContext.Provider>
  )
}
