import { Skeleton } from '@mui/material'
import { Grid } from 'components/design-system'

export const Loading: React.FC = () => {
  return (
    <Grid container columnSpacing={2}>
      {[...Array(8)].map((_cur, i) => (
        <Grid item>
          <Skeleton key={i} animation='wave' width={110} height={50} />
        </Grid>
      ))}
    </Grid>
  )
}
