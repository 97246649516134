import { StyleSheet } from '@react-pdf/renderer'

// unit used is pt
export const styles = StyleSheet.create({
  classCard: {
    backgroundColor: '#F7F8FB',
    borderRadius: 12,
    padding: '29.3 27'
  },
  classInfo: {
    color: '#2C2C2C',
    fontWeight: 'medium'
  },
  classInfoBold: {
    color: '#2C2C2C',
    fontWeight: 'bold',
    marginRight: 3
  },
  classInfoContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  classInfoIcon: {
    marginRight: 6,
    padding: 1, // used to prevent image cropping
    width: 12
  },
  classInfoItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9.8,
    marginRight: 19.5
  },
  className: {
    color: '#3C4E88',
    fontSize: 24,
    fontWeight: 'semibold',
    margin: '6 0 12 0'
  },
  grades: {
    color: '#2C2C2C',
    fontSize: 9,
    fontWeight: 'medium'
  },
  segments: {
    color: '#2C2C2C',
    fontSize: 9,
    fontWeight: 'medium'
  },
  segmentsContainer: {
    marginBottom: 12
  }
})
