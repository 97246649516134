import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    description: {
      margin: `${theme.spacingStack.xxs} 0 ${theme.spacingStack.sm}`
    },
    scrollContainer: {
      margin: '0px 16px',
      maxHeight: '1342px',
      paddingRight: theme.spacingInline.xxxs,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '12px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.neutral.light20,
        borderRadius: '8px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.colors.neutral.light30
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.colors.neutral.light10,
        backgroundClip: 'content-box',
        border: '5px solid transparent'
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: '337px'
      }
    },
    checkboxSelectAllClassesContainer: {
      padding: `${theme.spacingInset.lg}!important`
    },
    checkboxSelectAllClasses: {
      '& .MuiTypography-root': {
        color: `${theme.colorBrand.medium}!important`,
        fontSize: `${theme.font.fontSize.sm}!important`,
        fontWeight: `${theme.font.fontWeight.semibold}!important`,
        paddingLeft: theme.spacingInline.nano
      }
    },
    title: {
      marginTop: theme.spacingStack.sm
    },
    addButtonContainer: {
      height: '380px',
      padding: '0!important',
      display: 'flex',
      justifyContent: 'flex-end',
      '&.MuiButton-root': {
        paddingTop: '80px'
      }
    },
    addButton: {
      marginTop: `${theme.spacingInline.xxxs}!important`
    },
    addButtonContent: {
      marginRight: theme.spacingInline.xxxs
    },
    createClassButtonContainer: {
      top: '0',
      position: 'sticky',
      zIndex: 2,
      marginTop: '8px',
      padding: '8px 0',
      background: theme.colors.neutral.lightBase
    },
    createClassButton: {
      width: '100%'
    },
    footerButtonGroup: {
      margin: `${theme.spacingStack.lg} 0 ${theme.spacingStack.xs}`,
      paddingTop: theme.spacingStack.sm
    },
    skippingChildrenContainerModal: {
      paddingBottom: '24px'
    }
  }
})
