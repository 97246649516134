import { useState } from 'react'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined'
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Text2 } from 'components/design-system'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import { useMediaQuery, useTheme } from '@mui/material'
import { useSnackbar } from 'navigation/hooks'
import { getLastUpdatedDate } from 'services/cloe-reports'
import { lastUpdatedDateAtom } from '../components/ManageClass/components/ReportsTab/atomStore'
import { useUpdateAtom } from 'jotai/utils'

export const useReport = () => {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // atoms
  const setLastUpdatedDate = useUpdateAtom(lastUpdatedDateAtom)

  // states
  const [description, setDescription] = useState<React.ReactElement | undefined>(undefined)
  const [icon, setIcon] = useState<React.ReactNode | undefined>(undefined)
  const [isLoadingDate, setIsLoadingDate] = useState<boolean>(false)
  const [title, setTitle] = useState<string | undefined>(undefined)

  const getDeliveryStatus = (deliveryStatus: string) => {
    switch (deliveryStatus) {
      case 'Entregue':
        return (
          <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
            <Grid item>
              <AssignmentTurnedInOutlinedIcon fontSize='small' sx={{ color: `${theme.colors.information.feedbackMedium}!important`, marginTop: '4px' }} />
            </Grid>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                {t('Entregue')}
              </Text2>
            </Grid>
          </Grid>
        )
      case 'Não Entregue':
        return (
          <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
            <Grid item>
              <AssignmentLateOutlinedIcon fontSize='small' sx={{ color: `${theme.colors.negative.feedbackMedium}!important`, marginTop: '4px' }} />
            </Grid>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                {t('Não entregue')}
              </Text2>
            </Grid>
          </Grid>
        )
      case 'Pendente':
        return (
          <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
            <Grid item>
              <PendingActionsOutlinedIcon fontSize='small' sx={{ color: `${theme.colors.warning.feedbackDark}!important`, marginTop: '4px' }} />
            </Grid>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                {t('Pendente')}
              </Text2>
            </Grid>
          </Grid>
        )
    }
  }

  const getUpdatedDate = async () => {
    try {
      setIsLoadingDate(true)
      const response = await getLastUpdatedDate()

      if (response?.success) {
        setLastUpdatedDate(response?.data?.lastUpdated)
      } else {
        throw new Error(t('Erro ao obter a data de atualização'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setIsLoadingDate(false)
    }
  }

  const getValuesBySituation = (situation: number) => {
    switch (situation) {
      case 2:
        setDescription(
          <Trans>
            Para estudantes com média entre <strong>6,0</strong> e <strong>8,9</strong>, a sugestão é registrar quais habilidades mostram tendência de queda de rendimento. Observar temas de interesse do estudante e incentivá-lo a trazer dúvidas e aprofundar investigações é um bom convite para maior engajamento.
          </Trans>
        )
        setIcon(<FaceOutlinedIcon />)
        setTitle('Precisa de observação')
        break
      case 3:
        setDescription(
          <Trans>
            Estudantes com média superior a <strong>9,0</strong> podem ser instigados através da apresentação de temas de pesquisa adicionais, cursos e concursos apropriados para a faixa etária. Outra estratégia importante é incentivá-los a apoiar outros estudantes.
          </Trans>
        )
        setIcon(<PsychologyOutlinedIcon />)
        setTitle('Espaço pra desafios')
        break
      case -1:
      case 1:
      default:
        setDescription(
          <Trans>
            Para estudantes com média entre <strong>0</strong> e <strong>5,9</strong>, é recomendado um acompanhamento detalhado. Analisar avaliações com notas baixas pode indicar quais habilidades o estudante precisa desenvolver. Exercícios de reforço e materiais complementares são boas ferramentas de apoio.
          </Trans>
        )
        setIcon(<CrisisAlertOutlinedIcon />)
        setTitle('Requer intervenção')
    }
  }

  return {
    description,
    getDeliveryStatus,
    getUpdatedDate,
    getValuesBySituation,
    icon,
    isLoadingDate,
    title
  }
}
