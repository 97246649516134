import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => {
  return {
    sendNotificationBtn: {
      '& > svg': {
        marginLeft: '14px'
      }
    }
  }
})
