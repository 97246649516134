import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  loadingContainer: {
    display: 'flex',
    background: theme.colors.neutral.light10,
    borderRadius: theme.borderRadius.lg,
    padding: theme.spacingInset.sm,
    gap: theme.spacingInset.xs,
    flexDirection: 'row !important' as 'row',
    flexWrap: 'nowrap',
    marginBottom: theme.spacingStack.quarck
  },
  loadingLines: {
    borderRadius: `${theme.borderRadius.md} !important`,
    overflow: 'hidden'
  },
  loadingChart: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    justifyContent: 'center',
    gap: theme.spacingInline.nano,
    flex: 1,

    '& span': {
      borderRadius: `${theme.borderRadius.md} !important`
    }
  }
}))
