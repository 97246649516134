import { useEffect, useState } from 'react'
import { BigNumberBox, BigNumberBoxSubtitleProps } from 'components/common'
import { useTranslation } from 'react-i18next'
import { UserSchoolProfileTypeEnum } from 'services'
import { useRegisteredProfilesBox } from '../hooks'

export const RegisteredProfilesBoxTeacher: React.FC = () => {
  const { t } = useTranslation()
  const { data, fetchData, error, setReload, loading } = useRegisteredProfilesBox(UserSchoolProfileTypeEnum.teacher)

  // states
  const [subtitles, setSubtitles] = useState<BigNumberBoxSubtitleProps[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      const _subtitles: BigNumberBoxSubtitleProps[] = []

      _subtitles.push({
        label: t('{{total}} desativados', { total: data?.disabled })
      })

      _subtitles.push({
        label: t('{{total}} no total', { total: data?.all })
      })

      setSubtitles(_subtitles)
    }
  }, [data])

  const tryAgainCallback = () => {
    setReload(true)
  }

  return (
    <BigNumberBox
      bigNumber={data?.activated}
      loading={loading}
      subtitles={subtitles}
      title={t('Perfis ativos')}
      hasError={error}
      tryAgainCallback={tryAgainCallback}
      tooltipText={t('Todos os perfis ativos de professores, vinculados ou não a turmas do ano vigente.')}
    />
  )
}
