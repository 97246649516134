/* eslint-disable @typescript-eslint/prefer-includes */
import { useState, useImperativeHandle, forwardRef, useMemo, createRef } from 'react'
import { Grid, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'

import { Button, TextField } from 'components/design-system'
import { SearchOutlined, CloseOutlined } from '@mui/icons-material'
import useStyles from './style'

export interface ISearchProps {
  placeholder: string
  onSubmit?: (pattern: string) => void
  onClear?: () => void
  dataTestId?: {
    submit: string
    clear: string
  }
  showSubmitButton?: boolean
}

export const Search = forwardRef((props: ISearchProps, ref) => {
  const {
    placeholder,
    onSubmit,
    onClear,
    dataTestId
  } = props
  const showSubmitButton = props.showSubmitButton ?? false

  const [pattern, setPattern] = useState('')
  const [hasSubmited, setHasSubmited] = useState(false)

  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSearching = pattern !== '' && hasSubmited

  const inputRefIconSearch = createRef<HTMLDivElement>()
  const inputRefIconClear = createRef<HTMLDivElement>()

  const clear = () => {
    setHasSubmited(false)
    setPattern('')

    if (onClear) onClear()
  }

  useImperativeHandle(ref, () => ({
    clear() {
      clear()
    }
  }))

  const assistiveText = useMemo(() => {
    if (isSearching) {
      return t('Para limpar a pesquisa, clique no botão X')
    }
    if (pattern.length < 3 && pattern.length > 0) {
      return t('Para pesquisar, digite pelo menos 3 caracteres')
    }
    return t('Pressione ENTER ou clique na lupa para pesquisar')
  }, [isSearching, pattern.length])

  const SearchInnerButton = () => {
    if (isSearching) {
      return (
        <InputAdornment position='end'>
          <div ref={showSubmitButton ? '' : inputRefIconClear} data-testid={dataTestId?.clear} hidden />
          <button id='inner-input-search-button' className={classes.searchInnerButton} onClick={() => { inputRefIconClear?.current?.click(); submit() }}>
            <CloseOutlined onClick={() => clear()} style={{ color: theme.colorBrand.medium, fontSize: theme.font.fontSize.lg }} />
          </button>
        </InputAdornment>
      )
    }

    return (
      <InputAdornment position='end'>
        <div ref={showSubmitButton ? '' : inputRefIconSearch} data-testid={dataTestId?.submit} hidden />
        <button id='inner-input-search-button' className={classes.searchInnerButton} onClick={() => { inputRefIconSearch?.current?.click(); submit() }}>
          <SearchIcon style={{ color: theme.colorBrand.medium, fontSize: theme.font.fontSize.lg }} />
        </button>
      </InputAdornment>
    )
  }
  const submit = () => {
    if (pattern === '' || (pattern && hasSubmited)) {
      clear()
      return
    }

    if (pattern.length <= 2) {
      return
    }

    setHasSubmited(true)
    if (onSubmit) {
      onSubmit(pattern)
    }
  }

  return (
    <Grid
      className={classes.searchContainer}
    >
      <Grid
        container
        component='form'
        display='flex'
        justifyContent='flex-start'
        spacing={2}
        sx={{ flex: 1, display: 'block' }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          submit()
        }}
      >
        <Grid item>
          <TextField
            assistivetext={assistiveText}
            className={classes.searchInput}
            fullWidth
            id='search-input'
            variant='filled'
            label={placeholder}
            value={pattern}
            onChange={e => {
              setHasSubmited(false)
              setPattern(e.target.value)
            }}
            endAdornment={<SearchInnerButton />}
          />
        </Grid>
        {showSubmitButton && (
          <Grid item lg={3} xs={2}>
            <Button
              type='submit'
              id='search-button'
              className={classes.searchButton}
              startIcon={hasSubmited ? <CloseOutlined /> : <SearchOutlined />}
              variant='outlined'
              data-testid={
                hasSubmited && dataTestId ? dataTestId.clear : dataTestId?.submit
              }
            >
              {!isMobile && !hasSubmited && t('Localizar')}
              {!isMobile && hasSubmited && t('Limpar')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})
