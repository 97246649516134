import { ChangeEvent, KeyboardEvent, MouseEvent, createRef, useState } from 'react'
import {
  Button,
  Grid,
  IconButton,
  Pagination,
  Select,
  Text2
} from 'components/design-system'
import { SearchInput } from 'components/common'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import makeStyles from './style'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { Theme, useMediaQuery } from '@mui/material'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useTranslation } from 'react-i18next'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import {
  atomOrderBy,
  atomPage,
  atomSearchTerm,
  atomStudentPropsData
} from '../../atomStore'
import { useAppcues } from 'components/AppCues/useAppcues'
import { AppcuesEventsEnum } from 'components/AppCues/events'
import { CredentialsPatternModal } from '../CredentialsPatternModal'
import { UserSchoolProfileTypeEnum, orderByNameOptions } from 'services'
import { useAtom } from 'jotai'
import { SmallDropdownFilters } from '../SmallDropdownFilters'
import { LinkedProfilesBox, ProfilesAccessedLastWeekBox, RegisteredProfilesBox } from 'components/modules'

interface IStudentHeader {
  handleSearch: (event: KeyboardEvent<HTMLInputElement>) => void
  handleSearchClick: () => void
  handleSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void
}

export const StudentHeader: React.FC<IStudentHeader> = ({ handleSearch, handleSearchClick, handleSearchTerm }) => {
  const { t } = useTranslation()
  const classes = makeStyles()
  const history = useHistory()
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const massRegistrationButtonRef = createRef<HTMLDivElement>()
  const credentialsButtonRef = createRef<HTMLDivElement>()
  const { eventDispatcher } = useAppcues()
  const profileType = UserSchoolProfileTypeEnum.student

  // atoms
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const setPage = useUpdateAtom(atomPage)
  const searchTerm = useAtomValue(atomSearchTerm)
  const studentPropsData = useAtomValue(atomStudentPropsData)

  // states
  const [openCredentialsModal, setOpenCredentialsModal] = useState<boolean>(false)

  const handleOrderBy = () => setOrderBy(orderBy === 'asc' ? 'desc' : 'asc')

  const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => setPage(value)

  const handleMassRegistration = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    massRegistrationButtonRef?.current?.click()
    eventDispatcher(AppcuesEventsEnum.adm_cad_massa_estudante)
    history.push(PROFILE_TABS('students/bulk-registration'))
    eventDispatcher(AppcuesEventsEnum.adm_cad_massa_estudante)
  }

  const handleCredentials = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    credentialsButtonRef?.current?.click()
    setOpenCredentialsModal(true)
  }

  const handleCloseCredentialsModal = () => setOpenCredentialsModal(false)

  return (
    <>
      <Grid container className={classes.header}>
        <Grid container item alignItems='center' justifyContent='space-between' rowSpacing={2}>
          <Grid item xs={12} md='auto'>
            <Text2
              fontSize='lg'
              fontWeight='semibold'
              lineHeight='xs'
              mobile='lg'
              customColor={theme.colorBrand.medium}
            >
              {t('Estudantes')}
            </Text2>
          </Grid>
          <Grid container item xs={12} md='auto' alignItems='center' justifyContent={isTablet ? 'space-between' : ''} columnSpacing={3}>
            <Grid item xs={isMobile ? 'auto' : 12} sm='auto' md='auto'>
              <div ref={massRegistrationButtonRef} data-testid='menu_studentes_list_students_register_students_in_mass' hidden />
              <Button onClick={handleMassRegistration} size='medium' startIcon={<AddOutlinedIcon />}>
                {t('Cadastro em massa')}
              </Button>
            </Grid>
            <Grid container item xs={isMobile ? 'auto' : 12} sm='auto' md='auto' alignItems='center' columnSpacing={1}>
              <div ref={credentialsButtonRef} data-testid='menu_studentes_list_students_credential_pattern' hidden />
              {isMobile
                ? (
                  <IconButton variant='outlined' onClick={handleCredentials}>
                    <TipsAndUpdatesOutlinedIcon />
                  </IconButton>
                )
                : (
                  <Button
                    endIcon={<TipsAndUpdatesOutlinedIcon />}
                    onClick={handleCredentials}
                    size='medium'
                    variant='ghost'
                  >
                    {t('Consultar padrão de credenciais')}
                  </Button>
                )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item marginTop={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <RegisteredProfilesBox selectedProfile={profileType} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <LinkedProfilesBox selectedProfile={profileType} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ProfilesAccessedLastWeekBox selectedProfile={profileType} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item columnSpacing={isTablet ? 1 : 3} rowSpacing={isTablet ? 3 : 0} marginTop={isTablet ? 0 : 3} justifyContent={isTablet ? 'space-between' : ''}>
          <Grid item xs={12} md className={classes.search}>
            <SearchInput
              className={classes.search}
              defaultValue={searchTerm}
              helperText={t('Pressione ENTER ou clique na lupa para pesquisar')}
              helperTextClassName={classes.helperText}
              id='searchStudent'
              label={t('Pesquisar por nome')}
              onChange={handleSearchTerm}
              onClick={handleSearchClick}
              onKeyDown={handleSearch}
              variant='filled'
            />
          </Grid>
          <Grid item xs={12} sm={isTablet ? 'auto' : true} md={4} lg={3} className={classes.orderBy}>
            <Select
              dataTestId='manage_teachers_order_by'
              id='order-by'
              label='Ordenar por'
              options={orderByNameOptions}
              value={orderBy}
              onChange={handleOrderBy}
            />
          </Grid>
          {!isMobile && (
            <Grid item xs='auto'>
              <SmallDropdownFilters />
            </Grid>
          )}
          {!!studentPropsData && (
            <Grid item xs={12} sm={isTablet ? 'auto' : true} md='auto' className={classes.pagination}>
              <Pagination
                boundaryCount={isMobile ? 0 : 1}
                siblingCount={isMobile ? 0 : 1}
                count={studentPropsData?.lastPage}
                page={studentPropsData?.page + 1}
                size='small'
                onChange={handleChangePage}
                dataTestIdElements={{
                  previous: 'menu_studentes_list_students_back_previous_page',
                  page: 'menu_studentes_list_students_select_page_number',
                  next: 'menu_studentes_list_students_advance_next_page'
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container item justifyContent='flex-end'>
          <Grid item xs='auto'>
            <Text2 fontSize='xxs' fontWeight='medium' lineHeight='md' mobile='xxs' neutral='light40'>
              {t(
                `{{totalStudents}} estudante${studentPropsData?.total === 1 ? '' : 's'}`,
                { totalStudents: studentPropsData?.total }
              )}
            </Text2>
          </Grid>
        </Grid>
      </Grid>

      <CredentialsPatternModal handleClose={handleCloseCredentialsModal} open={openCredentialsModal} />
    </>
  )
}
