import React, { ReactNode } from 'react'
import { SnackbarProps } from '@mui/material'
import MUiSnackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/design-system'
import CloseIcon from '@mui/icons-material/Close'
import useStyles from './style'
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'

export interface ISnackbarProps extends SnackbarProps {
  action?: ReactNode
  content?: string
  horizontal: 'left' | 'center' | 'right'
  icon?: ReactNode
  label?: string
  severity?: AlertColor
  vertical: 'top' | 'bottom'
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const Snackbar: React.FC<ISnackbarProps> = ({ content, action, icon, label, vertical = 'bottom', horizontal = 'right', ...props }) => {
  const classes = useStyles()
  return (
    <MUiSnackbar
      {...props}
      data-testid='snackbar-component'
      style={{ position: 'fixed', bottom: vertical === 'top' ? '100%' : '50px' }}
      anchorOrigin={{ vertical, horizontal }}
      action={
        <React.Fragment>
          { label &&
            <React.Fragment>
              <Button color='inherit' size='small'>
                {label}{action}
              </Button>
              <IconButton
                aria-label='close'
                color='inherit'
                sx={{ p: 0.5 }}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        </React.Fragment>
      }
      className={classes.root}
    >
      <Alert sx={{ width: '100%' }} icon={icon} severity={props.severity}>
        {content}
      </Alert>
    </MUiSnackbar>
  )
}
