/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BigNumbers, ReportsList } from '../'
import { Text2 } from 'components/design-system'
import { CustomInfoTooltip, Disciplines } from 'components/common'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { currentDisciplineReportAtom, lastUpdatedDateAtom } from '../../atomStore'
import { LastUpdate } from '../StudentReport/components'
import { useReport } from 'pages/ClassManagement/hooks'

export const ClassReport: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { classId } = useParams<{ classId?: string }>()
  const { getUpdatedDate, isLoadingDate } = useReport()

  // atoms
  const [currentDisciplineReport, setCurrentDisciplineReport] = useAtom(currentDisciplineReportAtom)
  const lastUpdatedDate = useAtomValue(lastUpdatedDateAtom)
  const resetCurrentDisciplineReport = useResetAtom(currentDisciplineReportAtom)
  const resetLastUpdatedDate = useResetAtom(lastUpdatedDateAtom)

  // states

  useEffect(() => {
    resetCurrentDisciplineReport()
    resetLastUpdatedDate()
    getUpdatedDate()
  }, [])

  return (
    <Grid container>
      <Grid container direction='column'>
        <Grid container item alignItems='center' columnSpacing={1}>
          <Grid item>
            <Text2
              fontSize='lg'
              fontWeight='semibold'
              lineHeight='xs'
              mobile='lg'
              customColor={theme.colorBrand.medium}
            >
              {t('Acompanhamento')}
            </Text2>
          </Grid>
          <Grid item>
            {!lastUpdatedDate && (
              <CustomInfoTooltip text={t('Os dados são gerados após a liberação de uma atividade ou avaliação e atualizados uma vez ao dia. Dados gerados depois da atualização serão considerados no dia seguinte.')} />
            )}
          </Grid>
        </Grid>

        <Grid item>
          {lastUpdatedDate && (
            <LastUpdate isLoading={isLoadingDate} />
          )}
        </Grid>
      </Grid>

      <Grid container item marginTop={3.5} xs={12}>
        <Grid item xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Componente curricular')}:
          </Text2>
        </Grid>
        <Grid item xs={12}>
          <Disciplines
            actionDataTestId='followup_reload'
            currentDiscipline={currentDisciplineReport}
            dataTestid='followup_select_curricular_component'
            showAlertOnError
            showAll
            onChange={setCurrentDisciplineReport}
            classId={classId ?? 1}
          />
        </Grid>
      </Grid>

      <Grid container marginTop={3.5} marginBottom={5}>
        <BigNumbers currentDiscipline={currentDisciplineReport} />
      </Grid>
      <Grid container marginBottom={3.5}>
        <ReportsList currentDiscipline={currentDisciplineReport} />
      </Grid>
    </Grid>
  )
}
