import { IProgramResponse, IProgramDataResponse, request, IApiResponse } from 'services'
import { PROGRAM_GET, PROGRAM_COUNT, PROGRAM_SCHOOL_GET_BY_GRADETYPE_GRADE, PROGRAM_SCHOOL_CREATE } from './CONSTANTS'

export const getPrograms = async (schoolId: number, gradeTypeId: number, gradeId: number, disciplineId: number): Promise<IProgramResponse[]> => {
  const baseUrl = PROGRAM_GET(schoolId, gradeTypeId, gradeId, disciplineId)
  const url = new URL(baseUrl)

  const response = await request<IProgramResponse[]>({ url: url.toString() })

  return response.data
}

export const countPrograms = async (schoolId: number, gradeTypeId: number, gradeId: number): Promise<number> => {
  const baseUrl = PROGRAM_COUNT(schoolId, gradeTypeId, gradeId)
  const url = new URL(baseUrl)

  const response = await request({ url: url.toString() })

  return Number(response.data)
}

export const getSchoolProgramByGradetypeAndGrade = async (gradeTypeId: number, gradeId: number, schoolId: number, disciplineId?: number): Promise<IApiResponse<IProgramDataResponse>> => {
  const baseUrl = PROGRAM_SCHOOL_GET_BY_GRADETYPE_GRADE(gradeTypeId, gradeId, schoolId, disciplineId)
  const url = new URL(baseUrl)

  return await request({ url: url.toString() })
}

export const createSchoolProgram = async (gradeId: number, schoolId: number): Promise<IApiResponse<IProgramDataResponse>> => {
  const baseUrl = PROGRAM_SCHOOL_CREATE()
  const url = new URL(baseUrl)

  const requestBody = {
    gradeId,
    schoolId
  }

  return await request({ url: url.toString(), method: 'POST', data: requestBody })
}
