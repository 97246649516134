import { makeStyles, createStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.small': {
      fontSize: theme.font.fontSize.sm
    },
    '&.medium': {
      fontSize: theme.font.fontSize.lg
    },
    '&.large': {
      fontSize: theme.font.fontSize.xl
    },
    '&.xlarge': {
      fontSize: theme.font.fontSize.xxl
    },
    '&.xxxl': {
      fontSize: theme.font.fontSize.xxxl
    }
  }
}))
