import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist } from 'zustand/middleware'

interface ILocalPreferencesStoreProps {
  isQuickAccessOpen: boolean | null
  setIsQuickAccessOpen: (isQuickAccessOpen: boolean) => void
}

export const useLocalPreferencesStore = create<
ILocalPreferencesStoreProps,
SetState<ILocalPreferencesStoreProps>,
GetState<ILocalPreferencesStoreProps>,
StoreApiWithPersist<ILocalPreferencesStoreProps>
>(
  persist(
    devtools((set, get) => ({
      isQuickAccessOpen: null,
      setIsQuickAccessOpen: (isQuickAccessOpen: boolean) => set({ isQuickAccessOpen })
    })),
    { name: 'cloe::web::preferences', version: 1, getStorage: () => localStorage }
  )
)
