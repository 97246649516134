import { createRef, useEffect, useState } from 'react'
import makeStyles from './style'
import { useAtomValue } from 'jotai/utils'
import { Button, Grid, Tag, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'
import { Skeleton, Theme, useMediaQuery, useTheme } from '@mui/material'
import { ArrowBackIos, DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { ActionContextEnum, ActionsControlCenter, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { useHistory } from 'react-router'
import { atomClassGradeTypeWithGrades, atomCurrentClass } from 'pages/ClassManagement/atomStore'
import { deleteClass } from 'services'
import { ClipBoardCopy, ConfirmationModal } from 'components/common'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

type ClassHeaderStates = 'idle' | 'loading' | 'header'

interface IClassHeader {
  classId: string | undefined
}

export const ClassHeader: React.FC<IClassHeader> = ({ classId }) => {
  const classes = makeStyles()
  const history = useHistory()
  const { createSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const backToListButtonRef = createRef<HTMLDivElement>()
  const editClassButtonRef = createRef<HTMLDivElement>()
  const deleteClassButtonRef = createRef<HTMLDivElement>()

  // atoms
  const classGradeTypeWithGrades = useAtomValue(atomClassGradeTypeWithGrades)
  const currentClass = useAtomValue(atomCurrentClass)

  // states
  const [classHeaderState, setClassHeaderState] = useState<ClassHeaderStates>('idle')
  const [open, setOpen] = useState(false)
  const [openAssociated, setOpenAssociated] = useState(false)

  useEffect(() => {
    setClassHeaderState(classGradeTypeWithGrades.length && currentClass
      ? 'header'
      : 'loading'
    )
  }, [classGradeTypeWithGrades, currentClass])

  const handleDeleteClass = async () => {
    if (classId) {
      const resDeleteClass = await deleteClass(parseInt(classId ?? ''))
      if (resDeleteClass.success) {
        history.push(CLASS_MANAGEMENT('list'))
      } else {
        resDeleteClass.errors?.forEach(error => {
          error?.statusCode === 428 ? setOpenAssociated(true) : createSnackbar({ content: error.message, severity: 'error' })
        })
      }
    }
  }

  const handleOpen = () => setOpen(!open)
  const handleOpenAssociated = () => setOpenAssociated(!openAssociated)
  const handleCallBack = () => {
    setOpen(false)
    handleDeleteClass()
  }

  const handleClickBackToListButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    backToListButtonRef?.current?.click()
    history.push(CLASS_MANAGEMENT('list'))
  }

  const handleClickEditClassButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    editClassButtonRef?.current?.click()
    history.replace(CLASS_MANAGEMENT(`edit/${classId ?? ''}`))
  }

  const handleClickDeleteClassButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    deleteClassButtonRef?.current?.click()
    if (currentClass?.active) handleOpen()
  }

  const stateMachine = {
    states: {
      idle: null,
      loading: (
        <div className={classes.header}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <Skeleton animation='wave' width={175.86} height={40} />
            </Grid>

            <Grid container item xs={12} md={8} spacing={2}>
              <Grid item>
                <Skeleton animation='wave' width={154.03} height={26} />
              </Grid>
              <Grid container item spacing={0.5}>
                <Grid item>
                  <Skeleton animation='wave' width={81.41} height={28} />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item>
                  <Skeleton animation='wave' width={225.81} height={39} />
                </Grid>
                <Grid container item alignItems='center' spacing={0.5}>
                  <Grid item>
                    <Skeleton animation='wave' width={108.78} height={19} />
                  </Grid>
                  <Grid item>
                    <Skeleton animation='wave' width={47.14} height={19} />
                  </Grid>
                  <Grid item>
                    <Skeleton animation='wave' width={36} height={36} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={4} justifyContent={isMobile ? 'flex-start' : 'flex-end'} columnSpacing={1} rowSpacing={2}>
              <Grid item xs='auto'>
                <Skeleton animation='wave' width={158.17} height={40} />
              </Grid>
              <Grid item xs='auto'>
                <Skeleton animation='wave' width={152.47} height={40} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      ),
      header: (
        <>
          <div className={classes.header}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <div ref={backToListButtonRef} data-testid='list_classes_manage_class_back_to_class_list' hidden />
                <Button size='medium' onClick={handleClickBackToListButton} startIcon={<ArrowBackIos fontSize='small' />} variant='outlined'>
                  {t('Lista de turmas')}
                </Button>
              </Grid>

              <Grid container item xs={12} md={8} rowSpacing={2}>
                {classGradeTypeWithGrades.map((gt, index) =>
                  <Grid item key={index}>
                    <Tag
                      active
                      background={theme.colorBrand.lightest}
                      label={gt.gradeType}
                      size='small'
                      textcolor={theme.colorBrand.darkest}
                    />
                  </Grid>
                )}
                <Grid container item rowSpacing={0.5}>
                  {currentClass?.grades.map((grade, index) =>
                    <Grid item key={index}>
                      <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' neutral='dark10' mobile='xxs'>
                        {grade.name}{index + 1 === currentClass?.grades.length ? ' ' : ', '}
                      </Text2>
                    </Grid>
                  )}
                </Grid>
                <Grid container item rowSpacing={1}>
                  <Grid item>
                    <Text2 fontSize='xl' fontWeight='semibold' lineHeight='xs' colorbrand='dark' mobile='sm'>
                      {currentClass?.name}
                      {!currentClass?.active && (
                        <span className={classes.italicStatusClassName}> ({t('inativa')})</span>
                      )}
                    </Text2>
                  </Grid>
                  <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.view_class_id} options={{ fullFeaturesDisabler: !currentClass?.active }}>
                    <Grid container item alignItems='center' rowSpacing={0.5}>
                      <Grid item>
                        <Text2 fontSize='sm' fontWeight='bold' lineHeight='sm' neutral='dark10' mobile='xxs'>
                          {t('ID da Turma')}:
                        </Text2>
                      </Grid>
                      <Grid item>
                        <Text2 fontSize='sm' fontWeight='medium' lineHeight='sm' neutral='dark10' mobile='xxs'>
                          {currentClass?.id}
                        </Text2>
                      </Grid>
                      <Grid item>
                        <ClipBoardCopy dataTestId='list_classes_manage_class_copy_class_id' copyItem={currentClass ? currentClass?.id.toString() : ''} />
                      </Grid>
                    </Grid>
                  </ActionsControlCenter>
                </Grid>
              </Grid>

              <Grid container item xs={12} md={4} justifyContent={isMobile ? 'flex-start' : 'flex-end'} columnSpacing={1} rowSpacing={2}>
                <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.delete} options={{ fullFeaturesDisabler: !currentClass?.active }}>
                  <Grid item xs='auto'>
                    <div ref={editClassButtonRef} data-testid='list_classes_manage_class_delete_class' hidden />
                    <Button size='medium' disabled={!currentClass?.active} onClick={handleClickDeleteClassButton} startIcon={<DeleteOutlined />} variant='feedbackNegativeGhost'>
                      {t('Excluir turma')}
                    </Button>
                  </Grid>
                </ActionsControlCenter>
                <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.edit} options={{ fullFeaturesDisabler: !currentClass?.active }}>
                  <Grid item xs='auto'>
                    <div ref={editClassButtonRef} data-testid='list_classes_manage_class_edit_class' hidden />
                    <Button size='medium' onClick={handleClickEditClassButton} startIcon={<EditOutlined />} variant='ghost'>
                      {t('Editar turma')}
                    </Button>
                  </Grid>
                </ActionsControlCenter>
              </Grid>
            </Grid>
          </div>
          <ConfirmationModal
            handleClose={handleOpen}
            isOpen={open}
            confirmCallback={handleCallBack}
            title='Excluir turma'
            description='Tem certeza que deseja excluir a turma?'
            cancelButtonDataTestid='delete_class_do_not_delete'
            confirmButtonDataTestid='delete_class_confirm'
          />
          <ConfirmationModal
            handleClose={handleOpenAssociated}
            isOpen={openAssociated}
            confirmCallback={handleOpenAssociated}
            title='Excluir turma'
            description='Para excluir a turma, você precisa desvincular os estudantes e professores que estão na turma.'
          />
        </>
      )
    }
  }

  return stateMachine.states[classHeaderState]
}
