import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => {
  return {
    card: {
      width: '100%',
      maxWidth: '302px',
      height: '500px'
    },
    programClassContainer: {
      width: '100%'
    }
  }
})
