import { getLinkLmsPlatform } from 'services/lms-platform'

import { TFunction } from 'react-i18next'
import Analytics from 'utils/analytics'
import { IProfilesSessionResponse } from 'services/types'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

const handleEventAnalytics = (type: 'success' | 'error', profile: IProfilesSessionResponse | null) => {
  Analytics.recordEventClick({
    name: `redirect_lms_plataform_${type}`,
    attributes: {
      ...profile?.analytics
    }
  })
}

export function useLmsPlatform(_t: TFunction, profile?: IProfilesSessionResponse | null) {
  const { createSnackbar } = useSnackbar()
  const getLink = async () => {
    try {
      createSnackbar({ content: 'Você será redirecionado em instantes', severity: 'info' })
      const response = await getLinkLmsPlatform()

      if (!response.data.url) throw new Error()

      setTimeout(() => {
        window.open(response.data.url)
      })

      if (profile) handleEventAnalytics('success', profile)
    } catch (err) {
      if (profile) handleEventAnalytics('error', profile)
      createSnackbar({ content: 'Não foi possível redirecionar para Formação', severity: 'error' })
    }
  }
  return { getLink }
}
