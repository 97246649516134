import { Fragment, ReactNode } from 'react'
import { renderChildrenWithProps } from 'utils/wrappers'
import { ToggleFeaturesEnum } from './enabledFeatures'
import { useFeatureToggle } from './hooks/useFeatureToggle'

interface IFeatureToggleOnProps {
  children: React.ReactNode
  feature: ToggleFeaturesEnum
  [propName: string]: any
}

interface IRenderChildren {
  children: ReactNode
  props?: {
    [propName: string]: any
  }
}

export const FeatureToggleSwitcher = ({ children, feature, ...props }: IFeatureToggleOnProps) => {
  const { validateEnabledFeatures } = useFeatureToggle()

  const validateFeature = () => {
    if (validateEnabledFeatures) return !!validateEnabledFeatures(feature)
  }

  const isFeatureEnabled = validateFeature()

  const renderChildren = renderChildrenWithProps<IRenderChildren>({ children, props })

  if (!isFeatureEnabled) {
    return <></>
  }

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}
