export interface IRecordEventRoute {
  url: string
}

export interface IRecordEventClick {
  id?: string
  name: string
  attributes?: {}
}

export interface IDataSetup {
  username: string | number
  userId?: string | number
  attributes?: any
}

export abstract class IAnalytics {
  abstract readonly name: string
  abstract readonly hasSetup: boolean
  abstract setup(data: any): void
  abstract recordEventClick(data: IRecordEventClick): void
  abstract recordEventRoute(data: IRecordEventRoute): void
}
