interface ICheckBlockedUser {
  setIsUserBlocked: (userState: boolean) => void
}

export class CheckBlockedUser implements ICheckBlockedUser {
  private isUserBlocked: boolean = false

  setIsUserBlocked(userState: boolean) {
    if (userState) {
      this.isUserBlocked = userState
    }
  }

  getUserBlockedStatus() {
    return this.isUserBlocked
  }
}

export const checkBlockedUser = new CheckBlockedUser()
