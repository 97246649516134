import {
  IApiResponse,
  IRegistrationTokenRequest,
  IRegistrationTokenResponse,
  IRegistrationTokenUpdate,
  IVinculateResponse,
  request,
  UserSchoolProfileTypeEnum
} from 'services'
import {
  REGISTRATION_TOKEN_GET,
  REGISTRATION_TOKEN_CREATE,
  REGISTRATION_TOKEN_UPDATE
} from './CONSTANTS'

export const generateRegistrationToken = async (data: IRegistrationTokenRequest): Promise<IApiResponse<IRegistrationTokenResponse>> =>
  await request({ url: REGISTRATION_TOKEN_CREATE(), method: 'post', data: data })

export const getRegistrationToken = async (type: UserSchoolProfileTypeEnum.student | UserSchoolProfileTypeEnum.teacher, classId?: number, schoolId?: number): Promise<IApiResponse<IRegistrationTokenResponse[]>> =>
  await request({ url: REGISTRATION_TOKEN_GET(type, classId, schoolId) })

export const updateRegistrationToken = async (classId: number, data: IRegistrationTokenUpdate): Promise<IApiResponse<IRegistrationTokenResponse>> =>
  await request({ url: REGISTRATION_TOKEN_UPDATE(classId), method: 'put', data: data, isLogin: true })

export const postVinculateToken = async (token: string): Promise<IApiResponse<IVinculateResponse>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/users/vinculate-registration-token/${token}`, method: 'post', data: null })
