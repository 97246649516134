import { Theme } from '@mui/material'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'

const styleDefault: (theme: Theme) => CSSProperties = theme => ({
  textTransform: 'none',
  fontFamily: theme.font.fontFamily,
  fontSize: theme.font.fontSize.sm,
  fontWeight: theme.font.fontWeight.semibold,
  border: theme.borderThickness.none,
  padding: theme.spacingInline.xxxs,
  transition: '.3s',
  '& svg': {
    marginRight: '8px'
  }
})

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.primary': {
      ...styleDefault(theme),
      color: theme.colorBrand.medium,
      border: `solid ${theme.borderThickness.hairline}`,
      borderColor: theme.colorBrand.lightest,

      '&.Mui-selected': {
        background: theme.colorBrand.dark,
        borderColor: theme.colorBrand.dark,
        color: theme.colors.neutral.lightBase,

        '&:hover': {
          background: theme.colorBrand.darkest,
          borderColor: theme.colorBrand.darkest,
          color: theme.colors.neutral.lightBase
        }
      },
      '&:hover': {
        background: theme.colorBrand.lightest,
        color: theme.colorBrand.dark
      }
    },
    '&.MuiToggleButton-root': {
      '&.primary': {
        '&.tabrole': {
          fontSize: '16px',
          color: theme.colors.neutral.dark30,
          border: '0',
          borderRadius: '0',
          transition: 'none',
          '&[aria-pressed="true"]': {
            borderBottom: `2px solid ${theme.colorBrand.medium}`,
            backgroundColor: theme.colors.neutral.lightBase,
            color: theme.colorBrand.medium,
            '&:hover': {
              color: theme.colorBrand.medium
            }
          }
        },
        '&:hover': {
          transition: 'all ease-in-out 0.2s'
        }
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xs
      }
    }
  }
}))
