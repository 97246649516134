import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiTab-root': {
      maxWidth: 'none',
      minWidth: 'none',
      background: theme.colorBrand.lightest,
      borderRadius: '16px 16px 0px 0px',
      color: theme.colorBrand.darkest,
      flexGrow: 1,
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,
      padding: '16px',
      transition: '.25s',
      '&.Mui-selected': {
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase
      },
      '&.MuiTab-indicator': {
        backgroundColor: theme.colorBrand.lightest
      },
      '& svg': {
        marginRight: '10px',
        verticalAlign: 'middle'
      },
      '& span': {
        textTransform: 'none'
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0',
        '&.Mui-selected': {
          '& svg': {
            marginRight: '10px'
          },
          '& span': {
            display: 'inline',
            verticalAlign: 'middle'
          }
        },
        '& svg': {
          marginRight: '0'
        },
        '& span': {
          display: 'none'
        }
      }
    },
    '&.MuiButtonBase-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'inherit'
      }
    }
  }
}))
