import { Accordion, AccordionDetails, AccordionSummary, CardContent, useTheme, useMediaQuery } from '@mui/material'
import { Avatar, Card, Grid, Text2 } from 'components/design-system'
import React, { useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { StudentCardInfo } from '../StudentCardInfo'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IStudentsPerformance } from 'services'
import customParseFormat from 'dayjs/plugin/customParseFormat'

interface IStudentCardInfoWithAvatar {
  studentPerformanceAndData: IStudentsPerformance
}

export const StudentCardInfoWithAvatar: React.FC<IStudentCardInfoWithAvatar> = ({ studentPerformanceAndData }) => {
  dayjs.extend(customParseFormat)

  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const {
    user,
    user_school_profile: userSchoolProfile
  } = studentPerformanceAndData
  const { created_at: createdAt } = user
  const { last_used: lastUsed } = userSchoolProfile

  const fullname = user ? `${user.name} ${user.surname}` : ''

  // states
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const handleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <Card extraClasses={classes.studentCard} noPadding>
      <CardContent className={classes.cardContent}>
        <Grid container alignItems='center' className={classes.cardContentGrid} columnSpacing={3} rowSpacing={isMobile ? 2 : 0}>
          <Grid item xs={12} lg='auto' className={classes.avatarContainer}>
            <Avatar fullname={fullname} className={classes.customAvatar} />
          </Grid>
          <Grid container item xs={12} lg alignItems={isMobile ? 'center' : 'flex-start'} direction='column'>
            <Grid item className={classes.studentName}>
              <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='md' neutral='dark30'>
                {fullname}
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark40'>
                { t('Estudante desde {{date}}', { date: createdAt }) }
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark40'>
                { lastUsed
                  ? `${t('Último acesso')}: ${dayjs(lastUsed, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}`
                  : t('Nunca acessou')
                }
              </Text2>
            </Grid>
          </Grid>
          {isMobile && studentPerformanceAndData && (
            <Grid container item justifyContent='center' xs={12}>
              <Grid item xs>
                <Accordion className={classes.accordion} expanded={isExpanded}>
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon className={classes.expandIcon} fontSize='large' onClick={handleExpanded} />} onClick={handleExpanded}>
                    <Text2 fontSize='xs' fontWeight='bold' lineHeight='md' mobile='xs' colorbrand='medium' cursor='pointer'>
                      {t(`${isExpanded ? 'Ocultar' : 'Mostrar'} dados pessoais`)}
                    </Text2>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <StudentCardInfo studentPerformanceAndData={studentPerformanceAndData} noBorder noPadding />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
