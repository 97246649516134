import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Tooltip } from 'components/common'
import {
  Avatar,
  Container,
  Grid,
  Link,
  Switch,
  Tag,
  Text2,
  Loading
} from 'components/design-system'
import { PROGRAM_MANAGEMENT } from 'navigation/CONSTANTS'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import makeStyles from './style'
import { ProgramManagementCard } from '../ProgramManagementCard'
import { useAtom } from 'jotai'
import {
  atomDiscipline,
  atomGradeType,
  atomGrade,
  atomSelectingProgramDateCard,
  atomDisciplineProgramList
} from '../../atomStore'
import { DateRange } from '@mui/lab/DateRangePicker'
import { useState, useEffect } from 'react'
import format from 'date-fns/format'
import {
  activateProgramContentUnit,
  deleteProgramSetup,
  getSchoolProgramByGradetypeAndGrade,
  IContentUnitWithDiscipline,
  IContentUnit,
  IDisciplineResponse,
  IProgramDataResponse,
  IProgramRequest
} from 'services'
import { useStore } from 'store'
import { Typography } from '@mui/material'
import placeholder from 'assets/base-screen-background.svg'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

export const SchoolProgramList: React.FC = () => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const customTheme = useTheme<CustomTheme>()
  const { school } = useStore()

  const [currentDiscipline, setCurrentDiscipline] = useAtom(atomDiscipline)
  const [gradeType] = useAtom(atomGradeType)
  const [grade] = useAtom(atomGrade)
  const [, setSelectingProgramDateCard] = useAtom(atomSelectingProgramDateCard)
  const [dateRange, setDateRange] = useState<string[] | undefined>([])
  const [selectedCard, setSelectedCard] = useState<number | undefined>(undefined)
  const [disciplineList] = useAtom(atomDisciplineProgramList)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deletedContentUnits, setDeletedContentUnits] = useState<IContentUnitWithDiscipline[] | undefined>(undefined)
  const [contentUnits, setContentUnits] = useState<IContentUnitWithDiscipline[] | undefined>(undefined)
  const [schoolProgram, setSchoolProgram] = useState<IProgramDataResponse | undefined>(undefined)
  const { createSnackbar } = useSnackbar()

  const fetchProgramData = async ({ gradeTypeId, gradeId, schoolId, discipline }: IProgramRequest) => {
    if (gradeTypeId && gradeId && schoolId && discipline) {
      try {
        setIsLoading(true)
        const response = await getSchoolProgramByGradetypeAndGrade(gradeTypeId, gradeId, schoolId, discipline.id)
        if (response.success) {
          setSchoolProgram(response.data)
          setContentUnits(filterContentUnitByDiscipline(discipline, response.data.content_units))
          setDeletedContentUnits(filterContentUnitByDiscipline(discipline, response.data.deleted_content_units))
        } else {
          createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
        }
      } catch (err) {
        createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (gradeType?.id && grade?.id && school?.id && currentDiscipline) {
      fetchProgramData({
        gradeTypeId: gradeType.id,
        gradeId: grade.id,
        schoolId: Number(school?.id),
        discipline: currentDiscipline
      })
    }
  }, [currentDiscipline, school?.id])

  useEffect(() => {
    if (disciplineList?.length) {
      setCurrentDiscipline(disciplineList[0])
    }
  }, [disciplineList])

  const filterContentUnitByDiscipline = (discipline: IDisciplineResponse, contentUnitList: IContentUnit[] | undefined) => {
    if (contentUnitList) {
      return contentUnitList.map(ctu => {
        if (ctu.discipline_id === discipline.id) {
          return {
            ...ctu,
            discipline_name: discipline.name,
            discipline_code: discipline.code
          }
        }
        return ctu
      }).filter(ctu => ctu.discipline_id === discipline.id)
    }
  }

  const handleCalendarConfirm = (dates: DateRange<Date>, cardId: number) => {
    setSelectedCard(cardId)
    setDateRange(formatDates(dates))
    console.log(dateRange, selectedCard)
    setSelectingProgramDateCard(undefined)
  }

  const handleActivateContentUnit = async (contentUnit: IContentUnitWithDiscipline) => {
    if (gradeType?.id && grade?.id && school?.id && contentUnit?.program_setup_id && currentDiscipline) {
      const gradeTypeId = gradeType.id
      const gradeId = grade.id
      const schoolId = Number(school.id)
      const setupId = contentUnit.program_setup_id
      try {
        const response = await activateProgramContentUnit(gradeTypeId, gradeId, schoolId, setupId)
        if (response.errors) {
          throw new Error('Ocorreu um erro ao ativar este conteúdo. Tente novamente')
        } else {
          await fetchProgramData({
            gradeTypeId,
            gradeId,
            schoolId,
            discipline: currentDiscipline
          })
          createSnackbar({
            content: 'Unidade de conteúdo adicionada a lista de Ativos.',
            severity: 'success'
          })
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      }
    }
  }

  const handleDeactivateContentUnit = async (contentUnit: IContentUnitWithDiscipline) => {
    if (gradeType?.id && grade?.id && school?.id && contentUnit?.program_setup_id && currentDiscipline) {
      try {
        const response = await deleteProgramSetup(contentUnit.program_setup_id)
        if (response.errors) {
          throw new Error('Ocorreu um erro ao tentar inativar o conteúdo. Tente novamente')
        } else {
          await fetchProgramData({
            gradeTypeId: gradeType.id,
            gradeId: grade.id,
            schoolId: school.id,
            discipline: currentDiscipline
          })
          createSnackbar({ content: 'Unidade de conteúdo adicionada a lista de Desabilitadas.', severity: 'success' })
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      }
    }
  }

  const formatDates = (dates: DateRange<Date>) => {
    if (dates) {
      const [initialDate, finalDate] = dates
      if (initialDate && finalDate) {
        return [
          format(new Date(initialDate), 'dd/MM/yy'),
          format(new Date(finalDate), 'dd/MM/yy')
        ]
      }
    }
  }

  return (
    <Container>
      <Grid container>
        {/* Header */}
        <Grid container item alignItems='center' justifyContent='space-between' rowSpacing={3}>
          <Grid item xs={12} md={12}>
            <Link variant='returnArrow' onClick={() => history.push(PROGRAM_MANAGEMENT)} data-testid='program_school_program_back_to__home_screen' linkStyle>
              {t('Voltar para tela inicial')}
            </Link>
          </Grid>
          <Grid container item xs={12} md columnSpacing={1}>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='lg' colorbrand='darkest'>
                {t('Programa da escola')}
              </Text2>
            </Grid>
            <Grid item>
              <Tag label={gradeType?.name} />
            </Grid>
            <Grid item>
              <Tag label={grade?.name} />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={1}>
            <Grid item xs={12} md={12}>
              {t('Selecione um componente curricular')}:
            </Grid>
            {disciplineList?.map(d =>
              <Grid item key={d.id}>
                <Tag
                  label={d.name}
                  discipline={d.code}
                  className={`${d.id === currentDiscipline?.id ? 'active' : ''}`}
                  onClick={() => setCurrentDiscipline(d)}
                  data-testid='program_school_curricular_component'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Content units */}
        {
          isLoading
            ? <Grid className={classes.loadingBox}><Loading type='circular' /></Grid>
            : <Grid container>
              <Grid container item justifyContent='flex-start' alignItems='flex-start' spacing={1} sx={{ marginTop: customTheme.spacingInline.md }}>
                <Grid item>
                  <Avatar sx={{ bgcolor: customTheme.colorBrand.medium }}>
                    <NewspaperIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Text2 fontSize='md' fontWeight='bold' lineHeight='sm' mobile='sm' colorbrand='medium'>
                    {t('Unidade de conteúdo')}
                  </Text2>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                    <Trans>
                      Para tornar uma unidade de conteúdo inativo, <strong>clique no botão verde e altere o status da unidade de conteúdo ativo para inativo.</strong>
                    </Trans>
                  </Text2>
                </Grid>
                <Grid container item spacing={3} className={classes.cardsContainer}>
                  {
                    contentUnits?.map((item) => (
                      <Grid item key={item?.id}>
                        <ProgramManagementCard
                          contentfulCardProps={{
                            image: {
                              src: item?.cover?.url ?? placeholder,
                              alt: item?.title,
                              height: '141'
                            },
                            tag: {
                              label: item?.discipline_name,
                              discipline: item?.discipline_code
                            }
                          }}
                          calendarProps={{
                            onCancel: () => { setSelectingProgramDateCard(undefined) },
                            onConfirm: (dates: DateRange<Date>) => handleCalendarConfirm(dates, item?.id),
                            onChange: () => {
                              // This is intentional
                            }
                          }}
                          cardId={item?.id}
                        >
                          <Grid container rowSpacing={2}>
                            <Grid container item alignItems='center' rowSpacing={1}>
                              <Grid item xs={9} md={9} className={classes.switch}>
                                <Switch
                                  label='Conteúdo ativo'
                                  checked={!schoolProgram?.content_units?.includes(item)}
                                  onChange={async () => await handleDeactivateContentUnit(item)}
                                  dataTestId='program_school_disable_content'
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark30'>
                                  {item.classes} aulas
                                </Text2>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography noWrap><Text2 fontSize='sm' fontWeight='bold' lineHeight='xs' mobile='sm' colorbrand='medium'>
                                  {item.title}
                                </Text2></Typography>
                              </Grid>
                            </Grid>
                            <div className={classes.skillContainer}>
                              <Grid container item rowSpacing={1} columnSpacing={1}>
                                {
                                  item.skills.map(skill =>
                                    <Grid item xs={4} md={4} key={skill?.id}>
                                      <Tooltip
                                        label={skill.code}
                                        placement='top-start'
                                        title={skill.name}
                                        variant='colored'
                                      >
                                        <Tag
                                          className={classes.tag}
                                          label={skill.code}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            </div>
                          </Grid>
                        </ProgramManagementCard>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
              {
                deletedContentUnits && deletedContentUnits?.length > 0
                  ? <Grid container item justifyContent='flex-start' alignItems='flex-start' spacing={1} sx={{ marginTop: customTheme.spacingInline.md }}>
                    <Grid item>
                      <Avatar sx={{ bgcolor: customTheme.colorBrand.medium }}>
                        <VisibilityOffOutlinedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Text2 fontSize='md' fontWeight='bold' lineHeight='sm' mobile='sm' colorbrand='medium'>
                        {t('Desabilitados')}
                      </Text2>
                    </Grid>
                    <Grid container item spacing={3} className={classes.cardsContainer}>
                      {
                        deletedContentUnits?.map((item) => (
                          <Grid item key={item?.id}>
                            <ProgramManagementCard
                              contentfulCardProps={{
                                image: {
                                  src: item?.cover?.url ?? placeholder,
                                  alt: item?.title,
                                  height: '141',
                                  disabled: true
                                },
                                tag: {
                                  label: item?.discipline_name,
                                  discipline: item?.discipline_code
                                }
                              }}
                              calendarProps={{
                                onCancel: () => { setSelectingProgramDateCard(undefined) },
                                onConfirm: (dates: DateRange<Date>) => handleCalendarConfirm(dates, item?.id),
                                onChange: () => {
                                  // This is intentional
                                }
                              }}
                              cardId={item?.id}
                            >
                              <Grid container rowSpacing={2}>
                                <Grid container item alignItems='center' rowSpacing={1}>
                                  <Grid item xs={9} md={9} className={classes.switch}>
                                    <Switch
                                      label='Conteúdo inativo'
                                      checked={schoolProgram?.deleted_content_units?.includes(item)}
                                      onChange={async () => await handleActivateContentUnit(item)}
                                      dataTestId='program_school_enable_content'
                                    />
                                  </Grid>
                                  <Grid item xs={3} md={3}>
                                    <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark30'>
                                      {item.classes} aulas
                                    </Text2>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <Typography noWrap><Text2 fontSize='sm' fontWeight='bold' lineHeight='xs' mobile='sm' colorbrand='medium'>
                                      {item.title}
                                    </Text2></Typography>
                                  </Grid>
                                </Grid>
                                <Grid container item rowSpacing={1} columnSpacing={1}>
                                  {
                                    item.skills.map(skill =>
                                      <Grid item xs={4} md={4} key={skill?.id}>
                                        <Tooltip
                                          label={skill.code}
                                          placement='top-start'
                                          title={skill.name}
                                          variant='colored'
                                        >
                                          <Tag
                                            className={classes.tag}
                                            label={skill.code}
                                          />
                                        </Tooltip>
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              </Grid>
                            </ProgramManagementCard>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                  : null
              }
            </Grid>
        }
      </Grid>
    </Container>
  )
}

export default SchoolProgramList
