import React from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import useStyle from './style'

export const NewEnrollmentsChartSkeleton: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const chartItemWidth = isMobile ? 30 : 48
  const chartItemsHeight = [67, 100, 130, 120, 90, 88]

  return (
    <Grid container item xs={12} className={classes.loadingContainer}>
      <Grid item xs={12} className={classes.loadingChart}>
        {chartItemsHeight.map(height => (
          <Skeleton variant='rectangular' width={chartItemWidth} height={height} animation='wave' />
        ))}
      </Grid>
    </Grid>
  )
}
