import { UserSchoolProfileTypeEnum } from 'services'
import { LinkedProfilesBoxStudent } from './student'
import { LinkedProfilesBoxTeacher } from './teacher'

interface ILinkedProfilesBox {
  selectedProfile: UserSchoolProfileTypeEnum
}

export const LinkedProfilesBox: React.FC<ILinkedProfilesBox> = ({ selectedProfile }) => {
  if (selectedProfile === UserSchoolProfileTypeEnum.student) {
    return <LinkedProfilesBoxStudent />
  }
  if (selectedProfile === UserSchoolProfileTypeEnum.teacher) {
    return <LinkedProfilesBoxTeacher />
  }
  return <></>
}
