export type IModalBasicStructure = {
  [key: string]: List
}

type List = {
  [key: string]: Profile
}

type Profile = {
  [key: string]: Phrases
}

interface Phrases {
  singular: string
  plural: string
}

const modalActivate: IModalBasicStructure = {
  list: {
    teacher: {
      title: {
        singular: 'Tem certeza que deseja ativar o perfil do usuário selecionado?',
        plural: 'Tem certeza que deseja ativar os perfis dos usuários selecionados?'
      }
    },
    student: {
      title: {
        singular: 'Tem certeza que deseja ativar o perfil do usuário selecionado?',
        plural: 'Tem certeza que deseja ativar os perfis dos usuários selecionados?'
      }
    }
  },
  classList: {
    teacher: {
      title: {
        singular: 'Tem certeza que deseja ativar o perfil do usuário selecionado?',
        plural: 'Tem certeza que deseja ativar os perfis dos usuários selecionados?'
      }
    },
    student: {
      title: {
        singular: 'Tem certeza que deseja ativar o perfil do usuário selecionado?',
        plural: 'Tem certeza que deseja ativar os perfis dos usuários selecionados?'
      }
    }
  }
}

const modalDeactivate: IModalBasicStructure = {
  list: {
    teacher: {
      title: {
        singular: 'Tem certeza que deseja desativar o perfil do usuário selecionado na Cloe?',
        plural: 'Tem certeza que deseja desativar os perfis dos usuários selecionados na Cloe?'
      },
      description: {
        singular: 'Ao desativar o perfil do usuário, ele não terá mais acesso à plataforma. Usuários com mais de um perfil, somente o perfil de professor será desativado.',
        plural: 'Ao desativar os perfis dos usuários, eles não terão mais acesso à plataforma. Usuários com mais de um perfil, somente o perfil de professor será desativado.'
      }
    },
    student: {
      title: {
        singular: 'Tem certeza que deseja desativar o perfil do usuário selecionado na Cloe?',
        plural: 'Tem certeza que deseja desativar os perfis dos usuários selecionados na Cloe?'
      },
      description: {
        singular: 'Ao desativar o perfil do usuário, ele não terá mais acesso à plataforma.',
        plural: 'Ao desativar os perfis dos usuários, eles não terão mais acesso à plataforma.'
      }
    }
  },
  classList: {
    teacher: {
      title: {
        singular: 'Tem certeza que deseja desativar o perfil do usuário selecionado na Cloe?',
        plural: 'Tem certeza que deseja desativar os perfis dos usuários selecionados na Cloe?'
      },
      description: {
        singular: 'Ao desativar o perfil do usuário, ele não terá mais acesso à plataforma. Usuários com mais de um perfil, somente o perfil de professor será desativado.',
        plural: 'Ao desativar os perfis dos usuários, eles não terão mais acesso à plataforma. Usuários com mais de um perfil, somente o perfil de professor será desativado.'
      }
    },
    student: {
      title: {
        singular: 'Tem certeza que deseja desativar o perfil do usuário selecionado na Cloe?',
        plural: 'Tem certeza que deseja desativar os perfis dos usuários selecionados na Cloe?'
      },
      description: {
        singular: 'Ao desativar o perfil do usuário, ele não terá mais acesso à plataforma.',
        plural: 'Ao desativar os perfis dos usuários, eles não terão mais acesso à plataforma.'
      }
    }
  }
}

const modalClassSubsWarn: IModalBasicStructure = {
  list: {
    student: {
      title: {
        singular: 'O perfil do usuário não pode ser ativado',
        plural: 'Os perfis dos usuários não podem ser ativados'
      },
      description: {
        singular: 'Para ativar o perfil de usuário, ele precisa estar vinculado à uma turma. Verifique e tente novamente.',
        plural: 'Para ativar os perfis de usuários, eles precisam estar vinculados à uma turma. Verifique e tente novamente.'
      }
    },
    teacher: {
      title: {
        singular: '',
        plural: ''
      },
      description: {
        singular: '',
        plural: ''
      }
    }
  },
  classList: {
    student: {
      title: {
        singular: 'O perfil do usuário não pode ser ativado',
        plural: 'Os perfis dos usuários não podem ser ativados'
      },
      description: {
        singular: 'Para ativar o perfil de usuário, ele precisa estar vinculado à uma turma. Verifique e tente novamente.',
        plural: 'Para ativar os perfis de usuários, eles precisam estar vinculados à uma turma. Verifique e tente novamente.'
      }
    },
    teacher: {
      title: {
        singular: '',
        plural: ''
      },
      description: {
        singular: '',
        plural: ''
      }
    }
  }
}

const modalPartialClassSubsWarn: IModalBasicStructure = {
  list: {
    student: {
      title: {
        singular: 'O perfil de usuário abaixo não pode ser ativado',
        plural: 'Os perfis de usuários abaixo não podem ser ativados'
      },
      description: {
        singular: 'Para ativar o perfil de usuário, ele precisa estar vinculado à uma turma. Verifique e tente novamente.',
        plural: 'Para ativar os perfis de usuários, eles precisam estar vinculados à uma turma. Verifique e tente novamente.'
      },
      subtitle: {
        singular: 'Você pode ativar outro perfil que foi selecionado.',
        plural: 'Você pode ativar outros perfis que foram selecionados.'
      },
      subdescription: {
        singular: 'Deseja ativar o outro perfil de usuário selecionado?',
        plural: 'Deseja ativar os outros perfis de usuários selecionados?'
      }
    },
    teacher: {
      title: {
        singular: '',
        plural: ''
      },
      description: {
        singular: '',
        plural: ''
      },
      subtitle: {
        singular: '',
        plural: ''
      },
      subdescription: {
        singular: '',
        plural: ''
      }
    }
  },
  classList: {
    student: {
      title: {
        singular: 'O perfil de usuário abaixo não pode ser ativado',
        plural: 'Os perfis de usuários abaixo não podem ser ativados'
      },
      description: {
        singular: 'Para ativar o perfil de usuário, ele precisa estar vinculado à uma turma. Verifique e tente novamente.',
        plural: 'Para ativar os perfis de usuários, eles precisam estar vinculados à uma turma. Verifique e tente novamente.'
      },
      subtitle: {
        singular: 'Você pode ativar outro perfil que foi selecionado.',
        plural: 'Você pode ativar outros perfis que foram selecionados.'
      },
      subdescription: {
        singular: 'Deseja ativar o outro perfil de usuário selecionado?',
        plural: 'Deseja ativar os outros perfis de usuários selecionados?'
      }
    },
    teacher: {
      title: {
        singular: '',
        plural: ''
      },
      description: {
        singular: '',
        plural: ''
      },
      subtitle: {
        singular: '',
        plural: ''
      },
      subdescription: {
        singular: '',
        plural: ''
      }
    }
  }
}

export { modalActivate, modalDeactivate, modalClassSubsWarn, modalPartialClassSubsWarn }
