/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createRef, MouseEvent, useEffect, useState } from 'react'
import { Button, Grid, Text2 } from 'components/design-system'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { currentStudentPerformanceAndDataAtom, reportStateAtom } from 'pages/ClassManagement/atomStore'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { DetailedPerformance, LastUpdate, StudentCards, StudentClassComparison, StudentSituationDescription } from './components'
import { Disciplines } from 'components/common'
import { useParams } from 'react-router'
import { IDiscipline } from 'services/types/discipline'
import useStyles from './style'
import { BigNumbersStudent } from '../BigNumbersStudent'
import { useStore } from 'store'
import { getClassPerformance, getStudentPerformance } from 'services/cloe-reports'
import { IClassPerformance, IStudentPerformance } from 'services'
import { bigNumbersDataAtom } from '../BigNumbers/atomStore'
import { useAtom } from 'jotai'
import { currentDisciplineReportAtom, lastUpdatedDateAtom } from '../../atomStore'
import { useReport } from 'pages/ClassManagement/hooks'

export const StudentReport: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const backButtonRef = createRef<HTMLDivElement>()
  const { classId } = useParams<{ classId?: string }>()
  const classes = useStyles()
  const { profile, school } = useStore()
  const { getUpdatedDate, isLoadingDate } = useReport()

  // atoms
  const [bigNumbersData, setBigNumbersData] = useAtom(bigNumbersDataAtom)
  const currentStudentPerformanceAndData = useAtomValue(currentStudentPerformanceAndDataAtom)
  const lastUpdatedDate = useAtomValue(lastUpdatedDateAtom)
  const setReportState = useUpdateAtom(reportStateAtom)

  // state
  const [currentDisciplineReport, setCurrentDisciplineReport] = useAtom(currentDisciplineReportAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [studentPerformance, setStudentPerformance] = useState<IStudentPerformance | undefined>(undefined)

  const handleBackButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    backButtonRef?.current?.click()
    setReportState('classReport')
  }

  const handleChangeDiscipline = (discipline: IDiscipline) => setCurrentDisciplineReport(discipline)

  const fetchClassPerformance = async () => {
    if (typeof currentDisciplineReport === 'undefined') {
      return
    }

    try {
      if (!currentDisciplineReport?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const bigNumbersResponse = await getClassPerformance({ schoolId: profile?.school?.id, classId: Number(classId), disciplineId: Number(currentDisciplineReport.id) })

      if (!bigNumbersResponse.success) {
        if (!bigNumbersResponse.status || ![400, 404].includes(bigNumbersResponse.status)) { // TODO: CHANGE THIS 400 to only 404
          throw new Error(bigNumbersResponse.message)
        }
        bigNumbersResponse.data = null as unknown as IClassPerformance
      }

      const newClassData: IClassPerformance | null = bigNumbersResponse.data
      setBigNumbersData({ data: newClassData, error: null })
    } catch (err: any) {
      setBigNumbersData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    }
  }

  const fetchStudentPerformance = async () => {
    if (school?.id && classId && currentDisciplineReport?.id && currentStudentPerformanceAndData?.user_school_profile?.id) {
      const payload = {
        schoolId: school.id,
        classId: Number(classId),
        disciplineId: Number(currentDisciplineReport.id),
        studentProfileId: currentStudentPerformanceAndData.user_school_profile.id
      }

      const resStudentPerformance = await getStudentPerformance(payload)

      if (resStudentPerformance?.data) {
        setStudentPerformance(resStudentPerformance?.data)
      } else {
        setStudentPerformance(undefined)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!lastUpdatedDate) {
      getUpdatedDate()
    }
  }, [lastUpdatedDate])

  useEffect(() => {
    if (currentDisciplineReport && Number(currentDisciplineReport.id) !== 0) {
      setIsLoading(true)
      fetchClassPerformance()
      fetchStudentPerformance()
    }
  }, [currentDisciplineReport])

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs className={classes.backButtonGrid}>
        <div ref={backButtonRef} data-testid='followup_student_activity_back_to_followup_class' hidden />
        <Button onClick={handleBackButton} size='medium' startIcon={<ArrowBackIos fontSize='small' />} variant='ghost'>
          {t('Voltar')}
        </Button>
      </Grid>

      <StudentCards />

      <Grid container item xs={12} rowSpacing={1}>
        <Grid item xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Componente curricular')}:
          </Text2>
        </Grid>
        <Grid item xs={12}>
          <Disciplines
            actionDataTestId='followup_student_activity_evaluation_reload'
            currentDiscipline={currentDisciplineReport}
            dataTestid='followup_student_activity_select_curricular_component'
            showAlertOnError
            showAll={false}
            onChange={handleChangeDiscipline}
            classId={classId ?? 1}
          />
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <LastUpdate isLoading={isLoadingDate} />
        </Grid>
        <Grid item xs={12}>
          <BigNumbersStudent
            isLoading={isLoading}
            studentName={currentStudentPerformanceAndData?.user?.name ?? ''}
            studentPerformance={studentPerformance}
          />
        </Grid>
      </Grid>

      {((bigNumbersData?.data?.dec_performance !== undefined && studentPerformance?.dec_performance !== undefined) ?? isLoading)
        ? (
          <Grid container item xs={12} columnSpacing={4} rowSpacing={3}>
            <Grid item xs={12} md={6}>
              <StudentClassComparison
                classAverage={bigNumbersData?.data?.dec_performance ?? -1}
                isLoading={isLoading}
                studentSituation={studentPerformance?.nr_situation ?? -1}
                studentAverage={studentPerformance?.dec_performance ?? -1}
                studentName={currentStudentPerformanceAndData?.user?.name ?? ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StudentSituationDescription
                isLoading={isLoading}
                studentSituation={studentPerformance?.nr_situation ?? -1}
              />
            </Grid>
          </Grid>
        )
        : null
      }

      <DetailedPerformance
        currentDiscipline={currentDisciplineReport}
        isLoading={isLoading}
      />
    </Grid>
  )
}
