import { useState, useEffect } from 'react'
import { LastWeekAccessChart } from './components/LastWeekAccessChart'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { IWeeklyUsageResponse } from 'services'
import useStyle from './style'
import { Grid, useTheme } from '@mui/material'
import { getWeeklyUsage } from 'services/cloe-reports'
import { Alert, Text2 } from 'components/design-system'
import { CustomInfoTooltip } from 'components/common'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  READY = 'READY'
}

export const LastWeekAccess = () => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  const { school } = useStore()

  // states
  const [lastWeekData, setLastWeekData] = useState<IWeeklyUsageResponse>([])
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    fetchLastWeekAccessData()
  }, [school])

  const fetchLastWeekAccessData = async () => {
    setStateMachine(StateMachineEnum.LOADING)
    try {
      if (!school?.id) {
        throw new Error('Não foi possivel resgatar as informações da escola')
      }

      const response = await getWeeklyUsage({ schoolId: school.id })

      if (!response || !response.data) {
        throw new Error(response?.message ?? 'Erro ao resgatar informações de uso semanal')
      }

      setLastWeekData(response.data?.reverse() as unknown as IWeeklyUsageResponse)

      setStateMachine(StateMachineEnum.READY)
    } catch (err: any) {
      setStateMachine(StateMachineEnum.ERROR)
    }
  }

  const states = {
    IDLE: '',
    LOADING: <LastWeekAccessChart.Skeleton />,
    ERROR: <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} actionDataTestId='data_connection_failure_access' outlined={true} severity='error' onActionClick={fetchLastWeekAccessData} action={`${t('Recarregar')}`} />,
    READY: <LastWeekAccessChart.Item lastWeekData={lastWeekData} />
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }

  return (
    <Grid container className={classes.LastWeekAccessContainer}>
      <Grid item style={{ display: 'inline-flex', gap: theme.spacingInline.nano }}>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='lg'
          customColor={theme.colorBrand.medium}
        >
          {t('Acessos nas últimas semanas')}
        </Text2>
        <CustomInfoTooltip text={t('Os acessos são contabilizados uma vez ao dia por usuário, mesmo que ele faça vários logins diários.')} />
      </Grid>
      <Grid item container marginTop={3.5}>
        {renderMachineState()}
      </Grid>
    </Grid>
  )
}
