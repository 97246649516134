import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    header: {
      backgroundColor: 'rgba(219, 223, 238, 0.24)',
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} ${theme.borderRadius.none} ${theme.borderRadius.none}`,
      margin: `-${theme.spacingInline.xxs} -${theme.spacingInline.xxs} 0 -${theme.spacingInline.xxs}`,
      padding: `${theme.spacingInline.xxs} ${theme.spacingInline.xs}`,
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.borderRadius.none,
        margin: `-${theme.spacingInline.xxxs}`
      }
    },
    italicStatusClassName: {
      fontStyle: 'italic',
      fontWeight: theme.font.fontWeight.medium
    }
  }
})
