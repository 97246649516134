import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    card: {
      margin: '24px 0',
      textAlign: 'center'
    },
    centralized: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    closeIconModal: {
      color: theme.colors.neutral.light40
    },
    guide: {
      height: '94px',
      '& .MuiCardContent-root:last-child': {
        padding: '16px'
      }
    },
    modal: {
      '& .MuiBox-root': {
        [theme.breakpoints.up('md')]: {
          width: '715px'
        }
      }
    },
    textCenter: {
      textAlign: 'center'
    }
  }
})
