import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    changeSchoolPeriod: {
      '& .MuiTypography-root': {
        fontSize: '12px'
      }
    },
    counterTooltip: {
      paddingTop: '2px!important',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0!important',
        paddingBottom: '8px'
      }
    },
    createClassButtonGridItem: {
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacingInline.xxxs
      }
    },
    header: {
      background: theme.colors.neutral.lightBase,
      borderBottom: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: '16px 16px 0px 0px',
      padding: '24px 24px 8px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px 8px 16px',
        top: '-30px',
        zIndex: 2,
        '& .hidden': {
          display: 'none'
        }
      }
    },
    helperText: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    importButtonGrid: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    labelContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      paddingBottom: '4px',
      width: 'auto',
      '& label:last-child': {
        marginRight: theme.spacingInline.xs
      }
    },
    orderBy: {
      width: '100%'
    },
    pagination: {
      [theme.breakpoints.up('md')]: {
        lineHeight: '55px'
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiPagination-root': {
          margin: '0 auto',
          width: 'fit-content'
        }
      }
    },
    search: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important'
      }
    },
    tagBox: {
      '& > div': {
        flex: 'none',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacingInline.nano,
          '&:last-child': {
            marginRight: '0'
          }
        }
      }
    },
    tagContainer: {
      marginLeft: '24px',
      padding: `${theme.spacingInline.nano} 0px`,
      [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        padding: `${theme.spacingInline.nano} 0px`,
        whiteSpace: 'nowrap'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '16px'
      }
    }
  }
})
