import { KeyboardEvent } from 'react'
import {
  Grid,
  TextField
} from 'components/design-system'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'
import makeStyles from './style'

interface IMobileSearchBar {
  defaultValue?: string
  handleSearch?: (event: KeyboardEvent<HTMLInputElement>) => void
  handleOpenFilters?: () => void
  handleOpenOrderBy: () => void
  hasAppliedFilters?: boolean
  visible: boolean
}

export const MobileSearchBar: React.FC<IMobileSearchBar> = ({ handleSearch, handleOpenFilters, handleOpenOrderBy, hasAppliedFilters, visible, ...props }) => {
  const classes = makeStyles()

  return (
    <div className={`${classes.searchBarContainer} ${!visible && 'hidden'}`}>
      <Grid
        container
        alignItems='center'
      >
        <Grid item xs={2} className={classes.searchIcon}>
          <SearchIcon />
        </Grid>
        <Grid item xs>
          <TextField
            {...props}
            id='searchMobile'
            variant='outlined'
            label='Pesquisar por nome'
            onKeyDown={handleSearch}
            className={classes.searchBar}
          />
        </Grid>
        {handleOpenFilters && (
          <Grid item xs={2} className={`${classes.icon} ${hasAppliedFilters ? 'appliedFilters' : ''}`} onClick={handleOpenFilters}>
            <FilterListIcon />
          </Grid>
        )}
        <Grid item xs={2} className={classes.icon} onClick={handleOpenOrderBy}>
          <SwapVertOutlinedIcon />
        </Grid>
      </Grid>
    </div>
  )
}

export default MobileSearchBar
