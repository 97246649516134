import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DoubleColumnContainer, LeftColumnContent, RightColumnContent } from 'components/common'
import { Button, Grid, Link, Text2 } from 'components/design-system'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import okGirl from 'assets/ok.svg'
import useStyles from './style'

export const TeacherSuccess: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()

  const backToTeacherList = () => history.push(PROFILE_TABS('teachers'))

  return (
    <DoubleColumnContainer rounded>
      <LeftColumnContent>
        <Grid container rowSpacing={4}>
          <Grid container item rowSpacing={6}>
            <Grid item xs={12} md={12}>
              <Link
                variant='returnArrow'
                onClick={backToTeacherList}
                linkStyle
              >
                {t('Lista professores')}
              </Link>
            </Grid>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
                {t('Cadastro finalizado com sucesso!')}
              </Text2>
            </Grid>
          </Grid>
        </Grid>
      </LeftColumnContent>
      <RightColumnContent>
        <Grid container alignItems='center' direction='column' justifyContent='center' spacing={8}>
          <Grid item className={styles.imageContainer}>
            <img src={okGirl} />
          </Grid>
          <Grid item>
            <Button
              onClick={backToTeacherList}
              type='button'
              variant='primary'
            >
              {t('Voltar para o início')}
            </Button>
          </Grid>
        </Grid>
      </RightColumnContent>
    </DoubleColumnContainer>
  )
}
