import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  link: {
    cursor: 'pointer',
    padding: '0',
    '& svg': {
      marginRight: '4px',
      verticalAlign: 'middle'
    }
  },
  tabContent: {
    minHeight: '82vh',
    padding: `${theme.spacingInline.xs} ${theme.spacingInline.nano} 0 ${theme.spacingInline.nano}`
  }
}))
