import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  captionContainer: {
    textAlign: 'center',
    marginTop: theme.spacingInset.xs
  },
  source: {
    textTransform: 'uppercase',
    marginLeft: theme.spacingInline.nano
  },
  caption: {
    '& span p': {
      display: 'inline'
    },
    background: `rgba(0, 0, 0, ${theme.opacityLevels.intense})`,
    color: theme.colors.neutral.lightBase,
    width: '100%',
    padding: theme.spacingInline.nano,
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '33px',
    margin: `${theme.spacingInset.xxs} auto 0 auto`,
    textAlign: 'center',
    maxWidth: '607px',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      background: `rgba(0, 0, 0, ${theme.opacityLevels.intense})`,
      color: theme.colors.neutral.lightBase,
      fontSize: theme.font.fontSize.xxxs,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '28px',
      marginTop: '-35px',
      padding: theme.spacingInline.nano,
      textAlign: 'left',
      width: '100%'
    }
  }
}))
