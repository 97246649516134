import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  ConfirmationModal,
  DoubleColumnContainer,
  LeftColumnContent,
  RightColumnContent,
  DatePicker
} from 'components/common'
import { Button, Grid, Link, Text2, TextField, Switch } from 'components/design-system'
import { PROFILE_TABS } from 'navigation/CONSTANTS'
import { useStore } from 'store'
import { Formik, Form, Field, FieldProps } from 'formik'
import useStyles from './style'
import {
  createUserSchoolProfile,
  getUserByEmail,
  getUserByPhone,
  getUserSchoolProfileBySchoolAndUser,
  IProfileResponse,
  IUserSchoolProfileRequest,
  UserSchoolProfileTypeEnum,
  IUserTeacherRegisterRequest,
  TeacherFormValues,
  updateUserSchoolProfile,
  userRegister
} from 'services'
import { TeacherSchema } from 'schemas/yup/teacher'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { generateDefaultEmail } from 'utils'
import { useSnackbar, useUserSendDefaultCredentials } from 'navigation/hooks'

export const CreateTeacher: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()
  const { school } = useStore()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isLoadingModalButton, setIsLoadingModalButton] = useState<boolean>(false)
  const [userFoundId, setUserFoundId] = useState<number>()
  const [linkCoordinatorModal, setLinkCoordinatorModal] = useState<boolean>(false)
  const { createSnackbar } = useSnackbar()
  const { sendCredentials } = useUserSendDefaultCredentials()

  const initialValues = {
    name: '',
    surname: '',
    birthDate: '',
    cpf: '',
    email: '',
    phone: '',
    linkCoordinatorProfile: false,
    sendCredentialsNotice: false
  }

  const handlePreFormatData = ({ name, surname, birthDate, cpf, email, phone }: TeacherFormValues): IUserTeacherRegisterRequest => ({
    email: email ? email?.trim() : generateDefaultEmail(),
    name: name?.trim() ?? '',
    surname: surname?.trim() ?? '',
    birth_date: birthDate
      ? parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }).toISOString()
      : '',
    cpf: cpf?.trim().replace(/\D/g, ''),
    phone: phone ? Number(phone?.replace(/\D/g, '')) : undefined,
    profile: UserSchoolProfileTypeEnum.teacher,
    schoolId: school?.id ?? 0,
    confirmed: false,
    is_single_registration: true,
    isDefaultPass: true,
    sentDefaultCredentials: false
  })

  const checksUserExists = async (user: IUserTeacherRegisterRequest, formattedBirthDate: string) => {
    if (user.email && !(user.email.toLowerCase().includes('donotsend') && user.email.includes('@camino.com.br'))) {
      const usersFoundByEmail = await getUserByEmail(user.email)
      if (usersFoundByEmail.data.length) {
        return usersFoundByEmail.data.find(u =>
          u.cpf === user.cpf &&
          u.name === user.name &&
          u.surname === user.surname &&
          u.birth_date === formattedBirthDate
        )
      }
    }

    if (user.phone) {
      const usersFoundByPhone = await getUserByPhone(user.phone)
      if (usersFoundByPhone.data.length) {
        return usersFoundByPhone.data.find(u =>
          u.cpf === user.cpf &&
          u.name === user.name &&
          u.surname === user.surname &&
          u.birth_date === formattedBirthDate
        )
      }
    }

    return false
  }

  const handleUpdateUserSchoolProfile = async (userSchoolProfiles: IProfileResponse[]) => {
    const inactiveUserSchoolProfile = userSchoolProfiles.find(usp => !usp.active)

    if (inactiveUserSchoolProfile) {
      try {
        const userSchoolProfileRequest: IUserSchoolProfileRequest = { active: true }
        const response = await updateUserSchoolProfile(inactiveUserSchoolProfile.id, userSchoolProfileRequest)

        if (response.success) {
          return response
        } else {
          throw new Error(t('Erro ao tentar atualizar o perfil de coordenador existente.'))
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      }
    }
  }

  const handleCreateUserSchoolProfile = async (userSchoolProfileRequest: IUserSchoolProfileRequest) => {
    try {
      const response = await createUserSchoolProfile(userSchoolProfileRequest)
      if (response.success) {
        return response
      } else {
        throw new Error(t('Não foi possível criar o perfil de coordenador para o usuário'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const handleUserSchoolProfile = async (userSchoolProfileType: UserSchoolProfileTypeEnum.coordinator | UserSchoolProfileTypeEnum.teacher, userId: number) => {
    if (school?.id) {
      const response = await getUserSchoolProfileBySchoolAndUser(school?.id, userSchoolProfileType, userId)

      if (response?.data.length) {
        return await handleUpdateUserSchoolProfile(response?.data)
      } else {
        const userSchoolProfileRequest: IUserSchoolProfileRequest = {
          active: true,
          school: Number(school.id),
          type: userSchoolProfileType,
          user: userId,
          year: new Date().getFullYear()
        }
        return await handleCreateUserSchoolProfile(userSchoolProfileRequest)
      }
    }
  }

  const handleSubmit = async ({ name, surname, birthDate, cpf, email, phone, linkCoordinatorProfile, sendCredentialsNotice }: TeacherFormValues) => {
    const user = handlePreFormatData({ name, surname, birthDate, cpf, email, phone })

    // date in format yyyy-MM-dd used to compare
    const formattedBirthDate = format(parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }), 'yyyy-MM-dd')

    // checks if there is already a user registered
    const userFound = await checksUserExists(user, formattedBirthDate)
    if (!userFound) {
      try {
        const response = await userRegister(user)

        if (!response.success || !response.data) {
          throw new Error()
        }

        if (linkCoordinatorProfile) {
          await handleUserSchoolProfile(UserSchoolProfileTypeEnum.coordinator, Number(response.data.id))
        }

        if (sendCredentialsNotice) {
          await sendCredentials({
            users: [Number(response.data.id)]
          })
        }

        history.push(PROFILE_TABS('teachers/success-registration'))
      } catch (e: any) {
        if (e instanceof Error) {
          createSnackbar({ content: t('Ocorreu um erro ao tentar cadastrar o usuário. Tente novamente.'), severity: 'error' })
        }
      }
    } else {
      setUserFoundId(Number(userFound.id))
      setLinkCoordinatorModal(!!linkCoordinatorProfile)

      let responseTeacherProfiles
      let activeTeacherProfile
      let inactiveTeacherProfile

      // checks if there is a 'UserSchoolProfile' looking for the school id, profile type and user id found
      if (school?.id && userFound.id) {
        responseTeacherProfiles = await getUserSchoolProfileBySchoolAndUser(school.id, UserSchoolProfileTypeEnum.teacher, Number(userFound.id))
      }

      if (responseTeacherProfiles?.data.length) {
        activeTeacherProfile = responseTeacherProfiles?.data.find(p => p.active)
        inactiveTeacherProfile = responseTeacherProfiles?.data.find(p => !p.active)
      }

      // if there is no 'UserSchoolProfile' registered for this user, then we open a modal to register a new one
      if (!responseTeacherProfiles?.data.length || inactiveTeacherProfile) {
        setOpenModal(true)
      }

      if (activeTeacherProfile && userFound.id) {
        createSnackbar({ content: t('Usuário e perfil já existem.'), severity: 'warning' })
      }
    }
  }

  const handleCreateUserSchoolProfileModal = async () => {
    setIsLoadingModalButton(true)

    if (userFoundId) {
      if (linkCoordinatorModal) {
        await handleUserSchoolProfile(UserSchoolProfileTypeEnum.coordinator, userFoundId)
      }

      const response = await handleUserSchoolProfile(UserSchoolProfileTypeEnum.teacher, userFoundId)
      if (response?.success) {
        createSnackbar({ content: t('Professor vinculado a sua escola com sucesso.'), severity: 'success' })
        history.push(PROFILE_TABS('teachers'))
      }
    }

    setIsLoadingModalButton(false)
  }

  return (
    <>
      <DoubleColumnContainer rounded>
        <LeftColumnContent>
          <Grid container rowSpacing={4}>
            <Grid container item rowSpacing={6}>
              <Grid item xs={12}>
                <Link
                  data-testid='register_teacher_back_to_teachers_list'
                  variant='returnArrow'
                  onClick={() => history.push(PROFILE_TABS('teachers'))}
                  linkStyle
                >
                  {t('Lista professores')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
                  {t('Formulário de cadastro')}
                </Text2>
              </Grid>
            </Grid>
            <Grid item>
              <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
                {t('Informe os dados do novo professor nos campos ao lado.')}
              </Text2>
            </Grid>
          </Grid>
        </LeftColumnContent>
        <RightColumnContent>
          <Formik
            initialValues={initialValues}
            validationSchema={TeacherSchema}
            onSubmit={async (values) =>
              await handleSubmit(values)
            }
            validateOnBlur={false}
          >
            {({ dirty, errors, isValid, touched, setFieldValue }) => (
              <Form>
                <Grid container spacing={5}>
                  <Grid container item xs={12} md={8} spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Todos os campos são obrigatórios')}
                      </Text2>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.fieldGrid}>
                      <Field name='name'>
                        {({ field }: FieldProps<TeacherFormValues>) => (
                          <TextField
                            {...field}
                            id='name'
                            variant='filled'
                            label={t('Nome')}
                            warning={Boolean(errors?.name && touched.name)}
                            assistivetext={(errors?.name && touched.name)
                              ? errors?.name
                              : ''
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8} className={styles.fieldGrid}>
                    <Field name='surname'>
                      {({ field }: FieldProps<TeacherFormValues>) => (
                        <TextField
                          {...field}
                          id='surname'
                          variant='filled'
                          label={t('Sobrenome')}
                          warning={Boolean(errors?.surname && touched.surname)}
                          assistivetext={(errors?.surname && touched.surname)
                            ? errors?.surname
                            : ''
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid container item xs={12} md={5} spacing={0.5}>
                    <Grid item xs={12} md={12} className={styles.fieldGrid}>
                      <Field name='birthDate'>
                        {({ field }: FieldProps<TeacherFormValues>) => (
                          <Grid container display='flex' alignItems='center'>
                            <div>
                              <TextField
                                {...field}
                                id='birthDate'
                                mask='99/99/9999'
                                variant='filled'
                                label={t('Data de nascimento')}
                                warning={Boolean(errors?.birthDate && touched.birthDate)}
                                assistivetext={(errors?.birthDate && touched.birthDate)
                                  ? errors?.birthDate
                                  : ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
                              />
                            </div>
                            <div>
                              <DatePicker maxDate={new Date()} minDate={new Date('01/01/1900')} onChange={(date: string) => setFieldValue('birthDate', date)} />
                            </div>
                          </Grid>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.helpText}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Digite apenas números')}
                      </Text2>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={0.5}>
                    <Grid item xs={12} md={8} className={styles.fieldGrid}>
                      <Field name='cpf'>
                        {({ field }: FieldProps<TeacherFormValues>) => (
                          <TextField
                            {...field}
                            id='cpf'
                            mask='999.999.999-99'
                            variant='filled'
                            label='CPF'
                            // className={styles.input}
                            warning={Boolean(errors?.cpf && touched.cpf)}
                            assistivetext={(errors?.cpf && touched.cpf)
                              ? errors?.cpf
                              : ''
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.helpText}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Digite apenas números')}
                      </Text2>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Text2 fontSize='sm' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Informe pelo menos um meio de contato com o professor*')}
                      </Text2>
                    </Grid>
                    <Grid item xs={12} md={8} className={styles.fieldGrid}>
                      <Field name='email'>
                        {({ field }: FieldProps<TeacherFormValues>) => (
                          <TextField
                            {...field}
                            id='email'
                            variant='filled'
                            label={t('E-mail')}
                            error={Boolean(errors?.email && touched.email)}
                            warning={Number(!!((errors as any).emailOrPhone) && touched.email)}
                            assistivetext={(errors?.email ?? (errors as any).emailOrPhone) && touched.email
                              ? (errors?.email ?? (errors as any).emailOrPhone)
                              : ''
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={5} spacing={5}>
                    <Grid container item xs={12} md={12} spacing={0.5}>
                      <Grid item xs={12} md={12} className={styles.fieldGrid}>
                        <Field name='phone'>
                          {({ field }: FieldProps<TeacherFormValues>) => (
                            <TextField
                              {...field}
                              id='phone'
                              mask='(99) 99999-9999'
                              variant='filled'
                              label={t('Telefone')}
                              error={Boolean(errors?.phone && touched.phone)}
                              warning={Number(!!((errors as any).emailOrPhone) && touched.phone)}
                              assistivetext={(errors?.phone ?? (errors as any).emailOrPhone) && touched.phone
                                ? (errors?.phone ?? (errors as any).emailOrPhone)
                                : ''
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={12} className={styles.helpText}>
                        <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                          {t('Digite apenas números')}
                        </Text2>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Field name='linkCoordinatorProfile'>
                        {({ field }: FieldProps<TeacherFormValues>) => (
                          <Switch
                            data-testid='individual_registration_teacher_activate_coordinator_profile'
                            {...field}
                            id='linkCoordinatorProfile'
                            label={t('Vincular perfil de coordenador a este professor')}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field name='sendCredentialsNotice'>
                      {({ field }: FieldProps<TeacherFormValues>) => (
                        <Switch
                          {...field}
                          id='sendCredentialsNotice'
                          label={t('Enviar e-mail com credenciais')}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid container item justifyContent='flex-end' xs={12} md={12} spacing={4}>
                    <Grid item>
                      <Button
                        data-testid='individual_registration_teacher_cancel_registration'
                        variant='outlined'
                        onClick={() => history.push(PROFILE_TABS('teachers'))}
                      >
                        {t('Cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        data-testid='individual_registration_teacher_finish_registration'
                        disabled={!(dirty && isValid)}
                        type='submit'
                        variant='primary'
                      >
                        {t('Finalizar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </RightColumnContent>
      </DoubleColumnContainer>
      <ConfirmationModal
        confirmButtonText={t('Vincular')}
        cancelButtonText={t('Cancelar')}
        confirmCallback={handleCreateUserSchoolProfileModal}
        description={t('Gostaria de vincular o professor a sua escola?')}
        subdescription={t('Um e-mail será disparado para o professor se vincular as turmas, nas quais ele irá lecionar.')}
        handleClose={() => setOpenModal(false)}
        isLoading={isLoadingModalButton}
        isOpen={openModal}
        title={t('Professor já cadastrado na Cloe')}
      />
    </>
  )
}
