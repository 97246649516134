import MUiToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ToggleButtonGroupProps } from '@mui/material'
import makeStyles from './style'
export interface IToggleButtonGroupProps extends ToggleButtonGroupProps {
  tabRole?: boolean
}

export const ToggleButtonGroup: React.FC<IToggleButtonGroupProps> = ({ children, tabRole = false, ...props }) => {
  const classes = makeStyles()

  return (
    <MUiToggleButtonGroup
      {...props}
      className={`${props.className as string} ${classes.root} ${tabRole ? 'tabrole' : ''}`}
    >
      {children}
    </MUiToggleButtonGroup>
  )
}
