import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useMediaQuery, useTheme } from '@mui/material'
import { Button, Tag, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { IClassSubscriptionResponse } from 'services'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'
import { USER_MANAGEMENT } from 'navigation/CONSTANTS'

interface ILinkedClassListProps {
  userClassSubscriptions: IClassSubscriptionResponse[]
  showAddClassButton?: boolean
}

type LinkedClassListStates = 'idle' | 'mobile' | 'desktop' | 'empty'

export const LinkedClassList: React.FC<ILinkedClassListProps> = ({ userClassSubscriptions, showAddClassButton = false }) => {
  const { userId } = useParams<{userId?: string}>()
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // states
  const [linkedClassListState, setLinkedClassListState] = useState<LinkedClassListStates>('idle')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const renderClassList = () => {
    if (userClassSubscriptions.length) {
      setLinkedClassListState(isMobile ? 'mobile' : 'desktop')
    } else {
      setLinkedClassListState('empty')
    }
  }

  useEffect(() => {
    renderClassList()
  }, [userClassSubscriptions, isMobile])

  const handleExpandClick = () => setIsExpanded(!isExpanded)

  const renderClasses = () => userClassSubscriptions?.map(profileClass => (
    <Grid item key={profileClass?.id}>
      <Tag label={profileClass.class_id.name} />
    </Grid>
  ))

  const renderAddClassButton = () => {
    return showAddClassButton && (
      <Button
        data-testid='list_students_edit_profile_add_another_class_to_the_student'
        onClick={() => history.push(USER_MANAGEMENT(`students/${userId ?? ''}/multiclass/edit`))}
        startIcon={<AddOutlinedIcon />}
        variant='ghost'
      >
        {t('Adicionar outra turma')}
      </Button>
    )
  }

  const CurrentYearLabel: React.FC = () => (
    <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xs' neutral='dark30'>
      {t('Ano vigente')}:
    </Text2>
  )

  const stateMachine = {
    states: {
      idle: null,
      mobile: (
        <>
          <Grid container rowSpacing={2}>
            <Grid item>
              <CurrentYearLabel />
            </Grid>
            <Grid item xs={12}>
              <Collapse orientation='vertical' in={isExpanded} collapsedSize={40}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  {renderClasses()}
                </Grid>
              </Collapse>
            </Grid>
            <Grid container justifyContent='flex-end' spacing={2}>
              <Grid item sx={{ marginTop: theme.spacingInline.nano }}>
                <Text2 onClick={handleExpandClick} fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='xs' colorbrand='medium' cursor='pointer'>
                  {t('Ver todos')}
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Text2>
              </Grid>
              <Grid item xs={12}>
                {renderAddClassButton()}
              </Grid>
            </Grid>
          </Grid>
        </>
      ),
      desktop: (
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xs={12}>
            <CurrentYearLabel />
          </Grid>
          <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
            {renderClasses()}
          </Grid>
          <Grid item>
            {renderAddClassButton()}
          </Grid>
        </Grid>
      ),
      empty: (
        <Grid container item spacing={2}>
          <Grid item>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
              {t('Nenhuma turma vinculada')}
            </Text2>
          </Grid>
          <Grid item>
            {renderAddClassButton()}
          </Grid>
        </Grid>
      )
    }
  }

  return stateMachine.states[linkedClassListState]
}
