import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    tabs: {
      margin: '-24px 0 0 -24px',
      width: 'calc(100% + 48px)',
      [theme.breakpoints.down('sm')]: {
        margin: '-24px 0 0 -16px',
        width: 'calc(100% + 32px)'
      }
    },
    tabsContainer: {
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '& .MuiToggleButton-root': {
        backgroundColor: theme.colorBrand.lightest,
        borderBottom: `4px solid ${theme.colorBrand.medium}!important`,
        borderLeft: '0',
        borderRight: '0',
        borderRadius: '16px 16px 0 0!important',
        color: `${theme.colorBrand.medium}!important`,
        [theme.breakpoints.down('sm')]: {
          borderRadius: '0!important'
        }
      },
      '&.tabrole': {
        backgroundColor: 'rgba(219, 223, 238, 0.24)',
        boxShadow: 'none!important',
        margin: '24px 0 0 0!important'
      },
      '& .tabrole[aria-pressed="true"]': {
        backgroundColor: `${theme.colorBrand.medium}!important`,
        borderBottom: 'none!important',
        color: `${theme.colors.neutral.lightBase}!important`
      }
    }
  }
})
