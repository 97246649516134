import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  LastWeekAccessContainer: {
    paddingBottom: theme.spacingInset.md,
    paddingTop: theme.spacingInset.md,
    height: '254px',

    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    }
  }
}))
