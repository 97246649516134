import { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { Button, Card, Grid, Modal, Tag, Text2 } from 'components/design-system'
import { SearchInput } from 'components/common'
import { IProfilesSessionResponse, UserSchoolProfileTypeEnum } from 'services'
import useStyle from './style'
import { formattedProfiles } from 'utils'

interface IChangeSchoolModal {
  handleOpenConfirmationModal: () => any
  openModal: boolean
  selectedProfile: IProfilesSessionResponse | null
  setOpenModal: React.Dispatch<SetStateAction<boolean>>
  setSelectedProfile: React.Dispatch<SetStateAction<IProfilesSessionResponse | null>>
}

export const ChangeSchoolModal: React.FC<IChangeSchoolModal> = ({ handleOpenConfirmationModal, openModal, selectedProfile, setOpenModal, setSelectedProfile }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { session, profile } = useStore()

  const initialFilteredProfiles = session?.profiles
    .filter(u => u.active && [UserSchoolProfileTypeEnum.admin, UserSchoolProfileTypeEnum.coordinator, UserSchoolProfileTypeEnum.teacher].includes(u.type)) ?? []

  // states
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<IProfilesSessionResponse[]>(initialFilteredProfiles)

  useEffect(() => {
    if (!searchTerm.length || session?.profiles) setSearchResults(initialFilteredProfiles)
  }, [searchTerm, session])

  useEffect(() => {
    setSearchResults(initialFilteredProfiles)
  }, [openModal])

  useEffect(() => {
    setSearchResults(initialFilteredProfiles?.filter(p =>
      p?.school?.name?.toLowerCase().includes(searchTerm.toLowerCase().trim()))
    )
  }, [searchTerm])

  const handleClean = () => setSearchTerm('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)

  const handleClose = () => {
    setSelectedProfile(profile)
    setSearchResults(initialFilteredProfiles)
    setOpenModal(false)
  }

  return (
    <Modal
      className={classes.schoolSelectionModal}
      onClose={handleClose}
      open={openModal}
      size='sm'
    >
      <Grid container direction='column'>
        <Grid item>
          <SearchInput
            defaultValue={searchTerm}
            id='searchSchoolByName'
            label={t('Pesquisar por nome')}
            onChange={handleChange}
            onClean={handleClean}
            variant='filled'
            className={classes.searchSchoolByName}
          />
        </Grid>
        <Grid item marginTop={3}>
          <div className={classes.schoolCardContainer}>
            <Grid container direction='column' rowSpacing={2}>
              {searchResults?.length
                ? searchResults?.map((p: IProfilesSessionResponse) => (
                  <Grid item key={p.id}>
                    <Card
                      onClick={() => setSelectedProfile(p)}
                      extraClasses={classes.schoolCard}
                      selected={p.id === selectedProfile?.id}
                      dataTestId='change_profile_coordinator_profile_list'
                      clickable
                    >
                      <Grid container direction='column' rowSpacing={1}>
                        <Grid item>
                          {p.school.name}
                        </Grid>
                        <Grid item>
                          <Tag label={t(formattedProfiles[p.type])} sx={{ cursor: 'pointer' }} />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))
                : (
                  <Grid item className={classes.noResultsFound}>
                    <Text2
                      fontSize='sm'
                      fontWeight='medium'
                      lineHeight='xs'
                      neutral='dark30'
                      mobile='md'
                    >
                      {t('Nenhum resultado encontrado')}
                    </Text2>
                  </Grid>
                )
              }
            </Grid>
          </div>
        </Grid>
        <Grid container item justifyContent='space-between' marginTop={3}>
          <Grid item>
            <Button
              data-testid='change_profile_coordinator_select'
              disabled={profile?.id === selectedProfile?.id}
              onClick={handleOpenConfirmationModal}
              fullWidth
            >
              {t('selecionar')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid='change_profile_coordinator_back_my_classes'
              onClick={handleClose}
              variant='ghost'
              fullWidth
            >
              {t('cancelar')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
