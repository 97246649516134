import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    cardContainer: {
      justifyContent: 'center',
      marginTop: theme.spacingStack.xs,
      width: '100% !important'
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.colors.neutral.lightBase,
      width: '100% !important',
      height: '172px',
      borderRadius: theme.borderRadius.xs,
      '& > div': {
        width: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    cardTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardBtn: {
      '& button': {
        width: '100%'
      }
    }
  }
})
