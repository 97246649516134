import React from 'react'
import { useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import { IPillProps, Pill } from '../Pill'

export interface BigNumberBoxSubtitleProps {
  label: string
  pill?: IPillProps
}

export const BigNumberBoxSubtitle: React.FC<BigNumberBoxSubtitleProps> = ({ label, pill }) => {
  const theme = useTheme()

  const getSubtitleByType = () => pill
    ? (
      <>
        <Pill {...pill} size='small' />
        <Text2
          customColor={theme.colors.neutral.lightBase}
          fontSize='xxs'
          fontWeight='medium'
          lineHeight='xs'
          mobile='xxs'
          sx={{ ml: 0.7 }}
        >
          {label}
        </Text2>
      </>
    )
    : (
      <Text2
        customColor={theme.colors.neutral.lightBase}
        fontSize='xxs'
        fontWeight='medium'
        lineHeight='xs'
        mobile='xxs'
      >
        {label}
      </Text2>
    )

  return getSubtitleByType()
}
