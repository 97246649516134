import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    heading: {
      marginTop: theme.spacingStack.md,
      marginBottom: theme.spacingStack.xs
    }
  }
})
