import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    buttonsContainer: {
      margin: '24px 16px 0px 0px'
    },
    cancelButton: {
      float: 'right'
    },
    filter: {
      width: '100%'
    },
    optionCard: {
      color: theme.colors.neutral.dark30,
      cursor: 'pointer',
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold,
      minHeight: '80px',
      lineHeight: '24px',
      marginBottom: theme.spacingInline.xxxs,
      '&:last-child': {
        marginBottom: '0'
      }
    },
    optionCardContainer: {
      overflowY: 'scroll',
      maxHeight: '350px',
      paddingRight: '8px',
      '&::-webkit-scrollbar': {
        width: '12px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.neutral.light20,
        borderRadius: '5px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.colors.neutral.light30
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: '337px'
      }
    },
    orderBySelectionModal: {
      '& .MuiBox-root': {
        padding: '24px 4px 16px 24px',
        '&:focus': {
          outline: 'none'
        }
      }
    },
    title: {
      marginBottom: theme.spacingInline.xxs
    }
  }
})
