import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.MuiPagination-root': {
      '& .MuiPaginationItem-root': {
        background: 'none',
        borderColor: theme.colorBrand.medium,
        color: theme.colors.neutral.light40,
        fontWeight: theme.font.fontWeight.medium,
        '&.Mui-selected': {
          color: theme.colorBrand.dark,
          fontWeight: theme.font.fontWeight.bold
        }
      }
    }
  }
}))
