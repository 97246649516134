import Slider, { SliderProps } from '@mui/material/Slider'
import { FeedbackTheme } from 'styles/theme'
import makeStyles from './style'

export interface IProgressBar extends SliderProps {
  feedbacktheme: FeedbackTheme
}

export const ProgressBar: React.FC<IProgressBar> = ({ ...props }) => {
  const styles = makeStyles(props)

  return <Slider {...props} className={styles.root} />
}
