import React, { useEffect, useState } from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { Alert, Text2 } from 'components/design-system'
import useStyle from './styles'
import { CloeTooltip } from '../CloeTooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BigNumberBoxSubtitle, BigNumberBoxSubtitleProps } from '..'
import { useTranslation } from 'react-i18next'

interface IBigNumberBoxProps {
  bigNumber: number | undefined
  loading: boolean
  title: string
  tooltipText?: string
  subtitles?: BigNumberBoxSubtitleProps[]
  hasError?: boolean
  tryAgainCallback?: () => void
  hasNoContent?: boolean
}

enum StateMachineEnum {
  ERROR = 'ERROR',
  NO_CONTENT = 'NO_CONTENT',
  LOADING = 'LOADING',
  READY = 'READY',
}

export const BigNumberBox: React.FC<IBigNumberBoxProps> = ({ bigNumber, loading, title, tooltipText, subtitles, tryAgainCallback, hasError, hasNoContent }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const fontSize = (bigNumber && bigNumber > 999) ? 'sm' : 'md'

  // states
  const [bigNumberBoxState, setBigNumberBoxState] = useState<StateMachineEnum>(StateMachineEnum.LOADING)

  useEffect(() => {
    if (loading) {
      setBigNumberBoxState(StateMachineEnum.LOADING)
      return
    }

    if (hasNoContent) {
      setBigNumberBoxState(StateMachineEnum.NO_CONTENT)
      return
    }

    if (hasError) {
      setBigNumberBoxState(StateMachineEnum.ERROR)
      return
    }
    setBigNumberBoxState(StateMachineEnum.READY)
  }, [loading, hasError])

  const stateMachine = {
    states: {
      ERROR: (
        <div className={classes.card}>
          <Grid container>
            <Grid container item xs={12} marginBottom={3}>
              <Grid item sx={{ width: '100%' }}>
                <Alert
                  action={`${t('Recarregar')}`}
                  content={t('Falha na conexão de dados.')}
                  onActionClick={tryAgainCallback}
                  themeColor={theme.colors.neutral.lightBase}
                  className='MuiAlert-outlinedWhite'
                  severity='info'
                  outlined
                />
              </Grid>
            </Grid>
            <Grid container item xs lg={12} marginTop={isTablet ? 0 : 2} alignItems='center'>
              <Grid item xs sm={12}>
                <Text2
                  className={classes.title}
                  customColor={theme.colors.neutral.lightBase}
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='xs'
                  mobile='sm'
                >
                  {title}
                </Text2>
                {tooltipText && (
                  <CloeTooltip
                    component={<InfoOutlinedIcon fontSize='small' sx={{ color: theme.colors.neutral.lightBase, marginLeft: '4px', verticalAlign: 'sub' }} />}
                    placement='top'
                    text={tooltipText}
                  />
                )}
              </Grid>
              {(!isMobile && subtitles?.length)
                ? (
                  <Grid item xs sm={12}>
                    <Grid container columnSpacing={2}>
                      {subtitles?.map(subtitle => (
                        <Grid item key={`subtitle-${subtitle.label.split(' ')[0]}`}>
                          <BigNumberBoxSubtitle {...subtitle} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )
                : null
              }
            </Grid>
          </Grid>
        </div>
      ),
      NO_CONTENT: (
        <div className={classes.card}>
          <Grid container>
            <Grid container item xs={12} marginBottom={3}>
              <Grid item sx={{ width: '100%' }}>
                <Alert
                  content={t('Sem dados para exibir.')}
                  themeColor={theme.colors.neutral.lightBase}
                  className='MuiAlert-outlinedWhite'
                  severity='info'
                  outlined
                />
              </Grid>
            </Grid>
            <Grid container item xs lg={12} marginTop={isTablet ? 0 : 2} alignItems='center'>
              <Grid item xs sm={12}>
                <Text2
                  className={classes.title}
                  customColor={theme.colors.neutral.lightBase}
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='xs'
                  mobile='sm'
                >
                  {title}
                </Text2>
                {tooltipText && (
                  <CloeTooltip
                    component={<InfoOutlinedIcon fontSize='small' sx={{ color: theme.colors.neutral.lightBase, marginLeft: '4px', verticalAlign: 'sub' }} />}
                    placement='top'
                    text={tooltipText}
                  />
                )}
              </Grid>
              {(!isMobile && subtitles?.length)
                ? (
                  <Grid item xs sm={12}>
                    <Grid container columnSpacing={2}>
                      {subtitles?.map(subtitle => (
                        <Grid item key={`subtitle-${subtitle.label.split(' ')[0]}`}>
                          <BigNumberBoxSubtitle {...subtitle} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )
                : null
              }
            </Grid>
          </Grid>
        </div>
      ),
      LOADING: (
        <div className={classes.skeletonCard}>
          <Grid container>
            <Grid item xs='auto' lg={12} marginRight={isTablet ? 2 : 0}>
              <Skeleton variant='circular' width={64} height={64} animation='wave' />
            </Grid>
            <Grid container item xs lg={12} marginTop={isTablet ? 0 : 2} alignItems='center'>
              <Grid item xs sm={12}>
                <Skeleton animation='wave' sx={{ width: '100%' }} height={24} />
              </Grid>
              {!isMobile && (
                <Grid item xs sm={12} marginTop={2}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs>
                      <Skeleton animation='wave' sx={{ width: '100%' }} height={16} />
                    </Grid>
                    <Grid item xs>
                      <Skeleton animation='wave' sx={{ width: '100%' }} height={16} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      ),
      READY: (
        <div className={classes.card}>
          <Grid container>
            <Grid item xs='auto' lg={12} marginRight={isTablet ? 2 : 0}>
              <div className={classes.bigNumber}>
                <Text2
                  customColor={theme.colors.neutral.lightBase}
                  fontSize={fontSize}
                  fontWeight='semibold'
                  lineHeight='xs'
                  mobile={fontSize}
                >
                  {bigNumber ?? '-'}
                </Text2>
              </div>
            </Grid>
            <Grid container item xs lg={12} marginTop={isTablet ? 0 : 2} alignItems='center'>
              <Grid item xs sm={12}>
                <Text2
                  className={classes.title}
                  customColor={theme.colors.neutral.lightBase}
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='xs'
                  mobile='sm'
                >
                  {title}
                </Text2>
                {tooltipText && (
                  <CloeTooltip
                    component={<InfoOutlinedIcon fontSize='small' sx={{ color: theme.colors.neutral.lightBase, marginLeft: '4px', verticalAlign: 'sub' }} />}
                    placement='top'
                    text={tooltipText}
                  />
                )}
              </Grid>
              {(!isMobile && subtitles?.length)
                ? (
                  <Grid item xs sm={12}>
                    <Grid container columnSpacing={2}>
                      {subtitles?.map(subtitle => (
                        <Grid item key={`subtitle-${subtitle.label.split(' ')[0]}`}>
                          <BigNumberBoxSubtitle {...subtitle} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )
                : null
              }
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  return stateMachine.states[bigNumberBoxState]
}
