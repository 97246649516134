import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((_theme: Theme) => createStyles({
  container: {
    border: '2px solid #CCCCCC',
    borderRadius: '8px',
    width: '100%',
    height: '200px',
    padding: '16px',
    marginBottom: '16px'
  },
  dragArea: {
    width: '100%',
    height: '100%',
    border: '2px dashed #CCCCCC',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    '&:hover': {
      boxShadow: '0px 0px 6px 2px'
    },
    position: 'relative'
  },
  hideInput: {
    display: 'none'
  },
  icon: {
    color: '#DADADA'
  }
}))
