import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiCard-root': {
      backgroundColor: theme.colors.neutral.lightBase,
      border: `1px solid ${theme.colors.neutral.light10}`,
      '&.checked': {
        border: `1px solid ${theme.colorBrand.medium}`
      },
      '&.disabled': {
        background: theme.colors.neutral.light10
      }
    }
  },
  checkbox: {
    '&.MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: theme.font.fontSize.md,
        fontFamily: theme.font.fontFamily,
        letterSpacing: theme.font.spacing.distant,
        lineHeight: theme.font.lineHeight.xs,
        fontWeight: theme.font.fontWeight.medium,
        color: theme.colors.neutral.dark40,
        [theme.breakpoints.down('xl')]: {
          fontSize: theme.font.fontSize.sm
        }
      }
    }
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  info: {
    '& .MuiIconButton-root': {
      marginLeft: '8px'
    }
  },
  negativeIcon: {
    color: theme.colors.negative.feedbackMedium,
    '& svg': {
      margin: '8px 4px 0px'
    }
  },
  positiveIcon: {
    color: theme.colors.positive.feedbackMedium,
    '& svg': {
      margin: '8px 4px 0px'
    }
  }
}))
