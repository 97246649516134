import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  tagsContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    fontSize: theme.font.fontSize.sm,
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  tagSelected: {
    color: theme.colors.neutral.lightBase
  }
}))
