import { StyleSheet } from '@react-pdf/renderer'

// unit used is pt
export const styles = StyleSheet.create({
  listHeader: {
    alignItems: 'center',
    color: '#434343',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    padding: '9 27'
  },
  outlinedUserContentCard: {
    alignItems: 'center',
    color: '#434343',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    marginBottom: 6,
    padding: '9 27'
  }
})
