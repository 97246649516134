import { Alert, Grid, Text2 } from 'components/design-system'
import { SheetStatusCard, ConfirmationModal, SendNotificationsButton } from 'components/common'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { Theme } from '@mui/material'
import { useTheme } from '@mui/styles'
import { GetFiles, CheckMassRegistration, errorMapping, Item, UserSchoolProfileTypeEnum } from 'services'
import { useStore } from 'store'
import { useAtom } from 'jotai'
import { allUploadedFiles } from 'pages/ProfileTabs/TeacherTab/TeacherActions/atomStore'
import { useEffect, useState } from 'react'
import moment from 'moment'

interface SpreadsheetStatusProps {
  target: UserSchoolProfileTypeEnum.teacher | UserSchoolProfileTypeEnum.student
  dataTestid?: string
  dataTestidCancel?: string
  dataTestidArrow?: string
}

export const SpreadsheetStatus: React.FC<SpreadsheetStatusProps> = ({ target, dataTestid, dataTestidCancel, dataTestidArrow }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const { school } = useStore()
  const [listOfFiles, setListOfFiles] = useAtom(allUploadedFiles)
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelController, setCancelController] = useState<AbortController | undefined>(undefined)

  useEffect(() => {
    getUploadedFiles()

    const timer = setInterval(() => {
      getUploadedFiles()
    }, 60000)

    return () => clearInterval(timer)
  }, [])

  // TODO create a enum or env for these hashs
  const getColor = (statusId?: string) => {
    switch (statusId) {
      case 'ff8c3120c9f4a193337988348f48a3f8':
      case 'c6f69ef69b3ed3da77021e0974da8aa5':
      case '6b5391112409294530b47429f3fc5407':
      case '874d591eccc4ad3ec908da37553fd9dc':
        return 'positive'
      case '7c7cb744e0d297421a8780cac1edd52c':
      case '2721b9bb6707fa93b7aec8dbe2f69488':
      case '07ae9013a4c7cd6a106be959f6e45637':
      case '9d7a562066d7036e57dfc9e686c4ea1e':
        return 'negative'
      case '202f07a1f640454bd532e4e1e6a75715':
        return 'warning'
      case '96d59fc9e5666d7ac9c922e217e44cfc':
      case '0154457cdd83227374891a1dfbc3ac2f':
      case 'bb3ccd5881d651448ded1dac904054ac':
      case '823a8f1e7b400e02fc4b463f4e2cbd72':
      case '8d53e33757b4107a21a51ba8d896cd44':
        return 'information'
      default:
        return 'information'
    }
  }

  const getStatus = (statusId?: string) => errorMapping.find(f => f.id === statusId)?.text ?? 'status undefined'

  const getAllowAction = (statusId?: string) => errorMapping.find(f => f.id === statusId)?.allowAction ?? false

  const getUploadedFiles = async () => {
    const response = await GetFiles(school?.id ?? 0)

    // TODO add a else with alert
    if (response.success) {
      const items = response.data.data?.Items?.filter(f => f.extra?.uploadtype === target).sort(function(a, b) {
        const dateA = new Date(a.createdAt).getTime()
        const dateB = new Date(b.createdAt).getTime()

        return dateA > dateB ? 1 : -1
      }).reverse()

      const fileResult: Item[] = []

      if (items) {
        for (const item of items) {
          const showButton = await getMassRegistration(item.transaction ?? '')

          fileResult.push({
            ...item,
            showButton
          })
        }
      }

      setListOfFiles(fileResult)
    }
  }

  const getMassRegistration = async (massId: string) => {
    const response = await CheckMassRegistration(massId)

    if (response.success) {
      return response.data
    }

    return false
  }

  const handleClose = (item: Item) => {
    // TODO add it in a enum or a env (wtf is this status???)
    if (item.statusId === '202f07a1f640454bd532e4e1e6a75715') {
      setCancelModal(!cancelModal)
      setCancelController(item.abortController)
    }
  }

  const handleCallback = () => {
    cancelController?.abort()
    setCancelController(undefined)
    setCancelModal(false)
  }

  const getLineString = (lineNumber: number) => lineNumber && lineNumber > 0
    ? ` - linha: ${String(lineNumber)}`
    : ''

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text2 fontSize='md' fontWeight='semibold' lineHeight='xs' mobile='md' neutral='dark30'>
          {t('Status das planilhas enviadas')}:
        </Text2>
      </Grid>
      {
        listOfFiles?.map((item, index) => {
          const date = item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''
          if (!item.errors) {
            console.log(item)
            return (
              <Grid item xs={12} key={index}>
                <SheetStatusCard
                  fileName={item.filename}
                  fileDate={date}
                  status={getStatus(item.statusId)}
                  type={getColor(item.statusId)}
                  onCloseClick={() => handleClose(item)}
                  dataTestIdCancel={dataTestidCancel}
                  dataArrowTestid={dataTestidArrow}
                  action={
                    getAllowAction(item.statusId) && item.showButton
                      ? <SendNotificationsButton dataTestid={dataTestid} notificationtype='credentials' recipienttype={target} transactionId={item.transaction}>
                        {t('Enviar credenciais')}
                      </SendNotificationsButton>
                      : null
                  }
                  showArrow={!!item.warnings}
                  transactionId={getAllowAction(item.statusId) ? item?.transaction : null}
                >
                  {
                    item.warnings?.map((warning, index2) =>
                      <div className={styles.alertContainer} key={index2}>
                        <Alert
                          severity='warning'
                          content={`${t(warning.message)} ${getLineString(warning.line)}`}
                          sx={{
                            alignItems: 'center',
                            background: theme.colors.neutral.lightBase,
                            fontSize: theme.font.fontSize.xxs,
                            lineHeight: '16px',
                            padding: theme.spacingInline.nano
                          }}
                          outlined
                        />
                      </div>
                    )
                  }
                </SheetStatusCard>
              </Grid>
            )
          } else {
            return (
              <Grid item xs={12}key={index}>
                <SheetStatusCard
                  fileName={item.filename}
                  fileDate={date}
                  status={getStatus(item.statusId)}
                  type={getColor(item.statusId)}
                  onCloseClick={() => handleClose(item)}
                  showArrow
                  dataTestIdCancel={dataTestidCancel}
                  dataArrowTestid={dataTestidArrow}
                  transactionId={getAllowAction(item.statusId) ? item?.transaction : null}
                >
                  {
                    item.warnings?.map((warning, index2) =>
                      <div className={styles.alertContainer} key={index2}>
                        <Alert
                          severity='warning'
                          content={`${t(warning.message)} ${getLineString(warning.line)}`}
                          sx={{
                            alignItems: 'center',
                            background: theme.colors.neutral.lightBase,
                            fontSize: theme.font.fontSize.xxs,
                            lineHeight: '16px',
                            padding: theme.spacingInline.nano
                          }}
                          outlined
                        />
                      </div>
                    )
                  }
                  {
                    item.errors?.map((error, index2) =>
                      <div className={styles.alertContainer} key={index2}>
                        <Alert
                          severity='error'
                          content={`${t(error.message)} ${getLineString(error.line)}`}
                          sx={{
                            alignItems: 'center',
                            background: theme.colors.neutral.lightBase,
                            fontSize: theme.font.fontSize.xxs,
                            lineHeight: '16px',
                            padding: theme.spacingInline.nano
                          }}
                          outlined
                        />
                      </div>
                    )
                  }
                </SheetStatusCard>
              </Grid>
            )
          }
        }
        )
      }
      <ConfirmationModal
        handleClose={() => setCancelModal(!cancelModal)}
        isOpen={cancelModal}
        confirmCallback={handleCallback}
        title='Parar envio de planilha'
        description='Ao parar o da planilha, você perderá todos os dados enviados até o momento. Deseja parar o envio?'
      />
    </Grid>
  )
}
