import { createContext, useContext } from 'react'
import { IGoToCloeWebProps, useProvideAuth } from './hooks/useProvideAuth'
import { IApiResponse, IUserMeResponse } from 'services'

interface IAuthProviderContext {
  user: IUserMeResponse | null
  currentUser: (token: string) => Promise<IApiResponse<IUserMeResponse>>
  signout: () => void
  clean: () => void
  setSession: () => void
  updateLastUsedProfile: (profileId: number) => Promise<void>
  goToCloeWeb: (props?: IGoToCloeWebProps) => void
}

const AuthContext = createContext<IAuthProviderContext | null>(null)

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): IAuthProviderContext | null {
  return useContext(AuthContext)
}
