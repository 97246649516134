import { Grid, Card } from 'components/design-system'
import makeStyles from './style'

interface IScrollableContainerProps {
  children: React.ReactNode
}
export const ScrollableContainer = ({ children }: IScrollableContainerProps) => {
  const styles = makeStyles()

  return (
    <Card fullwidth className={styles.card}>
      <Grid container spacing={2} className={styles.cardContainer}>
        {children}
      </Grid>
    </Card>
  )
}
