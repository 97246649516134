import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.MuiSnackbar-root': {
      ' &.MuiAlert-filledDefault': {
        color: theme.colors.neutral.lightBase,
        backgroundColor: theme.colorBrand.light
      },
      ' &.MuiAlert-filledError': {
        color: theme.colors.neutral.lightBase,
        backgroundColor: theme.colors.negative.feedbackMedium
      },
      ' &.MuiAlert-filledWarning': {
        color: theme.colors.neutral.darkBase,
        backgroundColor: theme.colors.warning.feedbackMedium
      },
      ' &.MuiAlert-filledSuccess': {
        color: theme.colors.neutral.darkBase,
        backgroundColor: theme.colors.positive.feedbackMedium
      },
      ' &.MuiAlert-filledInformation': {
        color: theme.colors.neutral.darkBase,
        backgroundColor: theme.colors.information.feedbackMedium
      }
    }
  },
  action: {
    cursor: 'pointer',
    right: '0'
  }
}))
