import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ISchoolPeriodAccordion } from '.'

export default makeStyles((theme: Theme) => createStyles({
  calendarIcon: {
    color: theme.colors.neutral.lightBase
  },
  expandIcon: {
    color: theme.colors.neutral.lightBase
  },
  previousSchoolYear: {
    zIndex: 3,
    '&.MuiAccordion-root': {
      backgroundColor: (props: ISchoolPeriodAccordion) => props.schoolPeriodType === 'future'
        ? theme.colors.support.colorSupport04
        : theme.colors.contentType.default,
      borderRadius: '16px 16px 0px 0px!important',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0px 0px 16px 16px!important'
      }
    },
    '& .MuiAccordionDetails-root': {
      textAlign: 'center'
    }
  },
  understandButton: {
    backgroundColor: 'transparent!important',
    border: `2px solid ${theme.colors.neutral.lightBase}!important`,
    color: `${theme.colors.neutral.lightBase}!important`,
    padding: `${theme.spacingSquish.nano}!important`,
    [theme.breakpoints.down('sm')]: {
      display: 'none!important'
    }
  }
}))
