export interface IClassPerformance {
  id_school: number
  id_class: number
  id_discipline: number
  nr_released_activity?: number
  nr_released_evaluation?: number
  nr_released?: number
  nr_released_activity_student?: number
  nr_released_evaluation_student?: number
  nr_released_student?: number
  nr_answered_activity?: number
  nr_answered_evaluation?: number
  nr_answered?: number
  nr_corrected_activity?: number
  nr_corrected_evaluation?: number
  nr_corrected?: number
  nr_corrected_student?: number
  per_activity?: number
  per_evaluation?: number
  per_participation?: number
  dec_performance?: number
  ts_processed: Date
}

enum EnumStudentsListPerformanceErrorCodes {
  DATAVIZ_API_ERROR = 'DATAVIZ_API_ERROR',
  DATAVIZ_API_NOT_FOUND = 'DATAVIZ_API_NOT_FOUND'
}
export interface IStudentsListPerformanceError {
  code: EnumStudentsListPerformanceErrorCodes
  elementsIds: number[]
}

export interface IActivityPerformance {
  id_school: number
  tp_activity: number
  id_teacher: number
  dec_performance: number | null
  dt_start_activity: Date
  id_discipline: number
  dec_performance_class: number | null
  id_contents: number
  dt_end_activity: Date
  id_activity: number
  id_student: number
  published_activity_status: string
  published_contents_status: string
  activity_submitted_status: string
  activity_type: string
  activity_correction_status: string
  title: string
  title_for_expedition: string
}

export interface IEvaluationPerformance {
  id_school: number
  tp_activity: number
  id_teacher: number
  dec_performance: number | null
  dt_start_activity: Date
  id_discipline: number
  dec_performance_class: number | null
  id_contents: number
  dt_end_activity: Date
  id_activity: number
  id_student: number
  published_activity_status: string
  published_contents_status: string
  activity_submitted_status: string
  activity_type: string
  activity_correction_status: string
  title: string
  title_for_expedition: string
}

export interface IUnitStudentDetailedPerformance {
  title: string
  suggested_position: number
  description: string
  activities: IActivityPerformance[]
  evaluations: IEvaluationPerformance[]
}
export interface IStudentDetailedPerformance {
  units: IUnitStudentDetailedPerformance[]
  hasMore: boolean
}

export interface IStudentPerformance {
  dec_performance: number
  nr_situation: 1 | 2 | 3 | -1 // Intervenção / Observação / Desafios / n há notas
  per_participation: number
  ts_processed: Date
  id_school?: number
  per_evaluation?: number
  id_class?: number
  id_discipline?: number
  per_performance?: number
  nr_released?: number
  nr_corrected?: number
  id_student?: number
  nr_answered?: number
  nr_answered_activity?: number
  nr_answered_evaluation?: number
  nr_corrected_activity?: number
  nr_corrected_evaluation?: number
  nr_released_activity?: number
  nr_released_evaluation?: number
  per_activity?: number
}

export interface IStudentsPerformance {
  performance: IStudentPerformance
  user: {
    id: number
    created_at?: string | null
    email?: string | null
    name: string
    phone?: string | null
    surname: string
    username: string
  }
  user_school_profile: {
    id: number
    last_used?: string | null
  }
}
export interface IStudentsListPerformance {
  result: IStudentsPerformance[]
  error: EnumStudentsListPerformanceErrorCodes[] | null
}

export interface ILastUpdatedResponse {
  lastUpdated: Date
}

export interface IWeekUsage {
  schoolId: number
  weekFirstDay: Date
  weekLastDay: Date
  totalAccessNumber: number
  studentAccessNumber: number
  teacherAccessNumber: number
}

export interface IEnrollmentDataResponse {
  schoolPeriod: number
  schoolPeriodId: number
  subscribed: number
  notSubscribed: number
  inContract: number
  enrollment: Array<{
    id_school: number
    per_student_estimated: number
    nr_student: number
    nr_student_estimated: number
    dt_date: Date
    per_enrollment: number
    id_school_period: number
    nr_enrollment: number
    nr_reenrollment: number
    per_reenrollment: number
  }>
}

export interface IEnrollmentDataDailyResponse {
  schoolPeriod: number
  schoolPeriodId: number
  subscribed: number
  notSubscribed: number
  inContract: number
  id_school: number
  per_student_estimated: number
  nr_student: number
  nr_student_estimated: number
  dt_date: Date
  per_enrollment: number
  id_school_period: number
  nr_enrollment: number
  nr_reenrollment: number
  per_reenrollment: number
}

export type IWeeklyUsageResponse = IWeekUsage[] | null
