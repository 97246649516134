export const GRADE_FROM_GRADE_TYPE = (gradeTypeId: number | string, schoolPeriodId?: number | 'current') => {
  const baseUrl = `${process.env.REACT_APP_BACKEND_URL as string}/grade-types/${gradeTypeId}/grades`

  const url = new URL(baseUrl)

  if (schoolPeriodId) url.searchParams.append('schoolPeriod', schoolPeriodId.toString())

  return url.toString()
}

export const GRADE_TYPES_GET = (id?: number) => {
  const _id = id ? `/${id}` : ''
  return `${process.env.REACT_APP_BACKEND_URL as string}/grade-types${_id}`
}
export const GRADE_TYPE_BASE_BY_PROGRAM = () => `${process.env.REACT_APP_BACKEND_URL as string}/grade-types/school/program`
export const GRADE_TYPE_BY_SCHOOL = (schoolId: number) => `${process.env.REACT_APP_BACKEND_URL as string}/schools/${schoolId}/enabled-grade-types-and-grades`
