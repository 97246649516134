import React from 'react'
import { Grid } from '@mui/material'

import { ReportsTable } from '../'

export const ReportsListLoading: React.FC = () => {
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <ReportsTable.Skeleton />
      </Grid>
    </Grid>
  )
}
