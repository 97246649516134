import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  header: {
    height: '105px',
    width: '100%',
    padding: theme.spacingInline.xxs,
    background: 'linear-gradient(white 60%, transparent)',

    // mobile
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      background: 'white',
      position: 'fixed',
      top: 0,
      zIndex: 2,
      padding: '12px 16px',
      boxShadow: theme.shadowLevels.level1,
      borderRadius: `0px 0px ${theme.borderRadius.md} ${theme.borderRadius.md}`,
      transition: 'top ease-in-out .35s',

      '&.hidden': {
        top: '-80px'
      }
    }
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center'
  },
  iconMenuContainer: {
    display: 'none',
    padding: theme.spacingInset.xs,
    marginRight: theme.spacingInset.xs,
    cursor: 'pointer',
    // mobile
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      display: 'flex',
      marginRight: theme.spacingInline.nano
    }
  },
  iconMenuSvg: {
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.sm,
    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xxxs
    }
  },
  schoolContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: theme.spacingInline.xxxs,
    borderRight: `1px solid ${theme.colors.neutral.light10}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingInline: theme.spacingInline.nano
    }
  },
  schoolInforamtionText: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: '24px',
    color: theme.colors.neutral.dark30
  },
  schoolChange: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    '&.highlight': {
      position: 'relative',
      zIndex: '2000',
      [theme.breakpoints.up('sm')]: {
        '& svg': {
          color: theme.colors.neutral.lightBase
        }
      }
    }
  },
  iconChangeSchool: {
    display: 'flex',
    cursor: 'pointer',
    padding: '0 4px 0 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px'
    }
  },
  iconSvg: {
    fontSize: theme.font.fontSize.sm,
    color: theme.colorBrand.medium,
    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xxxs
    }
  },
  schoolChangeTitle: {
    '& > span': {
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    '&.highlight': {
      '& span': {
        color: theme.colors.neutral.lightBase
      }
    }
  },
  iconNotification: {
    display: 'flex',
    // padding: theme.spacingInset.xs,
    padding: theme.spacingInset.xxs,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  avatar: {
    cursor: 'pointer',
    paddingRight: theme.spacingInline.nano
  },
  userInfo: {
    maxWidth: 0,
    maxHeight: '40px',
    overflow: 'hidden',
    transition: 'all ease-in-out .2s',

    '&.opened': {
      paddingRight: theme.spacingInline.nano,
      maxWidth: '300px'
    }
  },
  userInfoName: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xs,
    lineHeight: '24px',
    color: theme.colors.neutral.dark40,
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  userInfoLogout: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: '100%',
    color: theme.colorBrand.medium,
    cursor: 'pointer'
  },
  schoolNameContainer: {
    paddingRight: theme.spacingInline.nano
  },
  unitLabel: {
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xxxs
    },
    [theme.breakpoints.up('sm')]: {
      '&.highlight': {
        color: theme.colors.neutral.lightBase,
        position: 'relative',
        zIndex: '2000'
      }
    }
  },
  schoolName: {
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xxxs
    },
    [theme.breakpoints.up('sm')]: {
      '&.highlight': {
        color: theme.colors.neutral.lightBase,
        position: 'relative',
        zIndex: '2000'
      }
    }
  },
  buttonsContainer: {
    margin: '24px 16px 0px 0px'
  },
  cancelButton: {
    float: 'right'
  },
  schoolCardContainer: {
    overflowY: 'scroll',
    maxHeight: '350px',
    paddingRight: theme.spacingInline.quarck,
    '&::-webkit-scrollbar': {
      width: '12px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.neutral.light20,
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.colors.neutral.light30
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '337px'
    }
  },
  schoolCard: {
    color: theme.colors.neutral.dark30,
    cursor: 'pointer',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    minHeight: '80px',
    lineHeight: '24px',
    marginBottom: theme.spacingInline.xxxs
  },
  searchContainer: {
    margin: '0px 16px 24px 0px'
  },
  schoolSelectionModal: {
    '& .MuiBox-root': {
      maxHeight: '560px',
      transform: 'inherit',
      top: '80px',
      right: '140px',
      left: 'auto',
      padding: '24px 4px 16px 24px',
      '&:focus': {
        outline: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(-50%, -50%)',
        top: '50%;',
        right: 'auto',
        left: '50%;'
      }
    }
  },
  noResultsFound: {
    textAlign: 'center'
  }
}))
