import PlayArrowIcon from '@mui/icons-material/PlayArrow'

export const usePill = () => {
  const getArrowIconByValue = (value: number | undefined | null) => {
    if (value && value > 0) return <PlayArrowIcon sx={{ transform: 'rotate(270deg)', marginTop: '2px', marginLeft: '-2px' }} />

    if (value && value < 0) return <PlayArrowIcon sx={{ transform: 'rotate(90deg)', marginBottom: '2px', marginLeft: '-2px' }} />

    return undefined
  }

  const getLimitedValue = (value: number) => {
    if (value === 0) return '0'

    if (value > 100 || value < -100) return '> 100'

    // retornamos o módulo do número pois não exibimos o sinal de negativo
    // para representar valores negativos utilizamos a variant error
    return value ? String(Math.abs(value)) : undefined
  }

  const getVariantByValue = (value: number | undefined | null): 'warning' | 'success' | 'error' | 'default' => {
    if (value === 0 || !value) return 'warning'

    if (value > 0) return 'success'

    if (value < 0) return 'error'

    return 'default'
  }

  return {
    getArrowIconByValue,
    getLimitedValue,
    getVariantByValue
  }
}
