import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  dateInput: {
    width: '100%',
    '& .MuiFilledInput-root': {
      paddingLeft: '0px'
    },
    '& .MuiInputAdornment-root': {
      color: theme.colorBrand.medium,
      marginTop: '0px!important'
    }
  },
  gridInput: {
    '& .MuiFormHelperText-root': {
      margin: '0'
    }
  },
  modal: {
    margin: '0 auto',
    '& .MuiBox-root': {
      maxWidth: '525px'
    }
  }
}))
