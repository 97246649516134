import makeStyles from './style'
import readingListImage from 'assets/reading-list.svg'
import { EmptyOrErrorMessage, ProfileCard, ProfilesListBulkOptions } from 'components/common'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import { atomTeacherPropsData, atomSelectedTeachers } from '../../atomStore'
import { IUserResponse, ListTypeEnum, UserSchoolProfileTypeEnum, teacherListProfileBulkOptionsTags, teacherTags } from 'services'
import noSearchResults from 'assets/search.svg'
import { atomSearchTerm } from '../../atomStore/index'

export const TeacherList: React.FC = () => {
  const styles = makeStyles()
  const [selectedTeachers, setSelectedTeachers] = useAtom(atomSelectedTeachers)
  const [teacherPropsData] = useAtom(atomTeacherPropsData)
  const searchTerm = useAtomValue(atomSearchTerm)

  const handleSelectTeacher = (teacher: IUserResponse) => setSelectedTeachers(
    selectedTeachers.find((user) => user.id === teacher.id)
      ? selectedTeachers.filter((u) => u.id !== teacher.id)
      : [...selectedTeachers, teacher]
  )

  const selectAll = () => {
    const allTeachersFromThePage = teacherPropsData?.users
      ? teacherPropsData.users
      : []

    setSelectedTeachers(
      selectedTeachers.concat(
        allTeachersFromThePage.filter((item) => !selectedTeachers.find((teacher) => teacher === item))
      )
    )
  }

  const clearSelection = () => setSelectedTeachers([])

  if (teacherPropsData?.users?.length) {
    return (
      <div className={styles.teacherList}>
        <ProfilesListBulkOptions
          selectAllListItems={selectAll}
          clearListItemsSelection={clearSelection}
          tagging={teacherListProfileBulkOptionsTags}
          selectionList={selectedTeachers}
          hasSelection={selectedTeachers?.length > 0}
          profile={UserSchoolProfileTypeEnum.teacher}
          listbycontext={ListTypeEnum.list}
        />
        {
          teacherPropsData?.users?.map(user =>
            <ProfileCard
              key={user?.id}
              checked={!!(selectedTeachers?.find(u => u.id === user?.id))}
              onClick={() => handleSelectTeacher(user)}
              profile={user?.profiles.find(p => p.type === UserSchoolProfileTypeEnum.teacher)}
              profiletype={UserSchoolProfileTypeEnum.teacher}
              user={user}
              disabled={!user?.profiles.find(p => p.active && p.type === UserSchoolProfileTypeEnum.teacher)}
              tags={teacherTags}
            />
          )
        }
      </div>
    )
  }

  return (
    teacherPropsData?.users.length === 0 && searchTerm
      ? <EmptyOrErrorMessage
        message='Não encontramos o nome digitado na lista de professores. <b>Verifique e tente novamente.</b>'
        image={noSearchResults}
      />
      : <EmptyOrErrorMessage
        message='A lista de professores está vazia. Para cadastrar um usuário, clique no botão <b>Adicionar novo.</b>'
        image={readingListImage}
      />
  )
}

export default TeacherList
