import { useEffect, useState } from 'react'
import { IProfilesAccessedLastWeekBoxResponse, ISchoolPeriod, UserSchoolProfileTypeEnum, getProfilesAccessedLastWeekBoxData } from 'services'
import { useClassManagement, useSnackbar } from 'navigation/hooks'
import { useStore } from 'store'
import { pluralLowerCaseFormattedProfiles } from 'utils'
import { useTranslation } from 'react-i18next'

export const useProfilesAccessedLastWeekBox = (profileType: UserSchoolProfileTypeEnum) => {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const { getSchoolPeriods } = useClassManagement()

  // atoms
  const [data, setData] = useState<IProfilesAccessedLastWeekBoxResponse | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [hasNoContent, setHasNoContent] = useState<boolean>(false)

  // states
  const [reload, setReload] = useState<boolean>(false)

  const fetchData = async (schoolId: number, schoolPeriodId: number) => {
    try {
      setLoading(true)
      const response = await getProfilesAccessedLastWeekBoxData(schoolId, schoolPeriodId, profileType)

      if (response.success) {
        setError(false)
        setData(response?.data)
      } else if (response.status === 404) {
        setHasNoContent(true)
      } else {
        setError(true)
        throw new Error(t(`Erro ao tentar obter dados de acesso de ${pluralLowerCaseFormattedProfiles[profileType]}`))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setReload(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (reload) {
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      getSchoolPeriods(school?.id ?? 0).then((sps: ISchoolPeriod[]) => fetchData(school?.id ?? 0, sps.find(sp => sp.current)?.id ?? 0))
    }
  }, [reload])

  return {
    data,
    error,
    fetchData,
    loading,
    setReload,
    hasNoContent
  }
}
