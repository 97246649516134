import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Text2 } from 'components/design-system'
import { ModalWrapper } from 'components/common'

interface IKnowMoreButtonProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const KnowMoreModal: React.FC<IKnowMoreButtonProps> = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <ModalWrapper
      open={open}
      confirmLabel={t('Entendi!')}
      cancelDatatestId='performance_know_more_close'
      confirmDatatestId='performance_know_more_understood'
      onConfirm={() => setOpen(false)}>
      <Text2
        fontSize='lg'
        fontWeight='medium'
        lineHeight='xs'
        mobile='md'
        sx={{ textAlign: 'center' }}
        customColor={theme.colorBrand.dark}
      >
        {t('Situação do estudante: próximos passos')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='medium'
        lineHeight='xs'
        mobile='xs'
        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: theme.spacingStack.xxxs }}
        customColor={theme.colors.neutral.dark10}
      >
        <div dangerouslySetInnerHTML={{ __html: t('<b>Intervenção:</b> para estudantes com média entre <b>0</b> e <b>5,9</b>, é recomendado um acompanhamento detalhado. Analisar avaliações com notas baixas pode indicar quais habilidades o estudante precisa desenvolver. Exercícios de reforço e materiais complementares são boas ferramentas de apoio.', { interpolation: { escapeValue: false } }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('<b>Observação:</b> para estudantes com média entre <b>6</b> e <b>8,9</b>, a sugestão é registrar quais habilidades mostram tendência de queda de rendimento. Observar temas de interesse do estudante e incentivá-lo a trazer dúvidas e aprofundar investigações é um bom convite para maior engajamento.', { interpolation: { escapeValue: false } }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('<b>Desafios:</b> instigue os estudantes com média superior a <b>9,0</b> apresentando temas de pesquisa adicionais, cursos e concursos apropriados para a faixa etária. Outra estratégia importante é incentivá-los a apoiar outros estudantes.', { interpolation: { escapeValue: false } }) }} />
      </Text2>
    </ModalWrapper>
  )
}
