export interface ITabPanelProps {
  value: number
  index: number
}

export const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`cloe-tab-${index}`}
  >
    {value === index && children}
  </div>
)
