import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    orderBy: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    pagination: {
      [theme.breakpoints.up('md')]: {
        lineHeight: '55px'
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiPagination-root': {
          margin: '0 auto',
          width: 'fit-content'
        }
      }
    },
    paginationInfo: {
      textAlign: 'center'
    }
  }
})
