export interface IUrlRequest {
  success: boolean
  transaction: string
  message: string
  url: string
}

export interface IDownloadUrlRequest {
  success: boolean
  url: string
}

export interface IListOfFile {
  success?: boolean
  data?: Data
}

export interface Data {
  Items?: Item[]
  Count?: number
  ScannedCount?: number
}

export interface Item {
  extra?: Extra
  status: string
  filename: string
  bucket?: string
  delegate?: string
  key?: string
  transaction?: string
  createdAt: string
  statusId?: string
  errors?: Error[]
  warnings?: Warning[]
  abortController?: AbortController
  showButton?: boolean
}

export interface Error {
  user_email: string
  message: string
  fields: string[]
  line: number
}

export interface Warning {
  line: number
  message: string
}

export interface Extra {
  uploadtype: string
  schoolid: string
}

/** TODO turn this into a enum
 * e.g.
 * enum StatusEnum {
 *  SENDING = '202f07a1f640454bd532e4e1e6a75715'
 * }
 * and add to id: StatusEnum.SENDING
 * */
export const errorMapping = [
  { color: 'negative', allowAction: false, text: 'Erro no arquivo', id: '2721b9bb6707fa93b7aec8dbe2f69488' },
  { color: 'negative', allowAction: false, text: 'Erro no processamento', id: '7c7cb744e0d297421a8780cac1edd52c' },
  { color: 'negative', allowAction: false, text: 'Erro no processamento', id: '07ae9013a4c7cd6a106be959f6e45637' },
  { color: 'positive', allowAction: false, text: 'Concluída a validação', id: 'ff8c3120c9f4a193337988348f48a3f8' },
  { color: 'positive', allowAction: false, text: 'Concluída a importação', id: 'c6f69ef69b3ed3da77021e0974da8aa5' },
  { color: 'positive', allowAction: true, text: 'Concluído com erros', id: '6b5391112409294530b47429f3fc5407' },
  { color: 'positive', allowAction: true, text: 'Concluído', id: '874d591eccc4ad3ec908da37553fd9dc' },
  { color: 'information', allowAction: false, text: 'Enviado', id: '823a8f1e7b400e02fc4b463f4e2cbd72' },
  { color: 'information', allowAction: false, text: 'Lendo arquivo', id: '96d59fc9e5666d7ac9c922e217e44cfc' },
  { color: 'information', allowAction: false, text: 'Processando', id: '0154457cdd83227374891a1dfbc3ac2f' },
  { color: 'information', allowAction: false, text: 'Aguardando status', id: '8d53e33757b4107a21a51ba8d896cd44' },
  { color: 'warning', allowAction: false, text: 'Enviando', id: '202f07a1f640454bd532e4e1e6a75715' }
]
