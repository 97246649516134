/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  chartContainer: {
    height: '300px'
  }

}))
