import { ChangeEvent, KeyboardEvent, useState, useEffect, useRef } from 'react'
import { TeacherHeader, TeacherList } from './components'
import { MobileOrderByModal, MobileSearchBar } from 'components/common'
import { Loading } from 'components/design-system'
import { useStore } from 'store'
import { getProfiles, UserSchoolProfileTypeEnum } from 'services'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import {
  atomOrderBy,
  atomPage,
  atomSearchTerm,
  atomSelectedTeachers,
  atomTeacherPropsData
} from './atomStore'
import { atomReloadList } from '../atomStore'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const TeacherTab: React.FC = () => {
  const { school } = useStore()
  const theme = useTheme<Theme>()

  // atoms
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const [page, setPage] = useAtom(atomPage)
  const [searchTerm, setSearchTerm] = useAtom(atomSearchTerm)
  const setSelectedTeachers = useUpdateAtom(atomSelectedTeachers)
  const resetReloadList = useResetAtom(atomReloadList)
  const reloadList = useAtomValue(atomReloadList)
  const setTeacherPropsData = useUpdateAtom(atomTeacherPropsData)

  // states
  const [visible, setVisible] = useState<boolean>(true)
  const [openOrderByModal, setOpenOrderByModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageYOffset, setPageYOffset] = useState<number>(0)

  const previousPageYOffset = usePrevious(pageYOffset) as number

  const handleScroll = () => {
    const isScrollToDown = pageYOffset > previousPageYOffset

    if (!isScrollToDown !== visible) {
      setVisible(!isScrollToDown)
    }
  }

  if (document.body.clientWidth < theme.breakpoints.values.sm) {
    document.removeEventListener('scroll', () => setPageYOffset(window.scrollY))
    document.addEventListener('scroll', () => setPageYOffset(window.scrollY))
  }

  useEffect(() => {
    handleScroll()
  }, [pageYOffset])

  const handleClose = () => {
    setOpenOrderByModal(false)
  }

  const handleOrderByOpen = () => {
    setOpenOrderByModal(true)
  }

  const handleSelectOrder = (optionValue: string) => {
    setOrderBy(optionValue)
    setOpenOrderByModal(false)
  }

  const filter = async (pageNumber: number, sort: string, search: string) => {
    try {
      setIsLoading(true)
      const response = await getProfiles(
        UserSchoolProfileTypeEnum.teacher,
        school?.id ?? 0,
        pageNumber,
        sort,
        search.trim()
      )
      setTeacherPropsData(response.data)
    } catch (error) {
      setTeacherPropsData(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (reloadList) {
      filter(page ?? 1, orderBy, searchTerm)
      resetReloadList()
    }
  }, [reloadList])

  useEffect(() => {
    filter(page ?? 1, orderBy, searchTerm)
  }, [orderBy, page])

  useEffect(() => {
    setSelectedTeachers([])
    setPage(1)
    filter(1, orderBy, searchTerm)
  }, [school])

  const handleSearchClick = () => {
    filter(page ?? 1, orderBy, searchTerm)
  }

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.currentTarget.value)
      setSelectedTeachers([])
      setPage(1)
      filter(page ?? 1, orderBy, event.currentTarget.value)
    }
  }

  const handleSearchTerm = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)

  return (
    <>
      <TeacherHeader
        handleSearch={handleSearch}
        handleSearchClick={handleSearchClick}
        handleSearchTerm={handleSearchTerm}
      />
      { isLoading ? <Loading type='linear' /> : <TeacherList /> }
      <MobileSearchBar
        defaultValue={searchTerm}
        handleSearch={handleSearch}
        handleOpenOrderBy={handleOrderByOpen}
        visible={visible}
      />
      <MobileOrderByModal
        handleClose={handleClose}
        handleSelectOrder={handleSelectOrder}
        open={openOrderByModal}
        selectedOrderBy={orderBy}
      />
    </>
  )
}

export default TeacherTab
