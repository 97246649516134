/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import { ClassReport, StudentReport } from './components'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { reportStateAtom } from 'pages/ClassManagement/atomStore'

export const ReportsTab: React.FC = () => {
  // atoms
  const reportState = useAtomValue(reportStateAtom)
  const resetReportState = useResetAtom(reportStateAtom)

  useEffect(() => {
    resetReportState()
  }, [])

  const stateMachine = {
    states: {
      classReport: <ClassReport />,
      studentReport: <StudentReport/>
    }
  }

  return stateMachine.states[reportState]
}
