import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiInputAdornment-root': {
      '& .MuiButtonBase-root': {
        color: theme.colorBrand.medium
      }
    }
  }
}))
