/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useCommonStyles = makeStyles((theme: Theme) => createStyles({
  overlay: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(20, 20, 20, 0.64)',
      height: '100%',
      left: '0',
      position: 'fixed',
      top: '0',
      transition: 'all ease-in-out 0.2s',
      width: '100%',
      zIndex: '10'
    }
  },
  beforeMobile: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      left: '-550px'
    }
  },
  box: {
    backgroundColor: theme.colorBrand.light,
    borderRadius: '0 32px 32px 0',
    position: 'fixed',
    transition: 'all ease-in-out 0.2s',
    width: '72px',
    zIndex: '10',
    '& .label': {
      opacity: '0',
      position: 'fixed',
      transition: 'visibility 0s, opacity 0.2s linear',
      visibility: 'hidden',
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        opacity: '1',
        position: 'relative',
        transition: 'visibility 0s, opacity 0.2s linear',
        visibility: 'visible'
      }
    },
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        width: '250px',
        '& .label': {
          left: '58px',
          opacity: '1',
          visibility: 'visible'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '75%',
      '&:selected': {
        left: '-310px'
      }
    }
  },
  top: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    height: '30px',
    left: '0',
    overflow: 'hidden',
    position: 'absolute',
    top: '-28px',
    transition: 'all ease-in-out 0.2s',
    width: '30px',
    '&::before': {
      borderRadius: '100%',
      content: '""',
      height: '200%',
      left: '0',
      position: 'absolute',
      top: '-100%',
      width: '200%',
      zIndex: '0'
    }
  }
}))
