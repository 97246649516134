import { DragEvent, useEffect, useState } from 'react'
import { ConfirmationModal } from 'components/common'
import { Card, Container, Grid, Link, Loading, Tag, Text2 } from 'components/design-system'
import { useHistory, useParams } from 'react-router-dom'
import { USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import { Typography } from '@mui/material'
import { StudentClassCard } from '../../components/StudentClassCard'
import { useStore } from 'store'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useAtom } from 'jotai'
import {
  createClassSubscription,
  getClassSubscriptionsByUserTypeSchool,
  getGradeFromGradeType,
  getGradeTypesByProgram,
  getUserById,
  getUserSchoolProfileBySchoolAndUser,
  IClassesEntity,
  IClassSubscriptionRequest,
  IClassSubscriptionResponse,
  IGradeResponse,
  IGradeTypesResponse,
  IProfileResponse,
  IUserRegisterResponse,
  IUserSchoolProfileRequest,
  UserSchoolProfileTypeEnum,
  updateClassSubscription,
  updateUserSchoolProfile,
  getAvailableYears,
  ISchoolPeriod
} from 'services'
import { currentSchoolPeriodAtom } from '../atomStore'

interface IStudentSubscriptionToClass {
  userId: number
  classId: number
  userSchoolProfiles: IProfileResponse[]
}

export const EditStudentMultiClass: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const theme = useTheme<CustomTheme>()
  const styles = useStyles()
  const { school } = useStore()
  const { userId } = useParams<{ userId?: string }>()
  const { createSnackbar } = useSnackbar()

  // states
  const [activeClassSubscriptions, setActiveClassSubscriptions] = useState<IClassSubscriptionResponse[]>([])
  const [classSubscriptions, setClassSubscriptions] = useState<IClassSubscriptionResponse[]>([])
  const [dragging, setDragging] = useState<boolean>(false)
  const [draggingOver, setDraggingOver] = useState<boolean>(false)
  const [draggedElement, setDraggedElement] = useState<string | undefined>(undefined)
  const [grades, setGrades] = useState<IGradeResponse[] | null>([])
  const [gradeTypes, setGradeTypes] = useState<IGradeTypesResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingGradeList, setIsLoadingGradeList] = useState<boolean>(false)
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const [lastClassSubscription, setLastClassSubscription] = useState<IClassSubscriptionResponse>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedGradeType, setSelectedGradeType] = useState<IGradeTypesResponse | null>(null)
  const [user, setUser] = useState<IUserRegisterResponse>()
  const [userSchoolProfileList, setUserSchoolProfileList] = useState<IProfileResponse[]>([])

  // atoms
  const [currentSchoolPeriod, setCurrentSchoolPeriod] = useAtom(currentSchoolPeriodAtom)

  const getSchoolPeriods = async (schoolId: number) => {
    try {
      const response = await getAvailableYears(schoolId)
      if (response.success) {
        setCurrentSchoolPeriod(response.data.find((sp: ISchoolPeriod) => sp.current))
      } else {
        throw new Error(t('Não foi possível carregar os anos letivos.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getInitialData = async () => {
    if (!currentSchoolPeriod && school) await getSchoolPeriods(school.id)

    try {
      const response = await getGradeTypesByProgram()
      if (response?.success) {
        setGradeTypes(response.data)
        if (response.data.length) {
          selectGradeType(response.data[0])
        } else {
          throw new Error(t('Houve um erro ao tentar buscar os segmentos.'))
        }
      } else {
        throw new Error(t('Houve um erro ao tentar buscar os segmentos.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getUserSchoolProfileData = async () => {
    try {
      const response = await getUserSchoolProfileBySchoolAndUser(school?.id ?? 0, UserSchoolProfileTypeEnum.student, Number(userId))
      if (response.success) {
        setUserSchoolProfileList(response.data)
      } else {
        throw new Error(t('Não foi possível buscar as informações de perfil'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (selectedGradeType) {
      getGradeData(selectedGradeType?.id)
    }
  }, [selectedGradeType])

  useEffect(() => {
    if (userId) {
      getUser()
      getClassSubscriptions()
      getUserSchoolProfileData()
    }
  }, [userId])

  const getGradeData = async (gradeTypeId: number) => {
    try {
      if (currentSchoolPeriod) {
        const response = await getGradeFromGradeType(gradeTypeId, 'current')
        if (response.success) {
          setGrades(response.data)
        } else {
          throw new Error(t('Não foi possível obter as turmas do ano vigente'))
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setIsLoading(false)
      setIsLoadingGradeList(false)
    }
  }

  const selectGradeType = (gradeType: IGradeTypesResponse) => {
    setIsLoadingGradeList(true)
    setSelectedGradeType(gradeType)
  }

  const handleDragStart = (e: DragEvent, _class: IClassesEntity) => {
    setDragging(true)
    setDraggedElement(String(_class?.id))
    const json = JSON.stringify(_class)
    e.dataTransfer.setData('class', json)
  }

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    setDraggingOver(true)
    setDragging(true)
  }

  const handleDrop = async (e: DragEvent, _user: IUserRegisterResponse | undefined) => {
    e.preventDefault()
    const data: IClassesEntity = JSON.parse(e.dataTransfer.getData('class'))
    if (_user) {
      createSnackbar({ content: `Adicionando o estudante ${_user.name} a turma ${data.name}`, severity: 'info' })
      await handleSubscribeStudentForNewClassService({
        userId: Number(_user.id),
        classId: data.id,
        userSchoolProfiles: userSchoolProfileList
      })
    }
    setDraggingOver(false)
    setDragging(false)
  }

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    setDraggingOver(false)
    setDragging(false)
  }

  const getUser = async () => {
    try {
      const response = await getUserById(Number(userId))
      if (response?.success) {
        setUser(response.data)
      } else {
        throw new Error(t('Não foi possível obter as informações do estudante'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getClassSubscriptions = async () => {
    try {
      const response = await getClassSubscriptionsByUserTypeSchool(
        Number(userId),
        UserSchoolProfileTypeEnum.student,
        school?.id ?? 0
      )
      if (response?.success) {
        setClassSubscriptions(response.data)
        setActiveClassSubscriptions(response.data.filter(cs => cs.active))
      } else {
        throw new Error(t('Não foi possível obter as turmas do estudante'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const handleRemoveClass = async (classSubscription: IClassSubscriptionResponse) => {
    if (classSubscriptions?.length === 1) {
      setLastClassSubscription(classSubscription)
      setOpenModal(true)
    } else {
      removeClassSubscription(classSubscription)
    }
  }

  const handleRemoveLastClass = async () => {
    if (lastClassSubscription) {
      try {
        setIsLoadingButton(true)
        removeClassSubscription(lastClassSubscription)
        const userSchoolProfileRequest: IUserSchoolProfileRequest = {
          active: false
        }
        const responseUserSchoolProfile = await updateUserSchoolProfile(lastClassSubscription.user_school_profile.id, userSchoolProfileRequest)
        if (responseUserSchoolProfile?.errors) {
          throw new Error(t('Não foi possível desativar o perfil do usuário'))
        }
      } catch (e) {
        if (e instanceof Error) {
          createSnackbar({ content: e.message, severity: 'error' })
        }
      } finally {
        setIsLoadingButton(false)
        setOpenModal(false)
      }
    }
  }

  const removeClassSubscription = async (classSubscription: IClassSubscriptionResponse) => {
    try {
      const classSubscriptionRequest: IClassSubscriptionRequest = {
        active: false,
        class_id: classSubscription.class_id.id,
        user_id: classSubscription.user_id.id,
        user_school_profile: classSubscription.user_school_profile.id
      }
      const response = await updateClassSubscription(classSubscription.id, classSubscriptionRequest)
      if (response?.errors) {
        throw new Error(t('Não foi possível desvincular o estudante da turma'))
      }
      getClassSubscriptions()
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const updateStudentSubscriptionToClass = async (classSubscriptionRequest: IClassSubscriptionRequest, classSubscription: IClassSubscriptionResponse, hasActive: IClassSubscriptionResponse | undefined) => {
    try {
      const response = await updateClassSubscription(classSubscription.id, classSubscriptionRequest)
      createSnackbar({ content: `Turma vinculada ao estudante ${user?.name ?? ''}`, severity: 'success' })
      if (!hasActive && response.success) {
        history.push(USER_MANAGEMENT(`students/${userId ?? ''}/edit`))
        createSnackbar({ content: 'O perfil do estudante já pode ser ativado novamente.', severity: 'info' })
      }
      if (response.errors) {
        throw new Error(t('Ocorreu um erro ao tentar vincular a turma ao estudante. Tente novamente.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const subscribeStudentToNewClass = async (classSubscriptionRequest: IClassSubscriptionRequest, hasActive: IClassSubscriptionResponse | undefined) => {
    try {
      const response = await createClassSubscription(classSubscriptionRequest)
      createSnackbar({ content: `Turma vinculada ao estudante ${user?.name ?? ''}`, severity: 'success' })
      if (!hasActive && response.success) {
        history.push(USER_MANAGEMENT(`students/${userId ?? ''}/edit`))
        createSnackbar({ content: 'O perfil do estudante já pode ser ativado novamente.', severity: 'info' })
      }
      if (response.errors) {
        throw new Error(t('Ocorreu um erro ao tentar vincular a turma ao estudante. Tente novamente.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const handleSubscribeStudentForNewClassService = async ({ userId: _userId, classId, userSchoolProfiles }: IStudentSubscriptionToClass) => {
    const profileStudent = userSchoolProfiles.find(usp => usp.type === UserSchoolProfileTypeEnum.student)

    if (profileStudent) {
      const hasClassSubscription = classSubscriptions.find((cs: IClassSubscriptionResponse) => cs.class_id.id === classId)
      const hasActive = activeClassSubscriptions.find(cs => cs.active)

      const classSubscriptionRequest: IClassSubscriptionRequest = {
        active: true,
        class_id: classId,
        user_id: _userId,
        user_school_profile: profileStudent.id
      }

      if (classSubscriptions.length && hasClassSubscription && !hasClassSubscription?.active) {
        await updateStudentSubscriptionToClass(classSubscriptionRequest, hasClassSubscription, hasActive)
      } else if (hasClassSubscription?.active) {
        createSnackbar({ content: t('Turma já cadastrada'), severity: 'error' })
      } else {
        await subscribeStudentToNewClass(classSubscriptionRequest, hasActive)
      }
    }
    getClassSubscriptions()
  }

  return (
    <>
      <Container extraclasses={styles.container} disableGutters>
        <Grid container spacing={2} className={styles.header}>
          <Grid item xs={12}>
            <Link
              variant='returnArrow'
              onClick={() => history.push(USER_MANAGEMENT(`students/${userId ?? ''}/edit`))}
              linkStyle
              data-testid='add_another_class_to_the_student_back_to_student_edition'
            >
              {t('Voltar')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark30'>
              {t('Para adicionar o estudante a uma turma, clique e arraste a turma para o nome dele.')}
            </Text2>
          </Grid>
        </Grid>
        {isLoading
          ? <Loading type='linear' />
          : (
            <Grid container className={styles.twoColumnContainer}>
              <Grid container item xs={6}>
                <Grid container className={styles.topTableContainer}>
                  <Grid item xs={12} className={styles.topTableSection}>
                    <Grid container item spacing={2} className={`${styles.gradeTypeList} ${styles.scrollStyle}`}>
                      {gradeTypes.map(gt =>
                        <Grid item key={gt.id}>
                          <Tag
                            active={selectedGradeType?.id === gt.id}
                            background={theme.colorBrand.dark}
                            label={gt.name}
                            onClick={() => selectGradeType(gt)}
                            textcolor={theme.colors.neutral.lightBase}
                            data-testid='add_another_class_to_the_student_select_segment'
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={styles.bottomTableSection}>
                    <Grid item xs={12}>
                      <Text2 fontSize='md' fontWeight='semibold' lineHeight='xl' mobile='sm' colorbrand='medium'>
                        {selectedGradeType?.name}
                      </Text2>
                    </Grid>
                    <Grid container className={`${styles.gradesContainer} ${styles.scrollStyle}`}>
                      {isLoadingGradeList
                        ? <Grid container><Loading /></Grid>
                        : grades?.length
                          ? grades.map(grade => (
                            <Grid container spacing={2} key={grade.id}>
                              <Grid item xs={12}>
                                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xl' mobile='sm' neutral='dark30'>
                                  {grade.name}
                                </Text2>
                              </Grid>
                              <Grid container item spacing={2}>
                                {grade?.classes?.length
                                  ? grade.classes.map(_class => (
                                    <Grid item key={_class.id}>
                                      <Card
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, _class)}
                                        extraClasses={`${dragging && draggedElement === String(_class.id) ? styles.draggingClassCard : ''} ${styles.gradeCard}`}
                                        dataTestId='add_another_class_to_the_student_add_class_to_student'
                                      >
                                        <Grid container item alignItems='center'>
                                          <Grid container item xs={2} className={styles.classIcon}>
                                            <SchoolOutlinedIcon sx={{ color: '#5B5B5B' }} fontSize='small' />
                                          </Grid>
                                          <Grid container item xs={10}>
                                            <Typography noWrap>
                                              <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='sm' neutral='dark30' className={styles.draggableClassItem}>
                                                {_class.name}
                                              </Text2>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Grid>
                                  ))
                                  : (
                                    <Grid item>
                                      <Text2 fontSize='sm' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark30' className={styles.draggableClassItem}>
                                        {t('Nenhuma turma encontrada')}
                                      </Text2>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            </Grid>
                          ))
                          : (
                            <Grid item>
                              <Text2 fontSize='sm' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark30' className={styles.draggableClassItem}>
                                {t('Nenhuma turma encontrada')}
                              </Text2>
                            </Grid>
                          )
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={6} className={styles.intersection}>
                <Grid container className={styles.topTableContainer}>
                  <Grid item xs={12} className={styles.topTableSection}>
                    <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='lg' colorbrand='dark'>{t('Estudantes')}</Text2>
                  </Grid>
                  <Grid item xs={12} className={styles.bottomTableSection}>
                    <Grid container>
                      <StudentClassCard
                        classSubscriptions={activeClassSubscriptions.filter(cs => currentSchoolPeriod?.id === Number(cs.class_id.school_period))}
                        extraClasses={draggingOver ? styles.draggingOverStudentContainer : ''}
                        onDelete={handleRemoveClass}
                        onDragOver={(e) => handleDragOver(e)}
                        onDrop={async (e) => await handleDrop(e, user)}
                        onDragLeave={(e) => handleDragLeave(e)}
                        user={user}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Container>
      <ConfirmationModal
        confirmButtonText={t('Continuar')}
        cancelButtonText={t('Cancelar')}
        confirmCallback={handleRemoveLastClass}
        description={t('Ao desvincular a última turma, você desativará este perfil de estudante do usuário. Deseja continuar?')}
        handleClose={() => setOpenModal(false)}
        isLoading={isLoadingButton}
        isOpen={openModal}
        title='Desativar perfil de usuário'
      />
    </>
  )
}
