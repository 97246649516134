import { request, IApiResponse, IUrlRequest, IListOfFile } from 'services'
import { GET_S3_URL, LIST_OF_FILES, CHECK_MASS_REGISTRATION } from './CONSTANTS'

const getBinaryFromFile = async (file: File): Promise<string | ArrayBuffer | null> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.addEventListener('error', err => reject(err))

    reader.readAsArrayBuffer(file)
  })
}

export const getS3UploadUrl = async (file: File, fileName: string, delegate: string, schoolId: string, uploadType: string): Promise<IApiResponse<IUrlRequest>> => {
  const requestBody = {
    fileName: fileName,
    delegate: delegate,
    schoolId: schoolId,
    uploadType: uploadType,
    contentType: `${file.type}; charset=utf-8`
  }

  return await request({ url: GET_S3_URL(), method: 'POST', data: requestBody, isService: true })
}

export const UploadFile = async (file: File, url: string, abortController: AbortController): Promise<any> => {
  const blob = file.type
  const binary = await getBinaryFromFile(file)

  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': `${blob}; charset=utf-8`
    },
    body: binary,
    signal: abortController.signal
  })
}

export const GetFiles = async (schoolId: number): Promise<IApiResponse<IListOfFile>> =>
  await request({ url: LIST_OF_FILES(schoolId), method: 'GET', isService: true })

export const CheckMassRegistration = async (id: string): Promise<IApiResponse<boolean>> => {
  return await request({ url: CHECK_MASS_REGISTRATION(id), method: 'GET' })
}
