import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    actionButton: {
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    modalDescription: {
      padding: '0px 32px 32px 32px'
    },
    modalTitle: {
      paddingBottom: '32px',
      textAlign: 'center'
    },
    profileList: {
      padding: `${theme.spacingInline.xxxs} 0px`,
      '& > div + div': {
        marginTop: theme.spacingStack.xs
      }
    }
  }
})
