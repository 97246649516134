import { useState } from 'react'
import { Grid, Text2, Card, TextField, Loading } from 'components/design-system'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import makeStyles from './style'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { atomDisplayCreateClassCard, atomEnableSaveButton, atomReloadDestinyList, atomSelectedFiltersDestiny } from 'pages/ClassManagement/atomStore'
import { useStore } from 'store'
import { IClassRequest } from 'services'
import { useEditSchoolClass } from 'pages/ClassManagement/hooks'
import { useSnackbar } from 'navigation/hooks/useSnackbar'

interface ICreateClass {
  createClassName: string
}

interface ICreateClassCardPlaceholderProps {
  onCancel: () => void
}

export const CreateClassCardPlaceholder = ({ onCancel }: ICreateClassCardPlaceholderProps) => {
  const { t } = useTranslation()
  const classes = makeStyles()
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const { error, createClassHandler } = useEditSchoolClass()

  // state
  const [loadingCreatingClass, setLoadingCreatingClass] = useState<boolean>(false)

  // atoms
  const setReloadDestinyList = useUpdateAtom(atomReloadDestinyList)
  const setDisplayCreateClassCard = useUpdateAtom(atomDisplayCreateClassCard)
  const setEnableSaveButton = useUpdateAtom(atomEnableSaveButton)
  const selectedFiltersDestiny = useAtomValue(atomSelectedFiltersDestiny)

  const initialValuesForm = {
    createClassName: ''
  }

  const createClassNameSchema = Yup.object().shape({
    createClassName: Yup.string().required('Campo obrigatório')
  })

  const handleCreateClass = async (values: ICreateClass) => {
    if (selectedFiltersDestiny?.grade && selectedFiltersDestiny?.schoolPeriod && school?.id) {
      setLoadingCreatingClass(true)

      const payload: IClassRequest = {
        name: values.createClassName.trim(),
        student_limit: 30,
        school: school.id,
        grades: [selectedFiltersDestiny.grade],
        school_period: selectedFiltersDestiny.schoolPeriod
      }
      try {
        const response = await createClassHandler(payload, { schoolId: school.id })

        if (response?.success) {
          createSnackbar({ content: 'Turma criada com sucesso', severity: 'success' })
          setReloadDestinyList(true)
          setDisplayCreateClassCard(false)
          setEnableSaveButton(true)
        }

        if (error) {
          createSnackbar({ content: error, severity: 'error' })
        }
      } catch (err) {
        createSnackbar({ content: 'Erro ao criar turma', severity: 'error' })
      } finally {
        setLoadingCreatingClass(false)
      }
    }
  }

  return (
    <Card>
      <Formik
        initialValues={initialValuesForm}
        validationSchema={createClassNameSchema}
        onSubmit={async (values) => { handleCreateClass(values) }}
      >
        {({ values, errors, handleSubmit, handleChange }) => (
          <Form onSubmit={ handleSubmit } >
            <Grid container spacing={1}>
              <Grid item md={6} xl={8}>
                <Field
                  component={TextField}
                  name='createClassName'
                  id='createClassName'
                  variant='filled'
                  label={t('Nome da turma*')}
                  value={values.createClassName}
                  onChange={handleChange}
                  onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                  assistivetext={errors.createClassName ? t('Campo obrigatório') : undefined}
                  error={Boolean(errors.createClassName)}
                  className={classes.createClassNameInput}
                  autoFocus
                >
                </Field>
              </Grid>
              <Grid container item md={6} xl={4} display='flex' justifyContent='space-between' alignItems='center'>
                <Grid item xs={6} display='flex' justifyContent='center'>
                  {
                    loadingCreatingClass
                      ? <Loading size={24} />
                      : <Text2
                        fontSize='xs' fontWeight='semibold' lineHeight='sm' colorbrand='medium' mobile='xs' cursor='pointer'
                        data-testid='menu_class_management_import_save_create_new_class'
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation()
                          handleSubmit()
                        }}>
                        {t('Salvar')}
                      </Text2>
                  }
                </Grid>
                <Grid item xs={6} display='flex' justifyContent='center'>
                  <Text2
                    fontSize='xs' fontWeight='semibold' lineHeight='sm' negative='feedbackMedium' mobile='xs' cursor='pointer'
                    data-testid='menu_class_management_import_cancel_create_new_class'
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation()
                      onCancel()
                    }}
                  >
                    {t('Cancelar')}
                  </Text2>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Divider variant='fullWidth' sx={{ margin: '16px 0' }} />
      <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>
        {t('Nenhum estudante foi adicionado nessa turma.')}
      </Text2>
    </Card>
  )
}
