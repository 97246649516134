import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'

const phrases = {
  activate: {
    student: 'Você tem certeza de que deseja ativar o perfil do estudante',
    studentPlural: 'Você tem certeza de que deseja ativar os perfis dos estudantes selecionados',
    teacher: 'Você tem certeza de que deseja ativar o perfil do professor',
    teacherPlural: 'Você tem certeza de que deseja ativar os perfis dos professores selecionados'
  },
  deactivate: {
    student: 'Você tem certeza de que deseja desativar o perfil do estudante',
    studentPlural: 'Você tem certeza de que deseja desativar os perfis dos estudantes selecionados',
    teacher: 'Você tem certeza de que deseja desativar o perfil do professor',
    teacherPlural: 'Você tem certeza de que deseja desativar os perfis dos professores selecionados'
  }
}

export const getProperProfilePhraseForActivation = (profile: keyof typeof UserSchoolProfileTypeEnum, list?: IUserResponse[]) => {
  switch (profile) {
    case UserSchoolProfileTypeEnum.student:
      return list?.length ? phrases.activate.studentPlural : phrases.activate.student
    case UserSchoolProfileTypeEnum.teacher:
      return list?.length ? phrases.activate.teacherPlural : phrases.activate.teacher
  }
}

export const getProperProfilePhraseForDeactivation = (profile: keyof typeof UserSchoolProfileTypeEnum, list?: IUserResponse[]) => {
  switch (profile) {
    case UserSchoolProfileTypeEnum.student:
      return list?.length ? phrases.deactivate.studentPlural : phrases.deactivate.student
    case UserSchoolProfileTypeEnum.teacher:
      return list?.length ? phrases.deactivate.teacherPlural : phrases.deactivate.teacher
  }
}

export const getProperProfilePhrase = (profile: keyof typeof UserSchoolProfileTypeEnum, list?: IUserResponse[]) => {
  switch (profile) {
    case UserSchoolProfileTypeEnum.student:
      return list?.length ? 'estudantes' : 'estudante'
    case UserSchoolProfileTypeEnum.teacher:
      return list?.length ? 'professores' : 'professor'
  }
}
