import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const cloeOverflow = 25
const worm1Overflow = 35
const worm2Overflow = 35

export default makeStyles((theme: Theme) => createStyles({
  quickAccessContainer: {
    position: 'relative',
    background: 'linear-gradient(92.2deg, #499ACE -4.69%, #4B61AA 58.54%)',
    borderRadius: theme.borderRadius.md,
    padding: theme.spacingInline.xxxs,
    margin: `0 0 ${theme.spacingInline.md} 0`,
    flexDirection: 'column !important' as 'column',
    color: theme.colors.neutral.lightBase,

    [theme.breakpoints.up('md')]: {
      height: '157px',
      flexDirection: 'row !important' as 'row'
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`
    }
  },
  interactiveSection: {
    flexDirection: 'column !important' as 'column'
  },
  contentSeparator: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1
  },
  linksContainer: {
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: theme.spacingInline.xxxs,
    flexDirection: 'column !important' as 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row'
    }
  },
  cloeAvatar: {
    position: 'relative',
    marginTop: `-${theme.spacingInline.xxs}`,
    marginLeft: '1px',
    marginRight: theme.spacingInline.xxxs,
    height: `calc(${theme.spacingInline.xs} + ${cloeOverflow + 100}%)`,

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      top: `calc(-${theme.spacingInline.xxs})`
    },
    [theme.breakpoints.down('md')]: {
      height: '128px',
      marginRight: theme.spacingInline.nano,
      marginBottom: `-${theme.spacingInline.xs}`
    }
  },
  cloeWorm1: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.nano} - 24px - ${theme.spacingInline.xs})`,
    height: `calc(${theme.spacingInline.xs} + 184px)`,
    right: theme.spacingInline.xxxs,
    zIndex: 0
  },
  cloeWorm2: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.nano} - 24px - ${theme.spacingInline.xs})`,
    height: `calc(${theme.spacingInline.xs} + ${worm2Overflow + 100}%)`,
    right: `calc(-${theme.spacingInline.sm})`,
    zIndex: 0
  },
  cloeWorm3: {
    position: 'absolute',
    top: `calc(-${theme.spacingInline.xxxs} - ${worm1Overflow / 4}%)`,
    height: `calc(${theme.spacingInline.xs} + ${worm1Overflow + 20}%)`,
    right: `calc(-${theme.spacingInline.xxxs} - 2%)`,
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      height: `calc(${theme.spacingInline.xs} + ${175}%)`,
      right: `-${theme.spacingInline.xxs}`,
      top: `calc(-${theme.spacingInline.xxxs} - ${60}%)`
    }
  },
  hideButton: {
    display: 'flex',
    cursor: 'pointer !important',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  linksContainerHeader: {
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 2
  },
  welcomeSection: {
    position: 'relative',
    zIndex: 2,

    [theme.breakpoints.up('md')]: {
      maxWidth: '36%'
    }
  },
  welcomeTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  welcomeText: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word'
  },
  welcomeSubtitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word'
  }
}))
