import {
  Button,
  Card,
  Grid,
  Modal,
  Text2
} from 'components/design-system'
import makeStyles from './style'
import { useTranslation } from 'react-i18next'

interface ICredentialsPatternModal {
  handleClose: () => void
  open: boolean
}

export const CredentialsPatternModal: React.FC<ICredentialsPatternModal> = ({ handleClose, open }) => {
  const { t } = useTranslation()
  const classes = makeStyles()

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container display='flex' alignItems='center' justifyContent='center' spacing={1}>
        <Grid item xs={8} className={classes.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
            {t('Padrão de credenciais de login da sua escola')}
          </Text2>
        </Grid>
        <Grid container item display='flex' justifyContent='center' className={classes.credentialsCard}>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Text2 fontSize='lg' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark30'>
                {t('O padrão de nome de usuário é')}:
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Text2 fontSize='xs' fontWeight='medium' lineHeight='xxs' mobile='xs' neutral='dark30' className={classes.loginPattern}>
                  {t('primeiro_nome.último_sobrenome.ano_de_nascimento.5_números_aleatórios')}
                </Text2>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Text2 fontSize='lg' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark30'>
                {t('O padrão de senha é')}:
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Text2 fontSize='xs' fontWeight='medium' lineHeight='xxs' mobile='xs' neutral='dark30'>
                  {t('data de nascimento sem barras (ddmmaaaa)')}
                </Text2>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleClose}>
            {t('Concluir consulta')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
