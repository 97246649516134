import makeStyles from './style'
import { useTranslation } from 'react-i18next'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { ToggleButton, ToggleButtonGroup } from 'components/design-system'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined'
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined'
import { atomCurrentClass, atomCurrentTab, atomNoFetchData } from 'pages/ClassManagement/atomStore'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { ManageClassTabs } from 'services'

export const ClassTabs: React.FC = () => {
  const classes = makeStyles()
  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // atoms
  const [currentTab, setCurrentTab] = useAtom(atomCurrentTab)
  const currentClass = useAtomValue(atomCurrentClass)
  const setNoFetchData = useUpdateAtom(atomNoFetchData)

  const allGradesAreFromKindergarten = currentClass?.grades.every(g => g.grade_type === 1)

  const handleCurrentTab = (event: React.MouseEvent<HTMLElement>, newToggleValue: ManageClassTabs | null) => {
    event.preventDefault()
    if (newToggleValue) {
      setNoFetchData(true)
      setCurrentTab(newToggleValue)
    }
  }

  return (
    <div className={classes.tabs}>
      <ToggleButtonGroup className={classes.tabsContainer} id='class_tabs' onChange={handleCurrentTab} value={currentTab} fullWidth exclusive tabRole>
        <ToggleButton data-testid='list_classes_manage_class_teachers' value={ManageClassTabs.teacher} tabRole aria-pressed={currentTab === ManageClassTabs.teacher}>
          <BookOutlinedIcon />
          {(isMobile && currentTab === ManageClassTabs.teacher) || !isMobile ? t('Professores') : ''}
        </ToggleButton>
        <ToggleButton data-testid='list_classes_manage_class_students' value={ManageClassTabs.student} tabRole aria-pressed={currentTab === ManageClassTabs.student}>
          <BackpackOutlinedIcon />
          {(isMobile && currentTab === ManageClassTabs.student) || !isMobile ? t('Estudantes') : ''}
        </ToggleButton>
        {!allGradesAreFromKindergarten && (
          <ToggleButton data-testid='list_classes_manage_class_followup' value={ManageClassTabs.reports} tabRole aria-pressed={currentTab === ManageClassTabs.reports}>
            <RuleFolderOutlinedIcon />
            {(isMobile && currentTab === ManageClassTabs.reports) || !isMobile ? t('Acompanhamento') : ''}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  )
}
