
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { Link } from 'components/design-system'
import { ResourceCaption } from 'components/common'

// https://stackoverflow.com/a/9102270
const youTubeRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
const youtubeRegExpStartTimer = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*(&t=|\?t=)(.*[^#&?s])/
const youtubeRegExpFullTimer = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*(\?start=)(.*[^#&?s]).*(end=)(.*[^#&?s])/
export function getYouTubeVideoIdFromURL(url: string) {
  // We have different Regex for Start and End to a video, because
  // youtube does not allow both tags to be used at the same time
  // if you're using the end tag, start MUST be 00

  // Check if the url has a Full Timestamp
  const fullTimerMatch = url.match(youtubeRegExpFullTimer)
  if (fullTimerMatch) {
    const tFYoutubeLink: string = fullTimerMatch[2]
    const tFStartTimer: string = fullTimerMatch[4]
    const tFEndTimer: string = fullTimerMatch[6]
    return `${tFYoutubeLink}?start=${tFStartTimer}&end=${tFEndTimer}`
  }
  // Checks if the url has a Start Timestamp
  const startTimerMatch = url.match(youtubeRegExpStartTimer)
  if (startTimerMatch) {
    const tSYoutubeLink = startTimerMatch[2]
    const tSStartTimer = startTimerMatch[4]
    return `${tSYoutubeLink}?start=${tSStartTimer}`
  }
  // Checks for url without Timestamp
  const strMatch = url.match(youTubeRegExp)
  return (strMatch?.[2]) ? strMatch[2] : ''
}

export interface IYouTubePlayerProps {
  url: string
  source?: string | null
  description?: string | null
}

export const YouTubePlayer: React.FC<IYouTubePlayerProps> = ({ url, source, description }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const videoId = getYouTubeVideoIdFromURL(url)

  if (videoId) {
    return (
      <div style={{ width: '100%' }}>
        <div className={classes.youTubeWrapper}>
          <div className={classes.iFrameContainer}>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              title='Youtube Video'
            />
          </div>
        </div>
        <ResourceCaption source={source} description={description}></ResourceCaption>
      </div>

    )
  }

  return (
    <div className={classes.errorMessage}>
      {t('Desculpe, não foi possível carregar o vídeo')}
      <div>
        <Link
          variant='primary'
          href={url}
          target='_blank'
        >
          {url}
        </Link>
      </div>
    </div>
  )
}
