import { Card, Checkbox, Grid, Icon, Text2 } from 'components/design-system'
import { ChangeEvent, useEffect, useState } from 'react'
import { ClassListEnum, IClassItem, IClassProfileItem } from 'services'
import makeStyles from './style'
import { useAtom } from 'jotai'
import { atomOriginClasses, atomSchoolPeriod, atomStudentToDelete } from 'pages/ClassManagement/atomStore'
import CloseIcon from '@mui/icons-material/Close'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'

interface IStudentSelectionItemProps {
  classItem: IClassItem
  classListType: ClassListEnum
  student: IClassProfileItem
}

type StudentSelectionItemStates = 'idle' | 'origin' | 'destiny'

export const StudentSelectionItem: React.FC<IStudentSelectionItemProps> = ({ classItem, classListType, student }) => {
  const classes = makeStyles()

  // atoms
  const [originClasses, setOriginClasses] = useAtom(atomOriginClasses)
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const setStudentToDelete = useUpdateAtom(atomStudentToDelete)

  // states
  const [studentSelectionItemState, setStudentSelectionItemState] = useState<StudentSelectionItemStates>('idle')

  useEffect(() => {
    setStudentSelectionItemState(classListType === ClassListEnum.origin ? 'origin' : 'destiny')
  }, [])

  const updateStudent = (event: ChangeEvent<HTMLInputElement>) => {
    if (originClasses?.length && classItem) {
      const newClass: IClassItem | undefined = originClasses.find(c => c.id === classItem.id)
      const newStudentList = newClass?.students

      if (newStudentList?.length && newClass) {
        const studentIndex: number = newStudentList?.findIndex(s => s.user_id === student.user_id)
        newStudentList[studentIndex] = { ...student, checked: event.target.checked }

        const newClassList = [...originClasses]
        const classIndex: number = newClassList.findIndex(c => c.id === classItem.id)
        newClassList[classIndex].students = newStudentList
        setOriginClasses(newClassList)
      }
    }
  }

  const stateMachine = {
    states: {
      idle: null,
      origin: (
        <Card extraClasses={classes.card}>
          <Checkbox
            checked={student.checked ?? false}
            disabled={student.disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => updateStudent(event)}
            label={student.fullname}
            customClasses={classes.checkbox}
            dataTestid='menu_class_management_import_class_origin_study'
          />
        </Card>
      ),
      destiny: (
        <Card extraClasses={classes.card}>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item flex='1'>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark30'>
                {student.fullname}
              </Text2>
            </Grid>
            {!schoolPeriod?.current && (
              <Grid item className={classes.closeIcon}>
                <Icon titleAccess='CloseIcon' size='medium' onClick={() => setStudentToDelete(student.class_sub_id)} data-testid='menu_class_management_import_class_delete_study'>
                  <CloseIcon />
                </Icon>
              </Grid>
            )}
          </Grid>
        </Card>
      )
    }
  }

  return stateMachine.states[studentSelectionItemState]
}
