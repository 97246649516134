import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import useStyle from './style'

export const NewEnrollmentsChartSkeleton: React.FC = () => {
  const classes = useStyle()

  const chartItemHeight = 18
  const chartItemsWidth = [60, 60]

  return (
    <Grid container item xs={12} className={classes.loadingContainer}>
      <Grid container item sx={{ flex: 0 }}>
        <Skeleton variant='circular' width={144} height={144} animation='wave' />
      </Grid>
      <Grid container item className={classes.loadingChart}>
        {chartItemsWidth.map(width => (
          <Skeleton variant='rectangular' width={`${width}%`} height={chartItemHeight} animation='wave' />
        ))}
      </Grid>
    </Grid>
  )
}
