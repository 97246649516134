/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createRef } from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { Text, Icon, Button } from 'components/design-system'
import { StudentCard } from './components/StudentCard'
import { useTranslation } from 'react-i18next'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { StudentSituation } from './components'
import { IStudentsPerformance } from 'services/types'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import useStyle from '../../styles'
import { CloeTooltip } from 'components/common'
import { useUpdateAtom } from 'jotai/utils'
import { currentStudentPerformanceAndDataAtom, reportStateAtom } from 'pages/ClassManagement/atomStore'

interface IReportRowError {
  error?: boolean
}

interface IReportRowItem {
  studentPerformanceAndData: IStudentsPerformance
}

const ReportRowError: React.FC<IReportRowError> = ({ error }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const ErrorIcon = () => (
    <Icon titleAccess={t('Ícone de error')} size='medium' >
      <ReportProblemOutlinedIcon />
    </Icon>
  )

  return (
    <Grid container item className={classes.reportsRow}>
      <Grid container alignItems='center' rowSpacing={isMobile ? 2 : 0} className={classes.reportsRowContent}>
        <Grid container item xs={12} md={3}>
          <Grid item>
            {error && <ErrorIcon />}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Participação')}
            </Text>
          </Grid>
          <Grid item>
            {error && <ErrorIcon />}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Média')}
            </Text>
          </Grid>
          <Grid item>
            {error && <ErrorIcon />}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={2} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Situação')}
            </Text>
          </Grid>
          <Grid item>
            {error && <ErrorIcon />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReportRowItem: React.FC<IReportRowItem> = ({ studentPerformanceAndData }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const individualReportButtonRef = createRef<HTMLDivElement>()

  const {
    performance,
    user,
    user_school_profile: userSchoolProfile
  } = studentPerformanceAndData

  const {
    dec_performance: average,
    nr_situation: situation,
    per_participation: participation
  } = performance ?? {}

  const {
    name,
    surname,
    username: studentNick
  } = user

  const studentName = `${name} ${surname}`

  const {
    id: userSchoolProfileId
  } = userSchoolProfile

  // atoms
  const setCurrentStudentPerformanceAndData = useUpdateAtom(currentStudentPerformanceAndDataAtom)
  const setReportState = useUpdateAtom(reportStateAtom)

  const ErrorIcon = () => (
    <Icon titleAccess={t('Ícone de error')} size='medium' >
      <ReportProblemOutlinedIcon />
    </Icon>
  )

  const roundNumber = (num: number) => Math.round(num * 100) / 100

  const scrollToClassTabs = () => {
    const element = document.getElementById('class_tabs')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleIndividualReport = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    individualReportButtonRef?.current?.click()
    setReportState('studentReport')
    setCurrentStudentPerformanceAndData(studentPerformanceAndData)
    scrollToClassTabs()
  }

  const IndividualReportButton: React.FC = () => (
    <>
      <div ref={individualReportButtonRef} data-testid='followup_individual_report' hidden />
      <Button className={classes.individualReportButton} onClick={handleIndividualReport}>
        <Grid container alignItems='center' justifyContent='center' columnSpacing={2}>
          <Grid item className={classes.reportIcon}>
            <InsertChartOutlinedIcon />
          </Grid>
          {isMobile && (
            <Grid item>
              {t('Relatório individual')}
            </Grid>
          )}
        </Grid>
      </Button>
    </>
  )

  return (
    <Grid container item className={classes.reportsRow}>
      <Grid container alignItems='center' rowSpacing={isMobile ? 2 : 0} className={classes.reportsRowContent}>
        <Grid container item xs={12} md={3}>
          <Grid item>
            {
              (!!studentName && !!studentNick && !!userSchoolProfileId)
                ? (
                  <StudentCard.Item
                    studentName={studentName}
                    studentNick={studentNick}
                  />
                )
                : <ErrorIcon />
            }
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Participação')}
            </Text>
          </Grid>
          <Grid item>
            <Text className={classes.listHeaderText}>
              {participation !== null && typeof participation !== 'undefined' ? `${roundNumber(participation * 100)}%` : '-'}
            </Text>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Média')}
            </Text>
          </Grid>
          <Grid item>
            <Text className={classes.listHeaderText}>
              {average?.toLocaleString('pt-BR') ?? '-'}
            </Text>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={2} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
              {t('Situação')}
            </Text>
          </Grid>
          <Grid item>
            {situation
              ? <StudentSituation status={situation} />
              : <Text className={classes.listHeaderText}>-</Text>
            }
          </Grid>
        </Grid>
        <Grid container item xs={12} md={1} justifyContent={isMobile ? 'space-between' : 'flex-end'}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            {isMobile
              ? <IndividualReportButton />
              : (
                <CloeTooltip
                  className={classes.tooltip}
                  component={<IndividualReportButton />}
                  placement='top'
                  text={t('Relatório individual')}
                />
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReportRowSkeleton: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container item marginTop={isMobile ? 2 : 0} className={classes.reportsRow}>
      <Grid container alignItems='center' className={classes.reportsRowContent}>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <StudentCard.Skeleton />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Skeleton variant='text' sx={{ width: '100px' }} height={60} animation='wave' />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Skeleton variant='text' sx={{ width: '100px' }} height={60} animation='wave' />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={2} justifyContent={isMobile ? 'space-between' : 'center'}>
          <Grid item>
            <Skeleton variant='text' sx={{ width: '100px' }} height={60} animation='wave' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ReportRow = {
  Error: ReportRowError,
  Item: ReportRowItem,
  Skeleton: ReportRowSkeleton
}
