import { getUserProfileBlockedConfirmation, IApiResponse, IMessageErrors } from 'services'
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import Storage from 'utils/storage'
import { checkBlockedUser } from 'helpers/CheckBlockedUser'
import { CLOE_WEB, LOGIN } from 'navigation/CONSTANTS'
import { toast } from 'components/design-system/Toast/manager'

const client = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL as string })
interface IRequestOptions extends AxiosRequestConfig {
  isLogin?: boolean
  isService?: boolean
  removeBearer?: boolean
  customMime?: string
  signal?: AbortSignal
  disableToast?: boolean
}

interface IBusinessRules {
  [index: number | string]: {
    message?: string
    action?: () => void
  }
}

const BusinessRules: IBusinessRules = {
  400: {}, // Esse código deve ter mensagem vinda do backend
  403: {
    message: 'Ação não permitida.'
  },
  401: {
    message: 'Sessão Expirada. Redirecionando ao login...',
    action: () => {
      setTimeout(() => {
        Storage.token.remove()
        Storage.props.remove()
        window.location.href = LOGIN
      }, (3000))
    }
  },
  500: {
    message: 'Ops... Algo deu errado, tente novamente!'
  }
}

const arrayErrors = (message: any) => {
  if (Array.isArray(message)) {
    const errorMessages: IMessageErrors[] = [].concat.apply([], message?.map(
      (m: any) => m?.messages
    ))
    if (Array.isArray(errorMessages)) {
      if (errorMessages[0]) {
        return errorMessages.reduce((acc, cur) => `${acc}` + cur.message, '')
      } else return
    }
    return errorMessages
  }
  return message
}

export const request = async <T>(options: IRequestOptions): Promise<IApiResponse<T>> => {
  const { isLogin, isService, removeBearer, customMime, disableToast } = options

  if (!isLogin) {
    client.defaults.headers.common.Authorization = `Bearer ${Storage.token.get()}`

    if (Storage.props.getSubscription()) {
      client.defaults.headers.common.Class = Storage.props.getSubscription()?.class?.id
    }

    if (Storage.props.getProfile()) {
      client.defaults.headers.common.UserSchoolProfile = Storage.props.getProfile()?.id
    }

    //  Added isService to prevent sending these headers
    if (isService) {
      delete client.defaults.headers.common.UserSchoolProfile
      delete client.defaults.headers.common.Class
    }

    // Removing bearer because AWS upload service doesn't accept
    if (removeBearer) {
      delete client.defaults.headers.common.Authorization
    }

    if (customMime) {
      client.defaults.headers.common['content-type'] = `${customMime}; charset=utf-8`
    }
  } else {
    delete client.defaults.headers.common.Authorization
    delete client.defaults.headers.common.UserSchoolProfile
    delete client.defaults.headers.common.Class
  }

  const onSuccess = (response: AxiosResponse<T>) => {
    return {
      success: true,
      data: response.data,
      status: response.status
    }
  }
  const onError = (error: any) => {
    if (error.response) {
      if (error.response.status in BusinessRules) {
        const _businessRules = BusinessRules[error.response.status]
        const msg = arrayErrors(_businessRules.message ?? error.response.data.message)

        if (msg && typeof msg === 'string' && !disableToast) {
          toast.handler({
            content: msg,
            duration: 10000,
            severity: 'error'
          })
        }

        if (_businessRules.action) {
          _businessRules.action()
        }
      }
    } else if (error.request) {
      console.warn('request', error.request)
    } else {
      console.warn('Error', error.message)
    }

    if (Array.isArray(error?.response?.data?.message)) {
      const errorMessages: IMessageErrors[] = [].concat.apply([], error?.response?.data?.message?.map(
        (m: any) => m?.messages
      ))

      return {
        success: false,
        data: error.data,
        errors: errorMessages
      }
    }
    return {
      success: false,
      data: error?.data,
      errors: [error?.response?.data],
      message: error?.response?.data?.message ?? error.message,
      status: error?.response?.status
    }
  }

  return await client(options).then(onSuccess).catch(onError)
}

let hasCheckedUserStatus = false

client.interceptors.response.use((response) => {
  return response
}, async (error: AxiosError) => {
  if (error?.response?.status === 401 && !hasCheckedUserStatus) {
    const userSchoolProfileId = error?.config?.headers?.UserSchoolProfile
    if (userSchoolProfileId) {
      delete client.defaults.headers.common.Authorization

      getUserProfileBlockedConfirmation(+userSchoolProfileId)
        .then((response) => {
          if (response?.data?.user?.blocked) {
            hasCheckedUserStatus = true
            checkBlockedUser.setIsUserBlocked(true)
          }
        })
        .catch(() => {
          Storage.token.remove()
          Storage.props.remove()
          window.location.href = `${CLOE_WEB}/login`
        })
        .finally(() => {
          hasCheckedUserStatus = false
        })
    }
  }
  return await Promise.reject(error)
})
