import { useStore } from 'store'
import { Card, Grid } from 'components/design-system'
import { ChangeEvent, useEffect, useState } from 'react'
import { IGradeType, IGrade, IClassGetStudentsGroupingByClassRequest, ClassListEnum } from 'services'
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { atomSchoolPeriod, atomReloadDestinyList, atomOriginClasses, atomDestinyClasses, atomDisplayCreateClassCard, atomSchoolPeriods } from 'pages/ClassManagement/atomStore'
import { GradeFilter, SchoolYearFilter, SegmentFilter } from 'pages/ClassManagement/components'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'

interface IClassFilterGroup {
  classListType: ClassListEnum
  onFilter: (filterPayload: IClassGetStudentsGroupingByClassRequest) => void
  onResetFilters: (classListType: ClassListEnum) => void
}

export const ClassFilterGroup = ({ classListType, onFilter, onResetFilters }: IClassFilterGroup) => {
  const { school } = useStore()
  const { allGradeTypeData } = useFetchAllGradeTypes()

  // atoms
  const resetOriginClasses = useResetAtom(atomOriginClasses)
  const resetDestinyClasses = useResetAtom(atomDestinyClasses)
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const reloadDestinyList = useAtomValue(atomReloadDestinyList)
  const setDisplayCreateClassCard = useUpdateAtom(atomDisplayCreateClassCard)
  const schoolPeriods = useAtomValue(atomSchoolPeriods)

  // states
  const [grades, setGrades] = useState<IGrade[] | undefined>(undefined)
  const [selectedGradeType, setSelectedGradeType] = useState<IGradeType | undefined>(undefined)
  const [selectedGrade, setSelectedGrade] = useState<IGrade | undefined>(undefined)
  const [selectedSchoolPeriodId, setSelectedSchoolPeriodId] = useState<string>('')

  useEffect(() => {
    if (classListType === ClassListEnum.origin) {
      resetOriginClasses()
      onResetFilters(ClassListEnum.origin)
    } else {
      resetDestinyClasses()
      onResetFilters(ClassListEnum.destiny)
      setDisplayCreateClassCard(false)
    }
  }, [selectedGradeType, selectedGrade, selectedSchoolPeriodId])

  const handleSelectSchoolPeriod = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSchoolPeriodId(e.target.value)
    setSelectedGradeType(undefined)
    setSelectedGrade(undefined)
  }

  const handleSelectGradeType = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelectedGradeType = allGradeTypeData?.find(gt => Number(gt.id) === Number(e.target.value))
    setSelectedGradeType(newSelectedGradeType)
    setSelectedGrade(undefined)
  }

  const handleSelectGrade = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelectedGrade = grades?.find(g => Number(g.id) === Number(e.target.value))
    setSelectedGrade(newSelectedGrade)
  }

  const clearSelectInputs = () => {
    if (classListType === ClassListEnum.origin) {
      setSelectedSchoolPeriodId('')
    }
    setSelectedGradeType(undefined)
    setSelectedGrade(undefined)
  }

  const handleExposeFilters = () => {
    const schoolPeriodId = classListType === ClassListEnum.destiny && schoolPeriod?.id
      ? schoolPeriod.id
      : +selectedSchoolPeriodId

    if (school?.id && schoolPeriodId && selectedGrade && selectedGradeType && allGradeTypeData) {
      const payload: IClassGetStudentsGroupingByClassRequest = {
        schoolId: +school.id,
        school_period: schoolPeriodId,
        grade: +selectedGrade.id,
        grade_name: selectedGrade.name,
        grade_type: +selectedGradeType.id,
        grade_type_name: selectedGradeType.name
      }
      if (onFilter) {
        onFilter(payload)
      }
    }
  }

  useEffect(() => {
    clearSelectInputs()
  }, [])

  useEffect(() => {
    if (allGradeTypeData && selectedGradeType) {
      const _selectedGradeType = allGradeTypeData.find((gradeTypeItem) => gradeTypeItem.id === +selectedGradeType.id)
      if (_selectedGradeType) {
        setGrades(_selectedGradeType.grades)
      }
    }
  }, [selectedGradeType])

  useEffect(() => {
    if (selectedGrade?.id) {
      handleExposeFilters()
    }
  }, [selectedGrade])

  useEffect(() => {
    if (reloadDestinyList) {
      handleExposeFilters()
    }
  }, [reloadDestinyList])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <SchoolYearFilter
            classListType={classListType}
            handleSelectSchoolPeriod={handleSelectSchoolPeriod}
            schoolPeriods={schoolPeriods}
            selectedSchoolPeriodId={selectedSchoolPeriodId}
            originDataTestId='menu_class_management_import_class_origin_year'
            destinyDataTestId='menu_class_management_import_class_destiny_school'
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SegmentFilter
                classListType={classListType}
                gradeTypes={allGradeTypeData}
                handleSelectGradeType={handleSelectGradeType}
                selectedGradeTypeId={selectedGradeType ? String(selectedGradeType?.id) : ''}
                originDataTestId='menu_class_management_import_class_origin_segment'
                destinyDataTestId='menu_class_management_import_class_destiny_segment'
              />
            </Grid>
            <Grid item xs={6}>
              <GradeFilter
                classListType={classListType}
                grades={grades}
                handleSelectGrade={handleSelectGrade}
                selectedGradeId={selectedGrade ? String(selectedGrade?.id) : ''}
                originDataTestId='menu_class_management_import_class_origin_grade_year'
                destinyDataTestId='menu_class_management_import_class_destiny_year_grade'
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
