import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import baseScreenBackgroundImage from 'assets/base-screen-background.svg'

export default makeStyles((theme: Theme) => createStyles({
  baseScreenContainer: {
    position: 'relative',
    height: '100%',
    backgroundImage: `url(${baseScreenBackgroundImage})`,
    backgroundSize: 'cover',
    overflow: 'auto',

    // mobile
    [theme.breakpoints.down('sm')]: {
      overflow: 'unset',
      height: 'auto',
      background: theme.colors.neutral.lightBase
    }
  },
  baseScreenWrapper: {
    width: 'calc(100% - 40px)',
    paddingLeft: 70,
    margin: '0 auto',
    minHeight: 'calc(100% - 88px)',
    marginTop: 10,
    zIndex: -1,

    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      marginTop: 0,
      paddingLeft: 0,
      paddingTop: '64px'
    }
  },
  scrollTopBtn: {
    display: 'none',
    position: 'fixed',
    width: '40px',
    height: '40px',
    bottom: '90px',
    right: '16px',
    zIndex: 9,
    backgroundColor: theme.colorBrand.medium,
    color: 'white',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',
    border: 'none',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  wrapper: {
    position: 'absolute',
    borderRadius: '16px 16px 0px 0px',
    backgroundColor: 'transparent',
    width: 'calc(100% - 128px)',
    left: '96px',
    top: '105px',
    filter: 'drop-shadow(0px 16px 56px rgba(20, 20, 20, 0.16))',
    [theme.breakpoints.down('sm')]: {
      left: '0',
      width: '100%',
      top: '80px',
      borderRadius: '0',
      boxShadow: 'none'
    }
  }
}))
