import { Card, Text2, Button, Grid, Loading, TextField } from 'components/design-system'
import useStyles from './style'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useStore } from 'store'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useEditSchoolClass } from 'pages/ClassManagement/hooks'
import { IClassRequest, ISimpleGrade } from 'services'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useAtomValue, useUpdateAtom, useResetAtom } from 'jotai/utils'
import { atomIsRenamingClass, atomReloadClasslist, atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import { IconButton, Typography } from '@mui/material'
import { ActionContextEnum, ActionsControlCenter, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { ClipBoardCopy } from '../ClipBoardCopy'

export interface IClassProps {
  name: string
  classId: number
  studentCounter: number
  teacherCounter: number
  onClick?: any
  disabled?: boolean
  classGrades: ISimpleGrade[]
}

interface IEditClass {
  studentClassCardName: string
}

type ClassCardStates = 'list' | 'editingClass'

export const ClassCard: React.FC<IClassProps> = ({ name, classId, studentCounter, teacherCounter, disabled, classGrades, ...props }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const { error, editClassHandler } = useEditSchoolClass()

  // state
  const [loadingRenamingClass, setLoadingRenamingClass] = useState<boolean>(false)

  // state machine
  const [classCardState, setClassCardState] = useState<ClassCardStates>('list')

  // atoms
  const schoolPeriod = useAtomValue(atomSchoolPeriod)
  const resetReloadClassList = useResetAtom(atomReloadClasslist)
  const setReloadClassList = useUpdateAtom(atomReloadClasslist)
  const setIsRenamingClass = useUpdateAtom(atomIsRenamingClass)
  const resetIsRenamingClass = useResetAtom(atomIsRenamingClass)

  const studentClassCardNameSchema = Yup.object().shape({
    studentClassCardName: Yup.string().required('Campo obrigatório')
  })

  const initialValuesForm = {
    studentClassCardName: name ?? ''
  }

  const handleRenameClass = async (values: IEditClass) => {
    if (classGrades && school?.id && schoolPeriod?.id) {
      setLoadingRenamingClass(true)
      const gradesList = classGrades.map(c => c.id)
      const payload: IClassRequest = {
        name: values.studentClassCardName.trim(),
        student_limit: 30,
        school: school.id,
        grades: gradesList as [number],
        school_period: schoolPeriod.id
      }
      try {
        const response = await editClassHandler(payload, { classId, schoolId: school.id })

        if (response?.success) {
          createSnackbar({ content: 'Turma editada com sucesso', severity: 'success' })
          setClassCardState('list')
          setReloadClassList(true)
        }

        if (error) {
          createSnackbar({ content: error, severity: 'error' })
        }
      } catch (err) {
        if (err instanceof Error) {
          createSnackbar({ content: err.message, severity: 'error' })
        }
      } finally {
        setLoadingRenamingClass(false)
        resetReloadClassList()
        resetIsRenamingClass()
      }
    }
  }

  const handleCancelEditClassName = () => {
    setClassCardState('list')
    resetIsRenamingClass()
  }

  const handleEditClassName = () => {
    setClassCardState('editingClass')
    setIsRenamingClass(true)
  }

  const classCardStateMachine = {
    states: {
      list: (
        <Grid container spacing={1}>
          <Grid container item alignItems='center' spacing={2} wrap='nowrap' className={styles.classNameRow}>
            <Grid item>
              <SchoolOutlinedIcon sx={{ color: disabled ? '#b3b3b3' : '#5B5B5B' }} fontSize='medium' />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>
                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xxs' mobile='sm' neutral={`${disabled ? 'light30' : 'dark30'}`}>
                  {name}
                </Text2>
              </Typography>
            </Grid>
            <ActionsControlCenter
              ctx={ActionContextEnum.schoolClassManager}
              action={SchoolClassManagerActionsEnum.edit_name}
              options={{ fullFeaturesDisabler: disabled }}
            >
              <Grid item>
                <IconButton className={styles.editClassIcon} disabled={disabled} onClick={handleEditClassName}>
                  <EditOutlinedIcon
                    fontSize='medium'
                    titleAccess='Editar nome da turma'
                  />
                </IconButton>
              </Grid>
            </ActionsControlCenter>
          </Grid>
          <Grid container item alignItems='center' columnSpacing={2} className={styles.classIdRow}>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xxs' mobile='sm' neutral={`${disabled ? 'light30' : 'dark30'}`}>Id: {classId}</Text2>
            </Grid>
            <Grid item>
              <ActionsControlCenter
                ctx={ActionContextEnum.schoolClassManager}
                action={SchoolClassManagerActionsEnum.copy_id_class_card}
                options={{ fullFeaturesDisabler: disabled }}
              >
                <ClipBoardCopy copyItem={String(classId)} dataTestId='menu_students_list_classes_copy_class_id' />
              </ActionsControlCenter>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>
                <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' neutral='light40'>
                  {studentCounter} {t('estudantes')}
                </Text2>
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>
                <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' neutral='light40'>
                  {teacherCounter} {t('professores')}
                </Text2>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <ActionsControlCenter
              ctx={ActionContextEnum.schoolClassManager}
              action={SchoolClassManagerActionsEnum.manage_class}
              options={{ fullFeaturesDisabler: disabled }}
              fallback={(
                <Button fullWidth variant='primary' size='small' onClick={props.onClick} className={styles.button} data-testid='menu_students_list_classes_manage_class'>
                  {t('Visualizar turma')}
                </Button>
              )}
            >
              <Button fullWidth variant='primary' size='small' onClick={props.onClick} className={styles.button} data-testid='menu_students_list_classes_manage_class'>
                {t('Gerenciar turma')}
              </Button>
            </ActionsControlCenter>
          </Grid>
        </Grid>
      ),
      editingClass: (
        <Grid container spacing={1}>
          <Grid container item spacing={2} className={styles.classNameRow}>
            <Grid item>
              <SchoolOutlinedIcon sx={{ color: disabled ? '#b3b3b3' : '#5B5B5B' }} fontSize='medium' />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>
                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xxs' mobile='sm' neutral={`${disabled ? 'light30' : 'dark30'}`}>
                  {name}
                </Text2>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValuesForm}
              validationSchema={studentClassCardNameSchema}
              onSubmit={async (values) => { handleRenameClass(values) }}
            >
              {({ values, errors, handleSubmit, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item xs className={styles.classEditNameRow}>
                      <Field
                        component={TextField}
                        name='studentClassCardName'
                        id='studentClassCardName'
                        variant='filled'
                        label={t('Nome da turma*')}
                        value={values.studentClassCardName}
                        onChange={handleChange}
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        assistivetext={errors.studentClassCardName ? t('Campo obrigatório') : undefined}
                        error={Boolean(errors.studentClassCardName)}
                        className={styles.studentClassCardNameInput}
                        autoFocus
                      >
                      </Field>
                    </Grid>
                    <Grid container item columnSpacing={2} className={styles.editClassButtonGroup}>
                      <Grid item xs={6}>
                        <Button fullWidth variant='outlined' size='small' onClick={handleCancelEditClassName} className={styles.button} data-testid='menu_class_management_cancel_edit_name_class'>
                          {t('Cancelar')}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        {
                          loadingRenamingClass
                            ? <Loading size={24} sx={{ marginTop: '8px' }} />
                            : <Button
                              fullWidth
                              variant='primary'
                              size='small'
                              className={styles.button}
                              onClick={() => handleSubmit()}
                              data-testid='menu_class_management_save_edit_name_class'
                            >
                              {t('Salvar')}
                            </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Card extraClasses={`${styles.root} ${disabled ? 'disabled' : ''}`} data-testid='classCard-component'>
      {classCardStateMachine.states[classCardState]}
    </Card>
  )
}
