
import { Image, Text, View } from '@react-pdf/renderer'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { twoDigitFormatter } from 'utils'
import { useTranslation } from 'react-i18next'
import { accountCircleOutlinedIcon, noAccountsOutlinedIcon } from 'assets/base64Icons'
import { commonStyles } from '../../style'
import { styles } from './style'

interface IUserListProps {
  profile: UserSchoolProfileTypeEnum
  type: 'active' | 'inactive'
  users: IUserResponse[]
}

export const UserList: React.FC<IUserListProps> = ({ profile, type, users }) => {
  const { t } = useTranslation()

  const profileText = profile === UserSchoolProfileTypeEnum.teacher ? 'Professores' : 'Estudantes'
  const totalUsers: string = twoDigitFormatter(users.length)

  const titleTypes = {
    active: (
      <View style={commonStyles.sectionTitle}>
        <Image style={commonStyles.titleIcon} src={accountCircleOutlinedIcon} />
        <Text style={commonStyles.positiveTitle}>{`${profileText} ${t('Ativos')} (${totalUsers})`}</Text>
      </View>
    ),
    inactive: (
      <View style={commonStyles.sectionTitle}>
        <Image style={commonStyles.titleIcon} src={noAccountsOutlinedIcon} />
        <Text style={commonStyles.negativeTitle}>{`${profileText} ${t('Inativos')} (${totalUsers})`}</Text>
      </View>
    )
  }

  return users.length
    ? (
      <View style={commonStyles.section}>
        {titleTypes[type]}

        <View style={styles.listHeader}>
          <View style={commonStyles.flex4}>
            <Text>{t('Nome')}</Text>
          </View>
          <View style={commonStyles.flex4}>
            <Text>{t('Nome de usuário')}</Text>
          </View>
          <View style={commonStyles.flex1}>
            <Text>{t('Data de nascimento')}</Text>
          </View>
        </View>

        {users.map((user, index) =>
          <View key={user.id} style={[commonStyles.outlinedCard, styles.outlinedUserContentCard, index % 2 ? commonStyles.grayBackgound : commonStyles.whiteBackgound]} minPresenceAhead={1}>
            <View style={[commonStyles.flex4, commonStyles.medium]}>
              <Text>{`${user.name} ${user.surname}`}</Text>
            </View>
            <View style={[commonStyles.flex4, commonStyles.medium]}>
              <Text>{user.username}</Text>
            </View>
            <View style={[commonStyles.flex1, commonStyles.medium]}>
              <Text>{format(parse(user.birth_date, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy')}</Text>
            </View>
          </View>
        )}
      </View>
    )
    : null
}
