import { atom } from 'jotai'
import { IGrades, IUniqueDisciplines } from '../hooks'
import { IDisciplineResponse, IGradeTypesResponse, IProfilesSessionResponse } from 'services/types'
import { ISchoolDisciplinesResponse } from 'services/types/school'

export const gradesAtom = atom<IGrades[]>([])
export const segmentsAtom = atom<IGradeTypesResponse[]>([])
export const allAvailableDisciplinesAtom = atom<IUniqueDisciplines[]>([])
export const selectedDisciplinesAtom = atom<IDisciplineResponse[]>([])
export const lastFetchTimestampAtom = atom<number | null>(null)
export const lastFetchProfileAtom = atom<IProfilesSessionResponse | null>(null)
export const disciplinesPerSegmentGradeAtom = atom<ISchoolDisciplinesResponse[]>([])
