
import { Image, Text, View } from '@react-pdf/renderer'
import { IClassResponse } from 'services'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { cloeLogo } from 'assets/base64Images'
import { commonStyles } from '../../style'
import { styles } from './style'

interface IHeaderProps {
  currentClass: IClassResponse
}

export const Header: React.FC<IHeaderProps> = ({ currentClass }) => {
  const { t } = useTranslation()
  const { school } = useStore()

  return (
    <View style={[styles.header, commonStyles.spaceBetween]} fixed>
      <View>
        <Image style={styles.cloeLogo} src={cloeLogo} />
      </View>
      <View style={[commonStyles.pill, commonStyles.displayFlexRow]}>
        <View>
          <Text style={commonStyles.pillLabel} render={({ pageNumber }) => (pageNumber > 1 && t('Turma'))} />
          <Text style={commonStyles.pillName} render={({ pageNumber }) => (pageNumber > 1 && currentClass.name)} />
        </View>
        <View render={({ pageNumber }) => (pageNumber > 1 && <Text style={{ width: 32 }} />)} />
        <View>
          <Text style={commonStyles.pillLabel}>{t('Escola')}</Text>
          <Text style={commonStyles.pillName}>{school?.name}</Text>
        </View>
      </View>
    </View>
  )
}
