import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiCard-root': {
      width: '100%',
      borderRadius: theme.borderRadius.sm,
      borderColor: theme.colors.neutral.light10,
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none'
    },
    '& .MuiCardContent-root': {
      padding: '12px'
    },
    '& .MuiCardContent-root:last-child': {
      padding: '12px'
    }
  },
  cardMediaRoot: {
    '&.MuiCardMedia-root': {
      position: 'relative'
    }
  },
  cardMediaRootDisabled: {
    filter: 'grayscale(100%)'
  },
  tag: {
    marginLeft: theme.spacingStack.xxxs,
    marginTop: `-${theme.spacingStack.sm}`,
    position: 'absolute'
  }
}))
